import {Action, ExecutionActionType, MakeAction, Opponent, Parameter} from "../endpoints/mock";

export function isAddEffectAction(action: Action): action is {
  type: ExecutionActionType;
  effectUid: string;
  effectId: number;
  duration: number;
  target: Opponent;
} {
  return 'effectId' in action && 'effectUid' in action && 'duration' in action && 'target' in action;
}

export function isChangeParametersAction(action: Action): action is {
  type: ExecutionActionType;
  target: Opponent;
  parameter: Parameter;
  value: number;
} {
  return 'parameter' in action && 'value' in action && 'target' in action;
}

export function isUpdateCardsAction(action: Action): action is {
  type: ExecutionActionType;
  target: Opponent;
  cardIds: number[];
} {
  return 'cardIds' in action && 'target' in action;
}

export function isDiscardCardsAction(action: Action): action is {
  type: ExecutionActionType;
  target: Opponent;
  cardIds: number[];
  value: number;
} {
  return 'cardIds' in action && 'target' in action && 'value' in action;
}

export function isShuffleCardsAction(action: Action): action is {
  type: ExecutionActionType;
  target: Opponent;
  value: number;
} {
  return 'target' in action && 'value' in action;
}


export function isNoEffectAction(action: Action): action is {
  type: ExecutionActionType;
  target: Opponent;
  value: number;
} {
  return 'type' in action && 'target' in action;
}


