import React from "react";

export const ProgressBar = ({
  current,
  total,
  color,
}: {
  current: number;
  total: number;
  color?: string;
}) => {
  // Обчислюємо ширину прогрес бару в процентах
  const progressPercentage = (current / total) * 100;

  return (
    <div className="relative h-4 w-full rounded-[3px] shadow-inner bg-gradient-to-r from-[#47403A] via-[#DDD7CD] to-[#47403A] p-[1px]">
      <div className="bg-[#281D1D]">
        <div
          className={`relative h-3.5 rounded-sm ${color}`}
          style={{
            width: `${progressPercentage}%`,
            background: !color
              ? "linear-gradient(to top, #C6021D 0%, #580824 25%, #E3001C 100%)"
              : "",
          }}
        >
          {!color && (
            <div
              className={`absolute h-[2px] w-full top-[35%]`}
              style={{
                background:
                  "linear-gradient(to right, #ffffff00 0%, #F04D32 50%, #ffffff00 100%)",
              }}
            ></div>
          )}
        </div>
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-white text-sm text-stroke-small">{`${current}/${total}`}</span>
      </div>
    </div>
  );
};
