import { useCallback } from "react";
import { useTelegram } from "./useTelegram"; // імпорт вашого хука useTelegram
import { getMessageToShare } from "../endpoints/botEndpoints";

/**
 * Хук, що повертає функцію для шарингу меседжу через Telegram WebApp.
 * @param onSuccess - функція, яку викликаємо, якщо шаринг завершився успішно
 */
export function useTelegramShareMessage(onSuccess?: () => Promise<void>) {
  const { tg, userId } = useTelegram();

  const handleShare = useCallback(async () => {
    // await onSuccess?.()
    try {
      // 1. Отримуємо ID повідомлення (та іншу потрібну інфу) з бекенду
      const result = await getMessageToShare({
        clientId: userId,
        options: { allowGroupChats: true },
      });

      if (!result || !tg) return;
      // 2. «Промісифікуємо» виклик tg.shareMessage()
      const shareResult: boolean = await new Promise((resolve, reject) => {
        const test = tg.shareMessage(result.id, (res: boolean) => {
          console.log("test 1");
          // res === true - користувач погодився, false - скасовано
          if (res) {
            console.log("test 3");
            resolve(true);
          } else {
            console.log("test 4");
            reject(false);
          }
          console.log("test 2", test);
          console.log("we are hjere");
        });
      });

      // 3. Якщо користувач поділився повідомленням
      if (shareResult) {
        await onSuccess?.();
        console.log("Message shared successfully!");
      }
    } catch (error) {
      console.warn("Message sharing canceled or failed", error);
    }
  }, [tg, userId, onSuccess]);

  return { handleShare };
}
