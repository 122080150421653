import React, { useState, useEffect, useRef } from "react";
import { CardProps } from "../../interfaces/card";
import { ICard, CardType, EffectType, ISkill, level, Opponent } from "../../endpoints/mock";
import useFitText from "use-fit-text";
import { useTranslation } from "react-i18next";
import CardBuilderComponent from "./CardComponentBuilder";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import i18next from "i18next";

export const Card: React.FC<CardProps> = (props) => {
  // console.log('INIT CARD COMPONENT->->', props);
  const {t} = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cards = useSelector((state: RootState) => state.appConfig.cards);
  const [card, setCard] = useState<ICard & { lvl: number }>(
    {} as unknown as ICard & { lvl: number }
  );

  //console.log('INFO IN CArD', props)

  type ModifiedLevel = {
    animationCount?: number;
    value: number;
    target: "enemy" | "self";
    effectType: EffectType;
  };

  interface EffectDetails {
    whiteColor: "white";
    brownColor: "brown";
    greenColor: "green";
    redColor: "red";
    yellowColor: "yellow";
    blueColor: "blue";
    color: string;
    // name: string;
    enemy?: { value: number; animationCount?: number };
    self?: { value: number };
  }

  const getEffectDetails = (effect: ISkill): EffectDetails => {
    const effectMap: { [key in EffectType]: { color: string; name: string } } =
      {
        [EffectType.defense]: {color: "green", name: "Defense"},
        [EffectType.damage]: {color: "red", name: "Damage"},
        [EffectType.gainMaxHp]: {color: "green", name: "Health"},
        [EffectType.doubleDamage]: {color: "orange", name: "Double Damage"},
        [EffectType.heal]: {color: "blue", name: "Heal"},
        [EffectType.triggeredByEnemiesAttackCard]: {
          color: "purple",
          name: "Triggered by Enemy Attack",
        },
        [EffectType.returnDamage]: {color: "pink", name: "Return Damage"},
        [EffectType.repeatableDamage]: {
          color: "red",
          name: "Repeatable Damage",
        },
        [EffectType.getCard]: {color: "yellow", name: "Get Card"},
        [EffectType.shuffle]: {color: "cyan", name: "Shuffle"},
        [EffectType.discard]: {color: "black", name: "Discard"},
        [EffectType.lessCardPerRound]: {
          color: "lightgrey",
          name: "Less Card Per Round",
        },
        [EffectType.piercingDamage]: {
          color: "red",
          name: "Piercing Damage",
        },
        [EffectType.negateAttack]: {
          color: "darkgreen",
          name: "Negate Attack",
        },
        [EffectType.damageEqualPercentCurrentHealth]: {
          color: "red",
          name: "Damage Equal Percent Current Health",
        },
        [EffectType.exiled]: {color: "darkpurple", name: "Exiled"},
        [EffectType.addActionPoint]: {
          color: "blue",
          name: "Add Action Point",
        },
        [EffectType.copyAllFromHandByTypeAndShuffle]: {
          color: "silver",
          name: "Copy All From Hand By Type And Shuffle",
        },
        [EffectType.lessDamage]: {color: "lightred", name: "Less Damage"},
        [EffectType.additionalDamage]: {
          color: "darkorange",
          name: "Additional Damage",
        },
        [EffectType.additionalDefense]: {
          color: "green",
          name: "Additional Defense",
        },
        [EffectType.returnDamageByHandSize]: {
          color: "brown",
          name: "Return Damage By Hand Size",
        },
        [EffectType.damagePerLostHP]: {
          color: "darkred",
          name: "Damage Per Lost HP",
        },
        [EffectType.damagePerEquiped]: {
          color: "darkred",
          name: "Damage Per Equipped",
        },
        [EffectType.copyPreviousEffects]: {
          color: "grey",
          name: "Copy Previous Effects",
        },
        [EffectType.viewCardsandKeep]: {
          color: "blue",
          name: "View Cards and Keep",
        },
        [EffectType.drawCardPerUseATK]: {
          color: "green",
          name: "Draw Card Per Use ATK",
        },
        [EffectType.transformLeftmostAttackToCopy]: {
          color: "purple",
          name: "Transform Leftmost Attack To Copy",
        },
        [EffectType.shuffleTempCopiesOfAttack]: {
          color: "orange",
          name: "Shuffle Temp Copies Of Attack",
        },
        [EffectType.reduceCost]: {color: "teal", name: "Reduce Cost"},
        [EffectType.priceManna]: {color: "magenta", name: "Price Manna"},
        [EffectType.getRandomCardFromDeck]: {color: "white", name: "Random Card From Deck"},
        [EffectType.gainRandomTempAttack]: {color: "white", name: "Gain Random Temp Attack"},
        [EffectType.getRandomConfigCard]: {color: "white", name: "Random Config Card"},
        [EffectType.damagePerArtefact]: {color: "white", name: "Damage Per Artefact"},
        // [EffectType.viewCard]: {color: "white", name: "View Card"},
        [EffectType.viewCardConfig]: {color: "white", name: "View Card Config"},
        [EffectType.viewCardDeck]: {color: "white", name: "View Card Deck"},
        [EffectType.grimHarvest]: {color: "red", name: "Grim HArvest"},
        [EffectType.recklessSlash]: {color: "red", name: "Reckless Slash"},
        [EffectType.getBasicAttack]: {color: "white", name: "Get Basic Atack"},
        [EffectType.weightOfSteel]: {color: "red", name: "Weight Of Steel"},
        [EffectType.deathSentence]: {color: "red", name: "Death Sentence"},
        [EffectType.minusManaPerPower]: {color: "white", name: "Minus Mana Per Power"},
        [EffectType.addMana]: {color: "white", name: "Add Mana"},
        [EffectType.arcaneEcho]: {color: "white", name: "Arcane Echo"},
        [EffectType.mindBreak]: {color: "white", name: "Mind Break"},
        [EffectType.spectralOnslaught]: {color: "white", name: "Spectral Onslaught"},
        [EffectType.getCardNotArtefact]: {color: "white", name: "Get Card Not Artefact"},
        [EffectType.getCardDefence]: {color: "white", name: "Get CArd Defence"},
        [EffectType.equipCardArtefact]: {color: "white", name: "Equip Card Artefact"},
        [EffectType.dealDamageByHandSize]: {color: "white", name: "Deal DAmage By Hand Size"},
        [EffectType.restoreHealthByDamage]: {color: "white", name: "Retore Healtsh By Damage"},
        [EffectType.cloneBasicAttack]: {color: "white", name: "Clone Basic Attack"},
        [EffectType.doubleEffects]: {color: "white", name: "Double Effects"},
        [EffectType.noEffect]: {color: "white", name: "No Effect"},
        [EffectType.echoStrike]: {color: "white", name: "Echo Strike"},
        [EffectType.harlequinsTrick]: {color: "white", name: "Harlequin's Trick"},

      };

    const mappedEffect = effectMap[effect.effectType as EffectType];
    // console.log('mapped effect in card', mappedEffect, effect.effectType)
    if (!mappedEffect) {
      return {
        whiteColor: "white",
        brownColor: "brown",
        greenColor: "green",
        redColor: "red",
        yellowColor: "yellow",
        blueColor: "blue",
        color: "unknown color",
      };
    }

    const returnObject: EffectDetails = {
      whiteColor: "white",
      brownColor: "brown",
      greenColor: "green",
      redColor: "red",
      yellowColor: "yellow",
      blueColor: "blue",
      color: mappedEffect.color,
    };

    if (effect.target === Opponent.enemy) {
      returnObject['enemy'] = {
        value: effect.value,
        animationCount: effect.animationCount,
      };
    } else {
      returnObject['self'] = {value: effect.value};
    }

    return returnObject;
  };

  const renderDescription = ({
    id,
    lvl,
    levelData,
  }: {
   id: number;
   lvl?: number;
   levelData: {
    skills: ISkill[],
    cost: number,
  }
 }): any => {
   if (!levelData || Object.keys(levelData).length === 0) {
     return t(`cards.${id}`, {
       whiteColor: "white",
       brownColor: "brown",
       greenColor: "green",
       redColor: "red",
     });
   }

   const translationData: { [key: string]: any } = {};

   levelData.skills.forEach((levelArray) => {
     const key = EffectType[levelArray.effectType];
     const effectDetails = getEffectDetails(levelArray);
     // console.log('effect details', effectDetails)
     if (!translationData[key]) {
       translationData[key] = {};
     }

     (["enemy", "self", "both"] as Array<keyof EffectDetails>).forEach(
       (target) => {
         if (effectDetails[target]) {
           const targetDetails = effectDetails[target] ?? {};
           const combinedDetails = {
             ...effectDetails,
             //@ts-ignore
             ...targetDetails,
           };

           if (!translationData[key][target]) {
             translationData[key] = {
               ...translationData[key],
               ...effectDetails,
             };
           } else if (Array.isArray(translationData[key][target])) {
             translationData[key][target].push(combinedDetails);
           } else {
             translationData[key][target] = [
               translationData[key][target],
               combinedDetails,
             ];
           }
         }
       }
     );
   });
  
   // DESPERATION STRIKE (6) card description condition handler
  //  if (id === 6) {
  //   // Тягнемо переклад по ключу cards.6 з вашими даними
  //   const inputString = i18next.t(`cards.${id}`, translationData);
  //   console.log('INPUT STRING FOR ID 6', inputString);
  //   // Шукаємо патерн, що відповідає рядку:
  //   // |white:{{damage.self.value}} self-pierce|
  //   // |          - літерал "|"
  //   // white:      - слово "white:"
  //   // {{.?}} - будь-який вміст усередині {{ }}
  //   //  self-pierce|
  //   const pattern = /|white:{{.?}} self-pierce|/;
  
  //   let resultString;
  
  //   if (typeof inputString === "string") {
  //     if (pattern.test(inputString)) {
  //       console.log('INSIDE PATTERN TEST')
  //       // Якщо патерн знайдено, вирізаємо його (заміняємо на порожній рядок)
  //       resultString = inputString.replace(/|white:Repeats .*?|$/, "");
  //       console.log('RESULT STRING FOR ID 6', resultString);
  //     } else {
  //       // Якщо вхідний рядок не містить патерну, лишаємо як є
  //       resultString = inputString;
  //     }
  //     return resultString;
  //   }
  // }
  // console.log('Translation data', translationData, id)
   if (lvl === 2 && i18next.exists(`cards.${id}_3`)) {
     return i18next.t(`cards.${id}_3`, translationData);
   }
   if (lvl === 3 && i18next.exists(`cards.${id}_3`)) {
    return i18next.t(`cards.${id}_3`, translationData);
  }

   return i18next.t(`cards.${id}`, translationData);
 };
 
 
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    skills: {variables: configCards},
  } = appConfig;

  useEffect(() => {
    // console.log(configCards);
    const cardInfo: ICard = cards.find((card) => card.id === props.id)!;
    // console.log('CARD INFO IN USEEFFECT', cards, cardInfo)
    // const priceManna = cardInfo.data.priceManna;
    const levelData = cardInfo.stars[props.lvl - 1];
    // console.log('LEVEL DATA', levelData)
    // console.log('PROPS LEVEL', props.lvl)
    if (levelData) {
      const renderedDescription = renderDescription({
        id: cardInfo.id,
        lvl: props.lvl - 1,
        levelData,
      });
      console.log('renderedDescription',renderedDescription)
      const cardDataWithDescription = {
        ...cardInfo,
        description: renderedDescription,
        lvl: props.lvl,
      };
      // console.log('cardDataWithDescription', cardDataWithDescription)
      setCard({...cardDataWithDescription});
    }
  }, [props, props.lvl]);

  // console.log('CARD INFO', card )
  return card && (
    <>
      {props?.showId && (
        <div className="absolute bg-[#ffecc1] right-full top-2 text-[12px] p-1 leading-[1] rounded-[3px] -mr-1">
          {card.id}
        </div>
      )}
      <button
        ref={buttonRef}
        className={`relative scale-0.5 w-full 
          ${card.cardType === CardType.equip ? "rounded-card" : "rounded-md"}
          ${props.IsDraggable && props?.glow ? props?.card?.isBacklight ? "yellow-glow" : "blue-glow" : ""}
        `}>
        <CardBuilderComponent
          id={card.id}
          type={card.cardType}
          image={card.image}
          title={card.name}
          description={card.description}
          stars={card.lvl}
          maxStars={card.stars?.length} 
          priceMana={ card.stars && card.stars.length > 0 ? card.stars[card.lvl - 1].cost : 0}
          width={500}
          height={700}
          className='w-full h-full'
        />
      </button>
    </>
  );
}
