import { useEffect } from "react";
import {APP_ENV, TELEGRAM_CLIENT_ID} from "../config";
import { useSessionStorage } from '@uidotdev/usehooks';

const tg = window.Telegram?.WebApp;

const ConfigUser = {
  id: 1,
  first_name: "",
};

export function useTelegram() {
  const [telegramReady, setTelegramReady] = useSessionStorage("telegramReady", false);

  useEffect(() => {
    if (tg && !telegramReady) {
      setTelegramReady(true);
      tg.ready();

      const version = parseFloat(tg.version);

      if (version !== 6.0) {
        tg.expand();

        if (version >= 6.2) {
          tg.enableClosingConfirmation();
        }

        if (version >= 7.7) {
          tg.disableVerticalSwipes();
        }

        if (version >= 8.0 && window.screen.width < 992) {
          tg.requestFullscreen();
          tg.lockOrientation();
        }
      }
    }
  }, []);

  return {
    tg,
    user: tg?.initDataUnsafe?.user ?? ConfigUser,
    isActive: tg?.isActive,
    isExpanded: tg?.isExpanded,
    userId: tg?.initDataUnsafe?.user?.id?.toString() ?? window.deviceId ?? TELEGRAM_CLIENT_ID,
    startParam: tg.initDataUnsafe?.start_param
  };
}
