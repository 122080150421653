import React, { useMemo } from "react";
import { Friend } from "./components";

export const FriendsList = ({ userFriends }: { userFriends: {totalCount: number, data: any[]} }) => {
  const sortedFriends = useMemo(() => {
    if (!Array.isArray(userFriends?.data)) return [];

    return [...userFriends?.data].sort((a, b) => {
      const aRev = typeof a.revShareBrought === "number" ? a.revShareBrought : 0;
      const bRev = typeof b.revShareBrought === "number" ? b.revShareBrought : 0;
      return bRev - aRev;
    });
  }, [userFriends]);
  return (
    <>
      <div className="flex justify-between ">
        <div className="text-white text-[15px] font-extrabold leading-[15px]">
          Your friends list {userFriends?.totalCount}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {sortedFriends.length > 0 ? (
          sortedFriends.map((friend, index) => (
            <Friend
                key={friend.clientId || index}
                length={sortedFriends.length}
                index={index}
                name={friend.fullName}
                revShareBrought={friend.revShareBrought}
                imgSrc={friend.avatarId}
              />
          ))
        ) : (
          <div className="flex w-full items-center justify-center py-4 px-2 rounded-md border border-[#554837] shadow-inner-sm-white mb-[100px]">
            <div className="text-center text-[#6f675c] text-sm font-normal leading-[14px] p-4 w-full">
              Invite friends to get interest
            </div>
          </div>
        )}
      </div>
    </>
  );
};
