// QuestBlock.jsx
import React, {useState} from "react";
import { PopupButton } from "../../../../components/PopupButton";
import { Resources } from "../../../../enums/resources";
import {
  KitsuIco,
  StoneIco,
  XPIco,
} from "../../../../layout/components/HeaderFarm/components";
import { useTranslation } from "react-i18next";
import { TaskStatus } from "../../../../app/features/questsSlice";
import { handleRewardResult } from "./rewardParse";
import { QuestType } from "../..";
import { SocialTaskCategory } from "../../../../endpoints/userQuests";
import { useDispatch, useSelector } from "react-redux";
import { saveFarmTutorialProgress } from "../../../../app/features/farmTutoralSlice";
import { AppDispatch, RootState } from "../../../../app/store";
import { useTelegram } from "../../../../hooks/useTelegram";
import {
  TutorialFarmSave,
  TutorialFarmStage,
} from "../../../../interfaces/tutorial";
import {QuestAnimation} from "../../../../components";

export const getImageSrc = (category: SocialTaskCategory) => {
  switch (category) {
    case SocialTaskCategory.Telegram:
      return require("../../../../assets/images/quests/tg.png");
    case SocialTaskCategory.Twitter:
      return require("../../../../assets/images/quests/x.png");
    case SocialTaskCategory.TelegramPremium:
      return require("../../../../assets/images/quests/tg.png");
    default:
      return require("../../../../assets/images/quests/tg.png"); // Значення за замовчуванням
  }
};

interface QuestBlockProps {
  quest: any;
}

export const SocialBlock: React.FC<QuestBlockProps> = ({ quest }) => {

  const { t } = useTranslation();

  function decodeHTMLEntities(text: string): string {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  function stripHTMLTags(str: string): string {
    // Видаляємо всі HTML-теги з рядка
    const withoutTags = str.replace(/<\/?[^>]+(>|$)/g, "");

    // Декодуємо HTML-сутності (наприклад, &nbsp;)
    const decoded = decodeHTMLEntities(withoutTags);

    // Обрізаємо пробіли з обох кінців рядка
    const trimmed = decoded.trim();

    // Якщо після всіх операцій рядок порожній, повертаємо порожній рядок
    return trimmed === "" ? "" : trimmed;
  }

  const {
    category,
    currency,
    description,
    status,
    onClick,
    conditions,
    actionType,
    rewards,
    name,
  } = quest;

  //console.log("status", status);
  const messageParts = name;

  const Reward = () => {
    const rewardParts = rewards.map((reward: any, index: number) => {
      const { amount, rewardId, rewardType } = reward;

      const resource = handleRewardResult({
        rewardId: rewardId,
        rewardType: rewardType,
      });
      //console.log("amount", amount);
      //console.log("resource", resource);
      // Отримуємо назву ресурсу

      // Отримуємо іконку ресурсу
      const icon = resource?.image;

      // Формуємо частину повідомлення з кількістю і ресурсом
      //console.log("icon", icon);

      // Повертаємо JSX для кожного елемента з текстом і іконкою
      const resourceName = resource?.name as keyof typeof Resources;
      return (
        <div
          key={index}
          className={`flex items-center justify-center flex-col min-w-[40px] max-w-[40px] h-[40px] p-1 rounded-md border border-black bg-gradient-to-b 
            ${
              rewardId === Resources.kitsu
                ? "from-[#D158D8] to-[#9444DD]"
                : "from-[#484CAA] to-[#4247C9]"
            } pb-[1px]`}
        >
          {Resources[resourceName] === Resources["5m"] && (
            <div className="w-[38px] h-[10px] mt-[2px] flex items-center justify-center border-b border-black bg-[#333693] rounded-t-md">
              <span className="w-full text-[8px] text-white text-center">5m</span>
            </div>
          )}
          <div
            className={`relative w-full h-[30px] flex items-center justify-center ${
              Resources[resourceName] === Resources["5m"] && "mb-[5px]"
            }`}
          >
            <img
              src={icon}
              alt="Icon"
              className="w-[28px] h-[28px] object-cover rounded-lg"
            />
            <span
              className={`absolute text-[8px] text-white right-0 ${
                Resources[resourceName] === Resources["5m"]
                  ? "mt-[20px]"
                  : "mt-[25px]"
              }`}
              style={
                Resources[resourceName] !== Resources["5m"]
                  ? {
                      filter:
                        "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
                    }
                  : {}
              }
            >
              {Resources[resourceName] === Resources["5m"] ? 1 : amount}
            </span>
          </div>
        </div>
      );
    });

    return (
      <div className="flex  gap-1 w-full min-w-[40%] overflow-scroll">
        {rewardParts}
      </div>
    );
  };

  const fullMessage = `${messageParts}`;

  const imageSrc = getImageSrc(category.id);
  const [showSuccess, setShowSuccess] = useState(false);
  // Визначаємо тип кнопки та текст на основі статусу
  let buttonType: any;
  let buttonText = "";
  let showButton = true;

  switch (status) {
    case TaskStatus.NotDone:
      buttonType = "blue";
      buttonText = "GO";
      break;
    case TaskStatus.Done:
      buttonType = "green";
      buttonText = "Claim";
      break;
    case TaskStatus.Claimed:
      buttonType = "gray";
      buttonText = "Done";
      break;
    default:
      buttonType = "blue";
      buttonText = "GO";
  }
  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  return (
    <>
      <div
        className={`relative flex flex-col gap-2 rounded-md overflow-visible w-full 
          ${TaskStatus.Done === status ? "bg-[#584d3c] order-first" : ""}
          ${TaskStatus.Claimed === status ? "order-last" : ""}`}
      >
        {status === TaskStatus.Done ? (
          <img
            className="absolute -top-[1px] -right-[1px] z-[15] w-3 h-3 pointer-events-none"
            src={require("../../../../assets/images/heroes/cards/notification.png")}
            alt="Notification"
          />
        ) : null}

        <div className="flex items-center justify-center flex-grow w-full bg-[#473D37] rounded-md shadow-md border-2 border-[#574E48]">
          {/* Зображення */}
          <div className="w-[68px] h-[68px] p-1 flex-shrink-0 ml-2 mr-[2px] border border-black rounded-md bg-[#362D28] shadow-inner-sm-black">
            <img
              src={imageSrc}
              alt={`${category} Icon`}
              className="w-full h-full object-contain"
            />
          </div>

          {/* Текст та іконка */}
          <div className="flex flex-col items-center flex-grow p-[7px] gap-[3px]">
            <div
              className="text-white text-base font-bold leading-tight w-full break-words"
              style={{
                filter:
                  "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
              }}
            >
              {fullMessage}
            </div>
            {/* {stripHTMLTags(description).length ? (
              <div
                className="text-[#f6a000] text-[11px] font-bold w-full leading-[10px] break-words mb-[5px] text-shadow-black"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            ) : null} */}

            <div className="w-full flex items-center justify-between">
              <div className="self-start bg-[#00000033] p-[3px] rounded-md">
                <Reward />
              </div>
              {/* Кнопка */}
              {showButton ? (
                <div className="ml-auto min-w-[70px] flex flex-shrink-0 self-end">
                  {showSuccess ? (
                      <>
                        <div className="w-[300px] h-[300px] right-[-105px] top-[-95px] absolute z-50">
                          <QuestAnimation />
                        </div>
                      </>
                  ) : null}
                  <PopupButton type={buttonType} width="60px" onClick={()=>{
                    onClick();
                    if (quest.status === TaskStatus.Done) {
                      setShowSuccess(true);
                      setTimeout(() => {
                        setShowSuccess(false);
                      }, 1000);
                    }
                  }}>
                    {buttonText}
                  </PopupButton>
                </div>
              ) : (
                <div className="flex items-center h-full ml-auto mr-9">
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
