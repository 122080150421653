import { useRef, useEffect } from "react";

const CardCanvas = ({ cardId }: { cardId: number }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && typeof cardId === 'number' ) {
      const context = canvas.getContext("2d");
      if (context) {
        const image = new Image();

        const imagePath =
          require(`../../assets/images/cardsIcons/${cardId}.webp`) as string;
        image.src = imagePath;

        image.onload = () => {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
      }
    }
  }, [cardId]);

  return (
    <canvas
      ref={canvasRef}
      width={500}
      height={700}
      className='w-full h-full inline'
    />
  );
};

export default CardCanvas;
