import { Handle, Position } from "@xyflow/react";
import React from "react";

export const SkillNode = ({ data }: { data: any }) => {

  console.log('skill data', data);
  

  // Визначаємо шлях до зображення
  let imageSrc = require(`../../../../../../assets/images/heroes/upgradeIcons/${data.image}`);

  // Встановлюємо класи для розміру та анімації
  let imageClasses = `${
    data.size === "large" ? "w-[65px] h-[65px] scale-130" : "w-4 h-4 scale-200"
  } transition-transform duration-300`;

  // Ініціалізуємо рядок фільтрів
  let filterStyle = "";

  // Якщо навичка не заявлена, додаємо grayscale
  if (!data.claimed) {
    filterStyle += "grayscale(100%) ";
  }


  // Додаємо відповідні drop-shadow базуючись на стані
  if (data.selected) {
    // Якщо вибрана, додаємо drop-shadow-selected
    filterStyle +=
      "drop-shadow(0 0 6px rgba(255, 255, 0, 1)) " +
      "drop-shadow(0 0 10px rgba(255, 215, 0, 0.8)) " +
      "drop-shadow(0 0 15px rgba(255, 223, 0, 0.6))";
    // Додаємо масштабування
    imageClasses += " transform scale-105";
  } 

  return (
    <div className="relative flex justify-center items-center ">
      <Handle
        type="target"
        position={Position.Top}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          // transform: `translateX(${data.size === 'large' ? data.offsetXTarget + 220 : data.offsetXTarget}%)`,
          // translate: `${data.transform.x} `,
          // transform: ` translateY(1000%)`,
          margin: `${data.transformTarget.y} ${data.transformTarget.x}`,
          background: "rgba(0,0,0,0)", // Прозорий фон
          border: "none", // Без обводки
          width: 1,
          height: 1,
          pointerEvents: 'none',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          // transform: `translateX(${data.size === 'large' ? data.offsetXTarget - 220 : data.offsetXTarget}%)`,
          // transform: `translateX(${data.offsetXSource})`,
          margin: `${data.transformSource.y} ${data.transformSource.x}`,
          background: "rgba(0,0,0,0)", // Прозорий фон
          border: "none", // Без обводки
          width: 10,
          height: 10,
          pointerEvents: 'none',
        }}
      />
      <img
        className={imageClasses}
        src={imageSrc}
        alt={data.alt}
        style={{ filter: filterStyle }}
      />
      {data.levels.length > 1 && data.levels.some((lvl: any) => lvl.isClaimed) && !data.levels.every((lvl: any) => lvl.isClaimed) &&
        <div className="absolute -right-1 -bottom-1 text-white font-bold text-stroke-regular">
          {`${data.levels.filter((lvl: any)=>lvl.isClaimed).length}/${data.levels.length}`}
        </div>
      }
    </div>
  );
};
