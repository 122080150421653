import React, { useState, useEffect } from 'react';
import { ActionCard, ActionModal } from '../../containers/Play';
import { Playground } from '../../layout/components/Playground';
import {
  BattleSaves,
  CardType,
  ConditionParameter,
  ConditionSide,
  DungeonStage,
  DungeonStagesResponse,
  DungeonType,
  DungeonTypeConfig,
  Opponent,
  StageType,
} from '../../endpoints/mock';
import { updateBattleSave } from '../../utils/updateBattleSave';
import { useTelegram } from '../../hooks/useTelegram';
import { CardProps } from '../../interfaces/card';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { useUtils } from '../../utils/navigateTo';
import DungeonEffect from '../../containers/Router/components/DungeonEffect';
import {
  buyLifeWithShare,
  cancelDungeonCard,
  cancelDungeonCardResponse,
  CardsAtDeck,
  getDungeonProgressByDungeonId,
  resetCurrentDungeon,
  responseForManyEndpoints,
  usingDungeonCard,
} from '../../endpoints/dungeonEndpoints';
import { getTitleForStages } from '../../endpoints/dungeonMock';
import { toast } from 'react-toastify';
import update = toast.update;
import { updateBattleProgress } from '../../endpoints/battleProgress';
import { FooterCave } from '../../layout/components/FooterCave';
import { HeaderCave } from '../../layout/components/HeaderCave';
import { DungeonShop } from './DungeonShop';
import { Smithy } from '../../containers/Smithy';
import { saveFarmTutorialProgress, setFarmSave } from '../../app/features/farmTutoralSlice';
import { HeroList, TutorialFarmStage } from '../../interfaces/tutorial';
import { Tavern } from './Tavern';
import { motion, AnimatePresence } from 'framer-motion';
import { Resource } from '../../mock/resources';
import { Resources } from '../../enums/resources';
import { completeDungeon } from '../../app/features/dungeonCompleted';
import { Bag } from './Bag';
import { useSoundService } from '../../utils/soundService';
import { Chest } from './Chest';
import { useTranslation } from 'react-i18next';
import { heroesMap } from '../../utils/mapping';
import { HeroShard } from '../../interfaces/lootBotx';
import * as Sentry from '@sentry/react';
import { ErrorType, RuntimeErrorData } from '../../interfaces/error';
import { ErrorReportingService } from '../../services/errorReportingService';
import { ErrorComponent } from '../../components';
import { BattleRewards, getRewardsAfterBattle } from '../../endpoints/lobbyEndpoints';
import { DisplayData, handleLootBoxResult } from '../../utils/lootBoxHandler';
import { useIsDungeonFinishStage } from '../../hooks/useIsDungeonFinishStage';
import { useSessionStorage } from '@uidotdev/usehooks';
import { APP_ENV } from '../../config';
import ReactGA from 'react-ga4';
import * as amplitude from '@amplitude/analytics-browser';
import { completedBosses } from '../../endpoints/bossEndpoint';
import { useRiveFile } from '@rive-app/react-canvas';
import { SecretRoom } from './SecretRoom';
import { PopupButton } from '../../components/PopupButton';
import { useTelegramShareMessage } from '../../hooks/useTelegramShareMessage';
import { ConfigOffer, OfferType } from '../../mock/offers';
import { usePurchase } from '../../hooks/usePurchase';
import { HerzModal } from "./HerzModal";
import { SuccessOverlay } from "../../components/SuccessOverlay";

export type VisibleItems = DungeonStagesResponse[];

const CARDS_WITH_1_LVL = [36, 24];
const CARDS_WITH_2_LVL = [16, 29, 31];

const SomeTavernsStage = [StageType.abyss, StageType.shop, StageType.forge];

export const Dungeon = () => {
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const configCards = useSelector((state: RootState) => state.appConfig.cards);

  const {
    dungeonCards: { variables: dungeonConfigCards },
  } = appConfig;

  const dungeonConfig: DungeonTypeConfig[] = dungeonConfigCards;
  //console.log('DUNGEON START INIT', dungeonConfig);
  const { userId } = useTelegram();
  const { navigateTo } = useUtils();

  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save);

  const heroesList = useSelector((state: RootState) => state.heroes.heroesList);
  const selectedHero = useSelector((state: RootState) => state.selectedHero);

  const heroId = Number(selectedHero.uid);

  const heroName = heroesMap[Number(heroId) as HeroShard].name;
  const heroLevel = heroesList[heroId]?.level;

  //@ts-ignore
  // const {dungeonId} = actualSaves;
  let dungeonId = actualSaves?.dungeonId ?? 0;
  let buildingId = actualSaves?.bossId ? undefined : (actualSaves?.buildingId ?? 0);

  // state: displayed items in array
  const [visibleItems, setVisibleItems] = useState<VisibleItems>([]);

  // state: define next index of card to display
  const [nextIndex, setNextIndex] = useState(0);

  // state: lvl finished (0 cards remain)
  const [isFinished, setIsFinished] = useState(false);

  // state: active card index
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [lastStageId, setLastStageId] = useState(-1);

  // state: visibility of action modal (clicked on action in card)
  const [isModalVisible, setModalVisible] = useState(false);

  const [fadeClass, setFadeClass] = useState('');

  const [usedLives, setUsedLives] = useState(0);
  const [additionalCards, setAdditioinalCards] = useState(0);
  const [currentHp, setCurrentHp] = useState(20);
  const [currentMana, setCurrentMana] = useState(2);
  const [goldAmount, setGoldAmount] = useState(10);
  const [isShopOpen, setShopOpen] = useState(false);
  const [isSmithyOpen, setSmithyOpen] = useState(false);
  const [cardsAtDeck, setCardsAtDeck] = useState<CardsAtDeck[]>([]);
  const [isTavernOpen, setIsTavernOpen] = useState(false);
  const [isDeckOpen, setIsDeckOpen] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isScreenDarkened, setIsScreenDarkened] = useState(false);
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const [healAnimation, setHealAnimation] = useState(false);
  const [isChestOpen, setIsChestOpen] = useState(false);
  const [isSecretRoomOpen, setIsSecretRoomOpen] = useState(false);
  const [bossId, setBossId] = useState<number>();
  const [maxHp, setMaxHp] = useState(20);
  const [canceledCard, setCanceledCard] = useState<number>(-1);
    const [showHerzModal, setShowHerzModal] = useState(false);
    const [boughtLives, setBoughtLives] = useState(0);
  const { playSound } = useSoundService();
  const { t } = useTranslation();

  const openDeck = () => {
    setIsDeckOpen(!isDeckOpen);
  };

  const closeDeck = () => {
    setIsDeckOpen(!isDeckOpen);
  };

  const animationOn = () => {
    setIsAnimationActive(true);
  };

  const animationOff = () => {
    setIsAnimationActive(false);
  };

  const handleHeal = () => {
    console.log('here');
    setHealAnimation(true);
    setFadeClass('fade-in');

    setTimeout(() => {
      setFadeClass('fade-out');
      setTimeout(() => {
        setHealAnimation(false);
      }, 300);
    }, 300);
  };

  useEffect(() => {
    const getCompletedBosses = async () => {
      const bossIds = await completedBosses({ clientId: userId });
      if (bossIds.length >= 1) {
        setBossId(bossIds[bossIds?.length - 1]?.bossId);
      } else setBossId(1);
      //console.log('bossId in getCompleteBosses', bossIds)
    };
    //console.log('bossId', bossId)
    getCompletedBosses();
  }, []);

   const getDungeonSaveOrStartDungeon = async () => {
     //console.log("BOSSID IN GETDUNGEON PREOGRESS", bossId)

     const progress = buildingId
       ? await getDungeonProgressByDungeonId({
           clientId: userId,
           buildingId: buildingId,
           heroId,
         })
       : await getDungeonProgressByDungeonId({
           clientId: userId,
           bossId: bossId,
           heroId,
         });

     if (progress?.stages) {
       console.log('Progress', progress);
       setVisibleItems(progress.stages);
       setLastStageId(progress.stages[progress?.stages?.length - 1].stageId);
       setCurrentIndex(progress.stages[progress?.stages?.length - 1].stageId - 1);
       setNextIndex(progress.stages[progress?.stages?.length - 1].stageId + 1);
       setCurrentHp(progress.currentHp);
       setCurrentMana(progress.currentMana);
       setGoldAmount(progress.gold);
       setCardsAtDeck(progress?.cardsAtDeck);
       setMaxHp(progress?.hp);
       setAdditioinalCards(progress.boosts.additionalCards)
       updateBattleSave({
         save: {
           gold: progress.gold,
           currentHp: progress.currentHp,
           currentMana: progress.currentMana,
           stages: progress.stages,
           dungeonId,
           enemyId: actualSaves?.enemyId,
           buildingId,
           bossId: buildingId ? undefined : bossId,
         },
         clientId: userId,
       });
       setUsedLives(progress.usedLives);
       setBoughtLives(progress.boughtLives);
     }
   };
    useEffect(() => {
        // console.log('NEDDED EFFECT', dungeonId);
        if (dungeonId && (buildingId || bossId)) {
            //console.log('Has bossId or BuildingId')
         
            // @ts-ignore
            getDungeonSaveOrStartDungeon({
                clientId: userId,
                dungeonId: actualSaves?.dungeonId,
            });
        }
    }, [dungeonId, buildingId, bossId]);

  useEffect(() => {
    if (actualSaves?.dungeonId && actualSaves?.buildingId) {
      dungeonId = actualSaves.dungeonId;
      buildingId = actualSaves.buildingId;
    }
  }, [actualSaves]);
  //
  // useEffect(() => {
  //
  // }, [actualSaves]);

  // useEffect(() => {
  //   // @ts-ignore
  //   setVisibleItems(actualSaves?.stages);
  // }, [])

  //при инициализации сетаем текующий данж из сейва если нет начинаем сначала
  // useEffect(() => {
  //     setDungeonBySave({
  //       save: actualSaves,
  //     });
  // }, []);

  const handleStartBattle = () => {
    console.log('Start battle');
    setIsFadingOut(true);

    // Чекаємо завершення анімації зникнення (наприклад, 500ms)
    setTimeout(() => {
      // Ваш існуючий код handleAction
      // Наприклад: stageStrategy[item?.type!]({ stage: item });

      setIsScreenDarkened(true);
    }, 500); // Тривалість повинна відповідати тривалості анімації

    setTimeout(() => navigateTo('/battle'), 1000);
  };

  useEffect(() => {
    // console.log("CURRENT INDEX!!!!", currentIndex);
  }, [currentIndex]);

  const handleCompleteDungeon = (rewards: BattleRewards) => {
    // Приклад отриманих ресурсів
    const displayData: DisplayData[] = rewards.bossRewards.map(handleLootBoxResult).filter((data): data is DisplayData => data !== null);

    dispatch(completeDungeon(displayData));
  };

  const getRewards = async (stageId: number) => {
    const getRewardsResult = await getRewardsAfterBattle({
      dungeonId: actualSaves?.dungeonId!,
      clientId: userId,
      stageId: stageId,
    });
    if (getRewardsResult) {
      handleCompleteDungeon(getRewardsResult);
    }
  };

  const currentRoom = useSelector((state: RootState) => state.selectedRoom);
  const { buildingConfigs } = useSelector((state: RootState) => state.config);
  const selectedRoom = buildingConfigs!.filter((v) => v.id === currentRoom?.id)?.[0];
  const [startSelectHero, setStartSelectHero] = useSessionStorage('startSelectHero', false);

  const stageStrategy: {
    [k in StageType]?: ({ stage }: { stage: DungeonStagesResponse }) => void;
  } = {
    [StageType.boss]: ({ stage }) => {
      console.log('Satge in strategy', stage);
      // if (!isEnemyStage(stage)) {
      //   return;
      // }
      //@ts-ignore
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: visibleItems,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          //@ts-ignore
          enemyId: dungeonConfig.filter((dungeon: any) => dungeon.dungeonId.value === dungeonId)[0].stages.value[stage.stageId - 1]?.enemyId!.value,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      handleStartBattle();
    },
    [StageType.bonfire]: ({ stage }) => {
      playSound('itemUseFlask');
      handleHeal();
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: actualSaves?.stages!,
          //@ts-ignore
          enemyId: actualSaves?.enemyId,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      usingCard({ dungeonId, cardId: stage.stageId, clientId: userId });
    },
    [StageType.enemy]: ({ stage }) => {
      console.log('STAGE AT ENEMY->', stage, dungeonConfig.filter((d) => d.dungeonId.value === dungeonId)[0].stages.value[stage.stageId - 1]?.enemyId!.value);
      //@ts-ignore
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: visibleItems,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          //@ts-ignore
          enemyId: dungeonConfig.filter((dungeon: any) => dungeon.dungeonId.value === dungeonId)[0].stages.value[stage.stageId - 1]?.enemyId!.value,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      handleStartBattle();
    },
    [StageType.elixir]: ({ stage }) => {
      // console.log('STAGE-> ELIXIR', stage);
      playSound('itemUseFlask');
      handleHeal();
      //setCurrentHp(20);
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: actualSaves?.stages!,
          //@ts-ignore
          enemyId: actualSaves?.enemyId,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      usingCard({ dungeonId, cardId: stage.stageId, clientId: userId });
    },
    [StageType.shop]: ({ stage }) => {
      // console.log('STAGE-> SHOP', stage);
      playSound('itemUseShop');
      //@ts-ignore
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: actualSaves?.stages!,
          //@ts-ignore
          enemyId: actualSaves?.enemyId,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      setShopOpen(true);
    },
    [StageType.secretroom]: ({ stage }) => {
      console.log('secret room');

      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: actualSaves?.stages!,
          //@ts-ignore
          enemyId: actualSaves?.enemyId,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      setIsSecretRoomOpen(true);
    },
    [StageType.forge]: ({ stage }) => {
      // console.log('STAGE-> Smithy (forge)', stage);
      //@ts-ignore
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: actualSaves?.stages!,
          //@ts-ignore
          enemyId: actualSaves?.enemyId,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      setSmithyOpen(true);
      // console.log('Forge COMPLETED');
    },
    [StageType.abyss]: ({ stage }) => {
      // console.log('STAGE-> Tavern', stage);
      //@ts-ignore
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: actualSaves?.stages!,
          //@ts-ignore
          enemyId: actualSaves?.enemyId,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      setIsTavernOpen(true);
      // console.log('Tavern COMPLETED');
    },
    [StageType.chest]: ({ stage }) => {
      // console.log('STAGE-> Chest', stage);
      //@ts-ignore
      updateBattleSave({
        clientId: userId,
        save: {
          dungeonId,
          stages: actualSaves?.stages!,
          //@ts-ignore
          enemyId: actualSaves?.enemyId,
          currentHp: currentHp,
          currentMana: currentMana,
          gold: goldAmount,
          currentStage: stage.stageId,
          buildingId,
          bossId: buildingId ? undefined : bossId,
        },
      });
      setIsChestOpen(true);
      // console.log('Chest COMPLETED');
    },
    [StageType.endWay]: ({ stage }) => {
      getRewards(stage.stageId);
      if (APP_ENV === 'production' && actualSaves?.dungeonId) {
        ReactGA.event({
          category: 'Farm',
          action: `Dungeon ${actualSaves.dungeonId} completed`,
        });
        amplitude.track(`Dungeon ${actualSaves.dungeonId} completed`, {
          group: 'Farm',
        });
      }
      if (!isFarmTutorialCompleted && farmTutorialSave?.stage && farmTutorialSave?.stage === TutorialFarmStage.finishQuestTutor) {
        const dialogueId: number = farmTutorialSave?.dialogueId ?? 1;
        console.log('FARMTUTORIALSTAGE', farmTutorialSave?.stage);
        dispatch(
          saveFarmTutorialProgress({
            clientId: userId,
            save: {
              dialogueId: dialogueId + 1,
              stage: TutorialFarmStage.endbattle,
            },
          })
        );
      } else if (!isFarmTutorialCompleted && farmTutorialSave?.stage && farmTutorialSave?.stage === TutorialFarmStage.finishFirstBuilding) {
        const dialogueId: number = farmTutorialSave?.dialogueId ?? 1;

        dispatch(
          saveFarmTutorialProgress({
            clientId: userId,
            save: {
              dialogueId: dialogueId + 1,
              stage: TutorialFarmStage.clickOnQuests,
            },
          })
        );
      }
      actualSaves?.bossId ? navigateTo('/boss') : navigateTo('/island');
      usingCard({ dungeonId, cardId: stage.stageId, clientId: userId });
      setTimeout(() => {
        // @ts-ignore
        updateBattleSave({
          clientId: userId,
          save: {
            //@ts-ignore
            enemyId: actualSaves.enemyId!,
            dungeonId: -1,
            stages: [],
            currentHp: -1,
            gold: -1,
            currentMana: -1,
            buildingId: -1,
          },
        });
      }, 1000);

      const dungeonIds = selectedRoom?.dungeonIds;
      const currentId = dungeonIds?.map((id) => id.dungeonId).indexOf(dungeonConfig[dungeonId - 1].dungeonId.value);

      // Select hero modal after dungeon
      if (dungeonIds?.length! > currentId! + 1) {
        setStartSelectHero(true);
      }
    },
  };

  const cancelCard = (stageid: number, currentIndex: number) => {
    const cancelEndpoint = async () => {
      console.log('Stage Id in cances', stageid);
      //@ts-ignore
      const result = await cancelDungeonCard({
        dungeonId,
        clientId: userId,
        stageId: stageid,
      });
      console.log('CANCEL DUNGEON CARD RESULT', result);
      if (result.stages.length) {
        console.log('while closing has stages');
        const items = [...visibleItems];
        items[currentIndex] = result.stages[0];
        setVisibleItems(items);
        setLastStageId(result.stages[0].stageId);
        setCurrentIndex(result.stages[0]?.stageId - 1);
        setNextIndex(result.stages[0]?.stageId + 1);
        updateBattleSave({
          clientId: userId,
          save: {
            dungeonId,
            stages: items,
            //@ts-ignore
            enemyId: actualSaves?.enemyId,
            currentHp: currentHp,
            currentMana: currentMana,
            gold: goldAmount,
            currentStage: actualSaves?.currentStage,
            buildingId,
            bossId: buildingId ? undefined : bossId,
          },
        });
      }
      if (!result.stages.length) {
        console.log('while closing doesnt havee stages');
        setVisibleItems(visibleItems.filter((stage) => stage.stageId !== stageid));
      }
    };

    cancelEndpoint();
  };

  // method: card's action clicked
  const handleAction = (action: StageType, id: number) => {
    setModalVisible(true);
  };
  const dispatch = useDispatch<AppDispatch>();

  const isFarmTutorialCompleted = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save?.completed);

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const dialogueId: number = farmTutorialSave?.dialogueId ?? 1;
  // console.log("dialogueId dungeon", dialogueId)

  const testHandleEndDungeon = () => {
    if (!isFarmTutorialCompleted) {
      dispatch(
        saveFarmTutorialProgress({
          clientId: userId,
          save: {
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.endbattle,
          },
        })
      );
    }

    navigateTo('/island');
  };

  // const usingCard = ({dungeonId, cardId, clientId}: {dungeonId: number, clientId: number, cardId: number}) => {
  //   console.log('USING CARD START', {dungeonId, cardId, clientId});
  //   const usingDungeonCardEndpoint = async () => {
  //     //@ts-ignore
  //     const result = await usingDungeonCard({dungeonId, clientId, cardId});
  //     console.log('RESULT USING CARD->->->', result);
  //     if (result?.stages?.length) {
  //       console.log('AT CASE@@@@@@@@@@@@@@');
  //       const items = visibleItems.filter((item) => item.cardId !== cardId);
  //       console.log('ITEMS BEFORE ', items);
  //       const {cardId: id, type, amount, currentAmount} = result.stages[0];
  //       //@ts-ignore
  //       items.push({cardId: id, type: type, amount, currentAmount});
  //       console.log('NEW ITEMS', items);
  //       setLastStageId(id);
  //       setVisibleItems(items);
  //       setCurrentIndex(id - 1);
  //       setNextIndex(id + 1);
  //       setCurrentHp(result.currentHp);
  //       setCurrentMana(result.currentMana);
  //       setGoldAmount(result.gold);
  //       updateBattleSave({
  //         clientId: userId,
  //         save: {
  //           dungeonId,
  //           stages: items,
  //           //@ts-ignore
  //           enemyId: actualSaves?.enemyId,
  //           currentHp: currentHp,
  //           currentMana: currentMana,
  //           gold: goldAmount,
  //           currentStage: actualSaves?.currentStage,
  //         },
  //       });
  //     }
  //   }
  //   usingDungeonCardEndpoint();
  //
  // }

  useEffect(() => {}, [currentHp, goldAmount]);

  const usingCard = ({ dungeonId, cardId, clientId, index }: { dungeonId: number; clientId: string; cardId: number; index?: number }) => {
    // console.log('USING CARD START', {dungeonId, cardId, clientId});
    const usingDungeonCardEndpoint = async () => {
      //@ts-ignore
      const result = await usingDungeonCard({
        dungeonId,
        clientId,
        stageId: cardId,
      });
      console.log('RESULT USING CARD->->->', result);
      if (result?.stages?.length) {
        // console.log('AT CASE@@@@@@@@@@@@@@', result.stages.length);
        const items = [...visibleItems];
        // console.log('ITEMS BEFORE ', items);
        const { stageId: id, type, amount, currentAmount } = result.stages[0];
        //@ts-ignore
        // items.push({cardId: id, type: type, amount, currentAmount});
        items[index ? index : currentIndex] = {
          stageId: id,
          type,
          amount,
          currentAmount,
        };
        // console.log('NEW ITEMS', items);
        setLastStageId(id);
        setVisibleItems(items);
        setCurrentIndex(id - 1);
        setNextIndex(id + 1);
        setCurrentHp(result.currentHp);
        setCurrentMana(result.currentMana);
        setGoldAmount(result.gold);
        updateBattleSave({
          clientId: userId,
          save: {
            dungeonId,
            stages: items,
            //@ts-ignore
            enemyId: actualSaves?.enemyId,
            currentHp: currentHp,
            currentMana: currentMana,
            gold: goldAmount,
            currentStage: actualSaves?.currentStage,
            buildingId,
            bossId: buildingId ? undefined : bossId,
          },
        });
      } else {
        if (result) {
          // console.log('AT CASE NOOOOOOOO ADDITIONAL STAGE');
          const items = [...visibleItems];
          // console.log('ITEMS BEFORE ', items);
          //const {cardId: id, type, amount, currentAmount} = result.stages[0];
          //@ts-ignore
          // items.push({cardId: id, type: type, amount, currentAmount});
          items.splice(index ? index : currentIndex, 1);
          // console.log('NEW ITEMS', items);
          //setLastStageId(id);
          setVisibleItems(items);
          //@ts-ignore
          setCurrentIndex(null);
          //setNextIndex(id + 1);
          setCurrentHp(result.currentHp);
          setCurrentMana(result.currentMana);
          setGoldAmount(result.gold);
          updateBattleSave({
            clientId: userId,
            save: {
              dungeonId,
              stages: items,
              //@ts-ignore
              enemyId: actualSaves?.enemyId,
              currentHp: currentHp,
              currentMana: currentMana,
              gold: goldAmount,
              currentStage: actualSaves?.currentStage,
              buildingId,
            },
          });
        }
      }
    };
    usingDungeonCardEndpoint();
  };
  // console.log("VIsible Items", visibleItems);
  // console.log('DUNGEONID->', dungeonId);

  const closeShop = () => {
    playSound('button');
    setShopOpen(false);
    // usingCard({dungeonId, cardId: visibleItems[currentIndex].cardId, clientId: userId});
  };

  const closeSmithy = () => {
    playSound('button');
    setSmithyOpen(false);
    //usingCard({dungeonId, cardId: visibleItems[currentIndex].cardId, clientId: userId});
  };

  const closeTavern = () => {
    playSound('button');
    setIsTavernOpen(false);
    //usingCard({dungeonId, cardId: visibleItems[currentIndex].cardId, clientId: userId});
  };

  const closeChest = () => {
    playSound('button');
    setIsChestOpen(false);
    // usingCard({dungeonId, cardId: visibleItems[currentIndex].cardId, clientId: userId});
  };

  const updateGoldAmountHandler = (totalAmount: number, amount: number) => {
    if (totalAmount - amount > 0) {
      setGoldAmount(totalAmount - amount);
    }
  };

  useEffect(() => {}, [goldAmount]);

  const handleBackClick =
    dungeonId >= 1000
      ? () => {
          navigateTo('/boss');
        }
      : undefined;

  const calculateRemainingPages = () => {
    // console.log('HERE??????');
    //console.log('Dungeon Id', dungeonId)
    if (!visibleItems?.length) return;
    console.log('Visible items JJJJJJJJJJJJJJJJJJJ', visibleItems);
    const biggestId = [...visibleItems].sort((a, b) => b?.stageId - a?.stageId)[0]?.stageId;
    // console.log('Biggest ID', biggestId);
    //const stagesLength = dungeonConfig[dungeonId - 1].stages.value.length;
    const stagesLength = dungeonConfig.filter((dungeon: any) => dungeon.dungeonId.value === dungeonId)[0].stages.value.length;
    //console.log('Stages length', stagesLength)
    //console.log('DungeonConfig', dungeonConfig)
    const lastStageId = dungeonConfig.filter((dungeon: any) => dungeon.dungeonId.value === dungeonId)[0].stages.value[stagesLength - 1]?.id.value;
    return lastStageId - biggestId;
  };
  calculateRemainingPages();
  const updateSomeStates = ({ gold, currentMana, currentHp, cardsAtDeck }: responseForManyEndpoints) => {
    setGoldAmount(gold);
    setCurrentMana(currentMana);
    setCurrentHp(currentHp);
    setCardsAtDeck(cardsAtDeck);
  };

    // const resetDungeon = async () => {
    //     await resetCurrentDungeon({ clientId: userId, heroId: 1 });
    // };

    // useEffect(() => {
    //     if (usedLives > 1) {
    //         resetDungeon();
    //     }
    // }, [usedLives]);

  // SilverPack animation preload
  const { riveFile, status } = useRiveFile({
    src: 'animation/SilverPack.riv',
  });

  const getMaxLevel = (id: number) => {
    return configCards.filter((c) => c.id === id)[0]?.stars.length;
  };

  console.log('cardsAtDeck', configCards);

    //console.log('DungeonId', dungeonId)
    //console.log('Filter', dungeonConfig.filter((dungeon: any) => dungeon.dungeonId.value === dungeonId)[0])

    const {
      offers: { variables: allOffers },
    } = appConfig;

    const heartOffers: ConfigOffer = allOffers.find(
      (offer: ConfigOffer) => offer.offerType.value === OfferType.buyHeartByStarsOffer
    );

    const { isSuccessPurchase, confirmPurchase } = usePurchase();

    const calculateHerzAmount = () => {
        return Math.max(2, 2 - usedLives);
    }

    const { handleShare } = useTelegramShareMessage(async () => {
      hadnleBuyLifeWithShare();
    });
    
    const hadnleBuyLifeWithShare = async () => {
      const result = await buyLifeWithShare({ clientId: userId });
      if (result) {
          getDungeonSaveOrStartDungeon();
          setShowHerzModal(false);
      }
    };

    useEffect(() => {
      if(isSuccessPurchase){
        playSound("questsReward");
        setShowHerzModal(false);
      }
      getDungeonSaveOrStartDungeon();
    }, [isSuccessPurchase]);

  // @ts-ignore
  return visibleItems && visibleItems?.length ? (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: '' };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }
                        if (componentStack) {
                              errorData.stack = componentStack;
                        }

                return ErrorReportingService.reportError({
                    type: ErrorType.runtime,
                    errorData,
                    clientId: userId,
                });
            }}
            fallback={({ error, resetError, componentStack, eventId }) => {
                return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
            }}
        >
            {/* <div className='text-white' onClick={testHandleEndDungeon}>Back Button </div> */}
            <DungeonEffect direction='up'>
                {healAnimation && (
                    <div className={`absolute top-0 z-20 h-full w-full ${fadeClass}`}>
                        <div className='fixed inset-0 w-full h-full z-[9999] shadow-[inset_0_0_40px_30px_#24b510]'></div>
                    </div>
                )}
                <Playground>
                {isSecretRoomOpen && (
                  <SecretRoom  
                  setIsSecretRoomOpen={setIsSecretRoomOpen} 
                  gold={goldAmount} 
                  currentHpAmount={currentHp}
                  currentManaAmount={currentMana}
                  hpAmount={maxHp}
                  manaAmount={2}
                  cardsAtDeck={cardsAtDeck.map((card) => {
                    return { id: card.cardId, uid: card.cardUid, lvl: card.stars };
                  })}
                  openDeck={openDeck}
                  heroName={heroName}
                  heroLevel={heroLevel}
                  isDeckOpen={isDeckOpen}
                  closeDeck={closeDeck}
                  usedLives={usedLives}
                    />
                )}
                    {isShopOpen && (
                        <DungeonShop
                            gold={goldAmount}
                            close={() => closeShop()}
                            updateGoldAmount={(totalAmount, amount) =>
                                updateGoldAmountHandler(totalAmount, amount)
                            }
                            updateSomeStates={updateSomeStates}
                        />
                    )}
                    {isSmithyOpen && (
                        <Smithy
                            cardsAtDeck={cardsAtDeck.filter(
                                (v) =>
                                    v.stars < getMaxLevel(v.cardId) &&
                                    !CARDS_WITH_1_LVL.includes(v.cardId) &&
                                    (v.stars !== 2 || !CARDS_WITH_2_LVL.includes(v.cardId))
                            )}
                            closeDeck={() => closeSmithy()}
                            gold={goldAmount}
                            updateSomeStates={updateSomeStates}
                        />
                    )}
                    {isTavernOpen && (
                        <Tavern
                            gold={goldAmount}
                            closeDeck={() => closeTavern()}
                            cardsAtDeck={cardsAtDeck}
                            updateSomeStates={updateSomeStates}
                        />
                    )}
                    {isChestOpen && (
                        <Chest
                            close={() => closeChest()}
                            updateSomeStates={updateSomeStates}
                            usingCard={usingCard}
                            dungeonId={dungeonId}
                            clientId={userId}
                            stageId={visibleItems[currentIndex].stageId}
                            packAnimation={riveFile}
                        />
                    )}
                    {isDeckOpen && (
                        <Bag
                            cardsAtDeck={cardsAtDeck.map((card) => {
                                return { id: card.cardId, uid: card.cardUid, lvl: card.stars };
                            })}
                            closeDeck={closeDeck}
                        />
                    )}
                     {showHerzModal && boughtLives < 3 && (
                      <HerzModal 
                        boughtLives={boughtLives} 
                        offerCost={heartOffers.stars.value} 
                        handleShare={handleShare} 
                        handleBuy={() => confirmPurchase({ offerId: heartOffers.offerId.value })}
                        onClose={() => setShowHerzModal(false)} 
                      />
                    )}
                    <div className='absolute w-full z-10 left-0 top-0'>
                        <HeaderCave
                            pageName={
                                dungeonConfig.filter(
                                    (dungeon: any) => dungeon.dungeonId.value === dungeonId
                                )[0].description.value
                            }
                            remainingPages={calculateRemainingPages()}
                            gold={goldAmount}
                            onClick={handleBackClick}
                        />
                        <div className="pl-3 mt-2 flex items-center gap-2 text-white">
                          {[...Array(calculateHerzAmount())].map((_, index) => (
                            <img
                              className={`w-8 h-8 object-cover ${
                                index >=
                                 [...Array(calculateHerzAmount())].length - usedLives
                                   ? `grayscale`
                                   : ``
                                }`}
                              src={require("../../assets/images/Herz.png")}
                               alt={`${index} live`}
                            />
                           ))}
                           {boughtLives < 3 &&
                            <button
                              className="relative"
                              onClick={() => {
                                setShowHerzModal(true);
                              }}
                             >
                               <img
                                className={`w-8 h-8 object-cover grayscale opacity-80`}
                                src={require("../../assets/images/Herz.png")}
                                 alt={`Additional live`}
                               />
                               <div className="absolute -bottom-2 -right-2 flex justify-center items-center scale-50">
                                 <PopupButton
                                   size="small"
                                   type="blue"
                                   width="15px"
                                   height="15px"
                                   className=""
                                 >
                                   <div></div>
                                 </PopupButton>
                                 <div className="absolute mb-[2px] text-stroke-regular">
                                  +
                                 </div>
                               </div>
                             </button>
                           }
                         </div>
                    </div>
                    {isModalVisible ? (
                        <ActionModal
                            type={visibleItems[currentIndex].type}
                            onClose={() => setModalVisible(false)}
                        />
                    ) : null}

          {/* {allItems.length - nextIndex !== 0
    ?
    <p className="text-white">
      Cards remaining: {allItems.length - nextIndex}
    </p>
    : null} */}

                    <AnimatePresence>
                        {!isFadingOut && (
                            <motion.div
                                className='items-center justify-center text-center h-full flex flex-col'
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                {isFinished ? (
                                    <div>Finish</div>
                                ) : (
                                    <div className='flex flex-row gap-[8px]'>
                                        {[
                                            // {
                                            //   id: 99999,
                                            //   type: 10,
                                            //   stageId: 1,
                                            //   amount: 1,
                                            //   currentAmount: 0,
                                            // },
                                            ...visibleItems,
                                        ].map((item, index) =>
                                            index < 3 ? (
                                                <div className={`${canceledCard === index ? 'hidden' : ''}`}>
                                                    <ActionCard
                                                        isAnimation={true}
                                                        animationOn={animationOn}
                                                        animationOff={animationOff}
                                                        //@ts-ignore
                                                        handleAction={() => {
                                                            if (isAnimationActive) return;
                                                            console.log('Item in strat', item);
                                                            //@ts-ignore
                                                            stageStrategy[item?.type!]({ stage: item });
                                                        }}
                                                        type={item.type}
                                                        active={currentIndex === index}
                                                        //@ts-ignore
                                                        title={t('dungeon.' + StageType[item?.type] + '.title')}
                                                        //@ts-ignore
                                                        description={t(
                                                            'dungeon.' + StageType[item?.type] + '.description'
                                                        )}
                                                        onClickActive={() => setCurrentIndex(index)}
                                                        name={StageType[item?.type]}
                                                        key={index}
                                                        //@ts-ignore
                                                        enemyId={
                                                            item.type === StageType.enemy ||
                                                            item.type === StageType.boss
                                                                ? //@ts-ignore
                                                                  dungeonConfig.filter(
                                                                      (dungeon: any) =>
                                                                          dungeon.dungeonId.value === dungeonId
                                                                  )[0].stages.value[item.stageId - 1]?.enemyId.value
                                                                : undefined
                                                        }
                                                        //@ts-ignore
                                                        onDisapear={() => {
                                                            if (!isAnimationActive) {
                                                                if (
                                                                    item.type === StageType.shop ||
                                                                    item.type === StageType.forge ||
                                                                    item.type === StageType.abyss
                                                                ) {
                                                                    return usingCard({
                                                                        dungeonId,
                                                                        cardId: visibleItems[index].stageId,
                                                                        clientId: userId,
                                                                        index: index,
                                                                    });
                                                                } else {
                                                                    return cancelCard(
                                                                        visibleItems[index].stageId,
                                                                        index
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        }}
                                                    />
                                                </div>
                                            ) : null
                                        )}
                                    </div>
                                )}
                                <SuccessOverlay isSuccess={isSuccessPurchase} />
                            </motion.div>
                        )}
                    </AnimatePresence>

          {/* Затемнення екрану */}
          <AnimatePresence>
            {isScreenDarkened && (
              <motion.div
                className='fixed top-0 left-0 w-full h-full bg-black  z-20'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              />
            )}
          </AnimatePresence>

          <FooterCave
            tutorial={false}
            currentHpAmount={currentHp}
            currentManaAmount={currentMana}
            additionalCards={additionalCards}
            hpAmount={maxHp}
            manaAmount={2}
            cardsAtDeck={cardsAtDeck}
            openDeck={openDeck}
            heroName={heroName}
            heroLevel={heroLevel}
          />
        </Playground>
      </DungeonEffect>
    </Sentry.ErrorBoundary>
  ) : null;
  // return <></>
};
