import { RequestWebsocket } from "../interfaces/wsInterfaces";
import { generateRequestId } from "../utils/generateRequestId";
import { sendRequestAndGetResponse } from "../Websocket/websocketInit";

export enum ReferralCodeType {
  common,
  rare,
  epic,
}

export const getCodesByClientId = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-referral",
    lang: "en",
    method: "referralCode_getCodesByClientId",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);

    if (result.type === "Left") {
      return false;
    }
    if (!result?.value) {
      throw new Error("purchase ERROR");
    }
    
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};

export const claimCodesByClientId = async (params: {
  clientId: string;
  type: ReferralCodeType;
  amount: number;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-referral",
    lang: "en",
    method: "referralCode_claim",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error("purchase ERROR");
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};

export const promoteCodesByClientId = async (params: {
  clientId: string;
  link: string;
  code: string;
  title: string;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-referral",
    lang: "en",
    method: "promotion_promote",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error("purchase ERROR");
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};

export const updatePromotionByCode = async (params: { link: string; code: string; title: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-referral",
    lang: "en",
    method: "promotion_updateLink",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error("purchase ERROR");
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};

export const getPromotions = async (params: {}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-referral",
    lang: "en",
    method: "promotion_getPromotions",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error("purchase ERROR");
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};

export const enterCode = async (params: { clientId: string; code: string }): Promise<boolean> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "user_enterCode",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);

    if (result.type === "Left") {
      return false;
    }

    return true;
  } catch (e) {
    console.error((e as Error).message);
    return false;
  }
};

export const getPromotion = async (params: { code: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-referral",
    lang: "en",
    method: "promotion_getPromotionByCode",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error("purchase ERROR");
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};
