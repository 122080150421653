export enum Resources {
  kitsu,
  stone,
  experience,
  none,
  keys,
  refPoints,
  promotions,
  "5m",
}
