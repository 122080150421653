import { CHAIN, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { Address } from "@ton/ton";
import { SenderArguments } from "@ton/ton";
import { Sender } from "@ton/ton";
import { useState } from "react";

export enum TransactionStatus {
  idle,
  pending,
  success,
  error,
}

export function useTonConnect(): {
  sender: Sender;
  connected: boolean;
  wallet: string | null;
  network: CHAIN | null;
  transactionStatus: TransactionStatus;
} {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(TransactionStatus.idle);

  return {
    sender: {
      send: async (args: SenderArguments) => {
        try {
          const result = await tonConnectUI.sendTransaction({
            messages: [
              {
                address: args.to.toString(),
                amount: args.value.toString(),
                payload: args.body?.toBoc().toString("base64"),
              },
            ],
            validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
          });
          if (result.boc) {
            setTransactionStatus(TransactionStatus.pending);
          }
        } catch (error) {
          setTransactionStatus(TransactionStatus.error);
        }
      },
      address: wallet?.account?.address ? Address.parse(wallet?.account?.address as string) : undefined,
    },
    transactionStatus,
    connected: !!wallet?.account.address,
    wallet: wallet?.account.address ?? null,
    network: wallet?.account.chain ?? null,
  };
}
