import { useTranslation } from "react-i18next";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import { useUtils } from "../../utils/navigateTo";
import PageTransition from "../../containers/Router/components/PageTransition";
import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useTelegram } from "../../hooks/useTelegram";
import { PopupButton } from "../../components/PopupButton";
import * as Sentry from "@sentry/react";
import { ErrorType, RuntimeErrorData } from "../../interfaces/error";
import { ErrorReportingService } from "../../services/errorReportingService";
import { ErrorComponent } from "../../components";
import {
  getLeaderboard,
  ILeaderboard,
  LeaderboardType,
} from "../../endpoints/Leaderboard/getLeaderboard";
import { Outlet, useLocation } from "react-router-dom";
import { LeaderboardUser } from "./components/LeaderboardUser";
import { formatLeaderboardNumber } from "./utils/formatLeaderboardNumber";

export const LeaderboardPage = () => {
  const { t } = useTranslation();
  const { goBack, navigateTo } = useUtils();
  const dispatch = useDispatch<AppDispatch>();
  const { tg, userId } = useTelegram();
  const location = useLocation();

  const [safeAreaTop, setSafeAreaTop] = React.useState(() => {
    return sessionStorage.getItem("safeAreaTop") || 0;
  });

  const [currentTab, setCurrentTab] = React.useState(0);
  const leaderboardTabs = ["Top Holders", "Top Referrals"];
  const [leaderboard, setLeaderboard] = React.useState<ILeaderboard>();
  const [loading, setLoading] = React.useState(false);

  const fetchLeaderboard = async (type: LeaderboardType) => {
    try {
      setLoading(true);
      const data = await getLeaderboard({
        clientId: userId,
        leaderboardType: currentTab,
      });
      if (data) {
        setLeaderboard(data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    fetchLeaderboard(currentTab);
  }, [currentTab]);

  const PAGES = ["/leaderboard/alliance-join"];

  const isCurrentPageInArray = PAGES.some((page) =>
    location.pathname.includes(page)
  );


  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: "" };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return (
          <ErrorComponent
            jsError={{ error, resetError, componentStack, eventId }}
          />
        );
      }}
    >
      <PageTransition>
        {!isCurrentPageInArray && (
          <>
            <div className="absolute top-0 flex justify-start items-center w-[550px] bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black z-[999]">
              <div className="absolute top-[1px] pl-3">
                <HandleBackButton onClick={goBack} />
              </div>
              <div className="text-white text-2xl font-black leading-normal pl-16 text-shadow-black-sm">
                Leaderboard
              </div>
            </div>

            <div
              className={`absolute top-0 w-full h-full max-w-[550px] mx-auto z-[56] shadow-inner-sm-black overflow-y-auto`}
              style={{
                background:
                  "radial-gradient(circle at center, #332B26 20%, rgba(0,0,0,0.5) 100%)",
              }}
            >
              <div className="relative bg-[#201B18] border-2 border-t-0 border-[#534038] z-50 mt-10 pt-2">
                {/* Tab buttons */}
                <div className="flex items-center justify-between text-white text-sm mx-4 p-1 bg-[#463A35] rounded-lg mb-3">
                  {leaderboardTabs.map((tab, index) => (
                    <button
                      key={index}
                      className={`flex-1 px-5 py-2 rounded-md ${
                        currentTab === index ? `bg-[#25201C]` : `text-[#8A807C]`
                      }`}
                      onClick={() => {
                        setCurrentTab(index);
                      }}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
                {/* Top-3 */}
                {leaderboard?.users && leaderboard?.users.length > 0 && (
                  <div className="flex items-center justify-center gap-4 relative">
                    <LeaderboardUser
                      username={leaderboard.users[1].username}
                      place={2}
                      amount={leaderboard.users[1].amount}
                      currentTab={currentTab}
                      imgSrc={leaderboard.users[1].avatar}
                    />
                    <LeaderboardUser
                      username={leaderboard.users[0].username}
                      place={1}
                      amount={leaderboard.users[0].amount}
                      currentTab={currentTab}
                      imgSrc={leaderboard.users[0].avatar}
                    />
                    <LeaderboardUser
                      username={leaderboard.users[2].username}
                      place={3}
                      amount={leaderboard.users[2].amount}
                      currentTab={currentTab}
                      imgSrc={leaderboard.users[2].avatar}
                    />
                  </div>
                )}

                {/* Arrow devider */}
                <div
                  className="absolute -bottom-8 w-full h-8 bg-[#201B18]"
                  style={{
                    clipPath: "polygon(0 0, 100% 0, 50% 100%)",
                    background: "inherit",
                    zIndex: "3",
                  }}
                ></div>
                <div
                  className="absolute -bottom-[34px] w-full h-8 bg-[#534038]"
                  style={{
                    clipPath: "polygon(0 0, 100% 0, 50% 100%)",
                    zIndex: "2",
                  }}
                ></div>
                <div
                  className="absolute -bottom-9 w-full h-8 bg-black"
                  style={{
                    clipPath: "polygon(0 0, 100% 0, 50% 100%)",
                    zIndex: "1",
                  }}
                ></div>
              </div>
              {/* Other holders */}
              {leaderboard?.users && leaderboard?.users.length > 0 && (
                <div className="relative h-fit">
                  <div className="w-full bg-[#242520] border border-[#18191a] rounded-[2px] -mt-1 p-3 pt-0 shadow-inner-sm-white">
                    <div
                      className="relative w-full bg-[#201b18] rounded-[1px] p-3 shadow-inner-sm-black h-full"
                      style={{
                        background:
                          "radial-gradient(circle at center, #332B26 20%, #201B18 100%)",
                      }}
                    >
                      {/* Top 4-7 */}
                      <div className="flex justify-between items-stretch mt-7 mb-[18px]">
                        {leaderboard.users.slice(3, 7).map((user, index) => (
                          <LeaderboardUser
                            key={index}
                            username={user.username}
                            place={index + 4}
                            amount={user.amount}
                            currentTab={currentTab}
                            imgSrc={user.avatar}
                          />
                        ))}
                      </div>

                      {/* My stats */}
                      {currentTab === LeaderboardType.kitsuSupply && (
                        <div className="flex items-center gap-2 mb-2">
                          <div
                            className="py-3 flex-1 bg-[#473D37] border border-[#362D28] rounded cursor-pointer"
                            onClick={() => {
                              navigateTo("/profile");
                            }}
                          >
                            <div className="flex items-center justify-center gap-1 mb-2">
                              <img
                                className="w-[18px] h-[18px] mr-[2px]"
                                src={require("../../assets/images/kitsu-icon.webp")}
                                alt=""
                              />

                              <span className="text-lg uppercase text-white text-stroke-regular font-extrabold leading-none">
                                {leaderboard.myScore
                                  ? formatLeaderboardNumber(leaderboard.myScore)
                                  : "-"}
                              </span>
                            </div>
                            <div className="flex items-center justify-center text-[#E4B98F]">
                              <span className="text-[15px] mr-1">
                                Your Balance
                              </span>
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex items-center"
                              >
                                <path
                                  d="M5.95463 1.84049C5.74373 2.05146 5.62525 2.33756 5.62525 2.63587C5.62525 2.93418 5.74373 3.22027 5.95463 3.43124L11.5234 8.99999L5.95463 14.5687C5.7497 14.7809 5.63631 15.0651 5.63887 15.3601C5.64144 15.655 5.75975 15.9372 5.96834 16.1458C6.17692 16.3544 6.45908 16.4727 6.75406 16.4753C7.04903 16.4778 7.3332 16.3644 7.54538 16.1595L13.9095 9.79537C14.1204 9.5844 14.2389 9.2983 14.2389 8.99999C14.2389 8.70168 14.1204 8.41559 13.9095 8.20462L7.54538 1.84049C7.33441 1.62959 7.04832 1.51111 6.75001 1.51111C6.4517 1.51111 6.1656 1.62959 5.95463 1.84049Z"
                                  fill="#E4B98F"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="py-3 flex-1 bg-[#473D37] border border-[#362D28] rounded">
                            <div className="flex items-center justify-center mb-2">
                              <span className="text-lg uppercase text-white text-stroke-regular font-extrabold leading-none">
                                #
                                {leaderboard.myPlace
                                  ? leaderboard.myPlace
                                  : "-"}
                              </span>
                            </div>
                            <div className="flex items-center justify-center text-[#E4B98F]">
                              <span className="text-[15px] mr-1">
                                Your Rank
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentTab === LeaderboardType.referrals && (
                        <div className="flex items-center gap-2 mb-2">
                          <div
                            className="py-3 flex-1 bg-[#473D37] border border-[#362D28] rounded cursor-pointer"
                            onClick={() => {
                              navigateTo("/friends");
                            }}
                          >
                            <div className="flex items-center justify-center gap-1 mb-2">
                              <img
                                className="w-[18px] h-[18px] mr-[2px]"
                                src={require("../../assets/images/referrals_leaderboard_icon.png")}
                                alt=""
                              />
                              <span className="text-lg uppercase text-white text-stroke-regular font-extrabold leading-none">
                                {leaderboard.myScore
                                  ? formatLeaderboardNumber(leaderboard.myScore)
                                  : "-"}
                              </span>
                            </div>
                            <div className="flex items-center justify-center text-[#E4B98F]">
                              <span className="text-[15px] mr-1">
                                Your Friends
                              </span>
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex items-center"
                              >
                                <path
                                  d="M5.95463 1.84049C5.74373 2.05146 5.62525 2.33756 5.62525 2.63587C5.62525 2.93418 5.74373 3.22027 5.95463 3.43124L11.5234 8.99999L5.95463 14.5687C5.7497 14.7809 5.63631 15.0651 5.63887 15.3601C5.64144 15.655 5.75975 15.9372 5.96834 16.1458C6.17692 16.3544 6.45908 16.4727 6.75406 16.4753C7.04903 16.4778 7.3332 16.3644 7.54538 16.1595L13.9095 9.79537C14.1204 9.5844 14.2389 9.2983 14.2389 8.99999C14.2389 8.70168 14.1204 8.41559 13.9095 8.20462L7.54538 1.84049C7.33441 1.62959 7.04832 1.51111 6.75001 1.51111C6.4517 1.51111 6.1656 1.62959 5.95463 1.84049Z"
                                  fill="#E4B98F"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="py-3 flex-1 bg-[#473D37] border border-[#362D28] rounded">
                            <div className="flex items-center justify-center mb-2">
                              <span className="text-lg uppercase text-white text-stroke-regular font-extrabold leading-none">
                                #
                                {leaderboard.myPlace
                                  ? leaderboard.myPlace
                                  : "-"}
                              </span>
                            </div>
                            <div className="flex items-center justify-center text-[#E4B98F]">
                              <span className="text-[15px] mr-1">
                                Your Rank
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Alliance Buttons */}
                      {/* <div className="flex items-center gap-2 mb-3">
                        <PopupButton
                          type="blue"
                          size="big"
                          className="flex-1"
                          onClick={() => {
                            navigateTo("/leaderboard/alliance-join");
                          }}
                        >
                          Join Alliance
                        </PopupButton>
                        <PopupButton type="green" size="big" className="flex-1">
                          Create Alliance
                        </PopupButton>
                      </div> */}

                      {/* Separator */}
                      <div className="flex items-center gap-3 mb-4">
                        <div className="flex-1 ">
                          <div className="w-full h-[3px] bg-[#181615]"></div>
                          <div className="w-full h-[3px] bg-[#3b332e]"></div>
                        </div>

                        <img
                          className=""
                          src={require("../../assets/images/union.png")}
                          alt=""
                        />

                        <div className="flex-1">
                          <div className="w-full h-[3px] bg-[#181615]"></div>
                          <div className="w-full h-[3px] bg-[#3b332e]"></div>
                        </div>
                      </div>

                      {/* Other top players */}
                      <h3 className="text-[20px] text-center uppercase text-white text-stroke-regular font-extrabold leading-none mb-2">
                        {currentTab === LeaderboardType.kitsuSupply &&
                          "Top holders"}
                        {currentTab === LeaderboardType.referrals &&
                          "Top referrals"}
                      </h3>
                      <div className="flex flex-col items-center w-full gap-1">
                        {leaderboard.users.slice(7).map((user, index) => (
                          <LeaderboardUser
                            key={index}
                            username={user.username}
                            place={index + 8}
                            amount={user.amount}
                            currentTab={currentTab}
                            imgSrc={user.avatar}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {isCurrentPageInArray && <Outlet />}
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
