// src/components/AnimatedResource.jsx

import React from "react";
import { motion } from "framer-motion";
import { resourcesMap } from "../../../../../../../utils/mapping";
import { Resources } from "../../../../../../../enums/resources";

export const AnimatedResource = ({
  iconType,
  startPosition,
  endPosition,
  onAnimationComplete,
  spawnPosition,
}: {
  iconType: any;
  startPosition: any;
  endPosition: any;
  onAnimationComplete: any;
  spawnPosition: any;
}) => {
  const randomDelay = Math.random() * 0.5; // Випадкова затримка до 0.5 секунд

  return (
      <>
          <motion.div
              initial={{
                  x: spawnPosition.x,
                  y: spawnPosition.y,
                  opacity: 1,
                  scale: 0.5,
              }}
              animate={{
                  x: [spawnPosition.x, startPosition.x, endPosition.x], // Ключевые точки для X
                  y: [spawnPosition.y, startPosition.y, endPosition.y], // Ключевые точки для Y
                  opacity: 1,
                  scale: [0.5, 1, 0.5], // Увеличение, а потом уменьшение
              }}
              transition={{
                  duration: 1.5, // Общая длительность анимации
                  ease: "easeInOut",
                  times: [0, 0.5, 1], // Тайминг для ключевых кадров
              }}
              onAnimationComplete={onAnimationComplete} // Завершение анимации
              style={{
                  position: "absolute",
                  pointerEvents: "none",
                  zIndex: 999,
              }}
          >
              <div className="w-[40px] h-[40px]">
                  <img src={resourcesMap[iconType as Resources].image} />
              </div>
          </motion.div>
    </>
  );
};
/*
        <div className="w-[40px] h-[40px]">
          <img src={resourcesMap[iconType as Resources].image} />
        </div>
*/