import { motion } from "framer-motion";
import { PopupWindow } from "../../../../../../../components/PopupWindow";
import { useSessionStorage } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { PopupButton } from "../../../../../../../components/PopupButton";
import { claimCodesByClientId, enterCode, ReferralCodeType } from "../../../../../../../endpoints/codesEndpoints";
import { useTelegram } from "../../../../../../../hooks/useTelegram";
import { useTranslation } from "react-i18next";
import { getBalance } from "../../../../../../../endpoints/farmMock";
import { ClaimOptions } from "../../../DepositPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { Resources } from "../../../../../../../enums/resources";
import { resourcesMap } from "../../../../../../../utils/mapping";
import Slider from "rc-slider";

const depositPopupVariants = {
  hidden: { opacity: "0", transform: "translateY(110%)" },
  visible: { opacity: "1", transform: "translateY(-50%)" },
};

const EnterCodePopupHeader = ({}: {}) => {
  const { t } = useTranslation();
  window.location.hash = "input";

  return (
    <>
      <div className="absolute top-[-15px] left-0 right-0 flex justify-self-center z-20">
        <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
          <div className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border
            border-[#18191a] shadow-inner-sm-black">
            {t("codePurchase.claimCode")}
          </div>
        </div>
      </div>
    </>
  );
};

export const ClaimCodePopup = ({
  claimOffer,
  onClose,
  handleClaimCodes,
}: {
  claimOffer: ClaimOptions;
  onClose: () => void;
  handleClaimCodes: (type: ReferralCodeType, amount: number) => void;
}) => {
  const [openEnterCodePopup, setOpenEnterCodePopup] = useSessionStorage("openEnterCodePopup", false);
  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>("shouldHideElementKey", true);
  const [balanceTooltip, setBalanceTooltip] = useState<boolean>(false);
  const { userId } = useTelegram();
  const { t } = useTranslation();
  const [refBalance, setRefBalance] = useState(0);
  const [count, setCount] = useState(1);
  const resources = useSelector((state: RootState) => state.resources.resources);

  useEffect(() => {
    const currentValue = resources.find((v) => v.resourceType === Resources.refPoints)?.value || 0;
    setRefBalance(currentValue);
  }, [resources]);


  const maxCount = () => {
    if (claimOffer.type === 0) {
      return Math.floor(refBalance / 50000);
    } else if (claimOffer.type === 1) {
      return Math.floor(refBalance / 200000);
    } else if (claimOffer.type === 2) {
      return Math.floor(refBalance / 800000);
    } else {
      return 0;
    }
  };

  const handleSliderChange = (val: number | number[]) => {
    if (val > 0) setCount(+val);
  };

  const handleIncrease = () => {
    if (count < maxCount()) setCount((prev) => prev + 1);
  };

  const handleDecrease = () => {
    if (count > 1) setCount((prev) => prev - 1);
  };

  return (
    <motion.div
      variants={depositPopupVariants}
      initial="hidden"
      animate="visible"
      className={`absolute w-[97%] min-h-[300px] top-[50%] translate-y-[-50%] left-[1.5%] flex justify-center 
        align-center z-[2]`}
    >
      <PopupWindow
        onClickClose={() => {
          window.location.hash = "";
          onClose();
        }}
        headerElement={<EnterCodePopupHeader />}
      >
        <div className="flex justify-center items-center">
          <div className="text-center text-white text-[20px] leading-tight mt-[30px] mb-[26px] border border-[#5e5546]
            rounded-[6px] bg-[#18191a] py-1.5 px-2.5">
            Balance: <span className="font-black">{refBalance} Pts</span>
          </div>
          <div className="relative" onClick={() => setBalanceTooltip(!balanceTooltip)}>
            <img src={require('../../../../../../../assets/images/tooltip-icon.png')} className="w-5 h-5 ml-2.5"
                 alt=""/>
            <div
              className="absolute w-[166px] text-center text-[10px] font-bold bg-white border border-[#45362e]
              rounded-[4px] leading-[1.2] p-2 mx-auto text-black top-full mt-2 -right-4"
              style={{
                opacity: balanceTooltip ? "1" : "0",
                display: balanceTooltip ? "block" : "none",
              }}
              onClick={() => setBalanceTooltip(false)}
            >
              <div
                className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
              border-r-transparent border-b-[#45362e] bottom-full right-[11%]"
              ></div>
              <div
                className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
              border-r-transparent border-b-white bottom-full right-[11%] -mb-[1px]"
              ></div>
              {t("codePurchase.balanceTooltip")}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center gap-6">
          <div className={`relative w-[72px] h-[72px] min-w-[72px] flex flex-col gap-1 border border-white 
            bg-gradient-to-b rounded-md ${
            claimOffer.type === 0 ? "from-[#33a8cc] to-[#0d81b6]" :
              claimOffer.type === 1 ? "from-[#d95bd8] to-[#9245df]" :
                claimOffer.type === 2 ? "from-[#fffe2e] to-[#fe8315]" : ""
          }
          `}>
            <div className="flex justify-center items-center h-full">
              <img className="w-[65px]"
                   src={require("../../../../../../../assets/images/ticket-icon.png")} alt="Ticket Icon"/>
            </div>

            <div className="absolute right-1 bottom-1 text-right text-white text-[17px] font-bold leading-3
              text-shadow-black-sm">
              {claimOffer.type === 0 ? "10" :
                claimOffer.type === 1 ? "50" :
                  claimOffer.type === 2 ? "200" : ""}
            </div>
          </div>
          <div className={`relative w-[72px] h-[72px] min-w-[72px] flex flex-col gap-1 border border-white 
              bg-gradient-to-b rounded-md ${
                claimOffer.type === 0
                  ? "from-[#33a8cc] to-[#0d81b6]"
                  : claimOffer.type === 1
                  ? "from-[#d95bd8] to-[#9245df]"
                  : claimOffer.type === 2
                  ? "from-[#fffe2e] to-[#fe8315]"
                  : ""
              }
          `}
          >
            <div className="flex justify-center items-center h-full">
              <img className="w-[48px]" src={resourcesMap[Resources.promotions].image} alt="Ticket Icon" />
            </div>

            <div
              className="absolute right-1 bottom-1 text-right text-white text-[17px] font-bold leading-3
              text-shadow-black-sm"
            >
              {claimOffer.type === 0 ? "1" : claimOffer.type === 1 ? "4" : claimOffer.type === 2 ? "16" : ""}
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2 mx-4 mt-6">
          <PopupButton onClick={handleDecrease} disabled={count <= 1} type="blue" width="26px" height="26px">
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-[45px]">
              <div className="pb-[3px]">-</div>
            </div>
          </PopupButton>

          <div className="flex-1 w-[100px]">
            <Slider
              min={0}
              max={maxCount()}
              value={count}
              onChange={handleSliderChange}
              styles={{
                track: {
                  backgroundColor: "#3dbd85",
                  height: 16,
                  borderRadius: 2,
                },
                handle: {
                  height: 28,
                  width: 12,
                  backgroundColor: "#407FB7",
                  border: "1px solid black",
                  borderRadius: 2,
                  cursor: "pointer",
                  transform: "translate(-50%, 5%)",
                  opacity: 100,
                },
                rail: {
                  backgroundColor: "#ddd",
                  height: 16,
                  borderRadius: 2,
                },
              }}
              className="w-full flex items-center"
            />
          </div>

          <PopupButton onClick={handleIncrease} disabled={count === maxCount()} type="blue" width="26px" height="26px">
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-[45px]">
              <div className="pb-[1px]">+</div>
            </div>
          </PopupButton>

          <input
            type="text"
            value={count}
            readOnly
            className="w-[60px] h-[32px] bg-[#1b1817] text-center rounded shadow-inner
              border border-[#554837] text-white text-base font-normal  leading-none outline-0"
          />
        </div>

        <PopupButton
          type="green"
          size="big"
          className="my-3"
          onClick={() => {
            handleClaimCodes(claimOffer.type, count);
          }}
        >
          <span className="text-[18px] px-4">{claimOffer.amount * count} Pts</span>
        </PopupButton>
      </PopupWindow>
    </motion.div>
  );
};
