import React, { useEffect, useState } from "react";
import { HeroRarity, Tree } from "../../../../interfaces/hero";
import { TitleField } from "../../../Room";
import { useTranslation } from "react-i18next";
import { HeroRating } from "../HeroRating";
import { getHeroesShards, getHeroTierUpgradePrice } from "../../../../endpoints/heroEndpoints";
import { useTelegram } from "../../../../hooks/useTelegram";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { TutorialFarmSave, TutorialFarmStage } from "../../../../interfaces/tutorial";
import { saveFarmTutorialProgress } from "../../../../app/features/farmTutoralSlice";
import { CategoryItem, HeroesItem } from "../../../Inventory";
import { ItemType, UniversalShard } from "../../../../interfaces/lootBotx";
import { universalShardMap } from "../../../../utils/mapping";

interface HeroComponentProps {
  id: number;
  name: string;
  img: string;
  lvl: number;
  rating: { claimedLevels: number; totalLevels: number };
  rarity: string;
  selected: boolean;
  available: boolean;
  inDungeon: boolean;
  upgradeCards: number;
  hasLevelUp: boolean;
  treesData: Tree[];
}

export const HeroComponent: React.FC<HeroComponentProps> = ({
  id,
  name,
  img,
  lvl,
  rating,
  rarity,
  selected,
  inDungeon,
  available,
  hasLevelUp,
  upgradeCards,
  treesData
}) => {
  const { t } = useTranslation();
  console.log(rarity as HeroRarity);
  const [hasTierUp, setHasTierUp] = useState<boolean>(false);
  const { userId } = useTelegram();

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  const fetchUniversalShards = async () => {
    try {
      const responseHeroesShards = await getHeroesShards({
        clientId: userId,
      });

      const mappedUniversalShards: HeroesItem[] =
        responseHeroesShards.universalShards.map((item, index) => {
          return {
            id: `universalShards-${item.shardId}-${index}`,
            type: ItemType.universalShard,
            name: universalShardMap[item.shardId as UniversalShard].name,
            description:
              universalShardMap[item.shardId as UniversalShard].description,
            category: CategoryItem.Heroes,
            amount: item.amount,
            icon: universalShardMap[item.shardId as UniversalShard].image,
            rarity: item.shardId,
          };
        });

      if (mappedUniversalShards) {
        return mappedUniversalShards.find((shard) => shard.rarity === 0)!
          .amount;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchHeroTierUpgradePrice = async () => {
    try {
      const price = await getHeroTierUpgradePrice({
        clientId: userId,
        heroId: id,
      });
      return price;
    } catch (error) {}
  };
  
  useEffect(() => {
    const hasTierUpgrade = async () => {
      const universalShards = await fetchUniversalShards();
      const tierUpgradePrice = await fetchHeroTierUpgradePrice();
      const allTiersUpgrade: boolean[] = [];
      
      if (tierUpgradePrice) {
        treesData.forEach((node: any) => allTiersUpgrade.push(node.isClaimed));
        setHasTierUp((upgradeCards > tierUpgradePrice || universalShards! + upgradeCards > tierUpgradePrice) && !allTiersUpgrade.every((isClaimed) => isClaimed));
      }
    };

    if(treesData.length > 0){
      hasTierUpgrade();
    }
  }, [treesData]);

  return (
    <div
    className={`relative aspect-[0.75] h-full w-full max-h-[215px] shrink-0 bg-no-repeat p-[2px]`}
      style={{
        backgroundImage: `url(${require("../../../../assets/images/heroes/cards/hero-list-border-3.png")})`,
        backgroundSize: "100% 100%",
      }}
    >
      {available && farmTutorialSave?.stage === TutorialFarmStage.clickOnHeroes && 
      <div className="top-[4%] absolute w-[95%] h-[95%] borer rounded-lg shadow-[0_0_15px_rgba(222,129,24,0.9)]"></div>}
      {!available && (
        <div className="absolute z-10 -top-3 w-full left-1/2 -ml-[50%]">
          <TitleField title="COMING SOON" fontSize="11px" />
        </div>
      )}

      {/* {inDungeon && (
        <div className="absolute z-10 -top-3 w-full left-1/2 -ml-[50%]">
          <TitleField title="Dungening" fontSize="14px" />
        </div>
      )}
       */}
      <div
        className="relative h-full w-full bg-no-repeat p-[2px]"
        style={{
          backgroundImage: `url(${require(`../../../../assets/images/heroes/cards/hero-list-border-2-${rarity}.png`)})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div
          className="relative h-full w-full bg-no-repeat p-[3px]"
          style={{
            backgroundImage: `url(${require("../../../../assets/images/heroes/cards/hero-list-border-1.png")})`,
            backgroundSize: "100% 100%",
          }}
        >
          <div className="relative h-full w-full z-0">
            <div className="relative w-full h-full flex items-center justify-center ">
              <img
                className="h-full absolute inset-0 w-full z-10 rounded-lg"
                src={img}
                alt={name}
              />

              <div className="absolute w-full h-[80%] bottom-0 bg-gradient-to-t from-black to-transparent z-20 rounded-lg"></div>

              {lvl > 0 && (
                <div className="absolute top-1.5 left-1.5 text-white text-center text-[10px] leading-[1] z-30">
                  {lvl}
                  <div className="text-[8px]">Lvl</div>
                </div>
              )}

              <div className="absolute w-full bottom-5 text-white text-center text-[12px] z-30">
                {name}
              </div>

              <div className="absolute bottom-2 w-[80%] mx-auto flex justify-center space-x-1 z-30">
                <HeroRating
                  claimedLevels={rating.claimedLevels}
                  totalLevels={rating.totalLevels}
                  starsWidth={"w-[12px]"}
                />
              </div>

              <div
                className="absolute inset-0 h-full w-full bg-no-repeat bg-center z-40"
                style={{
                  backgroundImage: `url(${require(`../../../../assets/images/heroes/cards/hero-list-border-${rarity}.png`)})`,
                  backgroundSize: "100% 100%",
                }}
              ></div>

              {/* Додані зображення */}
              {/* Зображення notification.png у верхньому правому куті */}
              {hasLevelUp && (
                <img
                  className="absolute -top-[6px] -right-[6px] z-50 w-3 h-3"
                  src={require("../../../../assets/images/heroes/cards/notification.png")}
                  alt="Notification"
                />
              )}

              {/* Зображення level-up.png у нижньому правому куті */}
              {hasTierUp && (
                <img
                  className="absolute -bottom-[6px] -right-[6px] z-50 w-3 h-3"
                  src={require("../../../../assets/images/heroes/cards/level-up.png")}
                  alt="Level Up"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {!available && (
        <div className="absolute inset-0 bg-black opacity-50 z-60 rounded-2xl"></div>
      )}
    </div>
  );
};
