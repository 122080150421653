import { BrowserRouter } from "react-router-dom";
import { openWebSocket } from "../../Websocket/websocketInit";
import { useEffect } from "react";
import { AppDispatch, RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { AnimatedRoutes } from "./components";
import {useSoundService} from "../../utils/soundService";
import {fetchAppConfig} from "../../app/features/appConfigSlice";
import {useTelegram} from "../../hooks/useTelegram";

export const Router = () => {
  const dispatch = useDispatch<AppDispatch>();
  const ws = useSelector((state: RootState) => state.webSocket?.webSocket);
  const webSocketStatus = useSelector(
    (state: RootState) => state.webSocket.status
  );
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { isActive } = useTelegram();

  useEffect(() => {
    // console.log("before open WS");
    if (!['connected', 'connecting'].includes(webSocketStatus)) {
      //dispatch(openWebSocket());
    }
  }, [dispatch, webSocketStatus]);

  useEffect(() => {

  }, [ws]);

  useEffect(() => {
    if (ws?.readyState === 1 && !Object.keys(appConfig).length) {
      dispatch(fetchAppConfig());
    }
  }, [dispatch, ws, appConfig]);

  // console.log('WS', ws);
  return <BrowserRouter>{ws?.readyState && Object.keys(appConfig).length && <AnimatedRoutes />}</BrowserRouter>;
};
