import { useDispatch, useSelector } from 'react-redux';
import { HandleBackButton } from '../../../layout/components/HeaderCave/components';
import { useUtils } from '../../../utils/navigateTo';
import { AppDispatch, RootState, store } from '../../../app/store';
import { Room } from '../Room';
import { farmRoomMock, islandsConfigMock } from '../../../mock/buildings';
import { setSelectedRoom } from '../../../app/features/selectedRoom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BuildingPopup } from './components/BuildingPopup';
import { setSelectedIsland } from '../../../app/features/selectedIsland';
import {
  BuilderIcon,
  BuilderOffer,
  BuilderQueue,
  CheckInIcon,
  ClickAnimation,
  CloudsAnimation,
  ErrorComponent,
  EventIcon,
  Explosion,
  ShareMessage,
} from '../../../components';
import FarmEffect from '../../Router/components/FarmEffect';
import { useLocalStorage, useSessionStorage } from '@uidotdev/usehooks';
import { getBalance, getIslands } from '../../../endpoints/farmMock';
import { useTelegram } from '../../../hooks/useTelegram';
//import * as amplitude from "@amplitude/analytics-browser";
import { setDialogueInfo } from '../../../app/features/dialoguesSlice';
import { useTranslation } from 'react-i18next';
import { TutorialFarmSave, TutorialFarmStage, TutorialSave, TutorialStage } from '../../../interfaces/tutorial';
import TypingEffect from '../../../components/TypingEffect';
import { Preloader } from '../../../layout/components/Preloader';
import { fetchFarmTutorialProgress, saveFarmTutorialProgress } from '../../../app/features/farmTutoralSlice';
import { getFarmConfig } from '../../../endpoints/configEndpoint';
import { updateFarmTutorialProgress, updateTutorialProgress } from '../../../endpoints/tutorialProgress';
import { fetchConfig } from '../../../app/features/configSlice';
import {
  getDungeonProgressByDungeonId,
  getDungeonProgressSafe,
  resetProgress,
} from '../../../endpoints/dungeonEndpoints';
import { BuildingMask, CloudAnimation } from '../../../components/animation/CloudAnimation';
import { DungeonComplete } from '../../../components/DungeonComplete';
import { resetDungeon } from '../../../app/features/dungeonCompleted';
import { Explosions } from '../../../components/animation/Explosion';
import { Resource } from '../../../mock/resources';
import { Resources } from '../../../enums/resources';
import { setResource } from '../../../app/features/resourcesSlice';
import PageTransition from '../../Router/components/PageTransition';
import { PopupButton } from '../../../components/PopupButton';
import { useFarm } from '../../../pages/Farm/useFarm';
import { SpeedUpPopUp } from './components/SpeedUpPopUp';
import { RoomStatus } from '../../../enums/buildingStatus';
import { fetchActiveBoosts } from '../../../app/features/inventorySlice';
import { fetchIslands } from '../../../app/features/islandsSlice';
import { OpenLootBox } from '../../Inventory/components';
import { APP_ENV } from '../../../config';
import { ConnectWalletIcon } from '../../../components/ConnectWalletIcon';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import {
  getProfileInfo,
  getUserDeposites,
  getUserOffers,
  updateProfileWallet,
} from '../../../endpoints/getProfileInfo';
import * as Sentry from '@sentry/react';
import { resourcesMap } from '../../../utils/mapping';
import { FallbackComponent } from '../../FallbackComponent';
import { ErrorReportingService } from '../../../services/errorReportingService';
import { ErrorType, RuntimeErrorData } from '../../../interfaces/error';
import { DepositIcon } from '../../../components/DepositIcon';
import { DepositPopup } from './components/DepositPopup';
import { useSoundService } from '../../../utils/soundService';
import { PurchasePopup } from './components/PurchasePopup';
import { setSave } from '../../../app/features/tutorialSaveSlice';
import { preloadImages } from '../../../utils/preloadImages';
import { CheckIn } from '../../CheckIn';
import { markStepAsLoaded } from '../../../app/features/loadingManagerSlice';

export const IslandWindow = () => {
  const [currentRoomPopup, setCurrentRoomPopup] = useState<number>();
  const [openSpeed, setOpenSpeed] = useSessionStorage<any>('openSpeed', false);
  const { playSound } = useSoundService();
  const { userId } = useTelegram();
  const [speed, setSpeed] = useState(50);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [dialogStage, setDialogStage] = useState(0);
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);
  const [userDepositedAmount, setUserDepositedAmount] = useState(null);
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [currentDungeonId, setCurrentDungeonId] = useSessionStorage('currentDungeonId', 0);

  const { navigateTo } = useUtils();
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();

  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);

  const [buildingMasks, setBuildingMasks] = useState<BuildingMask[]>([
    { height: 187, width: 163, x: 136, y: 413 },
    { height: 200, width: 200, x: 230, y: 470 },
    { height: 195, width: 146, x: 0, y: 452 },
  ]);
  const buildingRefs = useRef<Record<number, HTMLDivElement | null>>({});

  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>('shouldHideElementKey', true);

  const { pausedMusic, playMusic } = useSoundService();

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  const fetchBalance = async () => {
    if (userId) {
      await getBalance({ clientId: `${userId}` });
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const handleOpenDepositModal = () => {
    setOpenedDepositModal(!openedDepositModal);
  };
  const handleOpenCheckIn = () => {
    setOpenCheckIn(!openCheckIn);
  };
  const isFarmTutorialCompleted = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave?.save?.completed
  );

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const dungeonIsCompleted = useSelector((state: RootState) => state.dungeonCompleted.dungeonCompleted);

  const dungeonResources = useSelector((state: RootState) => state.dungeonCompleted.resources);

  const { buildingConfigs } = useSelector((state: RootState) => state.config);

  // let dungeonIsCompleted = true

  // const dungeonResources: Resource[] = [
  //   { resourceType: Resources.experience, value: 100 },
  //   { resourceType: Resources.kitsu, value: 50 },
  //   { resourceType: Resources.stone, value: 30 },
  // ];

  // useEffect(() => {
  //   console.log("getFarmConfig")
  //   getFarmConfig();
  // }, []);
  const [isResoursesShown, setResoursesShown] = useState(false);
  const [isExplousenShown, setExplousenShown] = useState(false);

  useEffect(() => {
    if (dungeonIsCompleted) {
      setTimeout(() => {
        setResoursesShown(true);
      }, 1000);
    }
  }, [dungeonIsCompleted]);

  useEffect(() => {
    if (dungeonIsCompleted && isResoursesShown) {
      playSound('dungeonReward');
    }
  }, [dungeonIsCompleted, isResoursesShown]);

  useEffect(() => {
    if (!farmTutorialSave?.stage) {
      if (userId) {
        dispatch(fetchFarmTutorialProgress(userId));
      }
    }
  }, [userId, dispatch]);

  const updateBattleTutorialSave = ({ save }: { save: TutorialSave }) => {
    // const save = { stage: TutorialStage.stone }
    dispatch(setSave(save));
    const updatingSave = async () => {
      await updateTutorialProgress({
        clientId: userId,
        save: JSON.stringify(save),
      });
    };
    updatingSave();
  };

  useEffect(() => {
    updateBattleTutorialSave({ save: { completed: true } });
  }, []);

  const dialogueId: number = farmTutorialSave?.dialogueId ?? 1;
  const phrases = t(`farm.dialogues.dialogue${dialogueId}.phrases`, {
    returnObjects: true,
  });

  const handleTypingComplete = () => {
    setIsTypingComplete(true);
  };

  const updateSave = async (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  let selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);

  // console.log("SELECTED ISLAND", selectedIsland);
  // useEffect(() => {
  //   const fetchIslandsData = async () => {
  //     if (userId) {
  //       await getIslands({ clientId: `${userId}` });
  //     }
  //   };

  //   fetchIslandsData();
  // }, [userId]);

  useEffect(() => {
    dispatch(fetchIslands(`${userId}`));
  }, []);

  const { islands } = useFarm();

  const selectedRoom = useSelector((state: RootState) => state.selectedRoom);

  if (!selectedIsland && islands.length > 0) {
    store.dispatch(setSelectedIsland({ island: islands[0] }));
    selectedIsland = islands[0];
  }
  useEffect(() => {
    if (selectedRoom.id !== -1 && selectedRoom.buildingId) {
      setCurrentRoomPopup(selectedRoom.id);
    } else {
      setCurrentRoomPopup(undefined);
      setOpenSpeed(false);
    }
  }, [selectedRoom]);

  useEffect(() => {
    if (islands.length && buildingConfigs) {
      const building = islands[0].buildings.filter((building) => building.status === RoomStatus.battle)[0];
      const dungeonStages = buildingConfigs.find((v) => v.id === building?.buildingId)?.dungeonIds;

      const imageSources = dungeonStages?.map(({ dungeonId }) => {
        return dungeonId > 0 ? require(`../../../assets/images/dungeonStages/dungeon-stage-${dungeonId}.jpg`) : '';
      });

      if (imageSources) {
        preloadImages(imageSources).catch((err) => {
          console.warn('Failed to preload dungeon stages', err);
        });
      }
    }
  }, [islands.length, buildingConfigs]);

  useEffect(() => {
    if (islands.length) {
      const count = islands[0].buildings.filter(
        (building) => building.status === RoomStatus.farming || building.status === RoomStatus.builded
      ).length;

      const firstResult = count >= 2;

      const secondResult = count >= 3;

      if (firstResult && !secondResult) {
        updateSave({
          dialogueId: 999,
          stage: TutorialFarmStage.finishFirstBuilding,
        });
      }

      if (firstResult && secondResult) {
        updateSave({
          dialogueId: 999,
          completed: true,
          stage: TutorialFarmStage.finishSecondBuilding,
        });
      }
    }
  }, [islands.length]);

  useEffect(() => {
    const fetchDungeonProgress = async () => {
      if (islands.length) {
        for (const building of islands[0].buildings) {
          if (building.status === RoomStatus.battle) {
            try {
              const progress = await getDungeonProgressSafe({
                clientId: userId,
              });
              setCurrentDungeonId(progress.currentDungeonId);
            } catch (error) {
              console.warn('Error fetching dungeon progress:', error);
            }
          }
        }
      }
    };

    fetchDungeonProgress();
  }, [islands.length]);

  // useEffect(() => {
  //   // Перевіряємо, чи дані вже завантажені

  //   if (farmTutorialSave) {
  //     if (!isFarmTutorialCompleted && !farmTutorialSave.dialogueId) {
  //       setCloudCount(6);
  //       return;
  //     }

  //     if (farmTutorialSave.dialogueId! === 2) {
  //       setCloudCount((prevCount) => Math.max(prevCount - 1, 0));
  //       return;
  //     }

  //     if (farmTutorialSave.dialogueId! === 3) {
  //       setCloudCount(5);
  //       return;
  //     }
  //     const initialRoomCount = selectedIsland?.buildings?.length || 0;
  //     setCloudCount(Math.max(6 - initialRoomCount, 0));
  //   }
  // }, [isFarmTutorialCompleted, farmTutorialSave, selectedIsland]);

  // const [cloudCount, setCloudCount] = useState(6);

  const [previousRoomCount, setPreviousRoomCount] = useState(() => selectedIsland?.buildings?.length || 0);

  // useEffect(() => {
  //   if (selectedIsland?.buildings) {
  //     const currentRoomCount = selectedIsland.buildings.filter(
  //       (room) => room.id
  //     ).length;

  //     if (currentRoomCount !== previousRoomCount) {
  //       if (currentRoomCount > previousRoomCount) {
  //         setCloudCount((prevCount) => {
  //           const newCount = Math.max(
  //             prevCount - (currentRoomCount - previousRoomCount),
  //             0
  //           );
  //           return newCount;
  //         });
  //       }
  //       setPreviousRoomCount(currentRoomCount);
  //     }
  //   }
  // }, [selectedIsland?.buildings, previousRoomCount]);

  const next = () => {
    if (!isTypingComplete) {
      setSpeed(10);
      return;
    }
    setSpeed(50);
    setIsTypingComplete(false);
    if (dialogStage < Object.keys(phrases).length - 1) {
      setDialogStage(dialogStage + 1);
    } else {
      switch (dialogueId) {
        case 1:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.start,
          });

          break;
        case 2:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.clickOnQuests,
          });
          break;
        case 3:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.battle,
          });

          break;
        case 4:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.repair,
          });
          break;
        case 7:
          updateSave({
            dialogueId: dialogueId,
            completed: true,
            stage: TutorialFarmStage.finishFirstBuilding,
          });

          break;
        default:
          break;
      }

      setDialogStage(0);
      dispatch(setDialogueInfo({ id: dialogueId + 1 }));
    }
  };

  const [startText, setStartText] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setStartText(true);
    }, 1000);
  }, []);

  const [isResourcesShown, setIsResourcesShown] = useState(false);

  const handleClaimResourcesForCompletedDungeon = () => {
    // console.log("start claim resources");

    dispatch(resetDungeon());
  };

  useEffect(() => {
    if (isResourcesShown) {
      setTimeout(() => {
        dispatch(resetDungeon());
      }, 2500);
    }
  }, [isResourcesShown]);

  const [buildingRects, setBuildingRects] = useState([]);

  useEffect(() => {
    // Після рендерингу отримайте позиції будівель
    const currentIslandConfig = islandsConfigMock.find((island) => island.id === selectedIsland?.id);

    // Переконайтеся, що selectedIsland та currentIslandConfig визначені
    if (currentIslandConfig && selectedIsland) {
      const result: BuildingMask[] =
        currentIslandConfig?.scheme.reduce<BuildingMask[]>((acc, schemeItem, index) => {
          const correspondingBuilding = selectedIsland?.buildings[index];
          if (correspondingBuilding) {
            acc.push({
              x: schemeItem.left,
              y: schemeItem.top,
              width: schemeItem.width,
              height: schemeItem.height,
            });
          }
          return acc;
        }, []) || [];

      // setBuildingMasks(result);
    }
  }, []);

  const [showBuilder, setShowBuilder] = useState(false);

  const handleClickBuilder = () => {
    setShowBuilder(true);
  };
  const address = useTonAddress();

  const [showTooltip, setShowTooltip] = useState(false);

  // const toggleTooltip = (isVisible: boolean) => {
  //   setShowTooltip(isVisible);
  // };

  const toggleTooltip = () => {
    setTimeout(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 7000);
    }, 5000);
  };

  useEffect(() => {
    toggleTooltip();
  }, []);

  const [openedModal, setOpenedModal] = useSessionStorage('openModelFarm', false);
  const [firstConnectWallet, setFirstConnectWallet] = useState(false);
  const [isRefCodeAccess, setIsRefCodeAccess] = useSessionStorage('isRefCodeAccess', true);

  const hasLoadedImages = sessionStorage.getItem('imagesLoaded');

  useEffect(() => {
    if (!hasLoadedImages) {
      // card details
      let imagePaths: string[] = [
        require('../../../assets/images/cardsIcons/spell_frame.webp'),
        require('../../../assets/images/cardsIcons/artifact_frame.webp'),
        require('../../../assets/images/cardsIcons/atk_frame.webp'),
        require('../../../assets/images/cardsIcons/mana.webp'),
        require('../../../assets/images/stars/full-star.png'),
        require('../../../assets/images/stars/empty-start.png'),
        require('../../../assets/images/islandBgMain.webp'),
      ];

      // card images
      [
        0, 9, 17, 6, 18, 19, 1, 15, 20, 4, 13, 11, 22, 23, 21, 10, 14, 36, 12, 24, 25, 26, 2, 27, 37, 16, 28, 38, 29,
        33, 30, 34, 31, 39, 32, 40, 41, 7, 8, 3, 5, 35,
      ].forEach((number) => {
        imagePaths.push(require(`../../../assets/images/cards/${number}.png`));
      });

      sessionStorage.setItem('imagesLoaded', 'true');

      setTimeout(() => {
        preloadImages(imagePaths);
      }, 5000);
    }
  }, [hasLoadedImages]);

  const handleBackgroundLoad = () => {
    dispatch(markStepAsLoaded('background'));
  };

  const [tonConnectUI] = useTonConnectUI();

  const handleConnect = () => {
    tonConnectUI.openModal();
    setOpenedModal(false);
  };

  const handleOnClickToolTip = async () => {
    setOpenedModal(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 500);
  };

  const fetchProfileInfo = async () => {
    const data = await getProfileInfo({
      clientId: userId,
    });

    if (data) {
      setIsRefCodeAccess(data.isRefCodeAccess);
    }

    if (!data.wallet) {
      setFirstConnectWallet(true);
    }

    const userDeposites = await getUserDeposites({
      clientId: userId,
    });

    if (userDeposites) {
      setUserDepositedAmount(userDeposites.deposited);
    }

    const userOffersData = await getUserOffers({
      clientId: userId,
    });

    if (Array.isArray(userOffersData)) {
      setPurchasedOfferIds(
        new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
      );
    }
  };

  useEffect(() => {
    if (typeof userDepositedAmount !== 'number' || !purchasedOfferIds || typeof isRefCodeAccess === 'undefined') return;
    // console.log('hereherehereherehereherehereherehereherehereherehereherehereherehereherehereherehereherehere');
    const isNotPurchased = !purchasedOfferIds || purchasedOfferIds.size === 0;
    const isLowDeposit = userDepositedAmount < 50000;

    if (!isNotPurchased || !isLowDeposit) {
      setShouldHideElement(true);
    } else {
      setShouldHideElement(isRefCodeAccess);
    }
  }, [purchasedOfferIds, userDepositedAmount, isRefCodeAccess]);

  const handleUpdateProfileWallet = async (address: string) => {
    await updateProfileWallet({
      clientId: userId,
      wallet: address,
    });
    setFirstConnectWallet(false);
  };

  useEffect(() => {
    if (address) {
      handleUpdateProfileWallet(address);
    }

    fetchProfileInfo();
  }, [address]);

  const [error, setError] = useState(true);

  const isFarmTutorialStatus = useSelector((state: RootState) => state.farmTutorial.status);

  useEffect(() => {
    if (isFarmTutorialStatus === 'failed' || farmTutorialSave?.stage === undefined) {
      setError(true);
    } else {
      setError(false);
    }
  }, [isFarmTutorialStatus, farmTutorialSave?.stage]);
  console.log('TUTORIAL SAVE IN ISLaND WINDOW', TutorialFarmStage[farmTutorialSave?.stage!]);

  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: '' };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
      }}
    >
      <PageTransition>
        <>
          {!shouldHideElement && !openedDepositModal && (
            <div className="absolute inset-0 bg-black bg-opacity-80 z-[9900]"></div>
          )}
          {/* Dark bg for tutorial stages */}
          {farmTutorialSave?.stage === TutorialFarmStage.clickOnRewards ||
          farmTutorialSave?.stage === TutorialFarmStage.clickOnQuests ||
          farmTutorialSave?.stage === TutorialFarmStage.clickOnRewards2 ? (
            <div className="absolute inset-0 bg-black bg-opacity-80 z-[9900]"></div>
          ) : null}

          {/* <div className="absolute top-[50%] left-0 bg-[red] z-[990]"  onClick={() => navigateTo('/allCards')}>
            CARDS GLOSSARY
          </div> */}

          {/* shouldHideElement */}
          {shouldHideElement ? null : (
            <div className="absolute min-h-full max-h-full h-full flex min-w-full overflow-hidden z-[9909] ">
              <PurchasePopup />
            </div>
          )}
          {openCheckIn ? <CheckIn close={() => setOpenCheckIn(false)} /> : null}
          {openedDepositModal ? (
            <div className={`absolute z-[9910] w-full h-full bg-[rgba(0,0,0,0.8)]`}>
              <DepositPopup onClick={handleOpenDepositModal} address={address} />
            </div>
          ) : null}
          {/* <DepositPopup onClick={handleOpenDepositModal} address={address} /> */}
          {currentRoomPopup ? openSpeed ? <SpeedUpPopUp /> : <BuildingPopup /> : null}
          {APP_ENV !== 'production'
            ? farmTutorialSave?.stage !== TutorialFarmStage.endbattle &&
              farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards && (
                <div className="absolute top-[30%] left-3 z-[55]">
                  <PopupButton
                    type="red"
                    onClick={async () => {
                      await updateTutorialProgress({
                        clientId: userId,
                        save: JSON.stringify({
                          stage: 0,
                          dialogueId: 0,
                          cardIsDropped: false,
                          enemyId: 0,
                        }),
                      });
                      await updateFarmTutorialProgress({
                        clientId: userId,
                        save: '',
                      });
                      await resetProgress({ clientId: userId });

                      setTimeout(() => {
                        navigateTo('/battle');
                      }, 150);
                    }}
                  >
                    Reset
                  </PopupButton>
                </div>
              )
            : null}
          <main
            className={`absolute min-h-full max-h-full h-full flex min-w-full max-w-[550px] overflow-hidden  ${!isFarmTutorialCompleted ? 'z-[53]' : ''}`}
          >
            {/* (!purchasedOfferIds || purchasedOfferIds.size === 0 || userDepositedAmount < 50000) */}
            {shouldHideElement
              ? !isFarmTutorialCompleted &&
                farmTutorialSave?.stage! < TutorialFarmStage.clickOnRewards &&
                farmTutorialSave?.stage !== TutorialFarmStage.finishQuestTutor &&
                farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards2 &&
                dialogueId !== 3 &&
                dialogueId !== 5 &&
                dialogueId !== 6 &&
                dialogueId < 7 && (
                  <div
                    className="absolute min-h-full max-h-full h-full flex min-w-full overflow-hidden z-[9999] "
                    onClick={next}
                  ></div>
                )
              : null}

            <div className="w-full h-full overlay-element">
              <CloudAnimation buildingMasks={buildingMasks} />
            </div>
            {dungeonIsCompleted && (
              <>
                {isResoursesShown && (
                  <div className="z-[9999]">
                    <OpenLootBox
                      rewards={dungeonResources}
                      openBoxName={'Dungeon Completed'}
                      onClose={handleClaimResourcesForCompletedDungeon}
                    />
                  </div>
                )}
                {/* {isExplousenShown && (
          <div className="fixed inset-0 z-[9998]">
            <div className="absolute top-[10%] w-full h-full inset-x-4 rounded-[2px] p-[2px] left-0">
              <Explosions />
            </div>
          </div>
        )} */}
              </>
            )}

            {firstConnectWallet && (
              <div className="absolute z-[52] top-[90px] right-2.5">
                <ConnectWalletIcon
                  link={'/quests'}
                  onClick={handleOnClickToolTip}
                  onClickToolTip={handleOnClickToolTip}
                  isToolTipOpen={showTooltip}
                />
                {/* <EventIcon name={"Hot Deals"} link={"/quests"} />
      <EventIcon name={"Events"} link={"/quests"} /> */}
              </div>
            )}

            {/* <div className="absolute z-[100] top-[18%] left-2">
      <BuilderIcon onClick={handleClickBuilder} />
    </div> */}

            <div className={`absolute z-[52] ${firstConnectWallet ? 'top-[145px]' : 'top-[80px]'} right-2`}>
              <DepositIcon onClick={handleOpenDepositModal} />
            </div>
            <div className={`absolute z-[52] ${firstConnectWallet ? 'top-[220px]' : 'top-[150px]'} right-2`}>
              <CheckInIcon onClick={handleOpenCheckIn} />
            </div>
            {showBuilder && (
              <div className="absolute z-[100]">
                <BuilderQueue
                  closeDeck={() => {
                    setShowBuilder(false);
                  }}
                />
              </div>
            )}

            {/* {isFarmTutorialCompleted && (
        <div className="absolute top-[92px] left-5 z-30">
          <HandleBackButton
            onClick={() => {
              navigateTo("/");
              dispatch(setSelectedRoom({ id: -1 }));
            }}
          />
        </div>
      )} */}
            {/* BACKGROUND */}
            <div className={`absolute w-full max-w-full min-w-full h-full max-h-full min-h-full`}>
              <img
                className="w-full h-full bg-slate-900"
                onLoad={handleBackgroundLoad}
                src={require('../../../assets/images/islandBgMain.webp')}
              />
            </div>
            {/* {<BuilderOffer />} */}
            {selectedIsland &&
              islandsConfigMock
                .filter((v) => v.id === selectedIsland?.id)[0]
                .scheme.map((scheme, i) => {
                  return <Room key={scheme.id} scheme={scheme} index={i + 1} room={selectedIsland?.buildings[i]} />;
                })}

            {(farmTutorialSave?.stage === TutorialFarmStage.start || !farmTutorialSave?.stage) && (
              <>
                <div
                  className={`${
                    !i18n.exists(`farm.dialogues.dialogue${dialogueId}.phrases.${dialogStage}.person`) ||
                    (t(`farm.dialogues.dialogue${dialogueId}.phrases.${dialogStage}.person`) === '' && 'opacity-0')
                  }`}
                >
                  {startText && (
                    <div
                      className={`absolute z-[100] ${
                        'bottom-[25%]' // Стандартне положення
                      } left-[25%]`}
                    >
                      <div className="relative px-5">
                        <img
                          src={require('../../../assets/images/dialog-cloud.png')}
                          className={`w-[200px] ml-auto mr-0`}
                          alt=""
                        />
                        {/* Текст поверх зображення */}
                        <div className="absolute z-10 right-4 top-1 w-[200px] h-[100px]">
                          <p className="w-full p-2 text-wrap leading-[1.2] text-sm">
                            <TypingEffect
                              speed={speed}
                              onComplete={handleTypingComplete}
                              text={t(`farm.dialogues.dialogue${dialogueId}.phrases.${dialogStage}.person`)}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                (
                <div
                  className={`absolute bottom-0  transition-transform duration-1000 ease-in-out ${
                    'transform translate-x-[0vw] z-[53]' // Лисиця на початковій позиції ліворуч
                  }`}
                >
                  <img
                    src={require('../../../assets/images/tutorialFarmFox.webp')}
                    alt="Tutorial Farm Fox"
                    className={`w-[215px] h-auto`}
                  />
                </div>
                )
              </>
            )}
            {!isFarmTutorialCompleted &&
              farmTutorialSave?.stage !== TutorialFarmStage.building &&
              farmTutorialSave?.stage &&
              farmTutorialSave?.stage !== TutorialFarmStage.endbattle &&
              farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards &&
              farmTutorialSave?.stage !== TutorialFarmStage.finishQuestTutor &&
              farmTutorialSave?.stage !== TutorialFarmStage.clickOnQuests &&
              farmTutorialSave?.stage < TutorialFarmStage.repair && (
                <div
                  className={`absolute bottom-0 left-0 h-[175px] w-full max-w-full bg-gradient-to-b from-transparent to-black ${
                    farmTutorialSave?.stage === TutorialFarmStage.finishRepair ? 'z-[101]' : 'z-[54]'
                  }  flex justify-center items-end p-5`}
                >
                  <div className="text-center text-white text-lg font-normal leading-[18px]">{t('farm.tapToSkip')}</div>
                </div>
              )}
            {/* <div className="absolute bottom-0 left-0 h-[175px] w-full max-w-full">
        <img
          className="w-full h-full"
          src={require("../../../assets/images/bottomIslandBorder.png")}
        />
      </div> */}
            {openedModal && (
              <div className="absolute inset-0 bg-black opacity-60 z-[55]" onClick={() => setOpenedModal(false)}></div>
            )}
            <div
              className={`absolute transform transition-transform duration-200 z-[999] left-0 right-0 p-5 h-[282px] 
                -bottom-[282px] bg-no-repeat bg-[length:100%_100%] bg-[url('./assets/images/shop-buy-modal-background.png')] 
                ${openedModal ? '-translate-y-full' : 'translate-y-0'}`}
            >
              <button
                className="absolute z-10 w-5 h-5 top-3.5 right-3.5 flex items-center justify-center"
                onClick={() => setOpenedModal(false)}
              >
                <img src={require('../../../assets/images/shop-modal-close.png')} className="w-5 h-5" alt="" />
              </button>
              <div className="text-[30px] leading-[1.2] mb-2 text-[#19191B] text-center">{t('profile.connect')}</div>
              {firstConnectWallet && (
                <div className="flex flex-col gap-2 justify-center items-center">
                  <div className="flex justify-center items-center gap-2">
                    <div className="w-full text-[#18191a] text-lg font-black  leading-[18px]">
                      Connect Wallet to get reward
                    </div>
                    <div className="bg-[#8e855e] rounded shadow-inner border border-[#2a2827]/70 flex gap-2 p-1">
                      <div className="w-[26px] h-[26px]">
                        <img className="w-full h-full" src={resourcesMap[Resources.kitsu].image} />
                      </div>

                      <div className="text-center text-[#ffc923] text-[25px] font-black uppercase leading-[25px] tracking-wide">
                        200
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!address ? (
                <div className={`flex justify-center ${!address ? 'mt-8' : 'mt-2'}`}>
                  <div className="mt-5">
                    <PopupButton type="blue" width="200px" onClick={handleConnect}>
                      {t('profile.connect')}
                    </PopupButton>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="absolute w-full h-full">
              <ClickAnimation />
            </div> */}
          </main>
        </>
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
