import { RequestWebsocket } from '../interfaces/wsInterfaces';
import { generateRequestId } from '../utils/generateRequestId';
import { sendRequestAndGetResponse } from '../Websocket/websocketInit';

interface MessageOptions {
    allowUserChats?: boolean;
    allowBotChats?: boolean;
    allowGroupChats?: boolean;
    allowChannelChats?: boolean;
}

export const purchaseTelegram = async (params: {
    offerId: number;
    offerType: number;
    title: string;
    description: string;
    label: string;
    stageId?: number;
    cardUid?: string;
    cardId?: number;
    dungeonId?: number;
}): Promise<any> => {
    const request: RequestWebsocket = {
        agent: 'tg-battler-bot',
        lang: 'en',
        method: 'payments_getInvoiceLink',
        id: generateRequestId(),
        params,
        auth: {
            type: 'webSite',
        },
    };

    try {
        const result = await sendRequestAndGetResponse(request);
        if (!result?.value) {
            throw new Error('purchase ERROR');
        }
        return result?.value;
    } catch (e) {
        console.error((e as Error).message);
        return (e as Error).message as unknown as void;
    }
};

export const subscriptionCheck = async (params: { clientId: string; channelName: string }): Promise<boolean> => {
    const request: RequestWebsocket = {
        agent: 'tg-battler-bot',
        lang: 'en',
        method: 'userPayments_subscriptionCheck',
        id: generateRequestId(),
        params,
        auth: {
            type: 'webSite',
        },
    };

    try {
        const result = await sendRequestAndGetResponse(request);
        if (result?.type === 'Left') {
            throw new Error('purchase ERROR');
        }
        return result?.value;
    } catch (e) {
        console.error((e as Error).message);
        return (e as Error).message as unknown as boolean;
    }
};

export const userBoostsCheck = async (params: { clientId: string; channelName: string }): Promise<boolean> => {
    const request: RequestWebsocket = {
        agent: 'tg-battler-bot',
        lang: 'en',
        method: 'userPayments_userBoostsCheck',
        id: generateRequestId(),
        params,
        auth: {
            type: 'webSite',
        },
    };

    try {
        const result = await sendRequestAndGetResponse(request);
        if (result?.type === 'Left') {
            throw new Error('purchase ERROR');
        }
        return result?.value;
    } catch (e) {
        console.error((e as Error).message);
        return (e as Error).message as unknown as boolean;
    }
};

export const getMessageToShare = async (params: {
    clientId: number;
    options: MessageOptions;
}): Promise<{ id: string; expiration_date: number }> => {
    const request: RequestWebsocket = {
        agent: 'tg-battler-bot',
        lang: 'en',
        method: 'notification_prepareMessage',
        id: generateRequestId(),
        params,
        auth: {
            type: 'webSite',
        },
    };

    try {
        const result = await sendRequestAndGetResponse(request);
        if (result?.type === 'Left') {
            throw new Error('purchase ERROR');
        }
        return result?.value;
    } catch (e) {
        console.error((e as Error).message);
        return (e as Error).message as unknown as { id: string; expiration_date: number };
    }
};
