import { QuestsEvents } from "../enums/questsEvents";
import { Resources } from "../enums/resources";
import { HeroShard, LootboxId, TimeBoosts, UniversalShard } from "../interfaces/lootBotx";
import i18next from "i18next";

const resourcesMap: Record<Resources, { name: string; image: string; backgroundColor: string }> = {
  [Resources.kitsu]: {
    name: i18next.t(`quests.resources.${Resources.kitsu}`),
    image: require("../assets/images/resource/1.webp"),
    backgroundColor: "bg-gradient-to-b from-[#d95bd8] to-[#9245df]",
  },
  [Resources.stone]: {
    name: i18next.t(`quests.resources.${Resources.stone}`),
    image: require("../assets/images/resource/3.png"),
    backgroundColor: "bg-gradient-to-b from-[#cf0000] to-[#7f0a0a]",
  },
  [Resources.experience]: {
    name: i18next.t(`quests.resources.${Resources.experience}`),
    image: require("../assets/images/resource/4.webp"),
    backgroundColor: "bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]",
  },
  [Resources.none]: {
    name: i18next.t(`quests.resources.${Resources.none}`),
    image: "/path/to/none.png",
    backgroundColor: "",
  },
  [Resources.keys]: {
    name: i18next.t(`quests.resources.${Resources.keys}`),
    image: require("../assets/images/keys.png"),
    backgroundColor: "bg-gradient-to-b from-[#cf0000] to-[#7f0a0a]",
  },
  [Resources["5m"]]: {
    name: i18next.t(`quests.resources.${Resources["5m"]}`),
    image: require("../assets/images/resource/2.png"),
    backgroundColor: "bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]",
  },
  [Resources.refPoints]: {
    name: "Ref Points",
    image: require("../assets/images/resource/5.png"),
    backgroundColor: "bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]",
  },
  [Resources.promotions]: {
    name: "Promotions",
    image: require("../assets/images/resource/6.png"),
    backgroundColor: "bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]",
  },
};

/**TODO:спитати точний опис кожного з квестів */
const eventsMap: Record<QuestsEvents, { description: string; image: string }> = {
  [QuestsEvents.open_rare_magic_scroll]: {
    description: i18next.t("Open rare magic scroll"),
    image: require("../assets/images/quests/1.png"),
  },
  [QuestsEvents.open_magic_scroll]: {
    description: i18next.t("Open magic scroll"),
    image: require("../assets/images/quests/2.png"),
  },
  [QuestsEvents.earn_stone]: {
    description: i18next.t("Earn stone"),
    image: require("../assets/images/quests/3.png"),
  },
  [QuestsEvents.spend_stone]: {
    description: i18next.t("Spend stone"),
    image: require("../assets/images/quests/4.png"),
  },
  [QuestsEvents.collect_resources]: {
    description: i18next.t("Collect resources"),
    image: require("../assets/images/quests/5.png"),
  },
  [QuestsEvents.use_hero_key]: {
    description: i18next.t("Use hero key"),
    image: require("../assets/images/quests/6.png"),
  },
  [QuestsEvents.earn_kitsu]: {
    description: i18next.t("Earn $KITSU"),
    image: require("../assets/images/quests/7.png"),
  },
  [QuestsEvents.spend_kitsu]: {
    description: i18next.t("Spend $KITSU"),
    image: require("../assets/images/quests/8.png"),
  },
  [QuestsEvents.upgrade_tier]: {
    description: i18next.t("Upgrade tier"),
    image: require("../assets/images/quests/9.png"),
  },
  [QuestsEvents.upgrade_lvl]: {
    description: i18next.t("Upgrade hero level"),
    image: require("../assets/images/quests/10.png"),
  },
  [QuestsEvents.win_boss]: {
    description: i18next.t("Win boss"),
    image: require("../assets/images/quests/11.png"),
  },
  [QuestsEvents.play_cards]: {
    description: i18next.t("Play cards"),
    image: require("../assets/images/quests/12.png"),
  },
  [QuestsEvents.open_legendary_magic_scroll]: {
    description: i18next.t("Open legendary magic scroll"),
    image: require("../assets/images/quests/13.png"),
  },
  [QuestsEvents.use_5_min_speed_up]: {
    description: i18next.t("Use 5 min speed up"),
    image: require("../assets/images/quests/14.png"),
  },
  [QuestsEvents.tutorial]: {
    description: i18next.t("Complete the first battle"),
    image: require("../assets/images/quests/0.png"),
  },
};

const universalShardMap: Record<UniversalShard, { name: string; description: string; image: string }> = {
  [UniversalShard.heroCommonShard]: {
    name: i18next.t(`universalShard.${UniversalShard.heroCommonShard}`),
    description: i18next.t(`universalShardDescription.${UniversalShard.heroCommonShard}`),
    image: require("../assets/images/heroes/shards/1.png"),
  },
  [UniversalShard.heroRareShard]: {
    name: i18next.t(`universalShard.${UniversalShard.heroRareShard}`),
    description: i18next.t(`universalShardDescription.${UniversalShard.heroRareShard}`),
    image: require("../assets/images/heroes/shards/2.png"),
  },
  [UniversalShard.heroLegendaryShard]: {
    name: i18next.t(`universalShard.${UniversalShard.heroLegendaryShard}`),
    description: i18next.t(`universalShardDescription.${UniversalShard.heroLegendaryShard}`),
    image: require("../assets/images/heroes/shards/3.png"),
  },
  [UniversalShard.Rainbow]: {
    name: i18next.t(`universalShard.${UniversalShard.Rainbow}`),
    description: i18next.t(`universalShard.${UniversalShard.Rainbow}`),
    image: require("../assets/images/heroes/shards/4.png"),
  },
};

const timeBoostsMap: Record<TimeBoosts, { name: string; description: string; image: string }> = {
  [TimeBoosts.boost1m]: {
    name: i18next.t(`timeBoosts.${TimeBoosts.boost1m}`),
    description: i18next.t(`timeBoostsDescriptions.${TimeBoosts.boost1m}`),
    image: require("../assets/images/speedUp.png"),
  },
  [TimeBoosts.boost5m]: {
    name: i18next.t(`timeBoosts.${TimeBoosts.boost5m}`),
    description: i18next.t(`timeBoostsDescriptions.${TimeBoosts.boost5m}`),
    image: require("../assets/images/speedUp.png"),
  },
  [TimeBoosts.boost15m]: {
    name: i18next.t(`timeBoosts.${TimeBoosts.boost15m}`),
    description: i18next.t(`timeBoostsDescriptions.${TimeBoosts.boost15m}`),
    image: require("../assets/images/speedUp.png"),
  },
  [TimeBoosts.boost1h]: {
    name: i18next.t(`timeBoosts.${TimeBoosts.boost1h}`),
    description: i18next.t(`timeBoostsDescriptions.${TimeBoosts.boost15m}`),
    image: require("../assets/images/speedUp.png"),
  },
  [TimeBoosts.boost8h]: {
    name: i18next.t(`timeBoosts.${TimeBoosts.boost8h}`),
    description: i18next.t(`timeBoostsDescriptions.${TimeBoosts.boost8h}`),
    image: require("../assets/images/speedUp.png"),
  },
};

const heroShardMap: Record<HeroShard, { name: string; description: string; image: string }> = {
  [HeroShard.firstHero]: {
    name: i18next.t(`heroes.${HeroShard.firstHero}`) + " " + i18next.t("heroShard.0"),
    description: "Use a shard to increase the STARS of " + i18next.t(`heroes.${HeroShard.firstHero}`),
    image: require("../assets/images/heroes/heroShards/0.png"),
  },
};

const heroesMap: Record<HeroShard, { name: string; image: string }> = {
  [HeroShard.firstHero]: {
    name: i18next.t(`heroes.${HeroShard.firstHero}`),
    image: require("../assets/images/heroes/cards/hero-0.jpg"),
  },
};

const lootBoxMap: Record<LootboxId, { name: string; image: string; backgroundColor: string }> = {
  [LootboxId.Regular]: {
    name: i18next.t(`lootBoxMap.${LootboxId.Regular}`),
    image: require("../assets/images/inventoryItems/lootBox/0.png"),
    backgroundColor: "bg-gradient-to-b from-[#d95bd8] to-[#9245df]",
  },
  [LootboxId.Rare]: {
    name: i18next.t(`lootBoxMap.${LootboxId.Rare}`),
    image: require("../assets/images/inventoryItems/lootBox/1.png"),
    backgroundColor: "bg-gradient-to-b from-[#d95bd8] to-[#9245df]",
  },
  [LootboxId.Legendary]: {
    name: i18next.t(`lootBoxMap.${LootboxId.Legendary}`),
    image: require("../assets/images/inventoryItems/lootBox/2.png"),
    backgroundColor: "bg-gradient-to-b from-[#fffe2e] to-[#fe8315]",
  },
  [LootboxId.Keys]: {
    name: i18next.t(`lootBoxMap.${LootboxId.Keys}`),
    image: "/path/to/none.png",
    backgroundColor: "bg-gradient-to-b from-[#d95bd8] to-[#9245df]",
  },
};

export { resourcesMap, universalShardMap, timeBoostsMap, heroShardMap, heroesMap, lootBoxMap, eventsMap };
