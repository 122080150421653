import { UserRank } from "../interfaces/rank";
import { RequestWebsocket } from "../interfaces/wsInterfaces";
import { generateRequestId } from "../utils/generateRequestId";
import { sendRequestAndGetResponse } from "../Websocket/websocketInit";

export enum BonusRankType {
  ReduceCost,
  MaxProductionTimeBonus,
  BuildSpeedBonus,
  LifetimeKitsuMultiplier,
  AdditionalVIPTask,
  AdditionalBuilder,
  ProfileFrame,
}

export const getUserRank = async (params: {
  clientId: string;
}): Promise<UserRank> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-ranks",
    lang: "en",
    method: "ranks_getMyRank",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === "Left") {
      throw new Error("openLootbox ERROR");
    }
    return result?.value as UserRank;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as UserRank;
  }
};

export const claimOneTime = async (params: {
  clientId: string;
  lvl: number;
}): Promise<UserRank> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-ranks",
    lang: "en",
    method: "ranks_claimOneTime",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === "Left") {
      throw new Error("openLootbox ERROR");
    }
    return result?.value as UserRank;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as UserRank;
  }
};
export const claimRank = async (params: {
  clientId: string;
}): Promise<UserRank> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-ranks",
    lang: "en",
    method: "ranks_claimRank",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === "Left") {
      throw new Error("openLootbox ERROR");
    }
    return result?.value as UserRank;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as UserRank;
  }
};
