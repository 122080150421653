import React, {useEffect, useRef} from "react";
import {CardType} from "../../endpoints/mock";

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export enum TextColor {
  RED = "red",
  GREEN = "green",
  BROWN = "brown",
  WHITE = "white",
  YELLOW = "yellow",
  BLUE = "blue"
}

const colorCodes: { [key in TextColor]: string } = {
  [TextColor.RED]: "#DD5444",
  [TextColor.GREEN]: "#3ADC96",
  [TextColor.BROWN]: "#E1C78C",
  [TextColor.WHITE]: "white",
  [TextColor.YELLOW]: "#fec923",
  [TextColor.BLUE]: "#46abfd",
};

export enum CardImage {
  OBSIDIAN_GUARD = 0,
  TRICKSTERS_GAMBIT,
  POTION_OF_RAGE,
  SANGUINE_PACT,
  FORSAKEN_KNOWLEDGE,
  QUICKDRAW_STRATEGY,
  VOIDSTRIKE,
  MIRAGE_ASSAULT,
  MINDBREAK,
  BLAZING_WRATH,
  BOLSTERED_DEFENSES,
  KEEN_INSIGHT,
  JESTERS_MALICE,
  HARLEQUINS_TRICK,
  ASTRAL_WARD,
  EXILED_BASTION,
  STONEHOLD_BARRIER,
  ARCANE_ECHO,
  WARRIORS_GAMBIT,
  SPECTRAL_ONSLAUGHT,
  SNAKE_TRAP,
  GOBLET_OF_BLOOD,
  SLINGSHOT,
  WARRIORS_BREASTPLATE,
  STAFF_OF_ARCANE_POWER,
  BLADE_OF_VENGEANCE,
  BAG_OF_PAIN,
  BEAR_SNARE,
  ARMOR_OF_THE_WOLF,
  SPIKED_SHIELD,
  SORCERERS_HORN,
  CAGE_OF_FURY,
  SCYTHE_OF_DESPAIR,
  HELM_OF_VALOR,
  ROGUES_SATCHEL,
  MIRACLE_MIRROR,
  TOME_OF_CURSES,
  AMULET_OF_LIGHT,
  SHIELD_BASH,
  DEATH_SENTENCE,
  WEIGHT_OF_STEEL,
  GRIM_HARVEST,
  ECHO_STRIKE,
  RECKLESS_SLASH,
  TRIPLE_SCAR,
  BORROWED_INSIGHT,
  GHOST_PIERCER,
  BLADES_DANCE,
  SHADOW_PARRY,
  MIND_RIPPER,
  SHADOW_SUPPRESSION,
  FROST_BITE,
  DESPERATION_STRIKE,
  LIFE_DRAIN,
  STORMCALLERS_WRATH,
  EXILED_FURY,
  ROCK,
  STONE,
  PRANK,
}

interface CanvasImageProps {
  id: number;
  type: CardType;
  image: CardImage;
  title: string;
  description: string;
  stars?: number;
  maxStars?: number;
  priceMana?: number;
  width?: number;
  height?: number;
  className?: string;
}

type Position = {
  x: number;
  y: number;
};

type ImageSize = { width: number; height: number };

type Image = {
  src: string;
} & Position &
  ImageSize;

type CardTitle = {
  content: string;
  x: number;
  y: number;
  fontSize: number;
  maxWidth: number;
  lineSpacing: number;
} & Position;

type CardFrame = {
  src: string;
} & Position &
  ImageSize;

type CardDescription = {
  content: string;
  lineSpacing: number;
  maxWidth: number;
  fontSize: number;
} & Position;

type CardTypeLabel = {
  content: string;
  fontSize: number;
} & Position;

type CardStars = {
  stars: number;
  max: number;
} & Position;

type CardMana = {
  price: number;
} & Position;

interface BuildOptions {
  ctx: CanvasRenderingContext2D;
  frame: CardFrame;
  title: CardTitle;
  description: CardDescription;
  typeLabel: CardTypeLabel;
  image: Image;
  stars?: CardStars;
  mana?: CardMana;
  drawFrameFirst?: boolean;
}

interface DrawRestOptions extends Omit<BuildOptions, "frame" | "image" | "drawFrameFirst"> {}

const CardBuilderComponent: React.FC<CanvasImageProps> = ({
  id,
  type,
  image,
  title,
  description,
  stars,
  maxStars,
  priceMana,
  width = 500,
  height = 700,
  className = "",
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const spellFrame = require("../../assets/images/cardsIcons/spell_frame.webp");
  const artifactFrame = require("../../assets/images/cardsIcons/artifact_frame.webp");
  const atkFrame = require("../../assets/images/cardsIcons/atk_frame.webp");
  const manaIcon = require("../../assets/images/cardsIcons/mana.webp");
  const star = require("../../assets/images/stars/full-star.png");
  const starEmpty = require("../../assets/images/stars/empty-start.png");

  // console.log('PROPS ID CARDBUILDER', id,
  // type,
  // image,
  // title,
  // description,
  // stars,
  // maxStars,
  // priceMana,)

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext("2d");

      if (ctx && typeof type === 'number') {
        const renderCard = async () => {
          switch (type) {
            case CardType.atk:
              await getAttackCard(ctx);
              break;
            case CardType.spell:
              await getSpellCard(ctx);
              break;
            case CardType.equip:
              await getEquipCard(ctx);
              break;
            default:
              console.error("Unknown card type" + type);
          }
        };

        renderCard();
      }
    }
  }, [type, title, description, stars, maxStars, priceMana]);

  const build = async ({
    ctx,
    frame,
    title,
    description,
    typeLabel,
    image,
    stars,
    mana,
    drawFrameFirst = false,
  }: BuildOptions) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    try {
      const cardImage = await loadImage(image.src);
      const frameImage = await loadImage(frame.src);

      if (drawFrameFirst) {
        ctx.drawImage(frameImage, frame.x, frame.y, frame.width, frame.height);
        ctx.drawImage(cardImage, image.x, image.y, image.width, image.height);
      } else {
        ctx.drawImage(cardImage, image.x, image.y, image.width, image.height);
        ctx.drawImage(frameImage, frame.x, frame.y, frame.width, frame.height);
      }

      drawRest({ ctx, title, description, typeLabel, stars, mana });
    } catch (error) {
      console.error("Ошибка при загрузке изображения:", error);
    }
  };

  const drawRest = ({ ctx, title, description, typeLabel, stars, mana }: DrawRestOptions) => {
    setTitle(ctx, title, "#FFFFFF", true);
    setDescription(ctx, description, "#FFFFFF");
    setTypeLabel(ctx, typeLabel);

    if (stars && stars?.max > 1) {
      loadAndDrawStars(ctx, stars);
    }

    if (mana) {
      setMana(ctx, mana);
    }
  };

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const setTitle = (ctx: CanvasRenderingContext2D, title: CardTitle, color: string, isBold = false) => {
    ctx.font = `${isBold ? "bold" : ""} ${title.fontSize}px Sofia Sans Semi Condensed`;
    ctx.textAlign = "center";
    ctx.fillStyle = color;

    const maxWidth = title.maxWidth;
    let content = title.content;

    // Перевіряємо, чи весь текст довший за maxWidth
    if (ctx.measureText(content).width > maxWidth) {
      // Розбиваємо по пробілах
      const words = content.split(" ");

      // Залежно від логіки, що треба зробити зі словами
      // (наприклад, вставити \n, або обмежити кількість символів тощо)
      // ось дуже простий варіант: вставити переноси рядків після кожного слова:
      content = words.join("\n");

    }

    // Далі розбиваємо (як і було) по \n, фільтруємо порожні елементи
    const words = content.split(/\n/).filter(Boolean);
    // console.log("WORDS", words, title, id);
    let lines: string[] = [];
    let currentLine = "";

    words.forEach((word) => {
      if (word === "\\n") {
        lines.push(currentLine);
        currentLine = "";
      // console.log("RETURNS", id);
        return;
      }

      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const testLineWidth = ctx.measureText(testLine).width;
      // console.log("TEST LINE WIDTH", testLine, testLineWidth, maxWidth, id);
      if (testLineWidth > maxWidth) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }
    // console.log("LINES IN CARDBUILDER", lines, id);
    lines.forEach((line, index) => {
      //Moving title upwards if more then 1 line
      const offset = lines.length > 1 ? 30 : 0
      const y = title.y + index * title.lineSpacing - offset;
      // console.log("Y AXIS IN TITLE FOR CARD", y, id, title.y, index, title.lineSpacing);
      ctx.fillText(line, title.x, y, maxWidth);
    });
  };

  const setDescription = (ctx: CanvasRenderingContext2D, description: CardDescription, defaultColor: string) => {
    ctx.font = `${description.fontSize}px Sofia Sans Semi Condensed`;
    ctx.textAlign = "left";

    const maxWidth = description.maxWidth;
    const words = description.content.split(/(\|.*?:.*?\||\s+|\\n)/).filter(Boolean);

    let lines: { text: string; color: string }[][] = [];
    let currentLine: { text: string; color: string }[] = [];
    let currentColor = defaultColor;

    words.forEach((word) => {
      if (word === "\\n") {
        lines.push(currentLine);
        currentLine = [];
        return;
      }

      let text = word;
      let color = currentColor;

      const match = word.match(/\|(.*?):(.*?)\|/);
      if (match) {
        color = colorCodes[match[1] as TextColor] || defaultColor;
        text = match[2].trim();
      }

      const testLine = [...currentLine, { text, color }];
      const testLineWidth = testLine.reduce((width, part) => width + ctx.measureText(part.text).width, 0);

      if (testLineWidth > maxWidth) {
        lines.push(currentLine);
        currentLine = [{ text, color }];
      } else {
        currentLine.push({ text, color });
      }
    });

    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    lines.forEach((line, index) => {
      let currentY = description.y + index * description.lineSpacing;

      const totalLineWidth = line.reduce((width, part) => width + ctx.measureText(part.text).width, 0);
      let currentX = description.x + maxWidth / 2 - totalLineWidth / 2;

      line.forEach((part) => {
        ctx.fillStyle = part.color;
        ctx.fillText(part.text, currentX, currentY);
        currentX += ctx.measureText(part.text).width;
      });
    });
  };

  const setTypeLabel = (ctx: CanvasRenderingContext2D, typeLabel: CardTypeLabel) => {
    ctx.font = `bold ${typeLabel.fontSize}px Sofia Sans Semi Condensed`;
    ctx.fillStyle = "#31131A";
    ctx.textAlign = "center";
    ctx.fillText(typeLabel.content, typeLabel.x, typeLabel.y);
  };

  const setMana = (ctx: CanvasRenderingContext2D, mana: CardMana) => {
    const manaImage = new Image();
    manaImage.src = manaIcon;

    manaImage.onload = () => {
      ctx.drawImage(manaImage, mana.x, mana.y, 120, 120);

      ctx.fillStyle = "#FFFFFF";

      ctx.lineWidth = 8;
      ctx.font = "1000 71px Sofia Sans Semi Condensed";
      ctx.strokeStyle = "#18191a";
      ctx.strokeText(mana.price.toString(), mana.x + 60, mana.y + 80);

      ctx.font = "1000 70px Sofia Sans Semi Condensed";
      ctx.lineWidth = 5;
      ctx.fillText(mana.price.toString(), mana.x + 60, mana.y + 80);
    };
  };

  const loadAndDrawStars = (ctx: CanvasRenderingContext2D, stars: CardStars) => {
    const starImage = new Image();
    starImage.src = star;
    const starEmptyImage = new Image();
    starEmptyImage.src = starEmpty;

    let loadedImages = 0;
    const onImageLoad = () => {
      loadedImages++;
      if (loadedImages === 2) {
        drawStars(ctx, stars, starImage, starEmptyImage);
      }
    };

    starImage.onload = onImageLoad;
    starEmptyImage.onload = onImageLoad;
  };

  const drawStars = (
    ctx: CanvasRenderingContext2D,
    stars: CardStars,
    starImage: HTMLImageElement,
    starEmptyImage: HTMLImageElement
  ) => {
    const starSize = 55;

    const totalWidth = stars.max * starSize + (stars.max - 1);
    const startX = (ctx.canvas.width - totalWidth) / 2;

    for (let i = 0; i < stars.max; i++) {
      const x = startX + starSize * i;
      const img = i < stars.stars ? starImage : starEmptyImage;
      ctx.drawImage(img, x, stars.y, starSize, starSize);
    }
  };

  const resolveCardDetails = (cardImage: CardImage): DeepPartial<BuildOptions> => {
    switch (cardImage) {
      case CardImage.SLINGSHOT:
        return {
          image: { x: 100, y: 90, width: 325, height: 325 },
          description: { maxWidth: 360, x: 70 },
        };
      case CardImage.POTION_OF_RAGE:
        return {
          image: { x: 70, y: 90, width: 325, height: 350 },
          description: { maxWidth: 320, x: 95 },
        };
      case CardImage.SNAKE_TRAP:
        return {
          image: { x: 110, y: 90, width: 350, height: 350 },
          description: { maxWidth: 320, x: 95, y: 530 },
          title: {
            x: 250,
            y: 490,
          },
        };
      case CardImage.GOBLET_OF_BLOOD:
        return {
          image: { x: 105, y: 130, width: 300, height: 325 },
        };
      case CardImage.WARRIORS_BREASTPLATE:
        return {
          image: { x: 70, y: 80, width: 375, height: 350 },
        };
      case CardImage.BLADE_OF_VENGEANCE:
        return { image: { x: 80, y: 10, width: 350, height: 475 } };
      case CardImage.BAG_OF_PAIN:
        return {
          image: { x: 70, y: 100, width: 350, height: 325 },
        };
      case CardImage.BEAR_SNARE:
        return {
          image: { x: 45, y: 100, width: 400, height: 350 },
          description: { maxWidth: 280, x: 110, y: 540, fontSize: 34 },
        };
      case CardImage.ARMOR_OF_THE_WOLF:
        return { image: { x: 65, y: 100, width: 375, height: 325 } };
      case CardImage.STAFF_OF_ARCANE_POWER:
        return {
          image: { x: 120, y: 90, width: 325, height: 300 },
          description: { maxWidth: 320, x: 100, y: 540, fontSize: 34 },
        };
      case CardImage.SPIKED_SHIELD:
        return {
          image: { x: 75, y: 80, width: 350, height: 350 },
        };
      case CardImage.SORCERERS_HORN:
        return {
          image: { x: 45, y: 40, width: 400, height: 450 },
          title: {
            x: 250,
            y: 490,
          },
          description: { y: 530 },
        };
      case CardImage.CAGE_OF_FURY:
        return {
          image: { x: 90, y: 90, width: 325, height: 350 },
          description: { maxWidth: 370, x: 70 },
        };
      case CardImage.STONEHOLD_BARRIER:
        return {
          description: { y: 600 },
        };
      case CardImage.SCYTHE_OF_DESPAIR:
        return {
          image: { x: 30, y: 50, width: 450, height: 480 },
        };
      case CardImage.HELM_OF_VALOR:
        return {
          image: { x: 40, y: 60, width: 425, height: 375 },
          description: { maxWidth: 350, x: 70, fontSize: 36 },
        };
      case CardImage.FORSAKEN_KNOWLEDGE:
        return {
          title: { y: 450 },
        };
      case CardImage.ROGUES_SATCHEL:
        return {
          image: { x: 80, y: 90, width: 350, height: 300 },
          description: { x: 65 },
        };
      case CardImage.MIRACLE_MIRROR:
        return {
          image: { x: 110, y: 25, width: 300, height: 475 },
          title: {
            x: 250,
            y: 490,
          },
          description: { y: 530 },
        };
      case CardImage.TOME_OF_CURSES:
        return { image: { x: 80, y: 85, width: 350, height: 350 } };
      case CardImage.AMULET_OF_LIGHT:
        return {
          image: { x: 80, y: 80, width: 350, height: 370 },
          title: {
            x: 250,
            y: 490,
          },
          description: { y: 530 },
        };
      case CardImage.DEATH_SENTENCE:
        return {
          description: { maxWidth: 390, x: 60, y: 565 },
          title: { y: 515 },
        };
      case CardImage.RECKLESS_SLASH:
        return {
          description: { maxWidth: 320, x: 100 },
        };
      case CardImage.GHOST_PIERCER:
        return {
          description: { y: 600 },
          title: { y: 545 },
        };
      case CardImage.FROST_BITE:
        return {
          description: { y: 600 },
          title: { y: 545 },
        };
      case CardImage.STORMCALLERS_WRATH:
        return {
          description: { maxWidth: 280, x: 120 },
        };
      case CardImage.ROCK:
        return {
          description: { y: 600 },
          title: { y: 545 },
        };
      case CardImage.STONE:
        return {
          description: { y: 600 },
          title: { y: 545 },
        };
      case CardImage.VOIDSTRIKE:
        return {
          description: { y: 535 },
          title: { y: 485 },
        };
      case CardImage.MIRAGE_ASSAULT:
        return {
          description: { y: 535 },
          title: { y: 485 },
        };
      case CardImage.MINDBREAK:
        return {
          description: { y: 535 },
          title: { y: 485 },
        };
      case CardImage.HARLEQUINS_TRICK:
        return {
          description: { y: 535 },
          title: { y: 485 },
        };
      case CardImage.JESTERS_MALICE:
        return {
          description: { y: 535 },
          title: { y: 485 },
        };
      case CardImage.ARCANE_ECHO:
        return {
          description: { y: 535 },
          title: { y: 485 },
        };
      case CardImage.SPECTRAL_ONSLAUGHT:
        return {
          description: { x: 80, y: 535, maxWidth: 340 },
          title: { y: 485 },
        };
      case CardImage.PRANK:
        return {
          typeLabel: { content: "" },
          description: { y: 580 },
        };
      default:
        return {};
    }
  };

  const getAttackCard = async (ctx: CanvasRenderingContext2D) => {
    const cardDetails = resolveCardDetails(image);

    const options: BuildOptions = {
      ctx,
      frame: { src: atkFrame, x: 0, y: 0, width: 500, height: 700 },
      title: { content: title, x: 250, y: 510, maxWidth: 380, fontSize: 42, lineSpacing: 45 },
      description: {
        content: description,
        x: 55,
        y: 560,
        lineSpacing: 45,
        maxWidth: 400,
        fontSize: 36,
      },
      typeLabel: { content: "ATK", x: 250, y: 665, fontSize: 28 },
      image: {
        src: require(`../../assets/images/cards/${id}.png`),
        x: 45,
        y: 45,
        width: 415,
        height: 605,
      },
    };

    if (stars && maxStars) {
      options.stars = { stars, max: maxStars, x: 160, y: 75 };
    }

    if (cardDetails.description) {
      options.description = {
        ...options.description,
        x: cardDetails.description.x || options.description.x,
        y: cardDetails.description.y || options.description.y,
        maxWidth: cardDetails.description.maxWidth || options.description.maxWidth,
        fontSize: cardDetails.description.fontSize || options.description.fontSize,
      };
    }

    if (cardDetails.title) {
      options.title = {
        ...options.title,
        x: cardDetails.title.x || options.title.x,
        y: cardDetails.title.y || options.title.y,
        maxWidth: cardDetails.title.maxWidth || options.title.maxWidth,
        fontSize: cardDetails.title.fontSize || options.title.fontSize,
        lineSpacing: cardDetails.title.lineSpacing || options.title.lineSpacing,
      };
    }

    if (cardDetails.typeLabel && cardDetails.typeLabel.content) {
      options.typeLabel.content = cardDetails.typeLabel.content;
    }

    await build(options);
  };

  const getSpellCard = async (ctx: CanvasRenderingContext2D) => {
    const cardDetails = resolveCardDetails(image);

    const options: BuildOptions = {
      ctx,
      frame: { src: spellFrame, x: 0, y: 0, width: 500, height: 700 },
      title: { content: title, x: 250, y: 500, maxWidth: 350, fontSize: 42, lineSpacing: 40 },
      description: {
        content: description,
        x: 60,
        y: 550,
        lineSpacing: 40,
        maxWidth: 380,
        fontSize: 36,
      },
      typeLabel: { content: "Spell", x: 250, y: 664, fontSize: 28 },
      image: {
        src: require(`../../assets/images/cards/${id}.png`),
        x: 55,
        y: 50,
        width: 400,
        height: 375,
      },
    };

    if (stars && maxStars) {
      options.stars = { stars, max: maxStars, x: 150, y: 330 };
    }

    if (priceMana !== undefined && priceMana !== null) {
      options.mana = { price: priceMana, x: 20, y: 25 };
    }

    if (cardDetails.description) {
      options.description = {
        ...options.description,
        x: cardDetails.description.x || options.description.x,
        y: cardDetails.description.y || options.description.y,
        maxWidth: cardDetails.description.maxWidth || options.description.maxWidth,
        fontSize: cardDetails.description.fontSize || options.description.fontSize,
      };
    }

    if (cardDetails.title) {
      options.title = {
        ...options.title,
        x: cardDetails.title.x || options.title.x,
        y: cardDetails.title.y || options.title.y,
        maxWidth: cardDetails.title.maxWidth || options.title.maxWidth,
        fontSize: cardDetails.title.fontSize || options.title.fontSize,
      };
    }

    await build(options);
  };

  const getEquipCard = async (ctx: CanvasRenderingContext2D) => {
    const cardDetails = resolveCardDetails(image);

    const options: BuildOptions = {
      ctx,
      frame: { src: artifactFrame, x: 0, y: 0, width: 500, height: 700 },
      title: { content: title, x: 250, y: 500, maxWidth: 350, fontSize: 38, lineSpacing: 40 },
      description: {
        content: description,
        x: 60,
        y: 540,
        lineSpacing: 40,
        maxWidth: 380,
        fontSize: 32,
      },
      typeLabel: { content: "Artifact", x: 250, y: 665, fontSize: 28 },
      image: {
        src: require(`../../assets/images/cards/${id}.png`),
        x: 45,
        y: 40,
        width: 400,
        height: 375,
      },
      drawFrameFirst: true,
    };

    if (stars && maxStars) {
      options.stars = { stars, max: maxStars, x: 160, y: 77 };
    }

    if (cardDetails.image) {
      options.image = {
        ...options.image,
        x: cardDetails.image.x || options.image.x,
        y: cardDetails.image.y || options.image.y,
        width: cardDetails.image.width || options.image.width,
        height: cardDetails.image.height || options.image.height,
      };
    }

    if (cardDetails.description) {
      options.description = {
        ...options.description,
        x: cardDetails.description.x || options.description.x,
        y: cardDetails.description.y || options.description.y,
        maxWidth: cardDetails.description.maxWidth || options.description.maxWidth,
        fontSize: cardDetails.description.fontSize || options.description.fontSize,
      };
    }

    if (cardDetails.title) {
      options.title = {
        ...options.title,
        x: cardDetails.title.x || options.title.x,
        y: cardDetails.title.y || options.title.y,
        maxWidth: cardDetails.title.maxWidth || options.title.maxWidth,
        fontSize: cardDetails.title.fontSize || options.title.fontSize,
      };
    }

    await build(options);
  };

  return <canvas ref={canvasRef} width={width} height={height} className={`w-full ${className}`} />;
};

export default CardBuilderComponent;
