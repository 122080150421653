import { useTranslation } from "react-i18next";
import { HpBarAnimation } from "../../../components";
import { ItemCard } from "../FooterCave/components";
import { useSoundService } from "../../../utils/soundService";
import { PopupButton } from "../../../components/PopupButton";
import { Bag } from "../../../pages/Dungeon/Bag";

interface FooterSecretProps {
  heroName: string;
  heroLvl: number;
  currentManaAmount: number;
  currentHpAmount: number;
  hpAmount: number;
  openDeck?: () => void;
  closeDeck: () => void;
  cardsAtDeck: any[];
  isDeckOpen: boolean;
  usedLives: number;
}

export const FooterSecretRoom = (props: FooterSecretProps) => {
  const { playSound } = useSoundService();
  const { t } = useTranslation();

  const {
    heroName,
    heroLvl,
    currentManaAmount,
    currentHpAmount,
    hpAmount,
    cardsAtDeck,
    openDeck,
    closeDeck,
    isDeckOpen,
    usedLives,
  } = props;
  return (
    <div
      className={`bottom-0 fixed max-w-[550px] px-2 pt-[10px] pb-8 w-full bg-[#1b1b1d] flex flex-nowrap items-center ${
        isDeckOpen ? "z-[11]" : "z-[1]"
      }`}
    >
      {isDeckOpen && (
        <Bag cardsAtDeck={cardsAtDeck} closeDeck={closeDeck} />
      )}
      <div className="absolute top-0 left-0 w-full h-[6px] bg-gradient-to-b from-[#4f3e35] to-[#302620] border-y border-black shadow-[0_0.4px_0_1px_#131314]"></div>
      <div className="mr-2">
        <ItemCard
          img={undefined}
          name={t("deck")}
          onClick={() => {
            if (openDeck) {
              openDeck();
              playSound("button");
            }
          }}
          cardsAmount={cardsAtDeck.length}
        />
      </div>

      <div className="flex-1 mr-5">
        <div className="flex justify-between">
          <div className="flex items-center gap-2 mb-1">
            {heroName ? (
              <div className="text-white font-[900] text-[16px] leading-[1]">
                {heroName}
              </div>
            ) : null}
            <div className="text-amber-500 text-[16px] text-nowrap leading-[1] font-[600]">
              {t("level", { lvl: heroLvl })}
            </div>
          </div>
          <div className="flex gap-1 mb-1">
            <div className="w-[19px] h-[19px] flex items-center justify-center">
              <img
                className="w-full h-full object-cover"
                src={require("../../../assets/images/Herz.png")}
                alt="Herz"
              />
              <p className="absolute text-[11px] text-white text-stroke-regular leading-none">
                {2 - usedLives}
              </p>
            </div>
            <div className="w-[19px] h-[19px] flex items-center justify-center">
              <img
                className="w-full h-full object-cover"
                src={require("../../../assets/images/mana-icon.png")}
                alt="Mana"
              />
              <p className="absolute text-[11px] text-white text-stroke-regular leading-none">
                {currentManaAmount}
              </p>
            </div>
          </div>
        </div>
        <div className="relative text-white h-4 text-center text-xl leading-3 w-full">
          <HpBarAnimation currentHp={currentHpAmount} maxHp={hpAmount} />
        </div>
      </div>

      <PopupButton type="gray" className="flex-shrink-0" disabled={true}>
        {t("tutorial.endTurn")}
      </PopupButton>
    </div>
  );
};
