import { useSessionStorage } from "@uidotdev/usehooks";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { clearError } from "../../app/features/errorSlice";
import { TimerComponent } from "../TimerComponent";
import { useTranslation } from "react-i18next";
import { useSoundService } from "../../utils/soundService";

export const ErrorComponent = ({ hasError, jsError }: { hasError?: boolean; jsError?: any }) => {
  const { t } = useTranslation();
  const [attempts, setAttempts] = useSessionStorage("attemptsReload", 0);
  const [retryDelay, setRetryDelay] = useSessionStorage("retryDelay", 3);
  const [errorResolved, setErrorResolved] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const webSocketStatus = useSelector((state: RootState) => state.webSocket.status);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timerInitializedRef = useRef<boolean>(false);
  const { pausedMusic } = useSoundService();

  const resetErrorState = () => {
    setAttempts(0);
    setRetryDelay(3);
    setErrorResolved(true);

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    timerInitializedRef.current = false;
    dispatch(clearError());

    window.location.href = "/?timestamp=" + new Date().getTime();
  };

  const timerCompletedHandler = () => {
    if (!hasError && !jsError) {
      resetErrorState();
    } else if (hasError || jsError) {
      sessionStorage.setItem("lastErrorTime", new Date().toISOString());
      pausedMusic();

      if (webSocketStatus === "connected" && hasError) {
        resetErrorState();
      }

      setAttempts((prev) => prev + 1);

      if (attempts >= 3) {
        window.location.href = "/?timestamp=" + new Date().getTime();
      }

      if (jsError) {
        jsError.resetError();
      }
    }
  };

  useEffect(() => {
    if (webSocketStatus === "connected" && (hasError || jsError)) {
      resetErrorState();
    }
  }, [webSocketStatus, hasError, jsError]);

  useEffect(() => {
    if (!hasError && !jsError) {
      resetErrorState();
    }
  }, [hasError, jsError]);

  if (!errorResolved && (hasError || jsError) && webSocketStatus !== "connected") {
    return (
      <div className="absolute w-full h-full flex flex-col justify-center items-center text-white text-5xl bg-[#1f1c1a] z-[9999]">
        {jsError ? (
          <img
            src={require("../../assets/images/bugImages/smthWentWrong.png")}
            alt=""
          />
        ) : hasError ? (
          <img
            src={require("../../assets/images/bugImages/retryConnection.png")}
            alt=""
          />
        ) : null}
        <div className="flex items-center justify-center space-x-2 mt-5 mb-2">
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce delay-200"></div>
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce delay-400"></div>
        </div>
        <div className="px-3">
          <div className="text-center text-[#ffefcb] text-2xl font-black leading-normal mb-2">
            {jsError
              ? attempts < 4
                ? t("errors.somethingWentWrong")
                : t("errors.tryReloadAppManually")
              : hasError && attempts < 4
                ? t("errors.connectionServerError")
                : t("errors.serverTemporarilyUnavailable")}
          </div>
          <TimerComponent
            startValue={retryDelay}
            onComplete={timerCompletedHandler}
          />
          {jsError && attempts >= 5 ? (
            <div className="text-center text-[#db9328] text-[14px] font-black leading-normal mt-5">
              {t("errors.sorryText")}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  // for debug
  return (
    <div className="text-white">
      <div>Runtime: {jsError ? 'yes' : 'no'}</div>
      <div>WS: {hasError ? 'yes' : 'no'}</div>
      <div>Status: {webSocketStatus}</div>
    </div>
  );

  return null;
};
