import { RequestWebsocket } from "../interfaces/wsInterfaces";
import { generateRequestId } from "../utils/generateRequestId";
import { sendRequestAndGetResponse } from "../Websocket/websocketInit";

export const completedBosses = async (params: {
  clientId: string;
}): Promise<{bossId:number}[]> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-lobby", //TODO узнать у бека
    lang: "en",
    method: "boss_getCompletedBosses",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === "Left") {
      throw new Error("startDungeon ERROR");
    }
    return result?.value as {bossId:number}[];
    // return {
    //   currentMana: 2,
    //   currentHp: 20,
    //   gold: 33,
    //   stages: [{cardId: 1, amount: 1, type: StageType.elixir, currentAmount: 0}, {cardId: 2, amount: 1, type: StageType.enemy, currentAmount: 0}, {cardId: 3, amount: 1, type: StageType.shop, currentAmount: 0}],
    // }
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as {bossId:number}[];
  }
};