import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Card } from "../../components/Card";
import { ICard } from "../../endpoints/mock";
import { CardProps } from "../../interfaces/card";
import * as Sentry from "@sentry/react";
import { ErrorType, RuntimeErrorData } from "../../interfaces/error";
import { ErrorReportingService } from "../../services/errorReportingService";
import { ErrorComponent } from "../../components";
import { useTelegram } from "../../hooks/useTelegram";
import { HandleBackButton } from "../../layout/components/HeaderCave/components/HandleBackButton";

const AllCardsPage: React.FC = () => {
  const cards = useSelector((state: RootState) => state.appConfig.cards);
  const [loadedCards, setLoadedCards] = useState<ICard[]>([]);
  const [zoomedCard, setZoomedCard] = useState<CardProps | null>(null);
  const [inputValue, setInputValue] = useState<string>('')
  const { userId } = useTelegram();

  const inputRef = useRef()

  useEffect(() => {
    if (cards && cards.length > 0) {
      setLoadedCards(cards);
    }
  }, [cards]);

  const handleCardClick = (card: CardProps) => {
    setZoomedCard(card);
  };

  const closeZoomedCard = () => {
    setZoomedCard(null);
  };

  if (loadedCards.length === 0) {
    return <div className="p-6">Loading cards...</div>;
  }

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    setInputValue(inputValue)
  };

  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: "" };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
      }}
    >
      <div className="fixed bottom-7 left-2 w-3 z-10">
        <HandleBackButton />
      </div>
      <input
        type="text"
        placeholder="ID"
        className={`fixed bottom-7 text-white bg-black right-2 border border-[yellow] w-[20%] h-[2em] z-10`}
        onChange={handleInputChange}
      />

      <div className="overflow-y-auto h-screen p-6">
        {loadedCards.filter((c) => inputValue?c.id === Number(inputValue):c).map((card: ICard) => (
          <div key={card.id} className="grid grid-cols-3 gap-6">
            {[...Array(card.stars.length || 1)].map((_, level) => (
              <div
                key={`${card.id}-${level}`}
                className="relative"
                onClick={() =>
                  handleCardClick({
                    id: card.id,
                    lvl: level + 1,
                    uid: `${card.id}-${level}`,
                  })
                }
              >
                <Card
                  id={card.id}
                  lvl={level + 1}
                  uid={`${card.id}-${level}`}
                  IsDraggable={false}
                  glow={false}
                  isBacklight={false}
                  card={{ id: card.id, lvl: level + 1 }}
                  showId={true}
                />
              </div>
            ))}
          </div>
        ))}
        {zoomedCard && (
          <div onClick={closeZoomedCard}>
            <div className="bg-black opacity-60 fixed top-0 left-0 right-0 bottom-0 w-full h-full z-10"></div>
            <div className="fixed z-20 top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center">
              <div className="relative w-[300px]">
                <Card
                  id={zoomedCard.id}
                  lvl={zoomedCard.lvl}
                  uid={zoomedCard.uid}
                  hidden={false}
                  glow={false}
                  showId={true}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default AllCardsPage;
