import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoadingState {
  loadingSteps: Record<string, boolean>;
}

const initialState: LoadingState = {
  loadingSteps: {},
};

const loadingManagerSlice = createSlice({
  name: "loadingManager",
  initialState,
  reducers: {
    markStepAsLoaded: (state, action: PayloadAction<string>) => {
      state.loadingSteps[action.payload] = true;
    },
  },
});

export const { markStepAsLoaded } = loadingManagerSlice.actions;

// Селекторы
export const isStepLoaded = (state: { loadingManager: LoadingState }, stepName: string) =>
  !!state.loadingManager.loadingSteps[stepName];

export const isAllStepsLoaded = (state: { loadingManager: LoadingState }, steps: string[]) =>
  steps.every((stepName) => !!state.loadingManager.loadingSteps[stepName]);

export default loadingManagerSlice.reducer;
