import { useTranslation } from "react-i18next";
import { useTelegram } from "../../hooks/useTelegram";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import React from "react";
import { getMyLootboxes } from "../../endpoints/lootBoxEndpoints";
import { useSessionStorage } from "@uidotdev/usehooks";

export const InventoryButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="mr-2 select-none cursor-pointer w-14 h-10 pb-[2px] bg-[#452B31] border border-black rounded-lg shadow-lg  relative transform transition-transform duration-150 active:scale-95">
      <div
        className="w-full h-full rounded-lg flex justify-center items-center bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] p-1 relative"
        onClick={onClick}
      >
        <div className="relative flex flex-col items-center ">
          <div className="relative w-full flex justify-center items-center overflow-visible">
            <img
              src={require("../../assets/images/inventory.png")}
              className="absolute -top-6 w-[34px] h-[34px] max-w-none"
            />
          </div>
          <div
            className="absolute -top-[23px] text-center text-white text-sm font-bold leading-3 mt-8"
            style={{
              filter:
                "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
            }}
          >
            {t("inventory.name")}
          </div>
        </div>
        <InventoryNotificationDot />
      </div>
    </div>
  );
};

const InventoryNotificationDot = () => {
  const { userId } = useTelegram();
  const boosts = useSelector((state: RootState) => state.inventory.boosts);
  const [inventoryItemsTotalAmount, setInventoryItemsTotalAmount] =
    React.useState(0);
  const [watchedInventoryItems, setWatchedInventoryItems] = useSessionStorage(
    "watchedItems",
    0
  );

  const fetchAndCheckNewInventoryItems = async () => {
    const responseLootboxes = await getMyLootboxes({
      clientId: userId,
    });

    if (responseLootboxes && boosts && responseLootboxes.length > 0) {
      const lootBoxesTotalAmount = responseLootboxes.reduce((prev, next) => {
        return prev + next.amount;
      }, 0);

      const boostsTotalAmount = boosts.reduce((prev, next) => {
        return prev + next.amount;
      }, 0);

      setInventoryItemsTotalAmount(lootBoxesTotalAmount + boostsTotalAmount);

      if (watchedInventoryItems > lootBoxesTotalAmount + boostsTotalAmount) {
        setWatchedInventoryItems(lootBoxesTotalAmount + boostsTotalAmount);
      }
    }
  };

  React.useEffect(() => {
    fetchAndCheckNewInventoryItems();
  }, []);

  return inventoryItemsTotalAmount > watchedInventoryItems &&
    watchedInventoryItems > 0 ? (
    <div className="absolute flex items-center justify-center -top-[6px] -right-[6px] z-50 w-4 h-4">
      {inventoryItemsTotalAmount - watchedInventoryItems > 1 && (
        <span className="text-center text-white font-bold text-[10px] z-10">
          {inventoryItemsTotalAmount - watchedInventoryItems}
        </span>
      )}
      <img
        className="absolute top-0 right-0 bottom-0 left-0"
        src={require("../../assets/images/heroes/cards/notification.png")}
        alt="Notification"
      />
    </div>
  ) : null;
};
