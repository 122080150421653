import React from "react";

export const ConnectionError = ({ isShow }: { isShow: boolean }) => {
  return (
    <div className="absolute w-[46px] h-[44px] z-[9999] top-[50%] -right-[46px] bg-gradient-to-b border-[1px]
        from-[#FEE7BA] to-[#B6765A] rounded-tl-[6px] rounded-bl-[6px] border-[#452B31] border-r-0 border-b-[4px]
        transition-all duration-500 ease-in-out" style={{
          right: isShow ? "0" : "-46px",
          opacity: isShow ? "1" : "0"
        }}>
      <div className="relative border-[4px] border-transparent border-t-[#f2675d] rounded-[50%] mx-auto w-[40px]
        h-[34px] top-[7px] animate-blink" style={{animationDelay: '0.2s'}}></div>
      <div className="relative border-[4px] border-transparent border-t-[#e3463b] rounded-[50%] mx-auto w-[30px]
        h-[26px] -top-[21px] animate-blink" style={{animationDelay: '0.1s'}}></div>
      <div className="relative border-[4px] border-transparent border-t-[#df3023] rounded-[50%] mx-auto w-[20px]
        h-[16px] -top-[41px] animate-blink" style={{animationDelay: '1.5s'}}></div>
      <div className="relative -top-[51px]">
        <div className="bg-[#d43024] rounded-[50%] w-[6px] h-[6px] mx-auto"></div>
      </div>
    </div>
  );
}
