import { useMemo, useState } from "react";
import { PopupButton } from "../../components/PopupButton";
import { PopupWindow } from "../../components/PopupWindow";
import { FooterSecretRoom } from "../../layout/components/FooterSecretRoom";
import { HeaderSecretRoom } from "../../layout/components/HeaderSecretRoom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useTranslation } from "react-i18next";

export const SecretRoom: React.FC<{
  hpAmount: number;
  currentHpAmount: number;
  manaAmount: number;
  currentManaAmount: number;
  heroName?: string;
  heroLevel?: number | undefined;
  cardsAtDeck: any[];
  openDeck?: () => void;
  closeDeck: () => void;
  setIsSecretRoomOpen: (isOpen: boolean) => void;
  gold?: number;
  isDeckOpen: boolean;
  usedLives: number;
}> = ({
  setIsSecretRoomOpen,
  gold,
  currentManaAmount,
  manaAmount,
  hpAmount,
  currentHpAmount,
  heroName,
  heroLevel,
  cardsAtDeck,
  openDeck,
  closeDeck,
  isDeckOpen,
  usedLives,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {};
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { t } = useTranslation();

  const selectedSecretRoom = useMemo(() => {
    if (!appConfig) return [];
    const {
      secretRooms: { variables: secretRoomConfig },
    } = appConfig;
    return secretRoomConfig.find((a: any) => a.roomId.value === 0);
  }, [appConfig]);
  console.log(selectedSecretRoom, "selectedRoom");

  return (
    <div
      className=" w-full h-full top-0 left-0 fixed z-[99] bg-cover bg-no-repeat "
      style={{
        backgroundImage: `url(${require("../../assets/images/secretRoom/first-secret-room.png")})`,
      }}
    >
      <div className="absolute top-0 left-0 w-full h-[35%] bg-gradient-to-b from-black/90 to-transparent"></div>
      <div className="absolute bottom-[60px] left-0 w-full h-[45%] bg-gradient-to-t from-black/90 to-transparent"></div>

      <HeaderSecretRoom setIsSecretRoomOpen={setIsSecretRoomOpen} gold={gold} />

      <div className="flex w-[270px] absolute top-[100px] left-1/2 transform -translate-x-1/2">
        <p
          className="w-full text-regular text-white break-words text-center"
          style={{
            lineHeight: "1.2",
            filter:
              "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
          }}
        >
          {t("rooms.0.title")}
        </p>
      </div>

      <div className="flex flex-col gap-[15px] absolute bottom-[125px] left-1/2 transform -translate-x-1/2">
        {selectedSecretRoom.buttons.value.map((id: any, index: any) => {
          return (
            <button
              key={index}
              className={`w-[213px] h-[34px] z-10 ${
                isOpen ? "[filter:drop-shadow(0px_0px_10px_#C6D9FF)]" : ""
              } `}
              style={{
                backgroundImage: `url(${require("../../assets/images/secretRoom/secret-button-choise.png")})`,
              }}
              onClick={() => setIsOpen(true)}
            >
              {t("rooms.0.options.1")}
            </button>
          );
        })}
      </div>

      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center ">
            <PopupWindow onClickClose={onClick} hideCloseButton={true}>
              <div className="flex flex-col items-center pt-[32px] pb-[16px] px-[32px] gap-[16px] z-[200] relative">
                <p
                  className="text-white text-sm font-light text-center"
                  style={{
                    lineHeight: "1.2",
                  }}
                >
                  You gave as many coins as the fountain demanded. In return, it
                  healed you. You gained 10 HP but lost 20 Gold.
                </p>
                <PopupButton
                  className="w-[150px]"
                  type="green"
                  onClick={() => setIsOpen(false)}
                >
                  CONFIRM
                </PopupButton>
              </div>
            </PopupWindow>
          </div>
        </>
      )}

      <FooterSecretRoom
        heroName={heroName || "Hero"}
        heroLvl={heroLevel ?? 1}
        currentManaAmount={currentManaAmount}
        currentHpAmount={currentHpAmount}
        hpAmount={hpAmount}
        openDeck={openDeck}
        closeDeck={closeDeck}
        cardsAtDeck={cardsAtDeck}
        isDeckOpen={isDeckOpen}
        usedLives={usedLives}
      />
    </div>
  );
};
