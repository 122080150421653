// store/questsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Resources } from "../../enums/resources";
import { number } from "yargs";
import { setTaskCompleted } from "../../endpoints/userQuests";

export enum TaskStatus {
  NotDone,
  Done,
  Claimed,
}

export enum MissionStatus {
  NotDone,
  Done,
}

export enum MissionConditionType {
  clearDungeon,
  upgradeBuilding,
  lvlUpHero,
  tierUpHero,
  useKeys,
}

interface QuestProgress {
  amount: number;
  resource: Resources;
}
export interface QuestTask {
  taskId: number;
  currentProgress: QuestProgress[];
  status: TaskStatus;
}
export interface SocialTaskReward {
  amount: number;
  rewardId: number;
  rewardType: number;
}

export interface SocialTaskCategory {
  id: number;
  isAvailable: boolean;
  name: string;
}
export interface SocialTask {
  category: SocialTaskCategory;
  channelId: string;
  description: string;
  icon?: string;
  id: number;
  isFriends: boolean;
  link: string;
  name: string;
  rewards: SocialTaskReward[];
  status: number;
}
export interface DepositTask {
  questId: number;
  condition: number;
  currentProgress: number;
  canClaim: boolean;
  rewards: SocialTaskReward[];
  status: number;
}

export interface MissonReward {
  rewardType: number;
  rewardId: number;
  multiplyByShirine: boolean;
  amount: number;
}

export interface MissionProgress {
  current: number;
  target: number;
  type: number;
}

export interface MissionCondition {
  type: number;
  value: number;
  buildingId: number;
}

export interface MissionTask {
  config: {
    questId: number;
    rewards: MissonReward[];
    baseQuestId?: number;
    conditions: MissionCondition[];
  };
  id: number;
  status: {
    isDone: boolean;
    progress: MissionProgress[];
  };
}

interface QuestsState {
  isVipTasksAllowed: boolean;
  quests: QuestTask[];
  socialTask: SocialTask[];
  depositTask: DepositTask | null;
  specialTask: SocialTask | null;
  missions: {
    currentMainAchievement: MissionTask | null;
    sideAchievements: MissionTask[];
  };
}

const initialState: QuestsState = {
  isVipTasksAllowed: false,
  quests: [],
  socialTask: [],
  depositTask: null,
  specialTask: null,
  missions: {
    currentMainAchievement: null,
    sideAchievements: [],
  },
};

export const completeTask = createAsyncThunk(
  "tasks/completeTask",
  async ({ clientId, taskId }: { clientId: string; taskId: number }, thunkAPI) => {
    const response = await setTaskCompleted({ clientId, taskId });
    return { taskId, ...response };
  }
);

const questsSlice = createSlice({
  name: "quests",
  initialState,
  reducers: {
    setIsVipTasksAllowed(state, action: PayloadAction<boolean>) {
      state.isVipTasksAllowed = action.payload;
    },
    setQuests(state, action: PayloadAction<QuestTask[]>) {
      state.quests = action.payload;
    },
    setSocialTask(state, action: PayloadAction<SocialTask[]>) {
      state.socialTask = action.payload;
    },
    setDepositTask(state, action: PayloadAction<DepositTask | null>) {
      state.depositTask = action.payload;
    },
    setSpecialTask(state, action: PayloadAction<SocialTask | null>) {
      state.specialTask = action.payload;
    },
    setMissions(
      state,
      action: PayloadAction<{
        currentMainAchievement: MissionTask | null;
        sideAchievements: MissionTask[];
      }>
    ) {
      state.missions = action.payload;
    },
    clearSpecialTask(state) {
      state.specialTask = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(completeTask.fulfilled, (state, action: PayloadAction<{ taskId: number }>) => {
      const { taskId } = action.payload;
      const task = state.socialTask.find((t) => t.id === taskId);
      if (task) {
        task.status = TaskStatus.Done;
      }
      // Якщо це `specialTask`, оновіть його
      if (state.specialTask && state.specialTask.id === taskId) {
        state.specialTask.status = TaskStatus.Done;
      }
      // Оновлення depositTask
      if (state.depositTask && state.depositTask.questId === taskId) {
        state.depositTask.status = TaskStatus.Done;
      }
    });
  },
});

export const {
  setQuests,
  setSocialTask,
  setDepositTask,
  setSpecialTask,
  setMissions,
  clearSpecialTask,
  setIsVipTasksAllowed,
} = questsSlice.actions;
export default questsSlice.reducer;
