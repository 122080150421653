import { SuccessAnimation } from "../../pages/Quests";
import React, { useEffect, useState } from "react";

interface SuccessOverlayProps {
  isSuccess: boolean;
  duration?: number; // by default 2 s
  onClose?: () => void;
}

export const SuccessOverlay: React.FC<SuccessOverlayProps> = ({ isSuccess, duration = 2000, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose?.();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [isSuccess, duration, onClose]);

  if (!isVisible) return null;

  return (
    <div className="absolute w-full h-full top-0 z-20">
      <div className="absolute inset-0 bg-black opacity-60 z-30 w-full h-full"></div>
      <div className="absolute w-full h-full z-40">
        <SuccessAnimation
          text="Successfully claimed"
          onClose={() => {
            setIsVisible(false);
            onClose?.();
          }}
        />
      </div>
    </div>
  );
};
