import { HandleBackButton } from "../HeaderCave/components";
import { GoldBalance } from "./components/GoldBalance";

export const HeaderSecretRoom: React.FC<{
  gold?: number;
  setIsSecretRoomOpen: (isOpen: boolean) => void;
}> = ({ gold = 0, setIsSecretRoomOpen }) => {

  return (
    <header
      className="w-full h-14 border border-transparent bg-transparent z-[1]"
    >
      <div className={`h-full flex items-center justify-between px-4`}>
        <HandleBackButton
          onClick={() => {
            setIsSecretRoomOpen(false);
          }}
        />
        <GoldBalance goldAmount={gold} />
      </div>
      
    </header>
  );
};
