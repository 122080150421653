import React, { useState } from "react";
import { PopupButton } from "../PopupButton";
import { QuantitySelector } from "../../containers/Inventory/components";
import { PopupWindow } from "../PopupWindow";
import { resourcesMap } from "../../utils/mapping";
import { handleRewardResult } from "../../utils/rewardParse";
import { ItemType } from "../../mock/offers";
import { useTranslation } from "react-i18next";

export const ResourcePurchase = ({
  swapId,
  firstResourceType,
  firstResourceID,
  secondResourceType,
  secondResourceID,
  currentFirstResource,
  currentSecondResource,
  swapRate,
  onSwap,
  onClose,
  minBuy,
}: {
  swapId: number;
  firstResourceType: number;
  firstResourceID: number;
  secondResourceType: number;
  secondResourceID: number;
  currentFirstResource: number;
  currentSecondResource: number;
  swapRate: number;
  onSwap: (amount: number, id: number) => void;
  onClose: () => void;
  minBuy: number;
}) => {
  const [balanceTooltip, setBalanceTooltip] = useState(false);
  const [keyQuantity, setKeyQuantity] = useState(minBuy);
  const { t } = useTranslation();
  /**
   * Обчислюємо максимально можливу кількість другого ресурсу (наприклад Keys),
   * яку можна отримати, виходячи з поточного балансу першого (Kitsu) і swapRate.
   */
  const maxQuantity = Math.floor(currentFirstResource / swapRate);

  // Обгортка над onSwap, щоб передати у функцію кількість, яку вибрав користувач
  const handleSwap = () => {
    onSwap(keyQuantity, swapId);
  };

  const firstResourseData = handleRewardResult({ rewardType: firstResourceType, rewardId: firstResourceID });
  const secondResourseData = handleRewardResult({ rewardType: secondResourceType, rewardId: secondResourceID });

  return (
    <>
      <div onClick={onClose} className="fixed inset-0 z-40 bg-[rgba(0,0,0,0.5)]" />
      <PopupWindow
        onClickClose={onClose}
        headerElement={
          <div className="absolute top-[-15px] left-0 right-0 flex justify-self-center z-20">
            <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
              <div
                className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border
                                            border-[#18191a] shadow-inner-sm-black"
              >
                {secondResourceType === ItemType.timeBoosts ?`${secondResourseData?.name}  ${t("timeBoostsShop")}` : secondResourseData?.name}
              </div>
            </div>
          </div>
        }
      >
        {currentFirstResource / swapRate > 1 ? (
          <div className="flex flex-col justify-start items-center w-full p-2 pt-5">
            <div className="flex justify-center items-center">
              <div
                className="text-center text-white text-[20px] leading-tight mt-[30px] mb-[26px] border border-[#5e5546]
          rounded-[6px] bg-[#18191a] py-1.5 px-2.5"
              >
                <div className="flex flex-col justify-center items-center">
                  {/* Баланс першого ресурсу */}
                  <div className="flex justify-between items-center gap-1 font-black w-full">
                    <div className="flex justify-between">Balance:</div>
                    {Math.floor(currentFirstResource)}
                  </div>

                  {/* Якщо потрібно показувати баланс другого ресурсу */}
                  {typeof currentSecondResource === "number" && currentSecondResource > 0 && (
                    <div className="flex justify-between items-center gap-1 font-black w-full">
                      <div className="flex justify-between">Balance {secondResourseData?.name}:</div>
                      {Math.floor(currentSecondResource)}
                    </div>
                  )}
                </div>
              </div>

              {/* Іконка з тултипом */}
              <div className="relative h-min z-10" onClick={() => setBalanceTooltip(!balanceTooltip)}>
                <img src={require("../../assets/images/tooltip-icon.png")} className="w-5 h-5 ml-2.5 mt-1" alt="" />
                <div
                  className="absolute w-[166px] text-center text-[10px] font-bold bg-white border border-[#45362e]
              rounded-[4px] leading-[1.2] p-2 mx-auto text-black top-full mt-2 -right-4"
                  style={{
                    opacity: balanceTooltip ? "1" : "0",
                    display: balanceTooltip ? "block" : "none",
                  }}
                  onClick={() => setBalanceTooltip(false)}
                >
                  {/* Трикутничок тултипа (двошаровий) */}
                  <div
                    className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
                border-r-transparent border-b-[#45362e] bottom-full right-[11%]"
                  ></div>
                  <div
                    className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
                border-r-transparent border-b-white bottom-full right-[11%] -mb-[1px]"
                  ></div>
                  {Math.floor(1 / swapRate) > 0
                    ? `${Math.floor(1 / swapRate)} ${secondResourseData?.name} for each 1 ${
                        firstResourseData?.name
                      }`
                    : `1 ${secondResourseData?.name} for each ${swapRate} ${firstResourseData?.name}`}
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center pb-4 relative">
              <div
                className={`p-2 border-2 border-[#fffe2e]  ${
                  secondResourseData?.backgroundColor
                    ? secondResourseData?.backgroundColor
                    : "bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]"
                } rounded-md`}
              >
                {secondResourceType === ItemType.timeBoosts ? (
                  <>
                    <div className="rounded-t-md absolute top-0 left-0 w-full bg-black/40 flex justify-center items-center w-fullinline-block pb-1 pt-2 text-white   text-basefont-normal leading-none text-shadow-black-sm">
                      {secondResourseData?.name}
                    </div>
                  </>
                ) : null}
                <div className="w-[72px] h-[72px]">
                  <img className="w-full h-full" src={secondResourseData?.image} />
                </div>
              </div>
              <div className="absolute bottom-5 right-2 text-right text-white text-base font-black leading-none text-shadow-black-sm">
                {keyQuantity}
              </div>
            </div>

            {/* Селектор кількості (QuantitySelector) */}
            <div className="w-full flex justify-center mb-5">
              <div className="w-[95%]">
                <QuantitySelector max={maxQuantity} value={keyQuantity} onChange={setKeyQuantity} step={minBuy} />
              </div>
            </div>

            {/* Кнопка "Purchase" */}

            <PopupButton type="gold" size="medium" onClick={minBuy > keyQuantity ? () => {} : handleSwap}>
              <div className="flex justify-center items-center gap-1 px-4">
                <div className="w-4 h-4">
                  <img className="w-full h-full" src={firstResourseData?.image} />
                </div>

                {minBuy > keyQuantity
                  ? `Min buy ${minBuy} ${secondResourseData?.name}`
                  : `${Math.round(swapRate * keyQuantity)} `}
              </div>
            </PopupButton>
          </div>
        ) : (
          <div className="text-center text-white text-[17px] font-bold  leading-[17px] p-10 ">
            Please earn or deposit more {firstResourseData?.name}
          </div>
        )}
      </PopupWindow>
    </>
  );
};
