import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../../../hooks/useTelegram";

export const HeaderIco = (props: { lvl: number }) => {
  const { t } = useTranslation();
  const { user } = useTelegram();
  return (
    <>
      <div className="relative w-[56px] h-[56px] bg-[#50493F] p-[3px] items-center justify-center flex border-[1px] border-black rounded-full">
        <div className="bg-[#7C7D43] w-full h-full border-[1px]  border-black items-center justify-center text-center flex rounded-full">
          <img className="rounded-full" src={user && user.photo_url ? user.photo_url : require("../../../../../assets/images/Kitsune.png")} />
        </div>
        <div className="absolute -top-0.5 z-10 flex w-[8px] h-[8px] bg-[#454545] border-[0.3px] border-black rounded-[1px] items-center justify-center">
          <div className="rounded-[50%] w-[2px] h-[2px] bg-[#2C2C2E] border-[0.1px] border-black [box-shadow:0px_0px_1px_0px_rgba(255,255,255,0.6)]" />
        </div>
        <div className="absolute -bottom-0.5 z-10 flex w-[8px] h-[8px] bg-[#454545] border-[0.3px] border-black rounded-[1px] items-center justify-center">
          <div className="rounded-[50%] w-[2px] h-[2px] bg-[#2C2C2E] border-[0.1px] border-black [box-shadow:0px_0px_1px_0px_rgba(255,255,255,0.6)]" />
        </div>
        <div className="absolute -left-0.5 z-10 flex w-[8px] h-[8px] bg-[#454545] border-[0.3px] border-black rounded-[1px] items-center justify-center">
          <div className="rounded-[50%] w-[2px] h-[2px] bg-[#2C2C2E] border-[0.1px] border-black [box-shadow:0px_0px_1px_0px_rgba(255,255,255,0.6)]" />
        </div>
        <div className="absolute -right-0.5 z-10 flex w-[8px] h-[8px] bg-[#454545] border-[0.3px] border-black rounded-[1px] items-center justify-center">
          <div className="rounded-[50%] w-[2px] h-[2px] bg-[#2C2C2E] border-[0.1px] border-black [box-shadow:0px_0px_1px_0px_rgba(255,255,255,0.6)]" />
        </div>
      </div>
    </>
  );
};
