import { useTelegram } from "../../hooks/useTelegram";
import { CopyButton, InviteButton } from "./components";

export const InvitesButtons = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-[#201b18] via-[#201b18]  flex justify-center gap-4 p-4 items-center z-[999] pb-5">
      <InviteButton children={undefined} size="big"/>
      <CopyButton />
    </div>
  );
};
