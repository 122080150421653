import { LeaderboardType } from "../../../../endpoints/Leaderboard/getLeaderboard";
import { formatLeaderboardNumber } from "../../utils/formatLeaderboardNumber";
import { truncateLeaderboardText } from "../../utils/truncateLeaderboardText";

interface LeaderboardUserProps {
  place: number;
  username: string;
  imgSrc?: string;
  amount: number;
  currentTab: number;
}

export const LeaderboardUser = ({
  place,
  username,
  imgSrc,
  amount,
  currentTab,
}: LeaderboardUserProps) => {
  switch (true) {
    case place === 1:
      return (
        <div className="self-end flex flex-col items-center justify-center mb-4 w-[101px]">
          <div className="flex items-center gap-1">
            <span className="text-3xl font-extrabold text-[#EFB04B] text-stroke-regular">
              1
            </span>

            <img
              className="w-[26px] object-cover mb-1"
              src={require("../../../../assets/images/fluent-emoji_crown.png")}
            />
          </div>
          <div
            className="w-[101px] h-[101px] flex items-center justify-center border border-black rounded-full mb-2"
            style={{
              background: "linear-gradient(#DE8E0D 20%, #FFD067 100%)",
              boxShadow: "0 0 30px 2px rgba(255, 208, 103, 0.8)",
            }}
          >
            <img
              className="w-[88px] h-[88px] rounded-full border-[1px] border-black"
              src={
                imgSrc
                  ? imgSrc
                  : require("../../../../assets/images/noPhotoFriend.png")
              }
            />
          </div>
          <p className="text-center text-[#FFEFCB] font-extrabold text-stroke-regular leading-none mb-[2px]">
            {truncateLeaderboardText(username)}
          </p>
          <div className="flex items-center justify-center">
            {currentTab === LeaderboardType.kitsuSupply && (
              <img
                className="w-5 h-5 mr-[2px]"
                src={require("../../../../assets/images/kitsu-icon.webp")}
                alt=""
              />
            )}
            {currentTab === LeaderboardType.referrals && (
              <img
                className="w-5 h-5 mr-[2px]"
                src={require("../../../../assets/images/referrals_leaderboard_icon.png")}
                alt=""
              />
            )}
            <span className="uppercase text-white text-xl text-stroke-regular font-extrabold leading-none">
              {formatLeaderboardNumber(amount)}
            </span>
          </div>
        </div>
      );

    case place === 2:
      return (
        <div className="self-end flex flex-col items-center justify-center w-[76px]">
          <span className="text-xl font-extrabold text-white text-stroke-regular">
            2
          </span>
          <div
            className="w-[76px] h-[76px] flex items-center justify-center border border-black rounded-full mb-2"
            style={{
              background: "linear-gradient(#F0F9FC 20%, #6C7579 100%)",
            }}
          >
            <img
              className="w-16 h-16 rounded-full border-[1px] border-black"
              src={
                imgSrc
                  ? imgSrc
                  : require("../../../../assets/images/noPhotoFriend.png")
              }
            />
          </div>
          <p className="text-center text-[#FFEFCB] font-extrabold text-sm text-stroke-regular leading-none mb-[2px]">
            {truncateLeaderboardText(username)}
          </p>
          <div className="flex items-center justify-center">
            {currentTab === LeaderboardType.kitsuSupply && (
              <img
                className="w-5 h-5 mr-[2px]"
                src={require("../../../../assets/images/kitsu-icon.webp")}
                alt=""
              />
            )}
            {currentTab === LeaderboardType.referrals && (
              <img
                className="w-5 h-5 mr-[2px]"
                src={require("../../../../assets/images/referrals_leaderboard_icon.png")}
                alt=""
              />
            )}
            <span className="uppercase text-white text-stroke-regular font-extrabold leading-none">
              {formatLeaderboardNumber(amount)}
            </span>
          </div>
        </div>
      );

    case place === 3:
      return (
        <div className="self-end flex flex-col items-center justify-center w-[76px]">
          <span className="text-xl font-extrabold text-white text-stroke-regular">
            3
          </span>
          <div
            className="w-[76px] h-[76px] flex items-center justify-center border border-black rounded-full mb-2"
            style={{
              background: "linear-gradient(#F09A6F 20%, #833F24 100%)",
            }}
          >
            <img
              className="w-16 h-16 rounded-full border-[1px] border-black"
              src={
                imgSrc
                  ? imgSrc
                  : require("../../../../assets/images/noPhotoFriend.png")
              }
            />
          </div>
          <p className="text-center text-[#FFEFCB] font-extrabold text-sm text-stroke-regular leading-none mb-[2px]">
            {truncateLeaderboardText(username)}
          </p>
          <div className="flex items-center justify-center">
            {currentTab === LeaderboardType.kitsuSupply && (
              <img
                className="w-5 h-5 mr-[2px]"
                src={require("../../../../assets/images/kitsu-icon.webp")}
                alt=""
              />
            )}
            {currentTab === LeaderboardType.referrals && (
              <img
                className="w-5 h-5 mr-[2px]"
                src={require("../../../../assets/images/referrals_leaderboard_icon.png")}
                alt=""
              />
            )}
            <span className="uppercase text-white text-stroke-regular font-extrabold leading-none">
              {formatLeaderboardNumber(amount)}
            </span>
          </div>
        </div>
      );

    case place >= 4 && place <= 7:
      return (
        <div className="flex flex-col items-center justify-center w-20">
          <div className="relative flex-shrink-0 w-16 h-16 flex items-center justify-center bg-[#5C5040] border border-black rounded-full mb-2">
            <img
              className="w-[50px] h-[50px] rounded-full border-[1px] border-black"
              src={
                imgSrc
                  ? imgSrc
                  : require("../../../../assets/images/noPhotoFriend.png")
              }
            />
            <div className="absolute bottom-0 w-5 h-5 border rounded-full border-black bg-white flex items-center justify-center">
              <span className="font-extrabold leading-[normal] text-white text-stroke-regular">
                {place}
              </span>
            </div>
          </div>
          <p className="h-full w-full text-white text-center font-extrabold text-sm text-stroke-regular leading-none mb-[2px]">
            {truncateLeaderboardText(username, 12)}
          </p>
          <div>
            <div className="flex items-center justify-center">
              {currentTab === LeaderboardType.kitsuSupply && (
                <img
                  className="w-5 h-5 mr-[2px]"
                  src={require("../../../../assets/images/kitsu-icon.webp")}
                  alt=""
                />
              )}
              {currentTab === LeaderboardType.referrals && (
                <img
                  className="w-5 h-5 mr-[2px]"
                  src={require("../../../../assets/images/referrals_leaderboard_icon.png")}
                  alt=""
                />
              )}
              <span className="text-[20px] uppercase text-white text-stroke-regular font-extrabold leading-none">
                {formatLeaderboardNumber(amount)}
              </span>
            </div>
          </div>
        </div>
      );

    case place > 7:
      return (
        <div className="w-full flex items-center bg-[#2A2827] border border-[#19191B] rounded p-2">
          <span className="w-8 h-8 mr-2 flex items-center justify-center text-center uppercase text-white text-stroke-regular font-extrabold leading-none">
            {place}
          </span>
          <div className="flex items-center gap-2 flex-1">
            <div className="w-12 h-12 flex-shrink-0 p-1 border border-[#19191B] bg-[#50493F]">
              <img
                className="border border-[#19191B]"
                src={
                  imgSrc
                    ? imgSrc
                    : require("../../../../assets/images/noPhotoFriend.png")
                }
              />
            </div>
            <span className="text-start uppercase text-white text-stroke-regular font-extrabold leading-none">
              {truncateLeaderboardText(username, 12)}
            </span>
          </div>
          <div className="w-[92px] relative flex items-center ml-4 justify-center bg-[#3C3C3C] py-1 px-5 border border-[#222020] rounded">
            {currentTab === LeaderboardType.kitsuSupply && (
              <img
                className="absolute -left-4 w-8 h-8"
                src={require("../../../../assets/images/kitsu-icon.webp")}
                alt=""
              />
            )}
            {currentTab === LeaderboardType.referrals && (
              <img
                className="absolute -left-4 w-8 h-8"
                src={require("../../../../assets/images/referrals_leaderboard_icon.png")}
                alt=""
              />
            )}

            <h3 className="text-[20px] text-center uppercase text-white text-stroke-regular font-extrabold leading-none">
              {formatLeaderboardNumber(amount)}
            </h3>
          </div>
        </div>
      );

    default:
      return null;
  }
};
