import { useEffect, useState } from 'react';
import { Card } from '../Card';
import { PopupButton } from '../PopupButton';
import CoinImage from '../CoinImage';
import { useSelector } from 'react-redux';
import { usePurchase } from '../../hooks/usePurchase';
import { RootState } from '../../app/store';
import { useSoundService } from '../../utils/soundService';
import { useTelegram } from '../../hooks/useTelegram';
import { CardProps } from '../../interfaces/card';
import { buyCard, getOverallStats } from '../../endpoints/dungeonEndpoints';
import { useTranslation } from 'react-i18next';
import { SuccessOverlay } from '../SuccessOverlay';

interface IDungeonShopBuyCard {
  card: any;
  setCard: (card: any | null) => void;
  gold: number;
  cards: CardProps[];
  setCards: (cards: CardProps[]) => void;
  updateGoldAmount: (totalAmount: number, amount: number) => void;
  updateSomeStates: any;
}

const DungeonShopBuyCard = ({ card, setCard, gold, cards, setCards, updateGoldAmount, updateSomeStates }: IDungeonShopBuyCard) => {
  const { t } = useTranslation();
  const { userId } = useTelegram();
  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save);
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const { playSound } = useSoundService();
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [notEnoughBalance, setNotEnoughBalance] = useState(false);
  const buyCardOfferId = 33;

  const removeCardAndBuy = ({ cardIndex, cardId, price }: { cardIndex: number; cardId: number; price: number }) => {
    const copyCards = [...cards];
    copyCards.splice(cardIndex, 1);
    setCards(copyCards);
    setCard(null);
    (async () => {
      try {
        const result = await buyCard({
          stageId: actualSaves?.currentStage!,
          cardId,
          clientId: userId,
        });
        updateGoldAmount(gold, price);
        updateSomeStates({
          gold: result.gold,
          currentMana: result.currentMana,
          currentHp: result.currentHp,
          cardsAtDeck: result.cardsAtDeck,
        });
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleBuyCard = (card: any, index: number) => {
    playSound('button');
    if (gold >= card.cost) {
      removeCardAndBuy({
        cardId: card.id,
        cardIndex: index,
        price: card?.cost,
      });
    } else {
      if (timeoutId) {
        setTimeoutId(null);
      }
      setTimeoutId(setTimeout(() => setNotEnoughBalance(false), 2000));
      setNotEnoughBalance(true);
    }
  };

  const handleCloseBuyCard = () => {
    setCard(null);
  };

  const handleBuyCardForStars = (cardId: number) => {
    playSound('button');

    confirmPurchase({
      offerId: buyCardOfferId,
      stageId: actualSaves?.currentStage,
      cardId,
      dungeonId: actualSaves?.dungeonId,
    });
  };

  const getStats = async () => {
    const response = await getOverallStats({
      clientId: userId,
    });

    console.log(response.cardsAtDeck, 'New Dack');

    updateSomeStates({
      gold: response.gold,
      currentMana: response.currentMana,
      currentHp: response.currentHp,
      cardsAtDeck: response.cardsAtDeck,
    });
  };

  useEffect(() => {
    if (isSuccessPurchase) {
      playSound('questsReward');
      const copyCards = [...cards];
      copyCards.splice(card.cardIndex, 1);
      setCards(copyCards);

      setTimeout(() => {
        setTimeout(() => {
          getStats();
        }, 0);
      }, 1500);

      handleCloseBuyCard();
    }
  }, [isSuccessPurchase]);

  return (
    <div className='fixed flex items-center justify-center top-0 left-0 h-screen w-screen z-30'>
      <div className='flex flex-col justify-center w-1/3 align-center z-30 gap-5'>
        <Card id={card.id} lvl={card.lvl} uid={card.uid} hidden={false} />
        <div className='flex justify-center items-center gap-[30px]'>
          <PopupButton type={gold >= card.cost ? 'green' : 'gray'} size='medium' onClick={() => handleBuyCard(card, card.cardIndex)} className='max-h-[50px]'>
            <div className='flex items-center px-5 gap-1 '>
              <CoinImage width={16} height={16} />
              <p className='text-white leading-[14px] font-[900] '>{card?.cost}</p>
            </div>
          </PopupButton>
          <PopupButton type='gold' size='medium' className='max-h-[50px]' onClick={() => handleBuyCardForStars(card.id)}>
            <div
              className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[20%] -translate-y-[10%] `}
            >
              <div
                className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
              >
                <img src={require('../../assets/images/shop/offer/notify.png')} className={`w-[2em] aspect-square -translate-y-[5%] `} alt='' />
                <p>5</p>
              </div>
            </div>
            <div className='justify-center flex gap-1 items-center px-7'>
              <img className='w-[16px] h-[16px]' src={require('../../assets/images/shop/offer/offer-star.png')} alt='' />
              <p className='text-white font-[900] leading-[14px]'>50</p>
            </div>
          </PopupButton>
        </div>
      </div>

      <div className='fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-60' onClick={handleCloseBuyCard}></div>
      {notEnoughBalance && (
        <div className='absolute bottom-[100px] left-1/2 -translate-x-1/2 bg-[rgba(120,22,22,0.8)] text-white py-2.5 px-4 z-[31] rounded flex items-center text-center leading-[1.2] whitespace-nowrap'>
          {t('notEnoughBalance')}
        </div>
      )}
      {/* <SuccessOverlay isSuccess={isSuccessPurchase} /> */}
    </div>
  );
};

export default DungeonShopBuyCard;
