import { useTelegram } from "../../hooks/useTelegram";
import { BattleRewards, endTurn, getRewardsAfterBattle, makeAction, startBattle } from "../../endpoints/lobbyEndpoints";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BattleEffects, Effects } from "./Effects";
import { BattleEffectsData, EnemyBattleData } from "./EnemyBattleData";
import { ActiveCards } from "./ActiveCards";
import {
  BattleSaves,
  CalculationMethod,
  ICard,
  CardType,
  ConditionParameter,
  ConditionSide,
  ExecutionActionType,
  MakeAction,
  Opponent,
  Parameter,
  EffectType,
  Action,
} from "../../endpoints/mock";
import { ProfileBattleData } from "../../layout/components/ProfileBattleData";
import { CardProps } from "../../interfaces/card";
import {
  BossDyingAnimation,
  FloatingNumber,
  HitAnimation,
  LevelUp,
  Startbattle,
  UnitAnimation,
} from "../../components";
import { YourTurn } from "../../components/";
import { setSave } from "../../app/features/tutorialSaveSlice";
import { AppDispatch, RootState } from "../../app/store";
import { useUtils } from "../../utils/navigateTo";
import {
  isAddEffectAction,
  isChangeParametersAction,
  isDiscardCardsAction,
  isNoEffectAction,
  isShuffleCardsAction,
  isUpdateCardsAction,
} from "../../utils/MakeActionTypeGuards";
import ReactGA from "react-ga4";
//import * as amplitude from "@amplitude/analytics-browser";

import { TutorialFarmStage, TutorialSave, TutorialStage } from "../../interfaces/tutorial";
import { updateTutorialProgress } from "../../endpoints/tutorialProgress";
import { updateBattleSave } from "../../utils/updateBattleSave";

import { useIsDungeonFinishStage } from "../../hooks/useIsDungeonFinishStage";
import { useFarm } from "../Farm/useFarm";
import { islandsConfigMock } from "../../mock/buildings";
import { IslandProps } from "../../containers/Farm/Island";
import { useIsland } from "../../containers/Farm/Island/useIsland";
import { sleep } from "../../utils/sleep";
import { motion } from "framer-motion";
import { usingCard } from "../../utils/usingCard";
import { PlayerGetHit } from "../../components/animation/PlayerGetHit";
import { CardDeck } from "./CardDeck";
// import { enemiesConfig } from "../../interfaces/battle";
import { useTranslation } from "react-i18next";
import { saveFarmTutorialProgress, setFarmSave, fetchFarmTutorialProgress } from "../../app/features/farmTutoralSlice";
import { completeDungeon } from "../../app/features/dungeonCompleted";
import { Resources } from "../../enums/resources";
import { Resource } from "../../mock/resources";
import { PopupButton } from "../../components/PopupButton";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import { useSoundService } from "../../utils/soundService";
import { useUserInteraction } from "../../utils/hasInteracted";
import { DisplayData, handleLootBoxResult } from "../../utils/lootBoxHandler";
import { CardIds, Enemies, parseEnemiesConfig } from "../../interfaces/battle";
import { fetchAppConfig } from "../../app/features/appConfigSlice";
import { APP_ENV } from "../../config";
import { ConfigHero, FullHero, Hero, heroesConfig, HeroRarity } from "../../interfaces/hero";
import { deleteRoomProgress, leaveCurrentFight } from "../../endpoints/battleProgress";
import { heroesMap } from "../../utils/mapping";
import { HeroShard } from "../../interfaces/lootBotx";
import { LoosePage } from "./LoosePage";
import { VictoryPage } from "./VictoryPage";
import { ShrinesModal } from "./BattleModals/ShrinesModal";
import { TiersModal } from "./BattleModals/TiersModal";
import { getHeroes } from "../../endpoints/heroEndpoints";
import { parseFullHero } from "../../utils/heroParser";
import { setHeroesList } from "../../app/features/heroesSlice";
import { transformTreeToSkillsData } from "../../containers/Heroes/components/HeroCard/components";
import { SkillData } from "../../containers/Heroes/components/HeroCard/components/transformTreeToSkillsData";
import { SelectCards } from "./SelectCards";
import * as Sentry from "@sentry/react";
import {  ErrorType, RuntimeErrorData  } from "../../interfaces/error";
import {  ErrorReportingService  } from "../../services/errorReportingService";
import {  ErrorComponent  } from "../../components";
import { buyLifeWithShare, resetCurrentDungeon } from "../../endpoints/dungeonEndpoints";
import { HeartStop } from "./HeartStop";
import { useSessionStorage } from "@uidotdev/usehooks";
import { ConfigOffer, OfferType } from "../../mock/offers";
import { usePurchase } from "../../hooks/usePurchase";
import { useTelegramShareMessage } from "../../hooks/useTelegramShareMessage";
import { SuccessOverlay } from "../../components/SuccessOverlay";

enum HitType {
  Slash1 = 1,
  Slash3 = 2,
  Slash2 = 3,
  Slash4 = 4,
  // Explosion1 = 4,
}

const getRandomHitType = (): number => {
  return Math.floor(Math.random() * 3) + 1;
};

interface ParameterInfo {
  currValue: number;
  maxValue: number;
}

const UNIQ_EFFECTS = [3];

export const Battle = () => {
  const tutorialSave = useSelector((state: RootState) => state.tutorialSave.tutorialSave.save);

  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save) as BattleSaves | null;
  // console.log('ACTUAL SAVES',actualSaves )
  const isFinish = useIsDungeonFinishStage({ actualSaves });

  const dispatch = useDispatch<AppDispatch>();

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const [cardsListForChoise, setCardsListForChoise] = useState<any>([]);
  const [mainCard, setMainCard] = useState<{ cardId: number; cardUid: string } | null>(null);

  const enemiesConfig = appConfig?.enemies?.variables;

  const configCards = useSelector((state: RootState) => state.appConfig.cards);

  const enemies: Enemies[] = parseEnemiesConfig(enemiesConfig);

  const { t } = useTranslation();
  const { tg, userId } = useTelegram();
  const { navigateTo, goBack } = useUtils();

  const [isBattleStart, setIsBattleStart] = useState(false);
  const [isBattleStartAnimation, setIsBattleStartAnimation] = useState(false);
  // const [battleInit, setBattleInit] = useState<BattleInit>({} as BattleInit);
  const [bossEffects, setBossEffects] = useState<BattleEffectsData[]>([]);
  const [bossCurrentHp, setBossCurrentHp] = useState(15);
  const [bossCurrentMana, setBossCurrentMana] = useState(2);
  const [bossMaxHp, setBossMaxHp] = useState(20);
  const [bossMaxMana, setBossMaxMana] = useState(8);
  const [currentHp, setCurrentHp] = useState(15);
  const [currentMana, setCurrentMana] = useState(0);
  const [maxHp, setMaxHp] = useState(20);
  const [usedLives, setUsedLives] = useState(0);
  const [boughtLives, setBoughtLives] = useState(0);
  const [maxMana, setMaxMana] = useState(8);
  const [isDeckOpen, setIsDeckOpen] = useState(false);
  const [effects, setEffects] = useState<BattleEffectsData[]>([]);
  const [bossName, setBossName] = useState("");
  const [heroName, setHeroName] = useState("");
  const [heroLvl, setHeroLvl] = useState(0);
  const [bossLvl, setBossLvl] = useState(0);
  const [isBoss, setIsBoss] = useState(false);
  const [cards, setCards] = useState<CardProps[]>([]);
  const [actionPoints, setActionPoints] = useState(0);
  const [cardsAtDeck, setCardsAtDeck] = useState<CardProps[]>([]);
  const [isBattleEnd, setIsBattleEnd] = useState(false);
  const [confirmEndModal, setConfirmEndModal] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [triggerHit, setTriggerHit] = useState(false);
  const [triggerHitAmount, setTriggerHitAmount] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [animationType, setAnimationType] = useState("");
  const [startTurn, setStartTurn] = useState(false);
  const [fadeClass, setFadeClass] = useState("");
  const [fadeClassEndBattle, setFadeClassEndBattle] = useState("");
  const [usedCards, setUsedCards] = useState<CardProps[]>([]);
  const [defense, setDefense] = useState(0);
  const [bossDefense, setBossDefense] = useState(0);
  const [triggerBossCardAnination, setTriggerBossCardAnination] = useState(false);
  const [triggerBossAninationHit, setTriggerBossAninationHit] = useState(false);
  const [showBossDeck, setShowBossDeck] = useState(false);
  const [bossAttack, setBossAttack] = useState(false);
  const [bossAttackAmount, setBossAttackAmount] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [bossHit, setBossHit] = useState();
  const [bossDying, setBossDying] = useState(false);
  const [bossDyingAnimation, setBossDyingAnimation] = useState(false);
  const [hitType, setHitType] = useState(HitType.Slash1);
  const [hitAmount, setHitAmount] = useState<number>(0);
  const [BossHitAmount, setBossHitAmount] = useState<number>(0);
  const [isDraggingCards, setIsDraggingCards] = useState(true);
  const [isReturnDamageAnimation, setIsReturnDamageAnimation] = useState(false);
  const [showPopupButton, setShowPopupButton] = useState(false);
  const [isAnimationWinStart, setIsAnimationWinStart] = useState(false);
  const [isLevelUpStart, setIsLevelUpStart] = useState(false);
  const [isAnimationLooseStart, setIsAnimationLooseStart] = useState(false);
  const [showDefeatScreen, setShowDefeatScreen] = useState(false);
  const [bossCardsAtHand, setBossCardsAtHand] = useState<CardProps[]>([]);
  const [rewards, setRewards] = useState<BattleRewards>({} as unknown as BattleRewards);
  const [isFirstUsedCard, setIsFirstUsedCard] = useState(false);
  const settings = useSelector((state: RootState) => state.settings.settings);
  const { playSound, playMusic, pausedSounds } = useSoundService();
  const hasInteracted = useUserInteraction();
  const [bossDeck, setBossDeck] = useState<CardProps[]>();
  const isInitialRender = useRef(true);
  const [countOfHits, setCountOfHits] = useState<number>(0);
  const [expandTiers, setExpandTiers] = useState<boolean>(false);
  const [lastRemovedCard, setLastRemovedCard] = useState<CardProps>();
  const { transformComponentRef } = useFarm();
  const [isAllStepsResolved, setIsAllStepsResolved] = useState(true);
  const [selectCardsPopup, setSelectCardsPopup] = useState<boolean>(false);
  const [startSelectHero, setStartSelectHero] = useSessionStorage(
    "startSelectHero",
    false
  );

  const { isSuccessPurchase, confirmPurchase } = usePurchase();

  const selectedHeroData = useSelector((state: RootState) => state.selectedHero);

  const heroId = selectedHeroData.uid;

  const heroesList = useSelector((state: RootState) => state.heroes.heroesList);

  const selectedHero = heroesList.find((hero) => hero.id === Number(heroId));

  const [skillsData, setSkillsData] = useState<SkillData[]>([]);

  const allHeroes: ConfigHero[] = appConfig?.heroes?.variables;
  let islandData = islandsConfigMock[0];

  let props: IslandProps = {
    island: islandData,
    zoomRef: transformComponentRef,
  };

  console.log("CARDS AT HAND", cards);

  const { onClickNavigate } = useIsland(props);

  const isFarmTutorialCompleted = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save?.completed
  );
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  function handleTiersExpand() {
    setExpandTiers(!expandTiers);
  }

  function setNewUsedCards(usedCards: CardProps[]) {
    setUsedCards(usedCards);
  }

  const popLastUsedCard = () => {
    console.log("inside pop", usedCards);
    const newUsedCards = usedCards;
    newUsedCards.pop();
    if (newUsedCards) {
      setUsedCards(newUsedCards);
    }
    console.log("inside pop1", newUsedCards);
  };

  const updateSave = ({ save }: { save: TutorialSave }) => {
    // const save = { stage: TutorialStage.stone }
    dispatch(setSave(save));
    const updatingSave = async () => {
      await updateTutorialProgress({
        clientId: userId,
        save: JSON.stringify(save),
      });
    };
    updatingSave();
  };

  let enemyId = 0;
  if (tutorialSave?.completed && actualSaves?.enemyId) {
    // console.log("ENEMIE Id from SAVE", actualSaves?.enemyId);
    //@ts-ignore
    enemyId = actualSaves?.enemyId;
  } else {
    enemyId = tutorialSave?.enemyId || 0;
    if (!tutorialSave?.stage) {
      updateSave({ save: { stage: TutorialStage.battle, enemyId: 0, cardIsDropped: false } });
    }
  }

  const setSelectedCardHandler = ({
    cardId,
    cardUid,
    drawCardId,
    drawCardLvl,
  }: {
    cardId: number;
    cardUid: string;
    drawCardId: number;
    drawCardLvl: number;
  }) => {
    makeCardAction({
      clientId: userId,
      cardId,
      cardUid,
      drawCardId,
      drawCardLvl,
    });
    setMainCard(null);
    setCardsListForChoise([]);
    setSelectCardsPopup(false);
  };

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const timeouts = useRef<any>([]);

  useEffect(() => {
    // console.log("hitAmount", hitAmount);
    if (hitAmount > 0) {
      setTriggerHit(true);
      playSound("enmHit");
      setTriggerHitAmount(true);
      const randomHitType = getRandomHitType();

      setHitType(randomHitType);
      // Після затримки анімації знімаємо тригер
      const timeoutId1 = setTimeout(() => {
        setTriggerHit(false);
      }, 250); //TODO WAS 250

      const timeoutId2 = setTimeout(() => {
        setTriggerHitAmount(false);
      }, 1000);

      timeouts.current.push(timeoutId1);
      timeouts.current.push(timeoutId2);

      return () => {
        // Очищення всіх таймерів при розмонтажі
        timeouts.current.forEach(clearTimeout);
        timeouts.current = [];
      };
    }
  }, [hitAmount, bossCurrentHp, bossDefense]);

  useEffect(() => {
    dispatch(fetchFarmTutorialProgress(userId));
  }, []);

  const handleHeroHpChange = async (
    newHp: number,
    fastAnim?: boolean | null
  ) => {
    if (newHp < currentHp) {
      // console.log("start here");
      if (effects.some((effectInfo) => effectInfo.effectId === EffectType.returnDamage)) {
        setIsReturnDamageAnimation(true);
        setTimeout(() => setIsReturnDamageAnimation(false), 2000);
      }
      if (fastAnim === null) {
        await delay(250);
      } else if (!fastAnim) {
        await delay(1000);
      }
      setTriggerBossAninationHit(true);
      await delay(200);
      // setBossAttack(true);
      setCurrentHp((prevHp) => {
        const hitAmount = prevHp - newHp;
        // console.log("hitAmount", hitAmount); // Логічне значення

        if (prevHp < newHp * 0.65) {
          playSound("damage");
        } else {
          playSound("enmHit");
        }
        // Оновлюємо hitAmount перед оновленням HP
        setBossHitAmount(hitAmount);
        setCountOfHits((prevCount) => {
          return prevCount + 1;
        });
        // Повертаємо новий HP
        return newHp;
      });

      setBossAttackAmount(true);
      setAnimationType("hit");
      setTriggerAnimation(true);
      setFadeClass("fade-in");
      setTriggerBossAninationHit(false);
      await delay(fastAnim === null ? 500 : 100);
      setFadeClass("fade-out");
      setBossAttack(false);
      await delay(500);
      setBossAttackAmount(false);
      await delay(200);
      setTriggerAnimation(false);
    } else if (newHp > currentHp) {
      // Виконуємо анімацію хіла
      setAnimationType("heal");
      setTriggerAnimation(true);
      setFadeClass("fade-in");

      await delay(500);
      setFadeClass("fade-out");

      await delay(500);
      setTriggerAnimation(false);
      setCurrentHp((prevHp) => {
        const hitAmount = prevHp - newHp;

        // Оновлюємо hitAmount перед оновленням HP
        setBossHitAmount(hitAmount);
        // Повертаємо новий HP
        return newHp;
      });
    }
  };

  const handleBossHpChange = async (newHp: number) => {
    console.log("INSIDE IF BOSSCARDANIMATION handleBossHpChange");
    await delay(150);
    setBossCurrentHp((prevHp) => {
      const hitAmount = prevHp - newHp;
      // console.log("hitAmount", hitAmount); // Логічне значення
      // Оновлюємо hitAmount перед оновленням HP
      setHitAmount(hitAmount);

      // Повертаємо новий HP
      return newHp;
    });
    await delay(850);
  };

  const handleStartTurn = async () => {
    setStartTurn(true);
    await delay(800);
    setStartTurn(false);
  };

  useEffect(() => {
    if (tg) {
      tg.setHeaderColor("#1f1c1a");
    }
  }, [tg]);

  useEffect(() => {}, [cards?.length]);

  function removeCard(cardUid: string) {
    setLastRemovedCard(cards.filter((v) => v.uid === cardUid)[0]);
    setCards(cards.filter((v) => v.uid !== cardUid));
    //console.log("cards at remove", cards.filter((v) => v.uid !== cardUid));
  }

  //new fix vibration
  useEffect(() => {
    //avoid initial render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    //if battle is going
    if (!isBattleEnd) {
      if (navigator?.vibrate && settings?.vibration) {
        navigator.vibrate(150);
        // console.log("start vibration");
        // setTimeout(() => console.log("end vibration"), 150);
      }
    }
    // console.log(countOfHits);
  }, [countOfHits, isBattleEnd]);

  const makeActionStrategy: {
    [k in number]?: (cardId: number, cardUid: string) => boolean | void;
  } = {
    //TODO: Механика выбора 1 из 3 карт на выбор, для CardId 37, 39, 41,
    [CardIds.fiveGreen]: (cardId, cardUid) => {
      const allCards = appConfig.skills.variables;
      // console.log(' fivegreen ALLCARDS', allCards)
      const filteredCards = allCards.filter(
        (card: { id?: { value?: number }; isDone: { value?: boolean } }) =>
          card?.id?.value !== cardId && card?.isDone.value
      );
      const shuffleArray = (array: any) => array.sort(() => Math.random() - 0.5);
      const shuffledCards = shuffleArray(filteredCards);
      const mappedShuffeledCards = shuffledCards.map((card: any, index: number) => {
        return { id: card.id.value, uid: index, lvl: 1 };
      });
      setCardsListForChoise(mappedShuffeledCards.slice(0, 3));
      setMainCard({ cardId, cardUid });
      setSelectCardsPopup(true);
      return true;
    },
    [CardIds.fourteenGreen]: (cardId, cardUid) => {
      const filteredCards = cardsAtDeck.filter((card) => card.id !== cardId);
      const shuffleArray = (array: any) => array.sort(() => Math.random() - 0.5);
      const shuffledCards = shuffleArray(filteredCards);
      console.log("SHUFFELED AND FILTERED", shuffledCards, filteredCards);
      setCardsListForChoise(shuffledCards.slice(0, 3));
      setMainCard({ cardId, cardUid });
      setSelectCardsPopup(true);
      return true;
    },
    [CardIds.seventeenGreen]: (cardId, cardUid) => {
      const allCards = appConfig.skills.variables;
      const filteredCards = cards.filter(
        (card) =>
          card.id !== cardId &&
          allCards.find((configCard: any) => configCard.id.value === card.id).cardType.value === CardType.atk
      );
      const shuffleArray = (array: any) => array.sort(() => Math.random() - 0.5);
      const shuffledCards = shuffleArray(filteredCards);
      console.log("SHUFFELED AND FILTERED", allCards);
      setCardsListForChoise(shuffledCards.slice(0, 3));
      setMainCard({ cardId, cardUid });
      setSelectCardsPopup(true);
      return true;
    },
  };

  const cardStrategy: {
    [k in CardType]?: ({ card }: { card: CardProps }) => void;
  } = {
    [CardType.atk]: ({ card }) => {
      // console.log("ATK TYPE");
      //TODO если подсветка не будет зависить от типа карты тогда убрать стратегию и поменять на метод
      //console.log("START CHECK SPELL");
      const copyCards = [...cards];
      const currentCard = copyCards.filter((cardInfo) => cardInfo.uid === card.uid)[0];
      const cardInfo = configCards.filter((cardData) => cardData.id === currentCard.id)[0];
      switch (cardInfo?.condition?.parameter) {
        case ConditionParameter.health:
          const healthInfo =
            cardInfo?.condition.target === Opponent.enemy
              ? { maxHp: bossMaxHp, currHp: bossCurrentHp }
              : { maxHp, currHp: currentHp };
          const updatedCards = copyCards.filter((v) => {
            if (v.uid !== card.uid) {
              return v;
            }
          });
          if (cardInfo?.condition.conditionSide === ConditionSide.less) {
            lessConditionSide(
              cardInfo,
              currentCard,
              { currValue: healthInfo.currHp, maxValue: healthInfo.maxHp },
              updatedCards
            );
          } else {
            //TODO вызывать moreConditionSide
          }
          break;
      }

      // const copyCards = cards;
      // console.log("UPDATED CARDS ->", copyCards);
      // const currentCard = copyCards.filter(
      //   (cardInfo) => cardInfo.uid === card.uid
      // )[0];
      // console.log("UPDATED CARDS AFTER FILTER->", copyCards);
      // currentCard.isBacklight = true;
      // setCards([]);
      // const updatedCards = copyCards.filter((v) => {
      //   if (v.uid !== card.uid) {
      //     return v;
      //   }
      // });
      // setCards([...(updatedCards as CardProps[]), currentCard]);
    },
    [CardType.spell]: ({ card }) => {
      // console.log("START CHECK SPELL");
      const copyCards = [...cards];
      const currentCard = copyCards.filter((cardInfo) => cardInfo.uid === card.uid)[0];
      const cardInfo = configCards.filter((cardData) => cardData.id === currentCard.id)[0];
      if (cardInfo.id === 4) {
        // console.log("CARD ->4");
      }
      switch (cardInfo?.condition?.parameter) {
        case ConditionParameter.health:
          const healthInfo =
            cardInfo?.condition.target === Opponent.enemy
              ? { maxHp: bossMaxHp, currHp: bossCurrentHp }
              : { maxHp, currHp: currentHp };
          if (cardInfo.id === 4) {
            // console.log("HEALTHINFO->", healthInfo);
          }
          const updatedCards = copyCards.filter((v) => {
            if (v.uid !== card.uid) {
              return v;
            }
          });
          if (cardInfo?.condition.conditionSide === ConditionSide.less) {
            lessConditionSide(
              cardInfo,
              currentCard,
              { currValue: healthInfo.currHp, maxValue: healthInfo.maxHp },
              updatedCards
            );
          } else {
            //TODO вызывать moreConditionSide
          }
          break;
      }
    },
    [CardType.equip]: ({ card }) => {
      // console.log("START CHECK EQUIP");
      const copyCards = [...cards];
      const currentCard = copyCards.filter((cardInfo) => cardInfo.uid === card.uid)[0];
      const cardInfo = configCards.filter((cardData) => cardData.id === currentCard.id)[0];
      switch (cardInfo?.condition?.parameter) {
        case ConditionParameter.health:
          const healthInfo =
            cardInfo?.condition.target === Opponent.enemy
              ? { maxHp: bossMaxHp, currHp: bossCurrentHp }
              : { maxHp, currHp: currentHp };
          const updatedCards = copyCards.filter((v) => {
            if (v.uid !== card.uid) {
              return v;
            }
          });
          if (cardInfo?.condition.conditionSide === ConditionSide.less) {
            lessConditionSide(
              cardInfo,
              currentCard,
              { currValue: healthInfo.currHp, maxValue: healthInfo.maxHp },
              updatedCards
            );
          } else {
            //TODO вызывать moreConditionSide
          }
          break;
      }
      // const copyCards = cards;
      // const currentCard = copyCards.filter(
      //   (cardInfo) => cardInfo.uid === card.uid
      // )[0];
      // const cardInfo = mockCards.filter(
      //   (cardData) => cardData.id === currentCard.id
      // )[0];
      // switch (cardInfo.data?.condition?.parameter) {
      //   case ConditionParameter.health:
      //     const healthInfo =
      //       cardInfo.data?.condition.target === Opponent.enemy
      //         ? { maxHp: bossMaxHp, currHp: bossCurrentHp }
      //         : { maxHp, currHp: currentHp };
      //     const updatedCards = copyCards.filter((v) => {
      //       if (v.uid !== card.uid) {
      //         return v;
      //       }
      //     });
      //     if (cardInfo.data?.condition.conditionSide === ConditionSide.less) {
      //       lessConditionSide(
      //         cardInfo,
      //         currentCard,
      //         { currValue: healthInfo.currHp, maxValue: healthInfo.maxHp },
      //         updatedCards
      //       );
      //     } else {
      //       //TODO вызывать moreConditionSide
      //     }
      //     break;
      // }
    },
  };

  const lessConditionSide = (
    cardInfo: ICard,
    currentCard: CardProps,
    parameterInfo: ParameterInfo,
    updatedCards: CardProps[]
  ) => {
    if (cardInfo.id === 4) {
      // console.log("START LESS CONDITION SIDE");
    }
    switch (cardInfo?.condition?.calculationMethod) {
      case CalculationMethod.percent:
        //console.log("before statement", parameterInfo);
        if (cardInfo.id === 4) {
          // console.log("BOSS CURRENT HP->", bossCurrentHp);
          // console.log(
          //   "CONDITIONVALUE",
          //   Math.ceil((bossMaxHp * cardInfo.data?.condition.value) / 100)
          // );
        }
        currentCard.isBacklight = bossCurrentHp <= Math.ceil((bossMaxHp * cardInfo?.condition.value) / 100);

        //console.log("CURRENT BACKLIGHT", currentCard.isBacklight);
        //setCards([]);
        setCards([...updatedCards, currentCard]);

        break;
      case CalculationMethod.count:
        //TODO когда появятся карты с нужным условием
        break;
    }
  };

  const moreConditionSide = (cardInfo: ICard, currentCard: CardProps, parameterInfo: ParameterInfo) => {
    switch (cardInfo?.condition?.calculationMethod) {
      case CalculationMethod.percent:
        currentCard.isBacklight = parameterInfo.currValue > (parameterInfo.maxValue * cardInfo?.condition.value) / 100;
        setCards([...cards, currentCard]);
        break;
      case CalculationMethod.count:
        //TODO когда появятся карты с нужным условием
        break;
    }
  };

  useEffect(() => {
    console.log("effects value changed", effects);
  }, [effects]);

  function getUniqueEffectsByEffectId(effects: BattleEffectsData[]): BattleEffectsData[] {
    const uniqueEffectsMap = new Map<EffectType, BattleEffectsData>();

    for (const effect of effects) {
      // If an effect with the same effectId doesn't exist in the map, add it.
      if (!uniqueEffectsMap.has(effect.effectId)) {
        uniqueEffectsMap.set(effect.effectId, effect);
      }
    }

    // Convert the Map's values back into an array.
    return Array.from(uniqueEffectsMap.values());
  }

  const checkEffectAndSet = (target: Opponent, effect: BattleEffectsData) => {
    console.log("checkEffectAndSet", effect, Opponent[target]);
    console.log("checkEffectAndSet1", effects);
    switch (target) {
      case Opponent.hero:
        console.log("herere setEffects", effect);
        const uniqueEffects = getUniqueEffectsByEffectId(effects);
        console.log("setEffect on 729", uniqueEffects);
        setEffects([...uniqueEffects, effect]);
        break;
      case Opponent.enemy:
        console.log("BOSS EFFECTS->", bossEffects);
        const uniqueBossEffects = getUniqueEffectsByEffectId(effects);
        setBossEffects((prevEffect) => {
          return [...uniqueBossEffects];
        });
        break;
      default:
        break;
    }
  };

  const changeBossHpAndShowDamageAnimation = async (bossHpValue: number) => {
    // console.log("we are here ");
    // if(effects.some((effectInfo) => effectInfo.effectId === BattleEffects.returnDamage)){
    //   setIsReturnDamageAnimation(true);
    //   setTimeout(() => setIsReturnDamageAnimation(false), 2000);
    // }
    await handleBossHpChange(bossHpValue);
  };

  const handleHeroDefenseChange = async (
    newDefense: number,
    fastAnim?: boolean | null
  ) => {
    // Зберігаємо попереднє значення захисту
    const prevDefense = defense;

    // Оновлюємо значення захисту
    setDefense((prevHp) => {
      console.log("inside handle hero defence change", defense);

      const hitAmount = prevHp - newDefense;
      // console.log("hitAmount", hitAmount); // Логічне значення

      // Оновлюємо hitAmount перед оновленням HP
      setBossHitAmount(hitAmount);

      // Повертаємо новий HP
      return newDefense;
    });

    // Оновлюємо ефекти
    console.log("EFFECTS IN HANDLE DEFENCE CHANGE", effects);

    const effectsWithoutDefense = effects.filter((v) => v.effectId !== EffectType.defense);

    // Запускаємо анімацію при зміні захисту
    if (newDefense < prevDefense) {
      if (effects.some((effectInfo) => effectInfo.effectId === EffectType.returnDamage)) {
        setIsReturnDamageAnimation(true);
        setTimeout(() => setIsReturnDamageAnimation(false), 2000);
      }
      // Якщо захист зменшився - виконуємо анімацію атаки боса

      if (fastAnim === null) {
        await delay(1200);
      } else if (!fastAnim) {
        await delay(1200);
      }
      setTriggerBossAninationHit(true);
      await delay(fastAnim === null ? 300 : 200);
      // Починаємо анімацію атаки боса
      setBossAttack(true);
      setBossAttackAmount(true);
      setAnimationType("defence");
      playSound("defend");
      setTriggerAnimation(true);
      setFadeClass("fade-in");
      console.log("setEffect on 807 new def", newDefense);

      setEffects([
        ...effectsWithoutDefense,
        // {
        //   effectId: EffectType.defense,
        //   amount: newDefense,
        //   effectUid: "someStringForDefense",
        // },
      ]);

      if (!newDefense) {
        setTimeout(() => {
          console.log("setEffect on 820", effectsWithoutDefense);

          setEffects([...effectsWithoutDefense]);
        }, 500);
      }

      if (navigator?.vibrate && settings?.vibration) {
        navigator.vibrate(150);
      }

      // Затримка на тривалість анімації
      await delay(fastAnim === null ? 500 : 200);

      // Завершуємо анімацію
      setFadeClass("fade-out");
      setBossAttack(false);
      await delay(500);
      setBossAttackAmount(false);
      await delay(fastAnim === null ? 500 : 200);

      setTriggerAnimation(false);
      setTriggerBossAninationHit(false);
    }

    if (newDefense > prevDefense) {
      console.log("setEffect on 845 new def", newDefense);
      setEffects([
        ...effectsWithoutDefense,
        // {
        //   effectId: EffectType.defense,
        //   amount: newDefense,
        //   effectUid: "someStringForDefense",
        // },
      ]);
      // Якщо захист збільшився - виконуємо анімацію захисту
      setAnimationType("defence");
      playSound("attackHit");
      setTriggerAnimation(true);
      setFadeClass("fade-in");

      // Затримка на тривалість анімації
      await delay(500);

      setFadeClass("fade-out");

      await delay(500);

      setTriggerAnimation(false);
    }
  };

  const handleBossDefenseChange = async (newDefense: number) => {
    // Зберігаємо попереднє значення захисту боса
    const prevDefense = bossDefense;

    // Оновлюємо значення захисту боса
    // setBossDefense(newDefense);
    // Запускаємо анімацію при зміні захисту боса
    console.log("INSIDE IF BOSSCARDANIMATION BEFORE SET BOSS DEFENCE");
    setBossDefense((prevDef) => {
      // console.log("prevDef", prevDef);
      // console.log("newDefense", newDefense);

      const hitAmount = prevDef - newDefense;

      // console.log("hitAmount", hitAmount); // Логічне значення

      // Оновлюємо hitAmount перед оновленням HP
      setHitAmount(hitAmount);

      // Повертаємо новий HP
      return newDefense;
    });
    console.log("INSIDE IF BOSSCARDANIMATION BEFORE IF STATEMENT");

    if (newDefense > 0) {
      if (newDefense > bossDefense) {
        // console.log('here', newDefense, bossDefense)
        // setShowBossDeck(true);
        await delay(500);
        // setTriggerBossCardAnination(true)

        // setAnimationType("defence");
        // setTriggerAnimation(true);
        // setFadeClass("fade-in");
        // // Очікуємо тривалість анімації fade-in
        // await delay(500);
        // setFadeClass("fade-out");
        // // Очікуємо тривалість анімації fade-out
        // await delay(500);
        // setTriggerAnimation(false);
      }

      if (bossDefense > newDefense) {
        // setAnimationType("defence");
        // setTriggerAnimation(true);
        // setFadeClass("fade-in");
        // // Очікуємо тривалість анімації fade-in
        // await delay(500);
        // setFadeClass("fade-out");
        // // Очікуємо тривалість анімації fade-out
        // await delay(500);
        // setTriggerAnimation(false);
      }
    }
    console.log(" INSIDE IF BOSSCARDANIMATION hAndleDefenceDelay");
    await delay(250);

    // Оновлюємо ефекти боса
    const effectsWithoutDefense = bossEffects.filter((v) => v.effectId !== EffectType.defense);

    setBossEffects([
      ...effectsWithoutDefense,
      // {
      //   effectId: EffectType.defense,
      //   amount: newDefense,
      //   effectUid: "someStringForDefense",
      // },
    ]);

    if (!newDefense) {
      setTimeout(() => {
        setBossEffects([...effectsWithoutDefense]);
      }, 500);
    }
    return true;
  };

  const actionStrategy: {
    [k in ExecutionActionType]?: ({ action, fastAnim }: { action: Action; fastAnim?: boolean | null }) => Promise<void>;
  } = {
    [ExecutionActionType.addEffect]: async ({ action }) => {
      console.log("ADD EFFECT", action);
      if (isAddEffectAction(action)) {
        console.log("ADD EFFECT2", action);
        checkEffectAndSet(action.target, {
          effectId: action.effectId,
          effectUid: EffectType[action.effectId],
          duration: action.duration,
        });
      }
    },
    [ExecutionActionType.removeEffect]: async ({ action }) => {
      if (isAddEffectAction(action)) {
        if (action.target === Opponent.hero) {
          console.log("setEffect on 965", effects);
          setEffects(effects.filter((effect) => effect?.effectUid !== action.effectUid));
        } else {
          setBossEffects(bossEffects.filter((bossEffect) => bossEffect?.effectUid !== action.effectUid));
        }
      }
    },
    [ExecutionActionType.executeSkill]: async ({ action }) => {
      // Логіка виконання скіла
    },
    [ExecutionActionType.executeEffect]: async ({ action }) => {
      // Логіка виконання ефекту
    },
    [ExecutionActionType.updateCards]: async ({ action }) => {
      if (isUpdateCardsAction(action)) {
        setCards(action.cardIds as unknown as CardProps[]);
      }
    },
    [ExecutionActionType.changeParameters]: async ({ action, fastAnim }) => {
      if (isChangeParametersAction(action)) {
        // console.log("at changeParameters", action);

        switch (action.parameter) {
          case Parameter.health:
            if (action.target === Opponent.hero) {
              await handleHeroHpChange(action.value, fastAnim);
            } else {
              await changeBossHpAndShowDamageAnimation(action.value);
            }
            break;
          case Parameter.mana:
            if (action.target === Opponent.hero) {
              setCurrentMana(action.value);
            } else {
              setBossCurrentMana(action.value);
            }
            break;
          case Parameter.actionPoints:
            if (action.target === Opponent.hero) {
              setActionPoints(action.value);
            }
            break;
          case Parameter.defense:
            if (action.target === Opponent.hero) {
              console.log("def param cahnge", action);
              await handleHeroDefenseChange(action.value, fastAnim); // Зміна захисту для героя
            } else {
              await handleBossDefenseChange(action.value); // Зміна захисту для боса
            }
            break;
        }
      }
    },
    [ExecutionActionType.discardCards]: async ({ action }) => {
      if (isDiscardCardsAction(action)) {
        setCards(action.cardIds as unknown as CardProps[]);
      }
    },
    [ExecutionActionType.shuffleCards]: async ({ action }) => {
      if (isShuffleCardsAction(action)) {
        // Додайте анімацію на замішування карти
      }
    },
    [ExecutionActionType.noEffect]: async ({ action }) => {
      if (isNoEffectAction(action)) {
        setTimeout(() => {
          console.log("INSIDE NOEFFECT ACTION", action);
        }, 1000);
      }
    },
  };

  function syncAndModifyArraysWithoutChangingOrder(firstArray: number[], secondArray: any[]): number[] {
    const copyCards = [...cards];
    secondArray.forEach((card, index) => {
      if (!copyCards.some((v) => v.uid === card.uid)) {
        copyCards[copyCards.length] = card;
      }
    });
    const setSecond = new Set(secondArray);

    // Оставляем в первом массиве только те элементы, которые есть во втором
    const preservedOrderArray = firstArray.filter((id) => setSecond.has(id));

    const setFirst = new Set(preservedOrderArray);

    // Добавляем в первый массив те id, которых в нем нет, но которые есть во втором
    const missingInFirst = secondArray.filter((id) => !setFirst.has(id));

    // Возвращаем объединенный массив с сохранением порядка существующих элементов
    return [...preservedOrderArray, ...missingInFirst];
  }

  useEffect(() => {
    console.log("CARDS CHANGES", cards);
  }, [cards]);

  const openDeck = () => {
    setIsDeckOpen(true);
  };

  const closeDeck = () => {
    setIsDeckOpen(false);
  };

  useEffect(() => {
    //console.log("cards before check", cards);
    if (cards) {
      cards.forEach((card) => {
        const cardType = configCards.filter((v) => v.id === card.id)[0]?.cardType;

        // console.log("$$$$$$$$$$$$$$$$$$$$$", cardType);
        //@ts-ignore
        if (cardType) cardStrategy[cardType]({ card });
      });
    }
  }, [cards?.length, currentHp, currentMana, actionPoints, defense, maxHp, bossMaxHp, bossCurrentHp, bossCurrentMana]);
  async function makeCardAction({
    clientId,
    cardUid,
    cardId,
    drawCardId,
    drawCardLvl,
  }: {
    clientId: string;
    cardUid: string;
    cardId: number;
    drawCardId?: number;
    drawCardLvl?: number;
  }): Promise<void> {
    setIsDraggingCards(false);

    //@ts-ignore
    const makeActionStrategyResult = makeActionStrategy[cardId]?.(cardId, cardUid);
    if (makeActionStrategyResult && typeof drawCardId !== "number") return;
    console.log("INSIDE MAKE ACTION", cardUid, drawCardId);
    const usedCardResult = (await makeAction({
      cardUid,
      clientId,
      drawCardId,
      drawCardLvl,
    })) as MakeAction;
    if (usedCardResult) {
      console.log("Actions->", usedCardResult);
      for (const action of usedCardResult.actions) {
        console.log("Action iterator", action);
        await new Promise<void>((resolve) => {
          // console.log("START ACTION", action);
          //@ts-ignore
          actionStrategy[action?.type]?.({ action });
          resolve();
        });
        await sleep(100);
      }
      //Если массив пассивок\эффектов существует отправляем в экшн стратеджи actionType 0
      // if(usedCardResult.battleEffectsData.length > 0 || usedCardResult.enemyBattleEffectsData.length > 0){
      //   usedCardResult.battleEffectsData.map((e: any, i: any) => console.log('EFFECTS IN BATTLE', {target: Opponent.hero, ...usedCardResult.battleEffectsData[0][i]}))

      //   usedCardResult.battleEffectsData.map((e: any, i: number) => actionStrategy[ExecutionActionType.addEffect]?.({target: Opponent.hero, ...usedCardResult.battleEffectsData[0][i]}))
      //   usedCardResult.battleEffectsData.map((e: any, i: number) => actionStrategy[ExecutionActionType.addEffect]?.({target: Opponent.enemy, ...usedCardResult.enemyBattleEffectsData[0][i]})
      // )
      // }
      // Оновлюємо карти
      cards.forEach((card) => {
        const cardType = configCards.find((v) => v.id === card.id)?.cardType;
        if (cardType && cardStrategy[cardType as CardType]) {
          //@ts-ignore
          cardStrategy[cardType]({ card });
        }
      });

      !isFirstUsedCard && setIsFirstUsedCard(true);
      setIsDraggingCards(true);

      const newEffects = usedCardResult?.battleEffectsData.length > 0 ? [...usedCardResult.battleEffectsData] : [];
      const uniqueNewEffects = getUniqueEffectsByEffectId(
        newEffects.map((e) => {
          return { ...e[0], effectUid: EffectType[e[0].effectId] };
        })
      );

      const newBossEffects =
        usedCardResult?.enemyBattleEffectsData.length > 0 ? [...usedCardResult.enemyBattleEffectsData] : [];
      const uniqueNewBossEffects = getUniqueEffectsByEffectId(
        newBossEffects.map((e) => {
          return { ...e[0], effectUid: EffectType[e[0].effectId] };
        })
      );

      console.log("setEffect on 1155", newEffects, newBossEffects);

      setEffects((prevEffect) => {
        console.log("prevEffect in HERO", prevEffect, ...usedCardResult.battleEffectsData);
        return [...uniqueNewEffects];
      });

      setBossEffects((prevEffect) => {
        console.log("prevEffect in BOSSEFFECT", prevEffect);
        return [...prevEffect.filter((e) => e.effectUid === "someStringForDefense"), ...uniqueNewBossEffects];
      });

      return void 0;
    }
  }

  const handleWin = () => {
    setIsBattleEnd(true);
    setIsWin(true);
    // navigateTo("/tutorial/dialogue");
    // if (tutorialSave) { //TODO зачем тут timeout?
    // setTimeout(() => {
    // @ts-ignore
    if (tutorialSave?.completed) {
      //@ts-ignore

      //@ts-ignore
      //usingCard({dungeonId: actualSaves?.dungeonId, clientId: userId, stageId: actualSaves?.currentStage});
      // updateBattleSave({
      //   clientId: userId,
      //   save: {
      //     //@ts-ignore
      //     dungeonId: actualSaves.dungeonId,
      //     //@ts-ignore
      //     lastStageId: actualSaves.lastStageId + 1,
      //     //@ts-ignore
      //     enemyId: actualSaves.enemyId,
      //     //@ts-ignore
      //     stages: actualSaves.stages,
      //     currentStage: actualSaves?.currentStage,
      //     }});
      navigateTo("/dungeon");

      return;
    }
    //@ts-ignore
    // console.log("CASE WITH WIN SKELETON AT TUTORIAL!!!!!!!!", tutorialSave);
    //@ts-ignore
    if (tutorialSave?.enemyId < 2) {
      if (!tutorialSave?.enemyId) {
        //dispatch(setSave({ enemyId: 1, stage: TutorialStage.dialogue1, dialogueId: (tutorialSave?.dialogueId ?? 0) + 1 }));
        updateSave({
          save: { enemyId: 1, stage: TutorialStage.dialogue1, dialogueId: 1 },
        });
        navigateTo("/tutorial/dialogue");
      } else {
        //dispatch(setSave({ enemyId: tutorialSave?.enemyId + 1, stage: TutorialStage.dialogue2, dialogueId: (tutorialSave?.dialogueId ?? 0) + 1 }));
        // console.log("BEFORE UPDATE SAVE AT END OF BATTLE", tutorialSave);
        updateSave({ save: { ...tutorialSave, completed: true } });

        if (APP_ENV === "production") {
          ReactGA.event({ category: "Tutorial", action: "Finish" });
          //amplitude.track("Finish", { group: "Tutorial" });
        }

        navigateTo("/island");
      }
    }
    // }, 1500);
    //}
  };

  const handleAnimationWinEnd = () => {
    // console.log("handleAnimationWinEnd");
    setFadeClassEndBattle("fade-out-end-battle");

    setTimeout(() => {
      setFadeClassEndBattle("");
      setIsAnimationWinStart(false);
      setShowPopupButton(false);
      const levelUp = false;
      if (levelUp) {
        setIsLevelUpStart(true);
        playSound("levelUp");
      } else {
        handleWin();
      }
    }, 1000);
  };

  const handleRetry = () => {
    setFadeClassEndBattle("fade-out-end-battle");
    setTimeout(() => {
      setIsAnimationLooseStart(false);
      setShowPopupButton(false);
      setIsBattleEnd(true);
      setFadeClassEndBattle("");
      //pausedSounds();
      resetBattle();
    }, 1000);
  };

  const handleLoose = () => {
    //setCards([]);
    setFadeClassEndBattle("fade-out-end-battle");
    setTimeout(() => {
      setIsAnimationLooseStart(false);
      setShowPopupButton(false);
      setIsBattleEnd(true);
      setFadeClassEndBattle("");
      //pausedSounds();
      if (tutorialSave?.enemyId === 2) {
        if (APP_ENV === "production") {
          ReactGA.event({ category: "Tutorial", action: "Died" });
          //amplitude.track("Died", { group: "Tutorial" });
        }
        //dispatch(setSave({ enemyId: tutorialSave?.enemyId, stage: TutorialStage.amulet, dialogueId: tutorialSave?.dialogueId }));
        updateSave({
          save: {
            enemyId: tutorialSave?.enemyId,
            stage: TutorialStage.finish,
            dialogueId: tutorialSave?.dialogueId,
          },
        });
        navigateTo("/tutorial");
      } else {
        if (enemyId > 2) {
           navigateTo("/dungeon");
        }
        resetBattle();
      }
    }, 1000);
  };

  const handeleStartBattle = () => {
    setIsBattleStartAnimation(true);
    playSound("battleStart");
    setTimeout(() => {
      setIsBattleStart(true);
      setIsBattleStartAnimation(false);
    }, 2500);
    if (APP_ENV === "production") {
      ReactGA.event({
        category: "Battle",
        action: "Start battle vs " + bossName,
      });
      // amplitude.track("Battle vs " + bossName, {
      //   group: "Battle",
      //   enemy: bossName,
      //   start: true,
      // });
    }
  };

  useEffect(() => {
    if (hasInteracted) {
      if (isBoss) {
        playMusic("battleBoss");
      } else {
        playMusic("battleEnemy");
      }
    }
  }, [hasInteracted]);

  const resetBattle = async () => {
    setIsBattleEnd(false);
    setIsWin(false);

    // let enemyId = 0; //TODO default value for first enemy
    // // if (tutorialSave) {
    //   if (tutorialSave?.completed) {
    //     //@ts-ignore
    //     enemyId = actualSaves?.enemyId;
    //   } else {
    //     enemyId = tutorialSave?.enemyId || 0;
    //   }
    // //}

    if (tutorialSave?.completed && (!actualSaves || (!actualSaves.buildingId && !actualSaves.bossId))) {
      console.log("GOING TO ISLAND", !actualSaves || !actualSaves.buildingId || !actualSaves.bossId);
      navigateTo("/island");
      return;
    }

    const battleInitEndpoint = await startBattle({
      clientId: userId,
      //@ts-ignore
      cardId: tutorialSave?.completed ? actualSaves?.currentStage : enemyId,
      dungeonId: actualSaves?.dungeonId ?? 0,
      buildingId: actualSaves?.bossId
        ? undefined
        : actualSaves?.buildingId ?? 0,
      bossId: actualSaves?.bossId,
    });

    if (battleInitEndpoint) {
      console.log("BATTLE INIT RESET", battleInitEndpoint);

      console.log("Battle Init ->", battleInitEndpoint);
      setMaxHp(battleInitEndpoint?.maxHp);
      setMaxMana(battleInitEndpoint?.maxMana);
      setCurrentHp(battleInitEndpoint?.currentHp);
      setCurrentMana(battleInitEndpoint?.maxMana);
      setBossMaxHp(battleInitEndpoint?.enemy?.maxHp);
      setBossMaxMana(battleInitEndpoint?.enemy?.maxMana);
      setBossDefense(battleInitEndpoint?.enemy.defense);
      setBossCurrentHp(battleInitEndpoint?.enemy?.currentHp);
      setBossCurrentMana(battleInitEndpoint?.enemy?.maxMana);
      setBossLvl(enemies[battleInitEndpoint?.enemy?.id]?.lvl);
      setIsBoss(enemies[battleInitEndpoint?.enemy?.id]?.isBoss);
      setCardsAtDeck(battleInitEndpoint?.cardsAtDeck);
      setBossName(enemies[battleInitEndpoint?.enemy?.id]?.name);
      setHeroName(heroesMap[battleInitEndpoint?.heroId as HeroShard].name);
      setCards([]);
      setCards(battleInitEndpoint?.cardsAtHand);
      setBossCardsAtHand(battleInitEndpoint?.enemy?.cardsAtHand);

      if (battleInitEndpoint?.enemy.defense) {
        const effectsWithoutDefense = bossEffects.filter((v) => v.effectId !== EffectType.defense);

        setBossEffects([
          ...effectsWithoutDefense,
          // {
          //   effectId: EffectType.defense,
          //   amount: battleInitEndpoint?.enemy.defense,
          //   effectUid: "someStringForDefense",
          // },
        ]);
      }
      if (battleInitEndpoint?.battleEffectsData) {
        const newEffects =
          battleInitEndpoint?.battleEffectsData.length > 0 ? [...battleInitEndpoint.battleEffectsData] : [];

        const uniqueNewEffects = getUniqueEffectsByEffectId(
          newEffects.map((e) => {
            return { ...e[0], effectUid: EffectType[e[0].effectId] };
          })
        );

        setEffects((prevEffect) => {
          return [...uniqueNewEffects];
        });
      }

      if (battleInitEndpoint?.enemy.battleEffectsData) {
        const newBossEffects =
          battleInitEndpoint?.enemy.battleEffectsData.length > 0 ? [...battleInitEndpoint.enemy.battleEffectsData] : [];

        const uniqueNewBossEffects = getUniqueEffectsByEffectId(
          newBossEffects.map((e) => {
            return { ...e[0], effectUid: EffectType[e[0].effectId] };
          })
        );

        setBossEffects((prevEffect) => {
          return [...uniqueNewBossEffects];
        });
      }

      handeleStartBattle();
    }
  };

  useEffect(() => {
    checkBattleEnd();
  }, [currentHp, bossCurrentHp]);

  const handleBossDying = () => {
    setBossDyingAnimation(true);
    playSound("death");
    delay(1000);

    setIsAnimationWinStart(true);

    delay(300);

    setShowPopupButton(true);
  };

  const checkBattleEnd = () => {
    if (currentHp <= 0) {
      playSound("battleEnd");
      setTimeout(()=>{
        if (APP_ENV === "production") {
          ReactGA.event({
            category: "Battle",
            action: "Loose in battle vs " + bossName,
          });
          // amplitude.track("Battle vs " + bossName, {
          //   group: "Battle",
          //   enemy: bossName,
          //   loose: true,
          // });
        }
        if (usedLives < 1) {
          setIsAnimationLooseStart(true);
        } else {
          setShowDefeatScreen(true);
        }
        delay(300);
        setShowPopupButton(true);
        setCards([]);
        setIsBattleEnd(true);
      },1000);
      // if (tutorialSave?.enemyId === 2) {
      //   ReactGA.event({ category: "Tutorial", action: "Died" });
      //   navigateTo("/tutorial");
      // } else {
      //   resetBattle();
      // }
    }

    if (bossCurrentHp <= 0) {
      playSound("battleEnd");
      setBossDying(true);
      const getRewards = async () => {
        const getRewardsResult = await getRewardsAfterBattle({
          dungeonId: actualSaves?.dungeonId!,
          clientId: userId,
          stageId: actualSaves?.currentStage!,
        });
        if (getRewardsResult) {
          setRewards(getRewardsResult);
        }
      };
      if (tutorialSave?.completed) {
        getRewards();
      } else {
        setRewards({ coins: 5, bossRewards: [] });
      }
      if (APP_ENV === "production") {
        ReactGA.event({
          category: "Battle",
          action: "Win in battle vs " + bossName,
        });
        // amplitude.track("Battle vs " + bossName, {
        //   group: "Battle",
        //   enemy: bossName,
        //   win: true,
        // });
      }
      setIsBattleEnd(true);
      // setIsWin(true);
      // if (tutorialSave) {
      //   dispatch(setSave({enemyId: tutorialSave.enemyId + 1}));
      // }
      // navigate('/tutorial/dialogue');
    }
  };

  async function executeAction(steps: { cardInfo?: { cardId: number; lvl: number; uid: string }; actions: Action[] }) {
    console.log("Steps in execute action", steps);
    // Перевіряємо, чи є cardInfo і чи дія належить босу
    if (steps.cardInfo) {
      console.log("INSIDE IF BOSSCARDANIMATION TRUE");
      // Встановлюємо поточну карту боса для анімації
      setShowBossDeck(true);
      await delay(500);
      console.log("INSIDE IF BOSSCARDANIMATION 2");
      setTriggerBossCardAnination(true);
      //await delay(1000);
    }
    console.log("INSIDE IF BOSSCARDANIMATION 3");

    let previousActionType: ExecutionActionType | null = null;

    for (let i = 0; i < steps.actions.length; i++) {
      const act = steps.actions[i];
      const nextAction = steps.actions[i + 1] || null;
      const prevAction = steps.actions[i - 1] || null;
      if (actionStrategy[act?.type]) {
        if (act.type !== ExecutionActionType.updateCards) {
          const sameType = previousActionType === act.type;
          const sameNextTarget = nextAction && act.target === nextAction.target;
          const samePrevTarget = prevAction && act.target === prevAction.target;
          const fastAnim = sameType;

          //@ts-ignore
          await actionStrategy[act.type]({
            action: act,
            fastAnim: steps.actions.length > 1 ? (sameNextTarget || samePrevTarget ? fastAnim : null) : null,
          }).then(() => {
            console.log("INSIDE IF BOSSCARDANIMATION FALSE");
            setTriggerBossCardAnination(false);
          });

          previousActionType = act.type;
        }
      } else {
        console.warn(`No handler for action type: ${act.type}`);
      }
    }
  }

  async function endTurnEndpoint() {
    setUsedCards([]);
    setIsDraggingCards(false);
    setIsAllStepsResolved(false);
    const endTurnResult = await endTurn({
      clientId: userId,
    });

    //TODO update for new return value
    if (endTurnResult) {
      let allBossCards: CardProps[] = [];
      console.log("EFFECTS IN ENDTURN", endTurnResult);
      // Проходимо по всіх кроках
      if (endTurnResult?.steps) {
        endTurnResult.steps.forEach((step) => {
          // Проходимо по всіх діях в кроці
          // Перевіряємо, чи є cardInfo та чи дія належить босу
          if (step.cardInfo) {
            allBossCards.push({ ...step.cardInfo, id: step.cardInfo.cardId });
          }
        });
      }
      console.log("allBossCards", allBossCards);
      setBossDeck(allBossCards);

      if (endTurnResult?.steps) {
        for (const action of endTurnResult.steps) {
          console.log("Processing action:", action);

          // here cards
          await executeAction(action);
          // for (const act of action.actions) {

          //   if (actionStrategy[act?.type]) {
          //     if (act.type !== ExecutionActionType.updateCards) {
          //       //@ts-ignore
          //       await actionStrategy[act.type]({ action: act });
          //     }
          //   } else {
          //     console.warn(`No handler for action type: ${act.type}`);
          //   }
          // }
          //@ts-ignore

          // if (endTurnResult.cardsAtHand.length > 0) {
          //   console.log('HEREEE at CARDS FROM ????');
          //   setCards(endTurnResult?.cardsAtHand as unknown as CardProps[]);
          //   const updatedCardsAtDeck = cardsAtDeck.filter(
          //     (deckCard) =>
          //       !endTurnResult?.cardsAtHand.some(
          //         (card) => card.uid === deckCard.uid
          //       )
          //   );
          //   setCardsAtDeck(updatedCardsAtDeck);
          // }

          // Можливо, потрібно затримати між діями для плавності анімації
          // await delay(600);
        }
      }
      if (endTurnResult?.cardsAtHand?.length > 0) {
        // console.log("HEREEE at CARDS FROM ????");

        setCards(endTurnResult?.cardsAtHand as unknown as CardProps[]);
        //console.log("END TURN CARDS", endTurnResult?.cardsAtHand);

        setCardsAtDeck(endTurnResult?.cardsAtDeck as unknown as CardProps[]);

        // console.log('cardsAtDeck', cardsAtDeck)
        // console.log('endTurnResult?.cardsAtHand', endTurnResult?.cardsAtHand)

        // const updatedCardsAtDeck = cardsAtDeck.filter(
        //   (deckCard) =>
        //     !endTurnResult?.cardsAtHand.some(
        //       (card) => card.uid === deckCard.uid
        //     )
        // );

        // console.log("updatedCardsAtDeck", updatedCardsAtDeck);

        // setCardsAtDeck(updatedCardsAtDeck);

        // preload my new cards
        endTurnResult.cardsAtHand.forEach((card) => {
          const foundCard = configCards.find((v) => v.id === card.id);

          if (foundCard) {
            try {
              const img = new Image();
              img.src = require(`../../assets/images/cards/${foundCard.id}.png`);
            } catch (e) {
              console.error(`Error loading image for card ${foundCard.id}`);
            }
          }
        });

        // preload new enemy cards
        if (endTurnResult?.enemy?.cardsAtHand && endTurnResult.enemy.cardsAtHand.length > 0) {
          endTurnResult.enemy.cardsAtHand.forEach((card) => {
            const foundCard = configCards.find((v) => v.id === card.id);

            if (foundCard) {
              const { name: cardName } = foundCard;
              try {
                const img = new Image();
                img.src = require(`../../assets/images/cards/${foundCard.id}.png`);
              } catch (e) {
                console.error(`Error loading card /images/cards/${foundCard.id}.png`);
              }
            }
          });
        }
      }

      setBossCardsAtHand(endTurnResult?.enemy?.cardsAtHand);

      // Оновлюємо карти
      cards.forEach((card) => {
        const cardType = configCards.find((v) => v.id === card.id)?.cardType;
        if (cardType && cardStrategy[cardType as CardType]) {
          //@ts-ignore
          cardStrategy[cardType]({ card });
        }
      });

      const newEffects = endTurnResult?.battleEffectsData.length > 0 ? [...endTurnResult.battleEffectsData] : [];
      const uniqueNewEffects = getUniqueEffectsByEffectId(
        newEffects.map((e) => {
          return { ...e[0], effectUid: EffectType[e[0].effectId] };
        })
      );

      const newBossEffects =
        endTurnResult?.enemy.battleEffectsData.length > 0 ? [...endTurnResult.enemy.battleEffectsData] : [];
      const uniqueNewBossEffects = getUniqueEffectsByEffectId(
        newBossEffects.map((e) => {
          return { ...e[0], effectUid: EffectType[e[0].effectId] };
        })
      );

      console.log("setEffect on 1650", newEffects, newBossEffects);

      setEffects((prevEffect) => {
        console.log("prevEffect in HERO", prevEffect);
        return [...uniqueNewEffects];
      });

      setBossEffects((prevEffect) => {
        console.log("prevEffect in BOSSEFFECT", prevEffect);
        return [...uniqueNewBossEffects];
      });

      setIsAllStepsResolved(true);

      // Чекаємо завершення всіх анімацій перед початком нового ходу
      await handleStartTurn();
      setIsFirstUsedCard(false);
      setIsDraggingCards(true);
      setShowBossDeck(false);
    }
  }

  useEffect(() => {
    console.log("Effect defence", defense);
  }, [defense]);

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function battleInit() {
      if (!tutorialSave) {
        dispatch(setSave({ enemyId: 0, stage: TutorialStage.battle }));
      }
  
      if (tutorialSave?.completed && (!actualSaves || (!actualSaves.buildingId && !actualSaves.bossId))) {
        console.log("Navigates to island", actualSaves);
        navigateTo("/island");
        return;
      }
  
      const battleInitEndpoint = await startBattle({
        clientId: userId,
        dungeonId: actualSaves?.dungeonId ?? 0,
        //@ts-ignore
        cardId: tutorialSave?.completed ? actualSaves?.currentStage : enemyId,
        buildingId: actualSaves?.bossId ? undefined : actualSaves?.buildingId ?? 0,
        bossId: actualSaves?.bossId,
      });
  
      if (!battleInitEndpoint) {
        setError(true);
      }
  
      if (battleInitEndpoint) {
        setError(false);
        setLoaded(true);
        if (APP_ENV === "production") {
          ReactGA.event({
            category: "Tutorial",
            action: "Battle " + (battleInitEndpoint?.enemy.id + 1),
          });
        }
        setMaxHp(battleInitEndpoint?.maxHp);
        setMaxMana(battleInitEndpoint?.maxMana);
        setCurrentHp(battleInitEndpoint?.currentHp);
        //console.log('AT MIDDLE STEP');
        setCurrentMana(battleInitEndpoint?.maxMana);
        setBossMaxHp(battleInitEndpoint?.enemy?.maxHp);
        setBossMaxMana(battleInitEndpoint?.enemy?.maxMana);
        setBossDefense(battleInitEndpoint?.enemy.defense);
        setBossCurrentHp(battleInitEndpoint?.enemy?.currentHp);
        setBossCurrentMana(battleInitEndpoint?.enemy?.maxMana);
        setBossLvl(enemies[battleInitEndpoint?.enemy?.id]?.lvl);
        setIsBoss(enemies[battleInitEndpoint?.enemy?.id]?.isBoss);
        setCardsAtDeck(battleInitEndpoint?.cardsAtDeck);
        setBossName(enemies[battleInitEndpoint?.enemy?.id]?.name);
        setHeroName(heroesMap[battleInitEndpoint?.heroId as HeroShard].name);
        setCards(battleInitEndpoint?.cardsAtHand);
        setBossCardsAtHand(battleInitEndpoint?.enemy?.cardsAtHand);
  
        // if (battleInitEndpoint?.enemy.defense) {
        //   const effectsWithoutDefense = bossEffects.filter((v) => v.effectId !== EffectType.defense);
  
        //   setBossEffects([
        //     ...effectsWithoutDefense,
        //     {
        //       effectId: EffectType.defense,
        //       amount: battleInitEndpoint?.enemy.defense,
        //       effectUid: "someStringForDefense",
        //     },
        //   ]);
        // }

        if (battleInitEndpoint?.battleEffectsData) {
        const newEffects =
          battleInitEndpoint?.battleEffectsData.length > 0 ? [...battleInitEndpoint.battleEffectsData] : [];

        const uniqueNewEffects = getUniqueEffectsByEffectId(
          newEffects.map((e) => {
            return { ...e[0], effectUid: EffectType[e[0].effectId] };
          })
        );

        setEffects((prevEffect) => {
          return [...uniqueNewEffects];
        });
      }

      if (battleInitEndpoint?.enemy.battleEffectsData) {
        const newBossEffects =
          battleInitEndpoint?.enemy.battleEffectsData.length > 0 ? [...battleInitEndpoint.enemy.battleEffectsData] : [];
          
        const uniqueNewBossEffects = getUniqueEffectsByEffectId(
          newBossEffects.map((e) => {
            return { ...e[0], effectUid: EffectType[e[0].effectId] };
          })
        );

        setBossEffects((prevEffect) => {
          return [...uniqueNewBossEffects];
        });
      }
        handeleStartBattle();
        // preload my cards
        if (battleInitEndpoint?.cardsAtHand && battleInitEndpoint.cardsAtHand.length > 0) {
          battleInitEndpoint.cardsAtHand.forEach((card) => {
            try {
              const img = new Image();
              img.src = require(`../../assets/images/cards/${card.id}.png`);
            } catch (e) {
              console.error(`Error loading image for card ${card.id}`);
            }
          });
        }
  
        // preload enemy cards
        if (battleInitEndpoint?.enemy?.cardsAtHand && battleInitEndpoint.enemy.cardsAtHand.length > 0) {
          battleInitEndpoint.enemy.cardsAtHand.forEach((card) => {
            try {
              const img = new Image();
              img.src = require(`../../assets/images/cards/${card.id}.png`);
            } catch (e) {
              console.error(`Error loading image for card ${card.id}`);
            }
          });
        }
      }
    }
    battleInit();
  }, []);


  useEffect(() => {
    handleStartTurn();
  }, [isBattleStart]);

  const checkIsCardUsage = () => {
    //TODO стратегия для подсветки карты
  };

  const card = {
    id: 1,
    lvl: 10,
    uid: "unique-id-1",
    selected: true,
    draggable: false,
    hidden: false,
    isBacklight: true,
  };

  const handleExitBattleBeforeEnd = async () => {

    if (usedLives < 1) {
      await deleteRoomProgress({
        clientId: userId,
      });
      await leaveCurrentFight({
        clientId: userId,
      });

      setIsAnimationLooseStart(true);
    } else {
      setShowDefeatScreen(true);
    }
  };

  const getHeroesList = async () => {
    try {
      // Отримання даних з API
      const result = await getHeroes({ clientId: userId });

      if (result?.heroes?.length) {
        // Створюємо мапу для швидкого пошуку Hero за heroId
        const heroMap: Record<number, Hero> = {};
        result.heroes.forEach((hero: Hero) => {
          heroMap[hero.heroId] = hero;
        });

        // Перетворюємо доступних героїв у FullHero[]
        const heroes: FullHero[] = allHeroes.map((configHero: ConfigHero) => {
          const heroData: Hero | undefined = heroMap[configHero.id.value];

          if (heroData) {
            const hero = parseFullHero(configHero, heroData);

            return {
              ...hero,
              name: `${t(`heroes.${configHero.id.value}`)}`,
            };
          } else {
            return {
              heroId: configHero.id.value,
              boosts: { hp: 0, mana: 0 },
              level: 0,
              inDungeon: false,
              upgradeCards: 0,
              expToNextLevel: 0,
              upgrades: [{ id: 1, level: 1 }],
              upgradesCount: 0,
              id: configHero.id.value,
              isAvaillable: false,
              tiers: [],
              levels: [],
              img: require(`../../assets/images/heroes/cards/hero-${configHero.id.value}.jpg`),
              name: "",
              rarity: HeroRarity.Rare,
              rating: { claimedLevels: 1, totalLevels: 30 },
              energyType: 0,
              energyAmount: 0,
              health: 0,
              nextLevel: null,
              cardsAmount: 0,
              cards: [],
              inDungeonId: 0,
              maxLevel: 1,
              usedLives: 0,
              boughtLives: 0,
            };
          }
        });

        dispatch(setHeroesList(heroes));
      }
    } catch (error) {
      console.error("Error fetching heroes:", error);
    }
  };

  useEffect(() => {
    //console.log("BEFORE HEROLIST");
    //console.log("GETHEROLIST REQ");
    getHeroesList();
  }, []);

  useEffect(() => {
    if (selectedHero) {
      setSkillsData(transformTreeToSkillsData(selectedHero?.tiers!));
      setHeroLvl(selectedHero?.level);
      setBoughtLives(selectedHero?.boughtLives);
      if (selectedHero.usedLives > 1) {
        resetDungeon();
        // handleLoose();
        // getHeroesList();
        if(actualSaves?.bossId){
          navigateTo('/boss');
        }else{
          navigateTo('/island');
          setStartSelectHero(true);
        }
      } else {
        setUsedLives(selectedHero.usedLives);
      }
    } else {
      setSkillsData([]);
    }
  }, [selectedHero, isSuccessPurchase]);

  const returnLastRemoved = () => {
    const card = lastRemovedCard;
    let newCards = cards;
    if (card) {
      newCards.push(card);
      setCards(newCards);
    }
  };

  const handleCloseSelectCards = () => {
    console.log("CARDS AT HANFD IN HANDLER", cards);
    setCardsListForChoise([]);
    setSelectCardsPopup(false);
    returnLastRemoved();
    setIsDraggingCards(true);
    setTimeout(() => popLastUsedCard(), 1000);
  };

  const resetDungeon = async () => {
    await resetCurrentDungeon({ clientId: userId, heroId: 1 });
  };

  const {
    offers: { variables: allOffers },
  } = appConfig;

  const heartOffers: ConfigOffer = allOffers.find(
    (offer: ConfigOffer) =>
      offer.offerType.value === OfferType.buyHeartByStarsOffer
  );



  const { handleShare } = useTelegramShareMessage(async () => {
    hadnleBuyLifeWithShare();
  });

  const hadnleBuyLifeWithShare = async () => {
    const result = await buyLifeWithShare({ clientId: userId });
    if (result) {
      setShowDefeatScreen(false);
      resetBattle();
      getHeroesList();
    }
  };

  useEffect(() => {
    if(isSuccessPurchase){
      playSound("questsReward");
      setShowDefeatScreen(false);
      resetBattle();
      getHeroesList();
    }
  }, [isSuccessPurchase]);


  // @ts-ignore

  // console.log("CHECK", !isBattleEnd && !isAllStepsResolved && bossDeck, triggerBossCardAnination);
  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: "" };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
      }}
    >
      {!error ? (
        <>
          {/* <button onClick={() => handleHeroHpChange(19)}>Heal</button> */}
          {isBattleStartAnimation && (
            <>
              {
                <motion.div
                  className="h-full w-full absolute bg-no-repeat bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${
                      enemyId
                        ? require(`../../assets/images/battle/battle-background-${enemyId}.webp`)
                        : require(`../../assets/images/battle/battle-background-0.webp`)
                    })`,
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2 }}
                ></motion.div>
              }
              <Startbattle className="absolute w-full h-full ml-[5px] z-20" />
            </>
          )}
          {isBattleStart && (
            <>
              <div>
                {/*<div> TODO battle background</div>*/}
                <div className="absolute flex items-center justify-center h-full w-full">
                  {tutorialSave?.completed && (
                    <div className="absolute top-2.5 left-2.5 z-10">
                      <div
                        className="flex items-center justify-center w-8 h-8 bg-stone-700 border border-zinc-900 cursor-pointer"
                        onClick={() => {
                          playSound("notification");
                          setConfirmEndModal(true);
                        }}
                      >
                        <div className="flex items-center justify-center w-[24px] h-[24px] bg-stone-500 border border-zinc-900">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.75 11.1477V12.8523H8.52273L13.2102 17.5398L12 18.75L5.25 12L12 5.25L13.2102 6.46023L8.52273 11.1477H18.75Z"
                              fill="#352A21"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                  {tutorialSave?.completed && confirmEndModal && (
                    <>
                      <div className={"absolute flex flex-col items-center justify-center inset-0 z-30"}>
                        <div className="text-[20px] leading-[1.2] mt-3 mb-2 text-white text-center">
                          {t("battleGoBackTitle")}
                        </div>
                        <img
                          className="max-w-32 max-h-32 object-cover"
                          src={require("../../assets/images/Herz_Big_Broken.png")}
                          alt="broken herz"
                        />
                        <div className="flex justify-center mt-8 gap-4">
                          <PopupButton
                            type="blue"
                            size="medium"
                            width="120px"
                            className="flex-1"
                            onClick={() => {
                              setConfirmEndModal(false);
                              playSound("button");
                            }}
                          >
                            {t("cancel")}
                          </PopupButton>
                          <PopupButton
                            type="red"
                            size="medium"
                            width="120px"
                            className="flex-1"
                            onClick={() => {
                              setConfirmEndModal(false);
                              handleExitBattleBeforeEnd();
                              playSound("battleEnd");
                              setIsBattleEnd(true);
                            }}
                          >
                            Leave
                          </PopupButton>
                        </div>
                      </div>
                      <div className="absolute inset-0 bg-black opacity-80 z-20"></div>
                    </>
                  )}
                  {tutorialSave && (
                    <div
                      className="h-full w-full absolute bg-no-repeat bg-center bg-cover"
                      style={{
                        backgroundImage: `url(${require(`../../assets/images/battle/battle-background-${enemyId}.webp`)})`,
                      }}
                    >
                      {tutorialSave.stage === TutorialStage.battle &&
                        tutorialSave.enemyId === 0 &&
                        !tutorialSave?.cardIsDropped &&
                        cards?.length > 0 && (
                          <div className="absolute inset-0 bg-black bg-opacity-50 z-[10] pointer-events-none"></div>
                        )}
                    </div>
                  )}
                  {triggerHit && (
                    <>
                      <HitAnimation
                        triggerHit={triggerHit}
                        hitType={hitType}
                        hitAmount={hitAmount}
                        className="absolute w-[300px] h-[300px] top-[20%] ml-[12px] z-10"
                      />
                    </>
                  )}
                  {triggerHitAmount && (
                    <div className="absolute w-[300px] h-[300px] top-[16%] z-10 flex items-center justify-center">
                      <FloatingNumber value={-hitAmount} />
                    </div>
                  )}
                  {triggerAnimation && (
                    <div className={`absolute top-0 z-20 h-full w-full ${fadeClass}`}>
                      {animationType === "heal" && (
                        <div className="fixed inset-0 w-full h-full z-[9999] shadow-[inset_0_0_40px_30px_#24b510]"></div>
                      )}
                      {animationType === "hit" && (
                        <div className="fixed inset-0 w-full h-full z-[9999] shadow-[inset_0_0_40px_30px_#a10b28]"></div>
                      )}
                      {animationType === "defence" && (
                        <div className="fixed inset-0 w-full h-full z-[9999] shadow-[inset_0_0_40px_30px_#1c79c3]"></div>
                      )}
                    </div>
                  )}
                  {/* <TextAnimation /> */}
                  {/*@ts-ignore*/}
                  {(!tutorialSave || enemyId < 1) && !isBattleEnd && (
                    <motion.div
                      initial={{ opacity: 1 }} // Початково непрозорий
                      animate={{ opacity: bossDyingAnimation ? 0 : 1 }} // Якщо bossDying true, то зникає (прозорість 0)
                      transition={{ duration: 2 }} // Тривалість анімації прозорості
                      className="flex justify-center items-center"
                    >
                      <UnitAnimation
                        fileName={"/enemy/newskeleton.riv"}
                        className={"absolute w-[300px] h-[300px] top-[20%] ml-[12px]"}
                        triggerHit={triggerHit}
                        triggerAttack={triggerBossAninationHit}
                      />
                    </motion.div>
                  )}
                  {/*@ts-ignore*/}
                  {tutorialSave && enemyId > 0 && !isBattleEnd && (
                    <motion.div
                      initial={{ opacity: 1 }} // Початково непрозорий
                      animate={{ opacity: bossDyingAnimation ? 0 : 1 }} // Якщо bossDying true, то зникає (прозорість 0)
                      transition={{ duration: 2 }} // Тривалість анімації прозорості
                      className="flex justify-center items-center"
                    >
                      <UnitAnimation
                        fileName={`/enemy/${enemyId}.riv`}
                        className="absolute w-[300px] h-[300px] top-[20%] ml-[12px]"
                        triggerHit={triggerHit}
                        triggerAttack={triggerBossAninationHit}
                      />
                    </motion.div>
                  )}
                  {bossAttack && (
                    <div className="absolute flex justify-center w-full bottom-0 top-[25%] z-[51]">
                      {/* <PlayerGetHit /> */}
                      <HitAnimation
                        triggerHit={bossAttack}
                        hitType={HitType.Slash4}
                        hitAmount={BossHitAmount}
                        className="absolute w-full h-[50vh] bottom-0 z-10"
                      />
                    </div>
                  )}
                  {bossAttackAmount && (
                    <div className="absolute flex justify-center w-full h-full top-[40%] -left-[20%] z-[51]">
                      <FloatingNumber value={-BossHitAmount} isHero={true} />
                    </div>
                  )}
                  {bossDying && (
                    <>
                      <BossDyingAnimation
                        className="absolute w-[300px] h-[300px] top-[20%] ml-[12px]"
                        bossDying={handleBossDying}
                      />
                    </>
                  )}

                  {!isBattleEnd && !isAllStepsResolved && bossDeck && (
                    <CardDeck
                      triggerBossCardAnimationHit={triggerBossCardAnination}
                      initialDeck={bossDeck}
                      showDeck={showBossDeck}
                    />
                  )}
                  {startTurn &&
                    !triggerBossCardAnination &&
                    currentHp > 0 &&
                    (!isAnimationWinStart || !isAnimationLooseStart) && (
                      <YourTurn startTurn={startTurn} className="absolute w-[700px] h-[700px] top-0 ml-[12px] z-30" />
                    )}
                  <EnemyBattleData
                    lvl={bossLvl}
                    maxHp={bossMaxHp}
                    maxMana={bossMaxMana}
                    name={bossName}
                    currentMana={bossCurrentMana}
                    currentHp={bossCurrentHp}
                    currentDefence={bossDefense}
                    effects={bossEffects}
                  />

                  {!isAnimationWinStart && currentHp > 0 && (
                    <ActiveCards
                      cards={cards}
                      makeCardAction={makeCardAction}
                      usedCards={usedCards}
                      setUsedCards={setNewUsedCards}
                      cardStrategy={cardStrategy}
                      removeCard={removeCard}
                      expandTiers={handleTiersExpand}
                      currentMana={currentMana}
                      isDraggingCards={
                        isAllStepsResolved &&
                        isDraggingCards &&
                        !isAnimationWinStart &&
                        !isAnimationLooseStart &&
                        !bossDying
                      }
                    />
                  )}

                  {selectCardsPopup && (
                    <SelectCards
                      count={1}
                      cards={cardsListForChoise}
                      mainCard={mainCard}
                      setSelectedCardHandler={setSelectedCardHandler}
                      onClose={handleCloseSelectCards}
                    />
                  )}

                  {/* {enemyId > 1 && <ShrinesModal expanded={isDraggingCards} />} */}

                  {skillsData.length && enemyId > 1 && (
                      <TiersModal
                        skillData={skillsData}
                        expanded={expandTiers}
                      />
                  )}

                  <Effects effects={effects} isReturnDamageAnimation={isReturnDamageAnimation} defence={defense} />
                </div>
                <ProfileBattleData
                  isFirstCardPlayed={isFirstUsedCard}
                  endTurnEndpoint={endTurnEndpoint}
                  cardsAtDeck={cardsAtDeck}
                  cardsAtHand={cards}
                  currentHp={currentHp}
                  heroName={heroName}
                  heroLvl={heroLvl}
                  currentMana={currentMana}
                  maxMana={maxMana}
                  maxHp={maxHp}
                  usedLives={usedLives}
                  closeDeck={closeDeck}
                  isDeckOpen={isDeckOpen}
                  openDeck={openDeck}
                  currentDefense={defense}
                  isDraggingCards={isDraggingCards}
                  startTurn={startTurn}
                />
              </div>
              <SuccessOverlay isSuccess={isSuccessPurchase} />
            </>
          )}
          {isAnimationWinStart && (
            <>
              <div className="absolute w-full h-full bg-black opacity-90 z-10" onClick={handleAnimationWinEnd}></div>
              <div className={`${fadeClassEndBattle}`} onClick={handleAnimationWinEnd}>
                <VictoryPage
                  className="absolute w-full h-full ml-[5px] z-20"
                  coinNum={rewards?.coins}
                  newHpNum={rewards?.maxHp}
                  oldHpNum={maxHp}
                  expBarNum={25}
                  lvlNum={1}
                />
              </div>
            </>
          )}
          {isLevelUpStart && (
            <div onClick={handleWin}>
              <LevelUp />
            </div>
          )}

          {isAnimationLooseStart &&
            <HeartStop usedLives={usedLives} handleLoose={handleLoose} />
          }
          {showDefeatScreen && (
            <>
              <div
                className="absolute w-full h-full bg-black opacity-80 z-10"
                // onClick={handleLoose}
              ></div>
              <div className={`${fadeClassEndBattle}`}>
                <LoosePage
                  boughtLives={boughtLives}
                  currentHp={currentHp}
                  handleResetDungeon={resetDungeon}
                  enemyId={enemyId ? enemyId : tutorialSave?.enemyId}
                  offerCost={heartOffers.stars.value}
                  handleShare={handleShare}
                  handleBuy={() => confirmPurchase({ offerId: heartOffers.offerId.value })}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div className="absolute w-full h-full flex flex-col gap-2 justify-center items-center text-white text-5xl bg-[#1f1c1a] ">
          <img src={require("../../assets/images/bugImages/retryConnection.png")} />
          <div className="pb-4">
            <div className="text-center text-[#ffefcb] text-2xl font-black  leading-normal">RETRY CONNECTION</div>
            <div className="w-60 text-center text-[#ffefcb] text-base font-semibold  leading-none">
              The connection seems to be off. <br />
              <br />
              Please reload the app and this should fix the problem
            </div>
          </div>

          <PopupButton
            type={"green"}
            onClick={() => {
              window.location.replace(window.location.href);
            }}
          >
            <div className="text-center text-[#ffefcb] text-lg font-black  leading-[18px]">Reload App</div>
          </PopupButton>
        </div>
      )}
    </Sentry.ErrorBoundary>
  );
};
