import i18next from "../i18n/config";

export const calculateTimer = (
  startTimeUTC: number,
  finishAction: () => void
) => {
  const time = startTimeUTC - Date.now();

  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((time / 1000 / 60) % 60);
  const seconds = Math.floor((time / 1000) % 60);

  if (time <= 0) {
    console.log("END TIMER!");
    finishAction();
    return void 0;
  } else {
    let timerStr = "";
    timerStr += days > 0 ? days + i18next.t("timer.day") + " " : "";
    timerStr += hours > 0 ? hours + i18next.t("timer.hour") + " " : "";
    timerStr += minutes > 0 ? minutes + i18next.t("timer.minute") + " " : "";
    timerStr += seconds > 0 ? seconds + i18next.t("timer.second") : "";
    return timerStr;
  }
};

export function msToTime(duration: number): string {
  if (!duration) {
    return '';
  }

  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const seconds = Math.floor((duration / 1000) % 60);

  const parts: string[] = [];

  // Якщо є дні — додаємо d, h, m і не показуємо секунди
  if (days > 0) {
    if (days !== 0) {
      parts.push(`${days}d`);
    }
    if (hours !== 0) {
      parts.push(`${hours}h`);
    }
    if (minutes !== 0) {
      parts.push(`${minutes}m`);
    }
  } else {
    // Якщо днів немає — додаємо лише ті компоненти, що не дорівнюють нулю
    if (hours !== 0) {
      parts.push(`${hours}h`);
    }
    if (minutes !== 0) {
      parts.push(`${minutes}m`);
    }
    if (seconds !== 0) {
      parts.push(`${seconds}s`);
    }
  }

  // Якщо всі значення були 0 (наприклад, duration дуже малий), 
  // то parts буде порожнім – повернемо "0s" чи будь-що на ваш розсуд.
  if (parts.length === 0) {
    return '0s';
  }

  // Повертаємо компоненти, розділені пробілами (або іншим роздільником, який вам до вподоби)
  return parts.join(' ');
}