import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from '../../components/Card';
import { CardProps } from '../../interfaces/card';
import { useScroll } from '../../utils/ScrollContext';
import { PopupButton } from '../../components/PopupButton';
import { GoldIco } from '../../layout/components/HeaderFarm/components/ResourceCard';
import { useTelegram } from '../../hooks/useTelegram';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useTranslation } from 'react-i18next';
import { HandleBackButton } from '../../layout/components/HeaderCave/components';
import { TitleField } from '../../containers/Room';
import { useSoundService } from '../../utils/soundService';
import { getBalance } from '../../endpoints/farmMock';
import { Offer, VipShop } from './components';
import { OfferProps } from './components/Offer';
import { motion } from 'framer-motion';
import { ConfigOffer, Items, OfferType } from '../../mock/offers';
import { OfferResourceProps } from './components/OfferResources';
import { usePurchase } from '../../hooks/usePurchase';
import { SuccessOverlay } from '../../components/SuccessOverlay';
import { getAvailableOffers, getDailyFreebieStatus, getUserOffers } from '../../endpoints/getProfileInfo';
import { DailySaleOffer } from './components/DailySaleOffer';
import { useSessionStorage } from '@uidotdev/usehooks';
import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
import { GrowthFound } from './components/GrowthFound/GrowthFund';
import { SuccessAnimation } from '../Quests';
import { defineCountString } from '../../utils/farmConfigParser';

enum ActiveTab {
  kitsune,
  dailySale,
  packStore,
  GrowthFund,
  vipShop,
}
export const getIsSelected = (item: Items): boolean => {
  return item.isSelected?.value ?? false;
};

export const Shop = ({ gold, close }: { gold: number; close: () => void }) => {
  const { t } = useTranslation();
  const { tg, userId } = useTelegram();
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.GrowthFund);
  const [buyModalOpen, setBuyModal] = useState(false);
  const { setScrollable } = useScroll();
  const scrollableElRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);
  const [availableOfferIds, setAvailableOfferIds] = useState<Set<number> | null>(null);
  const [dailyOfferPurchased, setDailyOfferPurchased] = useState<boolean>(false);
  const [dailyBundlePurchased, setDailyBundlePurchased] = useState<boolean>(false);
  const [canClaimFreebie, setCanClaimFreebie] = useSessionStorage('canClaimFreebie', false);
  // const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  // const [isSuccessPurchase, setIsSuccessPurchase] = useState(false);
  // const [selectedOffer, setSelectedOffer] = useState({} as any);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { playSound } = useSoundService();
  const [safeAreaTop, setSafeAreaTop] = useState(() => {
    return sessionStorage.getItem('safeAreaTop') || 0;
  });
  const {
    offers: { variables: allOffers },
  } = appConfig;

  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();

  useEffect(() => {
    setScrollable(true, scrollableElRef);

    return () => {
      setScrollable(false);
    };
  }, [setScrollable]);

  useEffect(() => {
    const getUserOffersData = async () => {
      const userOffersData = await getUserOffers({
        clientId: userId,
      });

      if (Array.isArray(userOffersData)) {
        setPurchasedOfferIds(
          new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
        );
      }

      const userAvailableOffersData = await getAvailableOffers({
        clientId: userId,
      });

      if (userAvailableOffersData) {
        setAvailableOfferIds(
          new Set(userAvailableOffersData.map((o: { offerId: number; offerType: number }) => o.offerId))
        );
      }
    };
    getUserOffersData();
  }, []);

  // const buyModalHandler = (offerId: number) => {
  //   const offer = allOffers.find((offer: ConfigOffer) => offer.offerId.value === offerId);
  //   if (offer) {
  //     initiatePurchase(offerId);
  //   }
  // };

  // const telegramPurchaseHandler = async () => {
  //   console.log("selectedOffer", selectedOffer);
  //   setIsPurchaseLoading(true);
  //   playSound("button");
  //   const invoiceLink = await purchaseTelegram({
  //     offerId: selectedOffer.offerId.value,
  //     offerType: selectedOffer.offerType.value,
  //     title:
  //       selectedOffer?.title ??
  //       selectedOffer.items.value[0].amount.value.toLocaleString() + " $KITSU",
  //     description:
  //       selectedOffer?.description ??
  //       selectedOffer.items.value[0].amount.value.toLocaleString() + " $KITSU",
  //     label:
  //       selectedOffer?.title ??
  //       selectedOffer.items.value[0].amount.value.toLocaleString() + " $KITSU",
  //   });

  //   tg.openInvoice(invoiceLink, (status: string | undefined) => {
  //     if (status === "paid") {
  //       setIsSuccessPurchase(true);
  //       setBuyModal(false);
  //     }

  //     setIsPurchaseLoading(false);
  //     setTimeout(async () => {
  //       await getBalance({ clientId: `${userId}` });
  //       setIsSuccessPurchase(false);
  //     }, 1500);
  //   });
  // };

  useEffect(() => {}, [isLoading]);

  const headerVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  // Варіанти для першого внутрішнього елемента (зліва)
  const leftElementVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, y: 0, opacity: 1 },
  };

  // Варіанти для другого внутрішнього елемента (справа)
  const rightElementVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, y: 0, opacity: 1 },
  };

  const shopVariants = {
    hidden: { y: 100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  const containerVariants = {
    hidden: {}, // Не змінюємо нічого
    visible: {
      transition: {
        staggerChildren: 0.2, // Затримка 0.2 секунд між анімаціями дочірніх елементів
      },
    },
  };

  // Варіанти для дочірніх елементів
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const modalVariants = {
    hidden: { y: 282, opacity: 0 }, // Початковий стан - нижче екрану
    visible: { y: 0, opacity: 1 }, // Кінцевий стан - на місці
  };

  const updatedKitsuneOffers = useMemo(() => {
    const allKitsuneOffers = allOffers
      .filter((offer: ConfigOffer) => offer.offerType.value == OfferType.kitsune)
      .sort((a: ConfigOffer, b: ConfigOffer) => {
        // Перевіряємо, чи `stars.value` існує і є числом
        const starsA = typeof a.stars.value === 'number' ? a.stars.value : 0;
        const starsB = typeof b.stars.value === 'number' ? b.stars.value : 0;
        return starsB - starsA; // Спадання: більше зірок першими
      });
    if (purchasedOfferIds) {
      const processedOffers = allKitsuneOffers.map((offer: ConfigOffer) => {
        const isPurchased = purchasedOfferIds.has(offer.offerId.value);
        return {
          ...offer,
          firstBuy: !isPurchased,
        };
      });
      return processedOffers.sort((a: ConfigOffer, b: ConfigOffer) => {
        // Перевіряємо, чи `stars.value` існує і є числом
        const starsA = typeof a.stars.value === 'number' ? a.stars.value : 0;
        const starsB = typeof b.stars.value === 'number' ? b.stars.value : 0;
        return starsB - starsA; // Спадання: більше зірок першими
      });
    }

    return allKitsuneOffers;
  }, [purchasedOfferIds, allOffers]);

  const allDailyOffers = useMemo(() => {
    const allDailyOffers = allOffers.filter((offer: ConfigOffer) => offer.offerType.value === OfferType.dailyOffer);
    if (availableOfferIds) {
      return allDailyOffers.map((offer: ConfigOffer) => {
        const isAvailable = availableOfferIds.has(offer.offerId.value);
        console.log('isavailable', isAvailable);
        return {
          ...offer,
          isClaimed: !isAvailable,
        };
      });
    }
  }, [availableOfferIds, allOffers]);

  const mappedDailyOffers = allDailyOffers?.map((offer: ConfigOffer): OfferProps => {
    const currentTime = new Date();
    const sortedItems = [...offer.items.value].sort((a, b) => {
      const aSelected = getIsSelected(a);
      const bSelected = getIsSelected(b);

      if (aSelected === bSelected) return 0;
      return aSelected ? -1 : 1;
    });

    const resourceList: OfferResourceProps[] = sortedItems.map((item, index): OfferResourceProps => {
      const isEnabled = getIsSelected(item);

      return {
        resourceType: item.rewardType.value,
        resourceId: item.rewardId.value,
        isEnabled: isEnabled,
        layout: 'vertical',
        amount: defineCountString(item.amount.value),
      };
    });

    return {
      id: offer.offerId.value,
      resourceList: resourceList,
      offerType: offer.offerType.value,
      layout: 'vertical',
      type: 'gold',
      label: 'vertical_corner',
      price: defineCountString(offer.stars.value),
      deadline: new Date(currentTime.getTime() + 24 * 60 * 60 * 1000).toISOString(),
      isClaimed: offer.isClaimed,
    };
  });
  console.log('Daily offers', mappedDailyOffers);
  console.log('PURCHASED Daily offers', dailyOfferPurchased);

  useEffect(() => {
    if (mappedDailyOffers) {
      setDailyOfferPurchased(mappedDailyOffers?.filter((offer: OfferProps) => offer.isClaimed === true).length > 0);
      setDailyBundlePurchased(mappedDailyOffers?.filter((offer: OfferProps) => offer.id === 29).length === 0);
    }
  }, [mappedDailyOffers]);

  const updatedallPackStoreOffers = useMemo(() => {
    const allPackStoreOffers = allOffers.filter(
      (offer: ConfigOffer) =>
        offer.offerType.value === OfferType.experience ||
        offer.offerType.value === OfferType.keysOffer ||
        offer.offerType.value === OfferType.speedUp ||
        offer.offerType.value === OfferType.stoneOffer
    );
    if (availableOfferIds) {
      return allPackStoreOffers
        .filter((offer: ConfigOffer) => offer.offerType.value !== 9)
        .map((offer: ConfigOffer) => {
          const isAvailable = availableOfferIds.has(offer.offerId.value);
          return {
            ...offer,
            isClaimed: !isAvailable,
          };
        });
    }

    return allPackStoreOffers;
  }, [availableOfferIds, allOffers]);

  const mappedAllPackStoreOffers = updatedallPackStoreOffers.map((offer: ConfigOffer): OfferProps => {
    const currentTime = new Date();
    console.log('offer', offer);

    const sortedItems = [...offer.items.value].sort((a, b) => {
      const aSelected = getIsSelected(a);
      const bSelected = getIsSelected(b);

      if (aSelected === bSelected) return 0;
      return aSelected ? -1 : 1;
    });

    const resourceList: OfferResourceProps[] = sortedItems.map((item, index): OfferResourceProps => {
      const isEnabled = getIsSelected(item);

      return {
        resourceType: item.rewardType.value,
        resourceId: item.rewardId.value,
        isEnabled: isEnabled,
        layout: 'vertical',
        amount: defineCountString(item.amount.value),
      };
    });

    return {
      id: offer.offerId.value,
      resourceList: resourceList,
      offerType: offer.offerType.value,
      layout: 'wide',
      type: 'gold',
      label: 'top',
      price: defineCountString(offer.stars.value),
      deadline: new Date(currentTime.getTime() + 16 * 60 * 60 * 1000).toISOString(),
      isClaimed: offer.isClaimed,
    };
  });

  function defineCountString(count: number) {
    if (Math.floor(count / 1000000) > 0) {
      return Math.round(count / 100000) / 10 + 'M';
    } else if (Math.floor(count / 1000) > 0) {
      return Math.round(count / 100) / 10 + 'K';
    } else {
      return count.toFixed(0);
    }
  }

  const containerVariantsPacks = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Затримка між появою елементів
      },
    },
  };

  const itemVariantsPacks = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const kitsuShopImgSizes: Record<number, number> = {
    0: 100,
    1: 90,
    2: 80,
    3: 75,
    4: 60,
    5: 60,
  };

  return (
    <>
      {isLoading && (
        <div className="z-40">
          <motion.div
            variants={headerVariants}
            initial="hidden"
            animate="visible"
            transition={{ type: 'spring', stiffness: 50, duration: 0.5 }}
            className="absolute top-0 left-0 right-0 z-10 flex justify-center"
          >
            <img src={require('../../assets/images/shopHeader.png')} alt="Shop Header" />
            <motion.div
              initial="hidden"
              animate="visible"
              variants={headerVariants}
              transition={{
                type: 'spring',
                stiffness: 50,
                duration: 0.5,
                delay: 0.2,
              }}
              className="top-2 right-0 left-0 px-4 text-white absolute w-full text-end flex justify-between items-center z-[11]"
            >
              {/* Лівий елемент: Кнопка назад та назва магазину */}
              <motion.div
                variants={leftElementVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  type: 'spring',
                  stiffness: 50,
                  duration: 0.5,
                  delay: 0.3,
                }}
                className="flex justify-center items-center gap-2"
              >
                <div className="z-[2]">
                  <HandleBackButton onClick={close} />
                </div>
                <div className="text-center text-white text-[28px] font-black leading-7 text-shadow">
                  {t('shop.name')}
                </div>
              </motion.div>

              {/* Правий елемент: Баланс */}
              <motion.div
                variants={rightElementVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  type: 'spring',
                  stiffness: 50,
                  duration: 0.5,
                  delay: 0.5,
                }}
                className="bg-[#312d27] border border-[#18191a] px-3 py-1 rounded-[6px] flex justify-center items-center"
              >
                <div className="mr-2 inline-block text-[16px] text-white text-sm font-medium leading-[14px]">
                  Balance:
                </div>
                <div className="flex">
                  <div className="w-5 h-5 mr-[4px]">
                    <img
                      src={require('../../assets/images/kitsu-icon.webp')}
                      className="w-full h-full"
                      alt="Kitsu Icon"
                    />
                  </div>

                  <div className="flex flex-col justify-end text-right text-white text-lg font-black leading-[18px]">
                    {defineCountString(gold)}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
          <div className="fixed top-0 left-0 w-full h-full bg-black"></div>

          <motion.div
            className="w-full fixed left-0 bottom-0 max-w-[550px] mx-auto right-0 z-5 flex items-center justify-center"
            variants={shopVariants}
            initial="hidden"
            animate="visible"
            transition={{
              type: 'spring',
              stiffness: 50,
              duration: 0.7,
              delay: 0.3,
            }}
            style={{
              top: `${125 + +safeAreaTop}px`,
            }}
          >
            <div className="w-full absolute -top-11 flex justify-center gap-x-2 mb-4">
              {/* Кнопки магазину */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px] px-[3px]
              ${activeTab !== ActiveTab.GrowthFund ? 'opacity-40' : ''}`}
                onClick={() => {
                  setActiveTab(ActiveTab.GrowthFund);
                  playSound('button');
                }}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
              >
                {activeTab === ActiveTab.GrowthFund ? (
                  <div className="w-18 h-11 relative">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-selected-card.png')}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Growth Fund
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative opacity-40">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-no-selected-card.png')}
                      alt="No Selected Card"
                    />
                    <div className="absolute -top-1 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Growth Fund
                    </div>
                  </div>
                )}
              </motion.button>

              {/* KITSU */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px] px-[3px]
              ${activeTab !== ActiveTab.kitsune ? 'opacity-40' : ''}`}
                onClick={() => {
                  setActiveTab(ActiveTab.kitsune);
                  playSound('button');
                }}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
              >
                {activeTab === ActiveTab.kitsune ? (
                  <div className="w-18 h-11 relative">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-selected-card.png')}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      KITSU
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative opacity-40">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-no-selected-card.png')}
                      alt="No Selected Card"
                    />
                    <div className="absolute -top-1 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      KITSU
                    </div>
                  </div>
                )}
              </motion.button>

              {/*pack store */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px] px-[3px]
              `}
                onClick={() => {
                  setActiveTab(ActiveTab.packStore);
                  playSound('button');
                }}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.6 }}
              >
                {activeTab === ActiveTab.packStore ? (
                  <div className="w-18 h-11 relative">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-selected-card.png')}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Pack Store
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative opacity-40">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-no-selected-card.png')}
                      alt="No Selected Card"
                    />
                    <div className="absolute -top-1 flex justify-center items-center w-full h-full  z-10 text-center text-white text-sm font-black leading-[14px]">
                      Pack Store
                    </div>
                  </div>
                )}
              </motion.button>

              {/*daily */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px] px-[3px]
              `}
                onClick={() => {
                  setActiveTab(ActiveTab.dailySale);
                  playSound('button');
                }}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.8 }}
              >
                {activeTab === ActiveTab.dailySale ? (
                  <div className="w-18 h-11 relative">
                    {canClaimFreebie && (
                      <div className="absolute right-[-5px] top-[-5px] w-[12px] h-[12px] bg-[red] border-[1px] border-black rounded-full z-[11]"></div>
                    )}
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-selected-card.png')}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Daily Sale
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative ">
                    {canClaimFreebie && (
                      <div className="absolute right-[-5px] top-[-5px] w-[12px] h-[12px] bg-[red] border-[1px] border-black rounded-full z-[11]"></div>
                    )}
                    <img
                      className="w-full h-full absolute -top-1 opacity-40"
                      src={require('../../assets/images/shop-no-selected-card.png')}
                      alt="No Selected Card"
                    />
                    <div className="absolute opacity-40 -top-1 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Daily Sale
                    </div>
                  </div>
                )}
              </motion.button>
              {/* vip shop */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px] px-[3px]
              `}
                onClick={() => {
                  setActiveTab(ActiveTab.vipShop);
                  playSound('button');
                }}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.8 }}
              >
                {activeTab === ActiveTab.vipShop ? (
                  <div className="w-18 h-11 relative">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require('../../assets/images/shop-selected-card.png')}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      VIP Store
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative ">
                    <img
                      className="w-full h-full absolute -top-1 opacity-40"
                      src={require('../../assets/images/shop-no-selected-card.png')}
                      alt="No Selected Card"
                    />
                    <div className="absolute opacity-40 -top-1 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      VIP Store
                    </div>
                  </div>
                )}
              </motion.button>
            </div>

            {/* Вміст магазину */}
            <div className="relative w-full h-full bg-[#554837] border border-[#18191a] rounded-[2px] p-[2px]">
              <div className="w-full h-full bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white">
                <div
                  className={`relative w-full h-full ${
                    activeTab === ActiveTab.GrowthFund
                      ? "bg-[url('./assets/images/shop/growthFound-bg.webp')] bg-cover"
                      : 'bg-[#201b18]'
                  } rounded-[1px] p-3 shadow-inner-sm-black overflow-hidden`}
                >
                  {activeTab === ActiveTab.dailySale && (
                    <div
                      className={`absolute h-[400px] w-full bg-no-repeat top-0 left-0 bg-cover bg-top 
                  bg-[url('./assets/images/daily-sale-background.jpg')]`}
                    ></div>
                  )}

                  {activeTab === ActiveTab.GrowthFund && (
                    <>
                      <div
                        className={`absolute w-[calc(100%+24px)] ml-[-12px] mt-[-12px] h-[150px] bg-gradient-to-b from-black`}
                      ></div>
                      <GrowthFound />
                    </>
                  )}

                  {activeTab === ActiveTab.kitsune && (
                    <>
                      <motion.div
                        className="grid grid-cols-3 gap-3 mb-3 text-white overflow-y-auto pt-3 pl-3 pr-3 max-h-[calc(100%-20px)]"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                      >
                        {updatedKitsuneOffers
                          .filter((offer: ConfigOffer) => offer.offerId.value !== 31)
                          .map((offer: ConfigOffer, index: number) => {
                            return (
                              <motion.div
                                key={index}
                                className={`relative px-[6px] bg-gradient-to-r rounded shadow-inner border-2 cursor-pointer aspect-square ${
                                  index > 1
                                    ? `from-[#BAAA7F] to-[#E1CA94] border-[#534038]`
                                    : `from-[#eba36c] to-[#fecb54] border-[#FE8315]`
                                }`}
                                onClick={() => confirmPurchase({ offerId: offer.offerId.value })}
                                variants={itemVariants}
                                transition={{
                                  type: 'spring',
                                  stiffness: 50,
                                  duration: 0.5,
                                }}
                              >
                                {offer.firstBuy ? (
                                  <>
                                    {/* Магазинний квиток */}
                                    <div className="absolute -top-2 -right-2 w-6 h-6 z-20">
                                      <img
                                        className="w-full h-full"
                                        src={require('../../assets/images/shopTicket.png')}
                                        alt="Shop Ticket"
                                      />
                                    </div>

                                    {/* Позначка x2 */}

                                    <div className="z-20 absolute top-1 right-0 origin-top-left rotate-[-35deg] text-center text-white text-xs font-extrabold leading-3">
                                      x2
                                    </div>
                                  </>
                                ) : null}

                                {/* Основний контент */}
                                <div className="flex flex-col justify-center items-center relative pt-[12px] py-[6px] pb-2">
                                  {/* Фон з градієнтом */}
                                  <div
                                    className="flex z-10 justify-center items-center absolute top-[6px] overflow-hidden flex-shrink-0 min-h-[20px] w-full"
                                    style={{
                                      background:
                                        'radial-gradient(circle, rgba(50, 50, 50, 0.9) 0%, rgba(50, 50, 50, 0) 90%)',
                                    }}
                                  >
                                    {/* Верхня рамка */}
                                    <div
                                      className="absolute top-0 left-0 w-full h-[1px] pointer-events-none"
                                      style={{
                                        background:
                                          'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
                                      }}
                                    ></div>

                                    {/* Нижня рамка */}
                                    <div
                                      className="absolute bottom-0 left-0 w-full h-[1px] pointer-events-none"
                                      style={{
                                        background:
                                          'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
                                      }}
                                    ></div>

                                    {/* Вміст елемента */}
                                    <div className="flex items-center justify-center gap-1">
                                      <img
                                        src={require('../../assets/images/kitsu-icon.webp')}
                                        className="w-4 h-4"
                                        alt="Kitsu Icon"
                                      />
                                      <div className="text-center text-white text-sm font-black leading-3">
                                        {offer?.items.value[0].amount?.value.toLocaleString()}
                                      </div>
                                    </div>
                                  </div>
                                  {/* Зображення пропозиції */}
                                  <img
                                    src={require(`../../assets/images/offers/offer-${offer.offerId.value}.png`)}
                                    className="h-[92px] w-[88px] transform pt-1 object-contain"
                                    alt={`Offer ${offer.offerId.value}`}
                                    style={{
                                      scale: `${kitsuShopImgSizes[index]}%`,
                                    }}
                                  />
                                  {/* Кнопка Popup */}

                                  <PopupButton className="w-full" type="gold">
                                    <div
                                      className={` absolute -top-4 left-3 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
                                    >
                                      <div
                                        className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                                      >
                                        <img
                                          src={require('../../assets/images/shop/offer/notify.png')}
                                          className={`w-[2em] aspect-square -translate-y-[5%] `}
                                          alt=""
                                        />
                                        {defineCountString(Math.floor(offer?.stars?.value / 10))}
                                      </div>
                                    </div>
                                    <div className="relative flex items-center justify-center w-full h-4">
                                      <div className="absolute flex items-center justify-center gap-1">
                                        <img
                                          className="w-[16px] h-[16px]"
                                          src={require('../../assets/images/shop/offer/offer-star.png')}
                                          alt=""
                                        />
                                        <div className="text-white text-stroke-regular text-[13px] leading-[16px] font-[800]">
                                          {offer?.stars?.value.toLocaleString('uk')}
                                        </div>
                                      </div>
                                    </div>
                                  </PopupButton>
                                </div>
                              </motion.div>
                            );
                          })}
                      </motion.div>
                    </>
                  )}
                  {activeTab === ActiveTab.dailySale && (
                    <div className="mb-3 text-white w-[calc(100%+1.5rem)] -ml-3 h-full">
                      <div className="relative h-[230px]  ">
                        <DailySaleOffer offers={mappedDailyOffers} offerStatus={dailyOfferPurchased} />
                        {/* <div className="flex items-center text-2xl z-[2] relative">
                          <img
                            src={require("../../assets/images/info-icon.png")}
                            className="w-6 h-6 mr-2"
                            alt="Info Icon"
                          />
                          Daily Sale
                        </div>
                        <div className="flex items-center ml-8 text-base z-[2] relative">
                          <img
                            src={require("../../assets/images/times-icon.png")}
                            className="w-4 h-4 mr-1"
                            alt="Times Icon"
                          />
                          16:06:12
                        </div>
                        <div className="absolute ml-8 bottom-[76px] left-0">
                          <img
                            src={require("../../assets/images/daily-freebie-icon.png")}
                            className="w-[66px] h-auto"
                            alt="Daily Freebie Icon"
                          />
                          <div className="absolute whitespace-nowrap text-[14px] text-stroke-regular text-[#FFF3DB] -bottom-1">
                            Daily Freebie
                          </div>
                        </div>
                        <img
                          src={require("../../assets/images/shop-hero-buy.png")}
                          className="absolute top-2 right-0 w-[190px] h-auto"
                          alt="Shop Hero Buy"
                        />

                        <div className="absolute right-1 bottom-[62px] text-[28px] text-stroke-regular uppercase font-[600]">
                          Hero name
                        </div>
                        <div className="absolute bottom-0 h-16 -left-3 -right-3 bg-gradient-to-r from-[#e4e081] to-[#fd9051] border-[1px] border-l-0 border-r-0 border-[#534037]">
                          <div className="flex h-full items-center justify-between">
                            <div className="w-[84px] min-w-[84px] text-center">
                              <img
                                src={require("../../assets/images/shop-discount-flag.png")}
                                className="absolute w-[96px] h-[46px] top-2"
                                alt="Discount Flag"
                              />
                              <div className="text-stroke-regular z-[2] relative text-2xl uppercase">
                                5765%
                              </div>
                            </div>
                            <div className="ml-4 text-[#372C25] text-base leading-[1.2]">
                              Bundle all packs for a discount
                            </div>
                            <div className="mx-2 whitespace-nowrap">
                              <PopupButton
                                type="gold"
                                onClick={() => confirmPurchase(111)}
                                width="auto"
                              >
                                <div className="text-stroke-regular relative text-[18px] text-[#54320E] mx-auto leading-[1]">
                                  <div className="relative z-[1] text-[13px]">
                                    7 USD
                                  </div>
                                  <div
                                    className="absolute top-0.5 mx-auto leading-[1] h-full w-full bg-[length:50px]
                                bg-[url('./assets/images/crossed-out-old-price.png')] z-[2] bg-no-repeat bg-center"
                                  ></div>
                                </div>
                                <div className="text-stroke-regular text-[18px] leading-[1]">
                                  4,99 USD
                                </div>
                              </PopupButton>
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <motion.div
                        variants={containerVariantsPacks}
                        initial="hidden"
                        animate="visible"
                        className="relative grid grid-cols-3 gap-12 px-4 pt-6 mb-3 bg-[#26211d] text-white h-[calc(100%-166px)] overflow-scroll"
                      >
                        {mappedDailyOffers.map((offer: OfferProps) =>
                          offer.id !== 29 ? (
                            <motion.div variants={itemVariantsPacks} className="w-full">
                              <Offer
                                onClick={() => {
                                  confirmPurchase({ offerId: offer.id });
                                }}
                                offerType={offer.offerType}
                                id={offer.id}
                                rankPoints={`${defineCountString(Math.floor(Number(offer.price) / 10))}`}
                                resourceList={offer.resourceList}
                                layout={offer.layout}
                                type={offer.type}
                                label={offer.label}
                                kitsu={offer.kitsu}
                                price={offer.price}
                                deadline={offer.deadline}
                                isClaimed={dailyBundlePurchased ? true : offer.isClaimed}
                              />
                            </motion.div>
                          ) : null
                        )}
                      </motion.div>
                    </div>
                  )}
                  {activeTab === ActiveTab.packStore && (
                    <motion.div
                      className="flex flex-col gap-4 overflow-x-auto h-[calc(100%-20px)] pt-2"
                      variants={containerVariantsPacks}
                      initial="hidden"
                      animate="visible"
                    >
                      {mappedAllPackStoreOffers.map((offer: OfferProps, index: number) => (
                        <motion.div key={offer.id} variants={itemVariantsPacks}>
                          <Offer
                            onClick={() => confirmPurchase({ offerId: offer.id })}
                            offerType={offer.offerType}
                            id={offer.id}
                            resourceList={offer.resourceList}
                            rankPoints={`${defineCountString(Math.floor(Number(offer.price) / 10))}`}
                            layout={offer.layout}
                            type={offer.type}
                            label={offer.label}
                            kitsu={offer.kitsu}
                            price={offer.price}
                            deadline={offer.deadline}
                            isClaimed={offer.isClaimed}
                          />
                        </motion.div>
                      ))}
                    </motion.div>
                  )}
                  {activeTab === ActiveTab.vipShop && <VipShop buyMoreStars={() => setActiveTab(ActiveTab.kitsune)} />}
                </div>
              </div>
              <SuccessOverlay isSuccess={isSuccessPurchase} />
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};
