import React, { useEffect, useState } from "react";

import { useTonAddress } from "@tonconnect/ui-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { PopupWindow } from "../../../../../../components/PopupWindow";
import { PopupButton } from "../../../../../../components/PopupButton";
import { deleteProfile } from "../../../../../../endpoints/getProfileInfo";
import { useTelegram } from "../../../../../../hooks/useTelegram";

export const DeleteInfo = ({
  closeDeleteMenu,
}: {
  closeDeleteMenu: () => void;
}) => {
  const [openedModal, setOpenedModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const address = useTonAddress();
  const { t } = useTranslation();
  const { userId, tg } = useTelegram();
  const handleCopyClick = async () => {
    if (!address) return;
    const urlToCopy = address;
    try {
      await navigator.clipboard.writeText(urlToCopy);
      setCopied(true);
      toast.success("Wallet copied to clipboard!");
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("Failed to copy URL.");
    }
  };
  function formatAddress(address: string) {
    if (address.length <= 12) {
      return address;
    }
    const start = address.slice(0, 7);
    const end = address.slice(-8);
    return `${start}...${end}`;
  }

  useEffect(() => {
    setOpenedModal(true);
  }, []);

  const handleDelete = async () => {
    const data = await deleteProfile({ clientId: userId });

    if (data) {
      tg.close();
    }
  };
  return (
    <div className="w-full h-full fixed top-0 left-0 bottom-0 max-w-[550px] mx-auto right-0 z-10 flex items-center justify-center">
      <div className="w-full h-full absolute bg-black opacity-60"></div>
      <div className="absolute inset-x-4 bg-[#554837] border border-[#18191a] rounded-[2px] p-[2px]">
        <img
          src={require("../../../../../../assets/images/cards-modal-border.png")}
          className="absolute w-[17.5px] h-[21px] -top-[6px] -left-[5px]"
          alt=""
        />
        <img
          src={require("../../../../../../assets/images/cards-modal-border.png")}
          className="absolute w-[17.5px] h-[21px] -bottom-[6.5px] -left-[4.5px] -rotate-90"
          alt=""
        />
        <img
          src={require("../../../../../..//assets/images/cards-modal-border.png")}
          className="absolute w-[17.5px] h-[21px] -bottom-[5.5px] -right-[4.5px] -rotate-180"
          alt=""
        />
        <button
          className="absolute z-10 w-7 h-7 -top-2 -right-2 flex items-center justify-center"
          onClick={closeDeleteMenu}
        >
          <img
            src={require("../../../../../../assets/images/shop-modal-close.png")}
            className="w-7 h-7"
            alt=""
          />
        </button>
        <div className="w-full  bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white">
          <div className="relative w-full bg-[#201b18] rounded-[1px] p-3 pt-7 shadow-inner-sm-black">
            <div className="absolute top-[-28px] left-0 right-0 flex justify-self-center">
              <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
                <div
                  className="bg-[#351d1e] text-center font-extrabold text-white text-xl p-[7px] leading-none rounded-[1px]
                        border border-[#18191a] shadow-inner-sm-black uppercase"
                >
                  Account
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center gap-6">
              <div className="text-center text-[#E1C78C] text-[28px] font-extrabold leading-9 break-words whitespace-normal">
                {t("profile.deleteAccount.areUSure")}
              </div>
              <p className="text-center text-[#FFEFCB] text-[15px] font-semibold text-stroke-small">
                {t("profile.deleteAccount.AllYourData")}
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center pt-5 pb-3 px-2 gap-4">
            <PopupButton
              type="red"
              size="big"
              onClick={handleDelete}
              className="flex-1"
            >
              <div className="flex items-center justify-center h-[20px]">
              <p className="text-[18px] absolute">
                {t("profile.deleteAccount.delete")}
              </p>
              </div>
            </PopupButton>
            <PopupButton
              type="green"
              size="big"
              onClick={closeDeleteMenu}
              className="flex-1"
            >
              {t("profile.deleteAccount.cancel")}
            </PopupButton>
          </div>
        </div>
      </div>
    </div>
  );
};
