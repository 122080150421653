import { RequestWebsocket } from "../interfaces/wsInterfaces";
import { generateRequestId } from "../utils/generateRequestId";
import { sendRequestAndGetResponse } from "../Websocket/websocketInit";

export enum CheckInStatus {
  NotAvailable,
  ToVerify,
  ToClaim,
  Claimed,
}

export const getCheckInStatusByClientId = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-calendar",
    lang: "en",
    method: "checkIn_getMyStatus",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);

    if (result.type === "Left") {
      return false;
    }

    if (!result?.value) {
      throw new Error("purchase ERROR");
    }

    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};
export const verifyShareMessage = async (params: { clientId: string; checkinId: number }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-calendar",
    lang: "en",
    method: "checkIn_verify",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);

    if (result.type === "Left") {
      return false;
    }

    if (!result?.value) {
      throw new Error("purchase ERROR");
    }

    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};
export const claimCheckInReward = async (params: { clientId: string; checkinId: number }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-calendar",
    lang: "en",
    method: "checkIn_claim",
    id: generateRequestId(),
    params,
    auth: {
      type: "webSite",
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);

    if (result.type === "Left") {
      return false;
    }

    if (!result?.value) {
      throw new Error("purchase ERROR");
    }

    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};
