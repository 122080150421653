// QuestBlock.jsx
import React, {useState} from "react";
import { PopupButton } from "../../../../components/PopupButton";
import { Resources } from "../../../../enums/resources";
import {
  KitsuIco,
  StoneIco,
  XPIco,
} from "../../../../layout/components/HeaderFarm/components";
import { useTranslation } from "react-i18next";
import { TaskStatus } from "../../../../app/features/questsSlice";
import { handleRewardResult } from "./rewardParse";
import { eventsMap } from "../../../../utils/mapping";
import { QuestsEvents } from "../../../../enums/questsEvents";
import { useDispatch, useSelector } from "react-redux";
import { saveFarmTutorialProgress } from "../../../../app/features/farmTutoralSlice";
import { AppDispatch, RootState } from "../../../../app/store";
import { useTelegram } from "../../../../hooks/useTelegram";
import {
  TutorialFarmSave,
  TutorialFarmStage,
} from "../../../../interfaces/tutorial";
import PuslAnimation from "../../../../components/animation/PulsAnimation";
import { RankTooltip } from "../../../Farm/IslandWindow/components/LvlGateButton";
import {QuestAnimation} from "../../../../components";

export enum TaskSource {
  tg,
  x,
}
export const getImageSrc = (source: TaskSource) => {
  switch (source) {
    case TaskSource.tg:
      return require("../../../../assets/images/quests/tg.png");
    case TaskSource.x:
      return require("../../../../assets/images/quests/x.png");
    default:
      return require("../../../../assets/images/quests/tg.png"); // Значення за замовчуванням
  }
};

export const getIcon = (currency: Resources) => {
  switch (currency) {
    case Resources.kitsu:
      return <KitsuIco />;
    case Resources.experience:
      return <XPIco />;
    case Resources.stone:
      return <StoneIco />;
    default:
      return null;
  }
};

interface QuestBlockProps {
  quest: any;
  mainBuildingLevel: number;
}

export const QuestBlock: React.FC<QuestBlockProps> = ({
  quest,
  mainBuildingLevel,
}) => {
  console.log("quest in questblock", quest);
  const isVipTasksAllowed = useSelector(
    (state: RootState) => state.quests.isVipTasksAllowed
  );

  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false);

  const { source, currency, status, onClick, conditions, actionType, rewards } =
    quest;
    //console.log('onClick', onClick);
    //console.log("status", status);
    const {
      conditions: {
        value: [
          {
            event: { value: eventValue } = { value: "" },
            amount: { value: questAmount },
          },
        ],
      },
    } = quest;
    const {
      currentProgress: [
        {
          event: progressEventValue,
          amount: progressAmount,
        },
      ]
    } = quest
    //console.log('progress amount', progressAmount)
    let vipStatus = false;
    if (quest.isVIP && quest.questId.value != 999) {
      vipStatus = true;
    }
    //console.log("Quest is vip?", vipStatus);
    const messageParts = conditions.value.map((condition: any) => {
      const { amount, resource } = condition;

      const resourceName = resource
        ? t(`quests.resources.${resource.value}`)
        : "";

      return t("quests.condition.messagePart", {
        amount: amount.value,
        resource: resourceName,
      }).trim();
    });
    const imageSrc =
      eventsMap[QuestsEvents[eventValue as keyof typeof QuestsEvents]].image;

    const Reward = () => {
      const rewardParts = rewards.value.map((reward: any, index: number) => {
        const { amount, rewardId, rewardType } = reward;
        //console.log(amount)
        const resource = handleRewardResult({
          rewardId: rewardId.value,
          rewardType: rewardType.value,
        });
        //console.log("resource", resource);
        // Отримуємо назву ресурсу
        const resourceName = resource?.name as keyof typeof Resources;

        // Отримуємо іконку ресурсу
        const icon = resource?.image;

        //console.log(resourceName)

        //console.log("icon", icon);
        // Повертаємо JSX для кожного елемента з текстом і іконкою
        return (
          <div
            key={index}
            className={`flex items-center justify-center flex-col min-w-[40px] max-w-[40px] h-[40px] p-1 rounded-md border border-black bg-gradient-to-b 
              ${rewardId.value === Resources.kitsu
                ? "from-[#D158D8] to-[#9444DD]"
                : "from-[#484CAA] to-[#4247C9]"
              } pb-[1px] `}
          >
            {Resources[resourceName] === Resources["5m"] && (
              <div className="w-[38px] h-[10px] mt-[2px] flex items-center justify-center border-b border-black bg-[#333693] rounded-t-md">
                <span className="w-full text-[8px] text-white text-center">
                  5m
                </span>
              </div>
            )}
            <div
              className={`relative w-full h-[30px] flex items-center justify-center ${
                Resources[resourceName] === Resources["5m"] && "mb-[5px]"
                }`}
            >
              <img
                src={icon}
                alt="Icon"
                className="w-[28px] h-[28px] object-cover rounded-lg"
              />
              <span
                className={`absolute text-[8px] text-white right-0 ${Resources[resourceName] === Resources["5m"]
                    ? "mt-[20px]"
                    : "mt-[25px]"
                  }`}
                style={
                  Resources[resourceName] !== Resources["5m"]
                    ? {
                      filter:
                        "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
                    }
                    : {}
                }
              >
                {Resources[resourceName] === Resources["5m"]
                  ? 1
                  : amount.value * mainBuildingLevel}
              </span>
            </div>
          </div>
        );
      });

      return <div className="flex gap-1 w-full min-w-[40%]">{rewardParts}</div>;
    };

  let buttonType: any;
  let buttonText = "";
  let showButton = true;

  switch (status) {
    case TaskStatus.NotDone:
      buttonType = "blue";
      buttonText = "GO";
      break;
    case TaskStatus.Done:
      buttonType = "green";
      buttonText = "Claim";
      break;
    case TaskStatus.Claimed:
      buttonType = "gray";
      buttonText = "Done";
      break;
    default:
      buttonType = "blue";
      buttonText = "GO";
  }

  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  const [isToolTip, setIsToolTip] = React.useState(false);
  const rankTooltip = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClick = (e: Event) => {
      if (
        !rankTooltip.current ||
        !rankTooltip.current.contains(e.target as Node)
      ) {
        setIsToolTip(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("wheel", handleClick);
    document.addEventListener("touchmove", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("wheel", handleClick);
      document.removeEventListener("touchmove", handleClick);
    };
  }, []);

  return (
    <>
      <div
        className={`relative flex flex-col w-full rounded-md my-[2px]
          ${TaskStatus.Done === status ? "bg-[#584d3c]" : ""} 
          ${quest.conditions.value[0].event.value === "tutorial"
            ? farmTutorialSave?.stage ===
              TutorialFarmStage.clickOnCompletedQuest ||
              farmTutorialSave?.stage ===
              TutorialFarmStage.clickOnQuestList ||
              farmTutorialSave?.stage === TutorialFarmStage.claimQuest
              ? "order-first z-[51] gap-0 "
              : " gap-2"
            : ""
          }
          ${quest.conditions.value[0].event.value == "upgrade_tier"
            ? farmTutorialSave?.stage ===
              TutorialFarmStage.clickOnUncompletedQuest ||
              farmTutorialSave?.stage === TutorialFarmStage.clickToNavigate
              ? "order-first mb-2 z-[51]"
              : ""
            : ""
          }   
          ${TaskStatus.Done === status
            ? vipStatus
              ? "bg-[#584d3c]"
              : "bg-[#584d3c] order-first"
            : ""
          }
          ${TaskStatus.Claimed === status ? "order-last" : ""}`}
      >
        {status === TaskStatus.Done && !vipStatus ? (
          <img
            className="absolute -top-[1px] -right-[1px] z-[15] w-3 h-3 pointer-events-none"
            src={require("../../../../assets/images/heroes/cards/notification.png")}
            alt="Notification"
          />)
          : status === TaskStatus.Done && vipStatus && isVipTasksAllowed ?
            (<img
              className="absolute -top-[1px] -right-[1px] z-[15] w-3 h-3 pointer-events-none"
              src={require("../../../../assets/images/heroes/cards/notification.png")}
              alt="Notification"
            />)
            : null}

        {/* Transparent bg for clickOnCompletedQuest stage */}
        {farmTutorialSave?.stage ===
          TutorialFarmStage.clickOnCompletedQuest && (
            <div
              className={`  absolute w-full h-full bg-transparent z-[50] `}
              onClick={() => {
                if (
                  farmTutorialSave?.stage ===
                  TutorialFarmStage.clickOnCompletedQuest
                ) {
                  updateSave({
                    stage: TutorialFarmStage.claimQuest,
                  });
                } else if (
                  farmTutorialSave?.stage ===
                  TutorialFarmStage.clickOnUncompletedQuest
                ) {
                  updateSave({
                    stage: TutorialFarmStage.clickToNavigate,
                  });
                }
              }}
            ></div>
          )}

        {/* Dark bg for claimQuest and clickToNavigate */}
        {(farmTutorialSave?.stage === TutorialFarmStage.claimQuest ||
          farmTutorialSave?.stage === TutorialFarmStage.clickToNavigate) && (
            <div
              className={`absolute w-full h-full bg-[rgba(0,0,0,0.5)] z-[2] `}
            ></div>
          )}

        {vipStatus && !isVipTasksAllowed && (
          <div
            className="absolute w-full h-full rounded-md"
            onClick={() => {
              setIsToolTip(true);
            }}
            ref={rankTooltip}
          >
            <div className="absolute inset-0 bg-black opacity-50 z-10 rounded-md"></div>
            <div className="relative w-full h-full flex items-center justify-center rounded-md gap-2">
              <span
                data-tooltip-id={`rank-tooltip-${quest.questId.value}`}
                className="flex items-center justify-center z-[15] text-white uppercase text-3xl font-[900] leading-[18px] break-words text-center text-shadow-black"
              >
                unlock on
              </span>
              <img
                src={require("../../../../assets/images/quests/ranks.png")}
                alt=""
                className="w-16 h-16 z-[15]"
              />
            </div>
            {isToolTip && (
              <RankTooltip isOpen={true} id={quest.questId.value} margin={35} classNameArrow="ml-[-90px]" />
            )}
          </div>
        )}
        <div className="flex items-center justify-center flex-grow w-full bg-[#473D37] rounded-md shadow-md border-2 border-[#574E48]">
          {/* Зображення */}
          <div className="w-[68px] h-[68px] p-1 flex-shrink-0 ml-2 mr-[2px] border border-black rounded-md bg-[#362D28] shadow-inner-sm-black">
            <img
              src={imageSrc}
              alt={`${source} Icon`}
              className="w-full h-full object-contain"
            />
          </div>

          {/* Текст та іконка */}

          <div className="flex flex-col items-center flex-grow p-[7px] gap-[3px]">
            <div
              className="text-white text-base font-bold leading-tight w-full break-words"
              style={{
                filter:
                  "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
              }}
            >
              <span>
                {t(
                  eventsMap[
                    QuestsEvents[eventValue as keyof typeof QuestsEvents]
                  ].description
                )}
              </span>
              <span className="ml-2">
                {Math.floor(progressAmount)}/{questAmount}
              </span>
            </div>

            <div className="w-full flex items-center justify-between">
              <div className="self-start bg-[#00000033] p-[3px] rounded-md">
                <Reward />
              </div>
              {/* Кнопка */}
              {showButton ? (
                <div
                  className={`ml-auto min-w-[70px] flex flex-shrink-0 self-end relative
                    ${farmTutorialSave?.stage === TutorialFarmStage.claimQuest?'animate-highlight':''}
              ${quest.conditions.value[0].event.value === "tutorial"
                      ? "z-[2]"
                      : ""
                    }
              ${quest.conditions.value[0].event.value === "upgrade_tier"
                      ? "z-[2]"
                      : ""
                    } `}
                >
                  {showSuccess ? (
                      <>
                        <div className="w-[300px] h-[300px] right-[-115px] top-[-120px] absolute z-[999]">
                          <QuestAnimation onAnimationReady={
                            ()=>{
                            setTimeout(() => {
                              setShowSuccess(false);
                            }, 2200); //конец анимации
                            }
                          }/>
                        </div>
                      </>
                  ) : null}
                  <PopupButton type={buttonType} width="60px" onClick={()=>{
                      onClick();
                      if (quest.status === TaskStatus.Done) {
                        setShowSuccess(true);
                      }
                    }
                  }>
                    {buttonText}
                  </PopupButton>
                </div>
              ) : (
                <div className="flex items-center h-full ml-auto mr-9">
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
