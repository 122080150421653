import React from "react";

interface ItemListProps {
    manaAmount: number;
    additionalCards: number;
}

export const ItemList: React.FC<ItemListProps> = ({ manaAmount, additionalCards }) => {

  return (
    <div className="h-full p-1.5 justify-start items-center gap-[11px] inline-flex">
      {/*<div className="w-4 h-4 flex flex-col items-center gap-y-0.5">*/}
      {/*  <div>*/}
      {/*    <img className="w-[18px] min-w-[18px] h-[18px]" src={require('../../../../../assets/images/card-mana.png')} alt=""/>*/}
      {/*  </div>*/}
      {/*  <div className="text-center text-violet-100 text-xs font-light leading-3">1</div>*/}
      {/*</div>*/}
      <div className="w-7 flex flex-col items-center gap-y-0.5">
        <div>
          <img className="w-[26px] min-w-[26px] h-[26px]" src={require('../../../../../assets/images/mana-icon.png')} alt=""/>
        </div>
        <div className="text-center text-violet-100 text-[14px] font-light leading-none mt-1">
            <p>{manaAmount}</p>
        </div>
      </div>
      <div className="w-7 flex flex-col items-center gap-y-0.5">
        <div>
          <img className="w-[26px] min-w-[26px] h-[26px]" src={require('../../../../../assets/images/cards-icon.png')} alt=""/>
        </div>
        <div className="text-center text-violet-100 text-[14px] font-light leading-none mt-1">
            <p>{ 3 + additionalCards }</p>
        </div>
      </div>
    </div>
  );
};



