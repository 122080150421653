import { InventoryButton } from "../../../components/InventoryButton";
import { ChatButton } from "../../../components/ChatButton";
import { useUtils } from "../../../utils/navigateTo";
import { useTranslation } from "react-i18next";
import { AllianceButton, FriendsButton, QuestsButton } from "../../../components";
import { CardsModal } from "../../../pages/Battle/CardsModal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Inventory, Smithy } from "../../../containers";
import { PopupButton } from "../../../components/PopupButton";
import { HandleBackButton } from "../HeaderCave/components";
import { Resources } from "../../../enums/resources";
import { CategoryItem, HeroesItem, InventoryItem } from "../../../containers/Inventory";
import { TutorialFarmSave, TutorialFarmStage, TutorialSave } from "../../../interfaces/tutorial";
import { motion, AnimatePresence } from "framer-motion"; // Імпорт Framer Motion
import { ConfigHero, FullHero, Hero, HeroRarity, Tree } from "../../../interfaces/hero";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { saveFarmTutorialProgress } from "../../../app/features/farmTutoralSlice";
import { useTelegram } from "../../../hooks/useTelegram";
import PuslAnimation from "../../../components/animation/PulsAnimation";
import { TutorialTooltip } from "../../../components/TutorialTooltip";
import { getHeroes, getHeroesShards, getHeroTierUpgradePrice } from "../../../endpoints/heroEndpoints";
import { parseFullHero } from "../../../utils/heroParser";
import { setHeroesList } from "../../../app/features/heroesSlice";
import { BossButton } from "../../../pages/BossDungeon/components/BossButton";
import { useSessionStorage } from "@uidotdev/usehooks";
import { ItemType, UniversalShard } from "../../../interfaces/lootBotx";
import { universalShardMap } from "../../../utils/mapping";
export const FooterFarm = ({ tutorialSave }: { tutorialSave?: TutorialFarmSave | TutorialSave | undefined }) => {
  const { navigateTo } = useUtils();
  const { t } = useTranslation();
  const [isCardsModalOpen, setIsCardsModalOpen] = useState(false);
  const [openInventory, setOpenInventory] = useSessionStorage("openInventory", false);
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage("openedDepositModal", false);
  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>("shouldHideElementKey", true);
  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  useEffect(() => {
    if (openInventory) {
      setIsCardsModalOpen(true);
    }
    setOpenInventory(false);
  }, []);

  const openCardsModal = () => {
    setIsCardsModalOpen(true);
  };

  const closeCardsModal = () => {
    setIsCardsModalOpen(false);
  };

  const style = {
    animation: `pulseShadow 1.5s infinite`,
    boxShadow: "0 0 15px rgba(222,129,24,0.9)",
    padding: "1rem",
    borderRadius: "8px",
  };

  const data: InventoryItem[] = [
    // {
    //   id: "1",
    //   type: TypeItem.Resource,
    //   amount: 1000,
    //   icon: Resources.stone,
    //   rarity: HeroRarity.Epic,
    // },
    // {
    //   id: "2",
    //   type: TypeItem.Resource,
    //   amount: 500,
    //   icon: Resources.experience,
    //   rarity: HeroRarity.Epic,
    // },
    // {
    //   id: "3",
    //   type: TypeItem.Spesial,
    //   amount: 200,
    //   icon: Resources.stone, // Замініть на відповідну іконку
    //   rarity: HeroRarity.Rare,
    // },
    // {
    //   id: "4",
    //   type: TypeItem.Hero,
    //   amount: 1,
    //   icon: Resources.experience, // Замініть на відповідну іконку
    //   rarity: HeroRarity.Legendary,
    // },
  ];

  // Варіанти анімації для футера
  const footerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
  };

  // Варіанти анімації для внутрішніх елементів
  const itemVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  };

  const pulsAnimation = PuslAnimation(tutorialSave);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  return (
    <>
      <AnimatePresence>
        {isCardsModalOpen && (
          <motion.div
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // exit={{ opacity: 0, scale: 0.95 }}
          // transition={{ duration: 0.1 }}
          >
            <Inventory closeDeck={closeCardsModal} />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Футер з анімацією */}
      <AnimatePresence>
        {tutorialSave?.stage! !== TutorialFarmStage.upgradeHero && (
          <motion.footer
            variants={footerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5, ease: "easeOut" }}
            className={`flex flex-col fixed bottom-0 pb-5 w-full 
              ${
                (tutorialSave?.stage === TutorialFarmStage.clickOnRewards ||
                  tutorialSave?.stage === TutorialFarmStage.clickOnRewards2 ||
                  tutorialSave?.stage === TutorialFarmStage.clickOnQuests) &&
                (openedDepositModal || shouldHideElement)
                  ? "pointer-events-auto z-[9907] "
                  : "z-[55]"
              }`}
          >
            <AnimatePresence>
              {tutorialSave?.stage! >= TutorialFarmStage.clickOnQuests && (
                <motion.div
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  transition={{ duration: 0.3 }}
                  className={`flex flex-col gap-3 items-end self-end w-full mb-7 ${
                    tutorialSave?.stage! === TutorialFarmStage.clickOnQuests ? "" : "z-10"
                  } `}
                >
                  {tutorialSave?.stage! >= TutorialFarmStage.clickOnRewards && (
                    <div
                      className={`flex justify-between w-full
                      ${
                        tutorialSave?.stage === TutorialFarmStage.clickOnRewards2
                          ? "brightness-50 pointer-events-none"
                          : ""
                      }
                      ${
                        tutorialSave?.stage === TutorialFarmStage.clickOnRewards
                          ? "brightness-50 pointer-events-none"
                          : ""
                      }`}
                    >
                      <BossButton />
                      <InventoryButton onClick={openCardsModal} />
                    </div>
                  )}
                  <motion.div
                    variants={itemVariants}
                    className={`${
                      tutorialSave?.stage! >= TutorialFarmStage.clickOnQuests ? "flex justify-between w-full" : ""
                    }`}
                  >
                    {tutorialSave?.stage! >= TutorialFarmStage.clickOnQuests && (
                      <motion.div
                        variants={itemVariants}
                        className={`ml-2 
                            ${
                              tutorialSave?.stage! < TutorialFarmStage.finishQuestTutor
                                ? "z-[9901]  animate-highlight"
                                : ""
                            } 
                            ${
                              tutorialSave?.stage === TutorialFarmStage.clickOnRewards2
                                ? "brightness-50 pointer-events-none"
                                : ""
                            }
                            ${
                              tutorialSave?.stage! === TutorialFarmStage.clickOnRewards
                                ? "brightness-50 pointer-events-none"
                                : ""
                            } `}
                      >
                        <QuestsButton />
                      </motion.div>
                    )}
                    {/* <AllianceButton/> */}
                    {tutorialSave?.stage! >= TutorialFarmStage.finishQuestTutor && (
                      <div
                        className={` ${
                          tutorialSave?.stage! === TutorialFarmStage.clickOnRewards
                            ? "brightness-50 pointer-events-none"
                            : ""
                        } `}
                      >
                        <FriendsButton />
                      </div>
                    )}
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>

            {tutorialSave?.stage! >= TutorialFarmStage.clickOnRewards && (
              <motion.div
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.3 }}
                className={`relative ${tutorialSave?.stage === TutorialFarmStage.clickOnRewards2 ? "z-[11]" : ""} `}
              >
                <div
                  className={`absolute w-full h-[calc(100%+34px)] bg-[#2a2827] opacity-50 border-t-black border-[1px] -bottom-[34px]
                     ${
                       tutorialSave?.stage === TutorialFarmStage.clickOnQuests
                         ? "brightness-50 pointer-events-none"
                         : ""
                     }`}
                  style={{
                    boxShadow: "inset 0 2px 8px rgba(255, 255, 255, 0.6)",
                  }}
                ></div>
                {/* Ваши інші елементи тут */}

                <motion.div variants={itemVariants} className="flex justify-between">
                  <motion.div
                    variants={itemVariants}
                    className="flex items-center justify-center -mt-2 w-24 h-[60px] pb-1 border-t border-r border-b border-black bg-transparent bg-gradient-to-b from-[#1E0400] to-[#452B31] rounded-r-md"
                  >
                    <button
                      className={`relative flex items-center justify-center w-full h-full bg-transparent bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] rounded-r-md 
                        ${
                          tutorialSave?.stage === TutorialFarmStage.clickOnQuests
                            ? "brightness-50 pointer-events-none"
                            : ""
                        }
                        ${
                          tutorialSave?.stage === TutorialFarmStage.clickOnRewards ||
                          tutorialSave?.stage === TutorialFarmStage.clickOnRewards2
                            ? "animate-highlight"
                            : ""
                        }`}
                      onClick={() => {
                        if (tutorialSave?.stage === TutorialFarmStage.clickOnRewards) {
                          updateSave({
                            stage: TutorialFarmStage.clickOnHeroes,
                          });
                        } else if (tutorialSave?.stage === TutorialFarmStage.clickOnRewards2) {
                          updateSave({
                            stage: TutorialFarmStage.clickOnRankUpgrade,
                          });
                        }
                        navigateTo("/heroes");
                      }}
                    >
                      {tutorialSave?.stage === TutorialFarmStage.clickOnRewards2 && (
                        <div className="absolute top-[-110%] left-[15px] z-[9999]">
                          <TutorialTooltip
                            value="Let's upgrade the hero's tier to become even stronger!"
                            type="bottom_left"
                          />
                        </div>
                      )}
                      <div className="absolute top-1 right-1 w-[5px] h-[5px] bg-[#864C39] rounded-full z-20"></div>
                      <div className="absolute bottom-1 left-1 w-[11px] h-[11px] bg-[#5B2400] rounded-full opacity-25"></div>
                      <div className="absolute top-2 left-1 w-[3px] h-[3px] bg-[#E4B98F] rounded-full"></div>
                      <div className="relative flex justify-center items-center w-14 h-14 -mt-5">
                        <img src={require("../../../assets/images/heroes.png")} className="w-full h-full" alt="" />
                        <div
                          className="absolute -bottom-3 flex justify-center items-end z-10 text-white text-xl font-bold tracking-[0.5px] leading-normal"
                          style={{
                            filter:
                              "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 2px 0px black) drop-shadow(0px -1px 0px black)",
                          }}
                        >
                          {t("footer.heroes")}
                        </div>
                      </div>
                      {appConfig.variables && <HeroesButtonNotificationDot userId={userId} />}
                    </button>
                  </motion.div>

                  {/* Додаткові кнопки можуть бути анімовані аналогічно */}
                </motion.div>
              </motion.div>
            )}
          </motion.footer>
        )}
      </AnimatePresence>
    </>
  );
};

interface HeroesButtonNotificationDotProps {
  userId: any;
}

const HeroesButtonNotificationDot = ({ userId }: HeroesButtonNotificationDotProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hasNotification, setHasNotification] = useState(false);
  const [hasTierUp, setHasTierUp] = useState(false);

  const resources = useSelector((state: RootState) => state.resources.resources);
  const currentValue = resources.find((v) => v.resourceType === Resources.experience)!.value;
  const currentKeys = resources.find((v) => v.resourceType === Resources.keys)?.value || 0;

  const heroesList = useSelector((state: RootState) => state.heroes.heroesList);

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const allHeroes: ConfigHero[] = appConfig.heroes.variables;

  const getHeroesList = async () => {
    try {
      // Отримання даних з API
      const result = await getHeroes({ clientId: userId });

      if (result?.heroes?.length) {
        // Створюємо мапу для швидкого пошуку Hero за heroId
        const heroMap: Record<number, Hero> = {};
        result.heroes.forEach((hero: Hero) => {
          heroMap[hero.heroId] = hero;
        });

        // Перетворюємо доступних героїв у FullHero[]
        const heroes: FullHero[] = allHeroes.map((configHero: ConfigHero) => {
          const heroData: Hero | undefined = heroMap[configHero.id.value];

          if (heroData) {
            const hero = parseFullHero(configHero, heroData);

            return {
              ...hero,
              name: `${t(`heroes.${configHero.id.value}`)}`,
            };
          } else {
            return {
              heroId: configHero.id.value,
              boosts: { hp: 0, mana: 0 },
              level: 0,
              inDungeon: false,
              upgradeCards: 0,
              expToNextLevel: 0,
              upgrades: [{ id: 1, level: 1 }],
              upgradesCount: 0,
              id: configHero.id.value,
              isAvaillable: false,
              tiers: [],
              levels: [],
              img: require(`../../../assets/images/heroes/cards/hero-${configHero.id.value}.jpg`),
              name: "",
              rarity: HeroRarity.Rare,
              rating: { claimedLevels: 1, totalLevels: 30 },
              energyType: 0,
              energyAmount: 0,
              health: 0,
              nextLevel: null,
              cardsAmount: 0,
              cards: [],
              inDungeonId: 0,
              maxLevel: 1,
              usedLives: 0,
              boughtLives: 0,
            };
          }
        });

        dispatch(setHeroesList(heroes));
      }
    } catch (error) {
      console.error("Error fetching heroes:", error);
    }
  };

  const fetchUniversalShards = async () => {
    try {
      const responseHeroesShards = await getHeroesShards({
        clientId: userId,
      });

      const mappedUniversalShards: HeroesItem[] =
        responseHeroesShards.universalShards.map((item, index) => {
          return {
            id: `universalShards-${item.shardId}-${index}`,
            type: ItemType.universalShard,
            name: universalShardMap[item.shardId as UniversalShard].name,
            description:
              universalShardMap[item.shardId as UniversalShard].description,
            category: CategoryItem.Heroes,
            amount: item.amount,
            icon: universalShardMap[item.shardId as UniversalShard].image,
            rarity: item.shardId,
          };
        });

      if (mappedUniversalShards) {
        return mappedUniversalShards.find((shard) => shard.rarity === 0)!
          .amount;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchHeroTierUpgradePrice = async (id: number, upgradeCards: number) => {
    try {
      const price = await getHeroTierUpgradePrice({
        clientId: userId,
        heroId: id,
      });
      return price;
    } catch (error) {}
  };

  const hasTierUpgrade = async (
    id: number,
    upgradeCards: number,
    heroTiers: Tree[]
  ) => {
    const universalShards = await fetchUniversalShards();
    const tierUpgradePrice = await fetchHeroTierUpgradePrice(id, upgradeCards);
    const allTiersUpgrade: boolean[] = [];
    heroTiers.forEach((node: any) => allTiersUpgrade.push(node.isClaimed));
    if (
      tierUpgradePrice &&
      universalShards &&
      (upgradeCards > tierUpgradePrice ||
        universalShards + upgradeCards > tierUpgradePrice) &&
      !allTiersUpgrade.every((isClaimed) => isClaimed)
    ) {
      setHasTierUp(true);
    } else {
      setHasTierUp(false);
    }
  };

  useEffect(() => {
    getHeroesList();

    heroesList
      .filter((hero) => hero.isAvaillable)
      .map((hero) => {
        hasTierUpgrade(hero.id, hero.upgradeCards, hero.tiers);

        if (hero.expToNextLevel < currentValue && hero.maxLevel > hero.level) {
          setHasNotification(true);
        } else if (currentKeys > 0) {
          setHasNotification(true);
        } else {
          setHasNotification(false);
        }
      });
  }, [userId, heroesList.length, currentValue, currentKeys]);

  return hasNotification || hasTierUp ? (
    <div className="absolute flex items-center justify-center -top-[4px] -right-[2px] z-50 w-4 h-4">
      <img
        className="absolute top-0 right-0 bottom-0 left-0"
        src={require("../../../assets/images/heroes/cards/notification.png")}
        alt="Notification"
      />
    </div>
  ) : null;
};
