import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { App, FallbackComponent } from "./containers";
import "./i18n/config";
import { ScrollProvider } from "./utils/ScrollContext";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { APP_ENV } from "./config";
import {ErrorComponent} from "./components";
import {useSoundService} from "./utils/soundService";

if (
  typeof window !== "undefined" &&
  window.matchMedia &&
  !window.matchMedia("(prefers-color-scheme: dark)").addEventListener
) {
  const matchMediaPrototype = Object.getPrototypeOf(
    window.matchMedia("(prefers-color-scheme: dark)")
  );

  matchMediaPrototype.addEventListener = function (
    event: string,
    callback: (e: Event) => void
  ) {
    if (event === "change") {
      this.addListener(callback);
    }
  };

  matchMediaPrototype.removeEventListener = function (
    event: string,
    callback: (e: Event) => void
  ) {
    if (event === "change") {
      this.removeListener(callback);
    }
  };
}

const AppWrapper = () => {
  const [renderKey, setRenderKey] = useState(0);
  const [orientation, setOrientation] = React.useState("");
  const [orientationScale, setOrientationScale] = React.useState(1);
  const [safeAreaInsetTop, setSafeAreaInsetTop] = React.useState<number>(0);
  const [contentSafeAreaInsetTop, setContentSafeAreaInsetTop] = React.useState<number>(0);
  const { pausedSounds, pausedMusic, playMusic } = useSoundService();
  const [safeAreaTop, setSafeAreaTop] = useState(() => {
    return sessionStorage.getItem("safeAreaTop") || 0;
  });

  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /mobile|android|iphone|ipad|phone/.test(userAgent);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const tg = window.Telegram?.WebApp;

      if (!tg?.isActive) {
        pausedSounds();
        pausedMusic();
      }

      if (typeof tg?.safeAreaInset?.top === 'number' && typeof tg?.contentSafeAreaInset?.top === 'number') {
        const newSafeAreaTop = tg.safeAreaInset.top + tg.contentSafeAreaInset.top;
        if (safeAreaInsetTop !== tg.safeAreaInset.top
          || contentSafeAreaInsetTop !== tg.contentSafeAreaInset.top
          || newSafeAreaTop+'px' !== document.documentElement.style.marginTop) {
          setSafeAreaInsetTop(tg.safeAreaInset.top);
          setContentSafeAreaInsetTop(tg.contentSafeAreaInset.top);
          setSafeAreaTop(newSafeAreaTop);

          sessionStorage.setItem("safeAreaTop", newSafeAreaTop);

          document.documentElement.style.marginTop = `${newSafeAreaTop}px`;
          document.documentElement.style.height = `calc(100vh - ${newSafeAreaTop}px)`;
          document.documentElement.style.minHeight = `calc(100vh - ${newSafeAreaTop}px)`;
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [window.Telegram?.WebApp?.safeAreaInset?.top, window.Telegram?.WebApp?.contentSafeAreaInset?.top]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && APP_ENV && APP_ENV && ["production", "stage"].includes(APP_ENV)) {
        setRenderKey(prevKey => prevKey + 1);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const checkOrientation = () => {
      let orientationScale;
      let isPortrait = document.documentElement.clientWidth < document.documentElement.clientHeight;

      orientationScale = isPortrait
        ? 1
        : +(window.innerHeight / window.innerWidth).toFixed(2);

      setOrientation(isPortrait ? "portrait" : "landscape");
      setOrientationScale(orientationScale);

      if (APP_ENV && ["production", "stage"].includes(APP_ENV) && !window.location.hash.includes("input")) {
        //setRenderKey((prevKey) => prevKey + 1);
        playMusic('old');
      }
    };

    checkOrientation();

    window.addEventListener("resize", checkOrientation);
    window.addEventListener("orientationchange", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
      window.removeEventListener("orientationchange", checkOrientation);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const lastErrorTime = sessionStorage.getItem("lastErrorTime");
      const attempts = parseInt(sessionStorage.getItem("attemptsReload") || "0");

      if (lastErrorTime) {
        const lastErrorDate = new Date(lastErrorTime);
        const now = new Date();

        if (now.getTime() - lastErrorDate.getTime() > 10 * 1000 && attempts !== 0) {
          sessionStorage.removeItem("lastErrorTime");
          sessionStorage.setItem("attemptsReload", "0");
        }
      }
    }, 10 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {orientation === "landscape" && isMobile &&(
        <div className="absolute flex justify-center items-center w-full h-full bg-[#000000] z-[9999]">
          <img
            src={require(`./assets/images/rotate-example.jpg`)}
            className="w-full max-w-[250px] h-auto"
            alt=""
          />
        </div>
      )}
      <Sentry.ErrorBoundary fallback={({error, resetError, componentStack, eventId}) => {
        return (<ErrorComponent jsError={{error, resetError, componentStack, eventId}}/>)
      }}>
        <App key={renderKey}/>
      </Sentry.ErrorBoundary>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <ScrollProvider>
        <AppWrapper />
      </ScrollProvider>
    </PersistGate>
  </Provider>
);
