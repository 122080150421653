import { FooterSelector, HeaderSelector } from "./components";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useEffect, useRef, useState } from 'react';
import { useSessionStorage } from "@uidotdev/usehooks";
import { AnimatePresence } from "framer-motion";
import { useUtils } from "../utils/navigateTo";

const PAGES_WITHOUT_FOOTER = ["/tutorial", "/battle"];
const PAGES_WITH_HEADER = ["/home", "/play", "/farm", "/room", "/", "/island"];
export const Layout = () => {
  const { activeDiv } = useUtils()
  const game = useRef<HTMLDivElement>(null);
  const [renderKey, setRenderKey] = useState(0);
  useEffect(() => {
    //console.log("CURRENT PAGE", activeDiv);
  }, [activeDiv]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (game.current && game.current.innerHTML.trim() === "") {
        alert('Empty.. Try reload game');
        setRenderKey(prevKey => prevKey + 1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div ref={game} key={renderKey}>
      <AnimatePresence mode="wait">
        {PAGES_WITH_HEADER.includes(activeDiv) && (
          <HeaderSelector
            key={`header-${activeDiv}`}
            currentPage={activeDiv}
          />
        )}
      </AnimatePresence>

      <div className="max-w-[550px]">
        <Outlet />
      </div>

      <AnimatePresence mode="wait">
        {!PAGES_WITHOUT_FOOTER.includes(activeDiv) && (
          <FooterSelector
            key={`footer-${activeDiv}`}
            currentPage={activeDiv}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
