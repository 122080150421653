import { RequestWebsocket } from "../interfaces/wsInterfaces";
import { generateRequestId } from "../utils/generateRequestId";
import { sendRequestAndGetResponse } from "../Websocket/websocketInit";



export const getGrowthFundTasks = async (params: {
  clientId: string;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-quests",
    lang: "en",
    method: "growthFund_getMyTasks",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const checkGrowthFund = async (params: {
    clientId: string;
  }): Promise<any> => {
    const request: RequestWebsocket = {
      agent: "battler-tg-quests",
      lang: "en",
      method: "growthFund_isEnabled",
      id: generateRequestId(),
      auth: {
        type: "mobileApp",
        deviceId: params.clientId,
      },
      params,
    };
    try {
      const result = await sendRequestAndGetResponse(request);
      return result?.value;
    } catch (e) {
      console.error((e as Error).message);
      return (e as Error).message as unknown as any;
    }
  };


export const claimGrowthFundTask = async (params: {
    clientId: string;
    taskId: number;
  }): Promise<any> => {
    const request: RequestWebsocket = {
      agent: "battler-tg-quests",
      lang: "en",
      method: "growthFund_claimTaskReward",
      id: generateRequestId(),
      auth: {
        type: "mobileApp",
        deviceId: params.clientId,
      },
      params,
    };
    try {
      const result = await sendRequestAndGetResponse(request);
      return result?.value;
    } catch (e) {
      console.error((e as Error).message);
      return (e as Error).message as unknown as any;
    }
  };