import { motion } from "framer-motion";
import { PopupButton } from "../../../../../../components/PopupButton";

export const VipOffer = ({
  id,
  firstImage,
  secondName,
  secondImage,
  backgroundColor,
  onClick,
  swapRate,
  minBuy,
}: {
  id: number;
  firstImage: string;
  secondName: string;
  secondImage: string;
  backgroundColor?: string;
  onClick: () => void;
  swapRate: number;
  minBuy: number;
}) => {
  return (
    <motion.div
      key={id}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.3,
        delay: id * 0.1,
      }}
      className="flex-1 bg-gradient-to-r from-yellow-400 to-orange-500 p-[2px]"
    >
      <div className="flex flex-col justify-center items-center bg-gradient-to-r from-[#b9a97e] to-[#e1c994] px-4 py-2 ">
        <div className="text-center text-white text-sm font-black  leading-[14px] pb-2 text-shadow-black-sm">
          {secondName}
        </div>
        <div className="flex justify-center items-center pb-2 relative">
          <div
            className={`p-2 border-2 border-[#fffe2e]  ${
              backgroundColor ? backgroundColor : "bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]"
            } rounded-md`}
          >
            <div className="w-[72px] h-[72px]">
              <img className="w-full h-full" src={secondImage} />
            </div>
          </div>
          <div className="absolute bottom-5 right-2 text-right text-white text-base font-black leading-none text-shadow-black-sm">
            {minBuy}
          </div>
        </div> 
        <PopupButton type="gold" onClick={onClick} className="w-full">
          <div className="flex justify-center items-center gap-1">
            <div className="w-5 h-5">
              <img className="w-full h-full" src={firstImage} />
            </div>
            <div className="text-right text-white text-md font-black leading-[14px]">{Math.round(swapRate * minBuy)}</div>
          </div>
        </PopupButton>
      </div>
    </motion.div>
  );
};
