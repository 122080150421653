import { Resources } from "../../enums/resources";
import { resourcesMap } from "../../utils/mapping";

export const CheckInIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <div onClick={onClick} className={`flex justify-center items-center relative w-[52px] h-[52px]`}>
      <img
        className={`absolute w-[52px] left-[50%] translate-x-[-50%] bottom-0`}
        src={require("../../assets/images/check-in-icon.png")}
        alt=""
      />

      <div
        className="absolute left-[50%] translate-x-[-50%] bottom-[-3px] text-[14px] text-white
        text-stroke-small whitespace-nowrap text-center font-black leading-[12px]"
      >
        Share
        <br />
        to Earn
      </div>
    </div>
  );
};
