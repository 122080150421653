import React, { useState, useEffect } from "react";
import { useUtils } from "../../utils/navigateTo";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";
import * as Sentry from "@sentry/react";
import {useTelegram} from "../../hooks/useTelegram";
import { setSave } from "../../app/features/tutorialSaveSlice";
import { getTutorialProgress } from "../../endpoints/tutorialProgress";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";

export const Home = () => {
  const { navigateTo } = useUtils();
  const { userId } = useTelegram();
  const dispatch = useDispatch<AppDispatch>();


  useEffect(() => {
    const getBattleTutorialData = async() => {
      const save = await getTutorialProgress({ clientId: userId });
      if (!save) throw new Error("No tutorial save data available");
      
      dispatch(setSave(save));

      //Так как хом рендерится каждый раз вместе с входом в приложение, отправляю на айленд только с выполненым тутором
      if(save && !save.completed){
        navigateTo('/battle')
      }else{
        navigateTo("/island");
      }
    }

    getBattleTutorialData()
    
  }, []);

  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: "" };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) { errorData.stack = componentStack }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
      }}
    >
      <></>
    </Sentry.ErrorBoundary>
  );
};
