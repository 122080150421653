import {Layout,Fit,Alignment, useRive} from "@rive-app/react-canvas";

export const QuestAnimation = ({className, onAnimationReady}: { className?: string,onAnimationReady?:()=>void }) => {
    const { RiveComponent } = useRive({
        src: "animation/dailyquestclaim.riv",
        stateMachines: "State Machine 1",
        onLoad: onAnimationReady ? ()=>{onAnimationReady()}  : ()=>{},
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.Center,
        }),
    });

    return <RiveComponent className={className} />;
};
