// components/QuantitySelector.tsx
import React, { useEffect } from "react";
import Slider from "rc-slider";
import Handle from "rc-slider";
import "rc-slider/assets/index.css";
import { PopupButton } from "../../../../components/PopupButton";

interface QuantitySelectorProps {
  max: number;
  value: number;
  onChange: (value: number) => void;
  step?: number;
}

const CustomHandle: React.FC<any> = (props) => {
  const { value, dragging, index, ...rest } = props;
  return (
    <Handle
      value={value}
      {...rest}
      style={{
        ...rest.style,
        height: 24,
        width: 12,
        backgroundColor: "blue",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};

export const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  max,
  value,
  onChange,
  step = 1, // за замовчуванням якщо step не передали
}) => {
  const handleIncrease = () => {
    const newValue = value + step;
    if (newValue <= max) {
      onChange(newValue);
    } else {
      onChange(max); // або не змінювати взагалі
    }
  };

  const handleDecrease = () => {
    const newValue = value - step;
    if (newValue >= step) {
      // або >= 1 – залежить, з якої позначки має починатися
      onChange(newValue);
    } else {
      onChange(step); // або 1
    }
  };

  const handleSliderChange = (val: number | number[]) => {
    if (typeof val === "number" && val > 0) {
      onChange(val);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <PopupButton
        onClick={handleDecrease}
        disabled={value <= step} // блокуємо коли вже мінімум
        type="blue"
        width="26px"
        height="26px"
      >
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-[45px]">
          <div className="pb-[3px]">-</div>
        </div>
      </PopupButton>

      <div className="flex-1 min-w-0">
        <Slider
          min={0}
          max={max}
          value={value}
          step={step}
          onChange={handleSliderChange}
          styles={{
            track: { backgroundColor: "#4caf50", height: 12, borderRadius: 0 },
            handle: {
              height: 26,
              width: 12,
              backgroundColor: "#407FB7",
              border: "2px solid black",
              borderRadius: 0,
              cursor: "pointer",
              transform: "translate(-50%, 5%)",
              opacity: 100,
            },
            rail: {
              backgroundColor: "#ddd",
              height: 12,
              borderRadius: 0,
            },
          }}
          className="w-full flex items-center"
        />
      </div>

      <PopupButton
        onClick={handleIncrease}
        disabled={value >= max}
        type="blue"
        width="26px"
        height="26px"
      >
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-[45px]">
          <div className="pb-[1px]">+</div>
        </div>
      </PopupButton>

      <input
        type="text"
        value={value}
        readOnly
        className="w-[60px] h-[32px] bg-[#1b1817] text-center rounded shadow-inner
        border border-[#554837] text-white text-base font-normal  leading-none outline-0"
      />
    </div>
  );
};
