import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestBlock, SocialBlock, DepositBlock } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { saveFarmTutorialProgress } from "../../app/features/farmTutoralSlice";
import { AppDispatch, RootState, store } from "../../app/store";
import { useTelegram } from "../../hooks/useTelegram";
import { TutorialFarmSave, TutorialFarmStage } from "../../interfaces/tutorial";
import { fetchIslands } from "../../app/features/islandsSlice";
import { setSelectedIsland } from "../../app/features/selectedIsland";
import { AnimatePresence, motion } from "framer-motion";
import { TaskStatus } from "../../app/features/questsSlice";
import { MissionBlock } from "./components/MissionBlock";

export enum QuestType {
  quests,
  missions,
}

export const QuestsList = ({
  depositTask,
  quests,
  socialTask,
  activeTab,
  mainMission,
  secondaryMissions,
  tutorQuest,
}: {
  depositTask?: any;
  quests: any[];
  socialTask: any[];
  activeTab: QuestType;
  mainMission: any;
  secondaryMissions: any[];
  tutorQuest?: any;
}) => {
  const { t } = useTranslation();

  console.log("depositTask", depositTask);
  console.log("quests", quests);
  console.log("socialTask", socialTask);
  console.log("mainMission", mainMission);

  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();
  const [mainBuildingLevel, setMainBuildingLevel] = useState(0);
  const islands = useSelector((state: RootState) => state.islands.islands);
  const [showAllSocial, setShowAllSocial] = useState(false);
  const [activeTasks, setActiveTasks] = useState<any[]>([]);
  const [activeQuests, setActiveQuests] = useState<any[]>([]);
  let selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);
  
  const selectedHero = useSelector((state: RootState) => state.selectedHero);

  const heroesList = useSelector((state: RootState) => state.heroes.heroesList);

  const heroId = Number(selectedHero.uid);

  const heroTiers = heroesList[heroId]?.tiers.filter((t) => t.isClaimed === true)?.length

  const heroLevel = heroesList[heroId]?.level;
  useEffect(() => {
    const filteredTasks = socialTask.filter((task) => task.status !== TaskStatus.Claimed);
    setActiveTasks(filteredTasks);
  }, [socialTask]);

  useEffect(() => {
    const filteredQuests = quests.filter((task) => task.status !== TaskStatus.Claimed);
    setActiveQuests(filteredQuests);
  }, [quests]);
  useEffect(() => {
    dispatch(fetchIslands(`${userId}`));
    if (!selectedIsland && islands.length > 0) {
      store.dispatch(setSelectedIsland({ island: islands[0] }));
      selectedIsland = islands[0];
    }
  }, []);

  useEffect(() => {
    if (selectedIsland) {
      const mainBuilding = selectedIsland.buildings.find((building) => building.id === 1);
      if (mainBuilding) {
        setMainBuildingLevel(mainBuilding.lvl || 1);
      }
    }
  }, [selectedIsland]);

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  console.log("Tutorial stage in quests:", farmTutorialSave?.stage!);

  return (
    <>
      <div
        className={`flex flex-col justify-start items-center pb-4 gap-2 overflow-y-auto overflow-x-hidden max-h-full 
        ${farmTutorialSave?.stage === TutorialFarmStage.clickOnQuestList ? "z-[20]" : ""} 
        ${farmTutorialSave?.stage ===
          TutorialFarmStage.clickOnCompletedQuest ||
          farmTutorialSave?.stage === TutorialFarmStage.claimQuest ? 'overflow-y-hidden' : ''}`}
      >
        {/* Transparent bg for clickOnQuestList stage */}
        {farmTutorialSave?.stage === TutorialFarmStage.clickOnQuestList && (
          <div
            className={`absolute w-full h-full bg-transparent z-[100]`}
            onClick={() => {
              updateSave({
                stage: TutorialFarmStage.clickOnCompletedQuest,
              });
            }}
          ></div>
        )}

        {activeTab === QuestType.quests && (
          <>
            {depositTask ? (
              <div className="w-full mb-1">
                <DepositBlock quest={depositTask} />
              </div>
            ) : null}
            <div className="w-full flex flex-col gap-2 relative mb-[27px]">
              {activeTasks.slice(0, 3).map((quest, index) => (
                <div className="z-10" key={quest.id}>
                  <SocialBlock quest={quest} />
                </div>
              ))}
              <AnimatePresence>
                {showAllSocial && (
                  <>
                    {activeTasks.slice(3, socialTask.length).map((quest, index) => (
                      <motion.div
                        key={quest.id}
                        className="z-10"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{
                          opacity: 0,
                          y: -10,
                          transition: {
                            delay: (socialTask.slice(3).length - 1 - index) * 0.1,
                            duration: 0.3,
                          },
                        }}
                        transition={{
                          delay: index * 0.1,
                        }}
                      >
                        <SocialBlock quest={quest} />
                      </motion.div>
                    ))}
                  </>
                )}
              </AnimatePresence>
              <AnimatePresence>
                <motion.div
                  className="flex items-end justify-center bg-[#8A1A1A] w-[100px] h-[calc(100%-37px)]  rounded-md absolute
              top-[64px] right-[9px] z-5"
                  initial={{ height: "237px" }}
                  animate={{
                    height: showAllSocial ? "calc(100% - 37px)" : "237px",
                  }}
                  exit={{
                    height: "237px",
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  transition={{
                    duration: 0.3,
                    delay: 0,
                  }}
                >
                  <div className="p-1 w-full" onClick={() => setShowAllSocial((prev) => !prev)}>
                    <div className="flex items-center justify-center">
                      <div className="text-[12px] font-extrabold text-white text-stroke-regular mr-1">
                        {showAllSocial ? "Hide " : "More "}
                        tasks
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`transition-transform duration-300 ${!showAllSocial ? "transform rotate-180" : ""}`}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20 14.995L12 6.995L4 14.995L6 17L12 10.995L18 17L20 14.995Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
            {activeQuests.map((quest, index) => {
              return (
                <React.Fragment key={quest.questId.value}>
                  <QuestBlock quest={quest} mainBuildingLevel={mainBuildingLevel} />
                </React.Fragment>
              );
            })}
          </>
        )}
        {activeTab === QuestType.missions && (
          <>
            <div className={`flex flex-col w-full`}>
              {mainMission.config && (
                <div className={`relative w-full flex flex-col mb-[10px]`}>
                  <div
                    className={`relative w-full flex justify-center items-center mb-[20px] text-white text-stroke-small font-[900] bg-[#f5a000] h-[26px] rounded-md`}
                  >
                    MAIN TASK
                    <div
                      className={`absolute bottom-[-8px] left-[50%] translate-x-[-50%] bg-[#f5a000] w-[27px] h-[10px]`}
                      style={{ clipPath: "polygon(50% 100%, 0 0, 100% 0)" }}
                    ></div>
                  </div>
                  <MissionBlock mission={mainMission} heroLvl={heroLevel} heroTiers={heroTiers} />
                </div>
              )}

              {secondaryMissions.length > 0 && (
                <div className={`relative w-full flex flex-col gap-1`}>
                  <div
                    className={`relative w-full flex justify-center items-center mb-[20px] text-white text-stroke-small font-[900] bg-[#407fb8] h-[26px] rounded-md`}
                  >
                    SIDE TASKS
                    <div
                      className={`absolute bottom-[-8px] left-[50%] translate-x-[-50%] bg-[#407fb8] w-[27px] h-[10px]`}
                      style={{ clipPath: "polygon(50% 100%, 0 0, 100% 0)" }}
                    ></div>
                  </div>
                  {secondaryMissions.map((m) => (
                    <div>
                      <MissionBlock key={m.id} mission={m} heroLvl={heroLevel} heroTiers={heroTiers} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}

        {/* {activeTab === QuestType.explore && (
          <>
            {socialTask.filter((task) => task.isFriends === false).length ? (
              <div className="flex justify-center items-center text-center text-white text-2xl font-normal leading-normal">
                $KITSU
              </div>
            ) : null}
            {socialTask
              .filter((task) => task.isFriends === false)
              .map((quest, index) => (
                <React.Fragment key={quest.id}>
                  <SocialBlock quest={quest} />
                  {index !== quests.length - 1 && (
                    <div className="w-full min-h-px bg-[#584d3c]"></div>
                  )}
                </React.Fragment>
              ))}
            {socialTask.filter((task) => task.isFriends === true).length ? (
              <div className="flex justify-center items-center h-10 text-center text-white text-2xl font-normal  leading-normal">
                Kitsune's Friends
              </div>
            ) : null}
            {socialTask
              .filter((task) => task.isFriends === true)
              .map((quest, index) => (
                <React.Fragment key={quest.id}>
                  <SocialBlock quest={quest} />
                  {index !== quests.length - 1 && (
                    <div className="w-full min-h-px bg-[#584d3c]"></div>
                  )}
                </React.Fragment>
              ))}
          </>
        )} */}
      </div>
    </>
  );
};
