// errorReportingService.ts

import * as Sentry from "@sentry/react";
import { store } from "../app/store";
import { setError } from "../app/features/errorSlice";
import { RequestWebsocket } from "../interfaces/wsInterfaces";
import { generateRequestId } from "../utils/generateRequestId";
import { WebsocketState } from "../enums/wsEnums";

import {
  ErrorType,
  ReportableError,
  RuntimeErrorData,
  WebSocketErrorData,
} from "../interfaces/error";
import { APP_ENV } from "../config";
import { sendRequestAndGetResponse } from "../Websocket/websocketInit";

export const getAttempts = (): number => {
  const attempts = sessionStorage.getItem(`attempts_errorReportingService`);
  return attempts ? parseInt(attempts, 10) : 0;
};

export const setAttempts = ( attempts: number): void => {
  sessionStorage.setItem(`attempts_errorReportingService`, attempts.toString());
};

export const clearAttempts = (): void => {
  sessionStorage.removeItem(`attempts_errorReportingService`);
};

export class ErrorReportingService {
  static reportError(error: ReportableError) {
    // return; // TODO: розкоментувати для відправки логів в Telegram
    if (APP_ENV && (APP_ENV === "stage" || APP_ENV === "production")) {
      if (error.type === ErrorType.websocket) {
        // Надсилаємо помилку вебсокету

        this.sendWebsocketError({
          clientId: error.clientId,
          errorData: error.errorData as WebSocketErrorData,
        });
      } else if (error.type === ErrorType.runtime) {
        // Надсилаємо рантайм помилку
        this.sendRunTimeError({
          clientId: error.clientId,
          errorData: error.errorData as RuntimeErrorData,
        });
      }
    }
    // return;
  }

  private static async sendWithRetries(
    request: RequestWebsocket,
    maxAttempts = 3
  ): Promise<boolean> {
    const webSocket: WebSocket | null = store.getState().webSocket.webSocket;

    if (!webSocket || webSocket.readyState !== WebsocketState.open) {
      console.warn("WebSocket is not open, cannot send error to Telegram bot");
      return false;
    }

    // Отримуємо поточну кількість спроб з sessionStorage
    let attempt = getAttempts();

    const currentAttempts = getAttempts();
    console.log(`Кількість спроб для запиту ${request.id}: ${currentAttempts}`);

    while (attempt < maxAttempts) {
      attempt++;
      // Зберігаємо оновлену кількість спроб у sessionStorage
      setAttempts(attempt);

      try {
        const result = await sendRequestAndGetResponse(request);
        if (result?.type === "Left") {
          throw new Error("Empty or invalid response value");
        }
        // Якщо успішно, очищаємо кількість спроб
        clearAttempts();
        return result.value;
      } catch (e) {
        console.error(
          `Failed to send message to Telegram bot (attempt ${attempt} of ${maxAttempts}):`,
          e
        );
        if (attempt === maxAttempts) {
          // Якщо це була остання спроба - фіксуємо помилку
          // Sentry.captureException(e);
          // store.dispatch(setError(e as Error));
          clearAttempts();
          return false;
        }
        // Затримка між спробами
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
    return false;
  }

  private static sendWebsocketError = async ({
    clientId,
    errorData,
  }: {
    clientId: string;
    errorData: WebSocketErrorData;
  }) => {
    const webSocket: WebSocket | null = store.getState().webSocket.webSocket;

    if (!webSocket || webSocket.readyState !== WebsocketState.open) {
      console.warn("WebSocket is not open, cannot send error to Telegram bot");
      return;
    }

    const request: RequestWebsocket = {
      agent: "tg-battler-bot",
      lang: "en",
      method: "notification_notifyErrorHappendOnApp",
      id: generateRequestId(),
      params: {
        agent: errorData.agent,
        method: errorData.method,
        clientId,
        type: ErrorType.websocket,
        requestId: errorData.requestId,
        requestParams: errorData.requestParams,
        responseData: errorData.responseData,
        environment: APP_ENV,
      },
      auth: {
        type: "webSite",
      },
    };
    console.log("request", request);
    await this.sendWithRetries(request, 3);
  };

  private static sendRunTimeError = async ({
    clientId,
    errorData,
  }: {
    clientId: string;
    errorData: RuntimeErrorData;
  }) => {
    const webSocket: WebSocket | null = store.getState().webSocket.webSocket;

    if (!webSocket || webSocket.readyState !== WebsocketState.open) {
      console.warn("WebSocket is not open, cannot send error to Telegram bot");
      return;
    }

    const request: RequestWebsocket = {
      agent: "tg-battler-bot",
      lang: "en",
      method: "notification_notifyErrorHappendOnApp",
      id: generateRequestId(),
      params: {
        clientId,
        type: ErrorType.runtime,
        message: errorData.message,
        stack: errorData.stack,
        environment: APP_ENV,
      },
      auth: {
        type: "webSite",
      },
    };
    console.log("request", request);
    await this.sendWithRetries(request, 3);
  };
}
