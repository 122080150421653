import { useTranslation } from "react-i18next";
import { UpgradeArrow } from "../../containers/Room";
import { PopupButton } from "../PopupButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRoom } from "../../app/features/selectedRoom";
import { StoneIco } from "../../layout/components/HeaderFarm/components";
import { Offer } from "../../pages/Shop/components";
import { usePurchase } from "../../hooks/usePurchase";
import { RootState } from "../../app/store";
import { ConfigOffer, OfferType } from "../../mock/offers";
import { OfferProps } from "../../pages/Shop/components/Offer";
import { OfferResourceProps } from "../../pages/Shop/components/OfferResources";
import { defineCountString } from "../../utils/farmConfigParser";
import { PurchaseModal } from "../PurchaseModal";
import { SuccessOverlay } from "../SuccessOverlay";
import { RoomStatus } from "../../enums/buildingStatus";
import { updateBattleSave } from "../../utils/updateBattleSave";
import { useUtils } from "../../utils/navigateTo";
import { useSessionStorage } from "@uidotdev/usehooks";
import { useTelegram } from "../../hooks/useTelegram";
import { getDungeonProgressByDungeonId } from "../../endpoints/dungeonEndpoints";
import { getIsSelected } from "../../pages/Shop";
import { msToTime } from "../../utils/timer";

export interface UpgradeDataRow {
  title: string;
  nowValue: number;
  newValue: number;
}

export const PopupUpgradeTable = (props: {
  upgradeData?: UpgradeDataRow[];
  requiredConditions?: {
    condition: string;
    met: boolean;
    buildingId?: number;
    lvl?: number;
    status?: number;
  }[];
  upgradeNormalCondition: {
    met: boolean;
    currentAmount: number;
    costAmount: number;
  };
  maxTimeMs?: {
    nowMaxTime: number | undefined;
    newMaxTime: number | undefined;
  };
}) => {
  const { upgradeData, requiredConditions, upgradeNormalCondition } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showOffer, setShowOffer] = useState(false);

  console.log("UPGRADEDATA", props);

  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const {
    offers: { variables: allOffers },
  } = appConfig;

  const stoneOffer = allOffers.filter(
    (offer: ConfigOffer) => offer.offerType.value === OfferType.stoneOffer && offer.offerId.value === 9
  );

  const mappedStoneStoreOffer: OfferProps[] = stoneOffer.map((offer: ConfigOffer): OfferProps => {
    const sortedItems = [...offer.items.value].sort((a, b) => {
      const aSelected = getIsSelected(a);
      const bSelected = getIsSelected(b);

      if (aSelected === bSelected) return 0;
      return aSelected ? -1 : 1;
    });

    const resourceList: OfferResourceProps[] = sortedItems.map((item, index): OfferResourceProps => {
      const isEnabled = getIsSelected(item);

      return {
        resourceType: item.rewardType.value,
        resourceId: item.rewardId.value,
        isEnabled: isEnabled,
        layout: "vertical",
        amount: defineCountString(item.amount.value),
      };
    });

    return {
      id: offer.offerId.value,
      resourceList: resourceList,
      offerType: offer.offerType.value,
      type: "gold",
      label: "corner",
      layout: "slim",
      price: defineCountString(offer.stars.value),
      deadline: "January 31, 2025",
    };
  });

  const offer = mappedStoneStoreOffer[0];

  return (
    <>
      <div className="w-full">
        {/* Upgrade Data Section */}

        {offer && upgradeNormalCondition.currentAmount < upgradeNormalCondition.costAmount ? (
          <div className="flex justify-center items-center p-2 w-full">
            <Offer
              onClick={() => {
                confirmPurchase({ offerId: offer.id });
              }}
              offerType={offer.offerType}
              key={0}
              id={offer.id}
              rankPoints={defineCountString(Math.floor(Number(offer.price) / 10))}
              resourceList={offer.resourceList}
              layout={offer.layout}
              type={offer.type}
              label={offer.label}
              kitsu={offer.kitsu}
              price={offer.price}
              deadline={offer.deadline}
            />
          </div>
        ) : null}

        <div className="w-full">
          {upgradeData?.map(
            (row, i) =>
              row.nowValue != row.newValue && (
                <>
                  <div key={i} className={`w-full flex flex-col ${i % 2 ? "bg-[#362d28]" : "bg-transparent"} `}>
                    <div className="flex flex-row items-center justify-between pl-6">
                      {/* Title */}
                      <p className="text-[15px] text-white text-stroke-regular leading-none font-bold">
                        {t(row.title)}
                      </p>

                      {/* Values with Arrow */}
                      <div className="flex flex-row items-center justify-end gap-2 px-6 py-4">
                        <p className="text-base text-white font-bold">
                          {i === 1 ? msToTime(row.nowValue) : row.nowValue}
                        </p>
                        <UpgradeArrow direction="right" />
                        <p className={`text-base font-bold ${i === 1 ? "text-white" : "text-[#F6A000]"}`}>
                          {i === 1 ? msToTime(row.newValue) : row.newValue.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )
          )}
          {upgradeData?.find((row) => row.nowValue != row.newValue) && (
            <>
              {/* Divider Between Sections */}
              <div className="w-full px-4 mt-4">
                <div className="w-full h-[3px] bg-[#181615] mb-1"></div>
                <div className="w-full h-[3px] bg-[#3b332e]"></div>
              </div>
            </>
          )}
        </div>
        {/* Required Conditions Section */}
        <div className="w-full">
          <div className="text-center text-[#A49A7C] font-bold text-[18px] mt-[12px]">Cost</div>
          {requiredConditions?.map((row, i) => (
            <React.Fragment key={i}>
              <div className="w-full flex flex-col bg-transparent">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex items-center py-2 px-4">
                    <img
                      className={`flex h-[40px] w-[40px] rounded-[15px] object-contain mr-2`}
                      src={require(`../../assets/images/buildings/build${row.buildingId}.png`)}
                      alt=""
                    />
                    {/* Condition Text */}
                    <p className={`text-[14px] ${row.met ? "text-white" : "text-red-600"}`}>{row.condition}</p>
                  </div>
                  {/* Status or Action */}
                  <div className={`flex flex-row items-center justify-end gap-1.25 pr-4 ${row.met ? "pr-6" : ""}`}>
                    {row.met ? (
                      <div className="w-6 h-6 ">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.35355 9.64645L6 9.29289L5.64645 9.64645L3.64645 11.6464L3.29289 12L3.64645 12.3536L9.64645 18.3536L10 18.7071L10.3536 18.3536L20.3536 8.35355L20.7071 8L20.3536 7.64645L18.3536 5.64645L18 5.29289L17.6464 5.64645L10 13.2929L6.35355 9.64645Z"
                            fill="#45B684"
                            stroke="#19191B"
                          />
                        </svg>
                      </div>
                    ) : row.buildingId ? (
                      row.status === RoomStatus.building ? (
                        <div className="text-white">You need to finish building</div>
                      ) : row.lvl !== -Infinity && row.lvl! > 0 ? (
                        <PopupButton type="green">
                          <div className="flex justify-center items-center w-full ">
                            <div
                              className="flex justify-center items-center w-[58px] h-[22px] text-center text-white text-base font-normal leading-none cursor-pointer"
                              onClick={() => {
                                dispatch(
                                  setSelectedRoom({
                                    id: row.buildingId ?? 1,
                                    buildingId: row.buildingId,
                                    lvl: row.lvl,
                                    type: 1,
                                  })
                                );
                              }}
                            >
                              <div className="text-[#FFEFCB] font-bold text-[16px]">Build</div>
                            </div>
                          </div>
                        </PopupButton>
                      ) : row.status === RoomStatus.battle ? (
                        // <div>hello</div>
                        <FightButton />
                      ) : (
                        <div className="text-white">You need to repair it</div>
                      )
                    ) : row.lvl !== -Infinity ? (
                      <div className="text-white">You need to repair it</div>
                    ) : (
                      <div className="w-6 h-6">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M18.9268 6.99662L19.1036 6.81984L18.9268 6.64307L17.3569 5.07322L17.1802 4.89645L17.0034 5.07322L12 10.0766L6.99662 5.07322L6.81984 4.89645L6.64307 5.07322L5.07322 6.64307L4.89645 6.81984L5.07322 6.99662L10.0766 12L5.07322 17.0034L4.89645 17.1802L5.07322 17.3569L6.64307 18.9268L6.81984 19.1036L6.99662 18.9268L12 13.9234L17.0034 18.9268L17.1802 19.1036L17.3569 18.9268L18.9268 17.3569L19.1036 17.1802L18.9268 17.0034L13.9234 12L18.9268 6.99662Z"
                            fill="#F04444"
                            stroke="black"
                            strokeWidth="0.5"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>

                {/* Separator */}
                <div className="h-px bg-[#584d3c] mx-4"></div>
              </div>
            </React.Fragment>
          ))}
          {
            <div className="flex flex-row items-center justify-between">
              <div className="flex gap-2 justify-center items-center p-4">
                <div className="w-[40px] h-[40px]">
                  <StoneIco />
                </div>
                <div className="text-[18px] text-stroke-regular">
                  <span
                    className={`${
                      upgradeNormalCondition.currentAmount < upgradeNormalCondition.costAmount
                        ? "text-red-600"
                        : "text-white"
                    }`}
                  >
                    {defineCountString(upgradeNormalCondition.currentAmount)}/
                  </span>
                  <span className="text-[#A3A3A3]">{upgradeNormalCondition.costAmount}</span>
                </div>
              </div>

              {upgradeNormalCondition.met}

              <div className="flex flex-row items-center justify-end gap-1.25 pr-4">
                {upgradeNormalCondition.currentAmount > upgradeNormalCondition.costAmount ? (
                  <div className="w-6 h-6 mr-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.35355 9.64645L6 9.29289L5.64645 9.64645L3.64645 11.6464L3.29289 12L3.64645 12.3536L9.64645 18.3536L10 18.7071L10.3536 18.3536L20.3536 8.35355L20.7071 8L20.3536 7.64645L18.3536 5.64645L18 5.29289L17.6464 5.64645L10 13.2929L6.35355 9.64645Z"
                        fill="#45B684"
                        stroke="#19191B"
                      />
                    </svg>
                  </div>
                ) : (
                  <PopupButton type="blue" onClick={() => setShowOffer(true)}>
                    <div className="flex justify-center items-center w-full ">
                      <div className="flex justify-center items-center w-[58px] h-[22px] text-center text-white text-base font-normal leading-none cursor-pointer">
                        <div className="text-[#FFEFCB] font-bold text-[16px]">Go</div>
                      </div>
                    </div>
                  </PopupButton>
                )}
              </div>
            </div>
          }
        </div>
        {showOffer && (
          <>
            <div className="fixed inset-0 min-w-full min-h-full z-[55]">
              {/* Чорний фоновий шар */}

              <div className="absolute inset-0 bg-black bg-opacity-50 z-20 pointer-events-none"></div>

              {/* Компонент Offer, який має бути поверх чорного фону */}
              <div className="absolute inset-0 z-30 flex items-center justify-center">
                <Offer
                  onClose={() => {
                    setShowOffer(false);
                  }}
                  onClick={() => confirmPurchase({ offerId: offer.id })}
                  offerType={offer.offerType}
                  id={offer.id}
                  rankPoints={defineCountString(Math.floor(Number(offer.price) / 10))}
                  resourceList={offer.resourceList}
                  layout={"full"}
                  type={offer.type}
                  label={"full_top"}
                  kitsu={offer.kitsu}
                  price={offer.price}
                  deadline={offer.deadline}
                />
              </div>
            </div>
          </>
        )}
        {/* Separator */}
        <div className="h-px bg-[#584d3c] mx-4"></div>
      </div>
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </>
  );
};

const FightButton = () => {
  const { navigateTo } = useUtils();

  const { userId } = useTelegram();
  const dispatch = useDispatch();

  const selectedHero = useSelector((state: RootState) => state.selectedHero);

  const heroId = Number(selectedHero.uid);
  const [currentBattleBuildingId, setCurrentBattleBuildingId] = useSessionStorage("currentBattleBuildingId", 0);

  const [isDungeonStart, setIsDungeonStart] = useSessionStorage("isDungeonStart", false);
  const [currentDungeonId, setCurrentDungeonId] = useSessionStorage("currentDungeonId", 0);

  const getDungeonSaveResult = async () => {
    const result = await getDungeonSave();
    setIsDungeonStart(result);
    return result;
  };

  const getDungeonSave = async () => {
    try {
      //@ts-ignore
      const progress = await getDungeonProgressByDungeonId({
        clientId: userId,
        buildingId: currentBattleBuildingId,
        heroId: heroId,
      });

      if (progress) {
        setCurrentDungeonId(progress.currentDungeonId);
        const maxStageId = Math.max(...progress.stages.map((stage) => stage.stageId));

        // Знаходимо об'єкт з цим stageId
        const maxStage = progress.stages.find((stage) => stage.stageId === maxStageId);
        // Перевіряємо, чи id цього об'єкта більше за 3
        return maxStage ? maxStage.stageId > 3 : false;
      }
      return false;
    } catch (error) {
      console.error("Error fetching dungeon progress:", error);
      return false;
    }
  };

  const onClickBattle = () => {
    console.log("curr build id", currentBattleBuildingId);

    const getDungeonSaveOrStartDungeon = async () => {
      //@ts-ignore
      const progress = await getDungeonProgressByDungeonId({
        clientId: userId,
        buildingId: currentBattleBuildingId,
        heroId: heroId,
      });

      if (progress?.stages?.length) {
        if (progress.currentDungeonId && currentBattleBuildingId) {
          updateBattleSave({
            save: {
              gold: progress.gold,
              currentHp: progress.currentHp,
              currentMana: progress.currentMana,
              stages: progress.stages,
              dungeonId: progress.currentDungeonId,
              buildingId: currentBattleBuildingId,
            },
            clientId: userId,
          });
          navigateTo("/dungeon");
        }
      }
    };
    // @ts-ignore
    getDungeonSaveOrStartDungeon();
    setTimeout(() => {
      setIsDungeonStart(false);
    }, 500);
  };

  const onClick = async () => {
    dispatch(setSelectedRoom({ id: currentBattleBuildingId }));
    const dungeonStarted = await getDungeonSaveResult();

    if (dungeonStarted) {
      return null;
    }

    return onClickBattle();
  };

  return (
    <PopupButton type="green">
      <div className="flex justify-center items-center w-full ">
        <div
          className="flex justify-center items-center w-[58px] h-[22px] text-[#FFEFCB] font-bold text-[16px] leading-none cursor-pointer"
          onClick={onClick}
        >
          Fight
        </div>
      </div>
    </PopupButton>
  );
};
