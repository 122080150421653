import { useSelector } from "react-redux";
import { RootState, store } from "../../../app/store";
import { RoomStatus } from "../../../enums/buildingStatus";
import { FooterSelectHero } from "../../../layout/components/FooterSelectHero";
import { RenderRoom } from "./components/RenderRoom";
import { RoomProps } from "./interface";
import { useRoom } from "./useRoom";
import { useEffect, useState } from "react";
import { FooterRestartBattle } from "../../../layout/components/FooterRestartBattle";
import { AnimatePresence, motion } from "framer-motion";
import { useSessionStorage } from "@uidotdev/usehooks";
import { isStepLoaded } from "../../../app/features/loadingManagerSlice";

export const Room = (props: RoomProps) => {
  const { scheme, room } = props;

  const {
    startSelectHero,
    onClick,
    Claim,
    building,
    timer,
    isBattle,
    roomDisplay,
    triggerEndBuild,
    triggerShowLevelUp,
    boosts,
    buildTime,
    openSpeed,
    isConfirmationRequired,
    confirmBuildingCompletion,
    heroesListRef,
    heroesListScroll,
    selectedHeroUid,
    setSelectedHeroUid,
    onClickBattle,
    getDungeonSave,
    isDungeonStart,
  } = useRoom(props);

  const selectedRoom = useSelector((state: RootState) => state.selectedRoom);
  // useEffect(() => {
  //   console.log('selectedRoom', selectedRoom)
  // }, [selectedRoom])
  const variants = {
    hidden: { opacity: 0, y: 50 }, // Початковий стан: прозорість 0, зміщення вниз
    visible: { opacity: 1, y: 0 }, // Кінцевий стан: прозорість 1, без зміщення
    exit: { opacity: 0, y: 0 }, // Вихідний стан: прозорість 0, зміщення вниз
  };

  const [isVisible, setIsVisible] = useState(false);

  const [isBuilderBusy, setIsBuilderBusy] = useSessionStorage<any>(
    "isBuilderBusy",
    false
  );
  const [currentDungeonId, setCurrentDungeonId] = useSessionStorage(
        "currentDungeonId",
        0,
      );

  useEffect(() => {
    if (isBattle() === RoomStatus.building) {
      setIsBuilderBusy(true);
    }
  }, [isBuilderBusy, isBattle() === RoomStatus.building]);

  const [currentBattleBuildingId, setCurrentBattleBuildingId] = useSessionStorage(
    "currentBattleBuildingId",
    0
  );
  useEffect(() => {
    if (room?.status === RoomStatus.battle) {
      setCurrentBattleBuildingId(room.buildingId!);
    }
  }, [currentBattleBuildingId, room?.status === RoomStatus.battle]);

  const onClickBuilding = () => {
    if (isBuilderBusy && isBattle() === RoomStatus.repair) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    } else {
      onClick();
    }
  };

  const [lvlGateUserStats, setLvlGateUserStats] = useSessionStorage<
      Record<number, { lvl: number; status: boolean }>
    >("lvlGateConditions", {});

      const backgroundLoaded = useSelector((state: RootState) => isStepLoaded(state, "background"));

  return (
    <>
      {backgroundLoaded &&
        <button
        id={"room_" + room?.id}
        onClick={onClickBuilding}
        className={`overflow-visible w-full h-full flex items-center justify-center relative`}
        style={{
          position: "absolute",
          top: roomDisplay.y,
          left: roomDisplay.x,
          width: roomDisplay.width,
          maxHeight: roomDisplay.height,
          height: roomDisplay.height,
        }}
      >
        <RenderRoom
          id={scheme.id}
          status={isBattle()}
          isClaim={Claim}
          building={building}
          triggerEndBuild={triggerEndBuild}
          triggerShowLevelUp={triggerShowLevelUp}
          roomLvl={room?.lvl}
          title={building?.title}
          boosts={boosts}
          buildTime={buildTime}
          isConfirmationRequired={isConfirmationRequired}
          confirmBuildingCompletion={confirmBuildingCompletion}
        />

        <AnimatePresence>
          {isVisible && (
            <motion.div
              className="absolute -top-7 p-2 overflow-hidden flex-shrink-0 z-[999] bg-red-700"
              style={{
                background:
                  "radial-gradient(circle, rgb(167 0 0 / 80%) 50%, rgba(250, 186, 60, 0) 100%)",
              }}
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.2 }}
            >

              <div
                className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
                style={{
                  background:
                    "radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)",
                }}
              ></div>


              <div
                className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
                style={{
                  background:
                    "radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)",
                }}
              ></div>


              <div>
                <p className="text-white">Builders are busy</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </button>
      }
      {(startSelectHero || isDungeonStart) && selectedRoom.id === room?.id && (
        <FooterSelectHero
          id={"room_" + room?.id}
          onClickBattle={onClickBattle}
        />
      )}

      {/* {isDungeonStart && selectedRoom.id === room?.id && (
        <FooterRestartBattle
          id={"room_" + room?.id}
          onClickRestartBattle={onClickBattle}
        />
      )} */}
    </>
  );
};
