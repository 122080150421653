import { RequestWebsocket } from "../../interfaces/wsInterfaces";
import { generateRequestId } from "../../utils/generateRequestId";
import { sendRequestAndGetResponse } from "../../Websocket/websocketInit";

interface IUser{
    username: string,
    avatar: string,
    amount: number,
}
export interface ILeaderboard{
   myPlace: number;
   myScore: number;
   users: IUser[];
}

export enum LeaderboardType {
  kitsuSupply,
  referrals,
}

export const getLeaderboard = async (params: {
  clientId: number; leaderboardType: LeaderboardType;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-leaderboard",
    lang: "en",
    method: "allTimeSupply_getLeaderBoard",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      //   deviceId: clientId
    },
    params: params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      return result.value;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message;
  }
};
