import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTelegram } from './useTelegram';
import { useSoundService } from '../utils/soundService';
import { RootState } from '../app/store';

import { ConfigOffer } from '../mock/offers';
import { getBalance } from '../endpoints/farmMock';
import { useTranslation } from 'react-i18next';
import { purchaseTelegram } from '../endpoints/botEndpoints';

export const usePurchase = () => {
    const { tg, userId } = useTelegram();
    const { t } = useTranslation();
    const { playSound } = useSoundService();
    const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
    const [isSuccessPurchase, setIsSuccessPurchase] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState<ConfigOffer | null>(null);
    const appConfig = useSelector((state: RootState) => state.appConfig.configs);
    const {
        offers: { variables: allOffers },
    } = appConfig;

    const getOfferById = (offerId: number) => {
        playSound('notification');
        const offer = allOffers.find((offer: ConfigOffer) => offer.offerId.value === offerId);
        if (offer) {
            setSelectedOffer(offer);
            return offer;
        }
    };

    function initiatePurchase(productId: string) {
        const message = JSON.stringify({
            type: 'purchase',
            productId: productId,
        });

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(message);
        }
    }

    const confirmPurchase = async ({
        offerId,
        stageId,
        cardUid,
        cardId,
        dungeonId,
    }: {
        offerId: number;
        stageId?: number;
        cardUid?: string;
        cardId?: number;
        dungeonId?: number;
    }) => {
        const selectedOffer: ConfigOffer | null = getOfferById(offerId);
        if (!selectedOffer) return;
        setIsPurchaseLoading(true);
        playSound('button');
        try {
            const invoiceLink = await purchaseTelegram({
                offerId: selectedOffer.offerId.value,
                offerType: selectedOffer.offerType.value,
                title: t(`shop.offers.${selectedOffer.offerType.value}.title`),
                description: t(`shop.offers.${selectedOffer.offerType.value}.title`),
                label: t(`shop.offers.${selectedOffer.offerType.value}.title`),
                stageId: stageId ? stageId : undefined,
                cardUid: cardUid ? cardUid : undefined,
                cardId: cardId ? cardId : undefined,
                dungeonId: dungeonId ? dungeonId : undefined,
            });
            if (invoiceLink) {
                console.log('invoiceLink 1111', invoiceLink);
                await new Promise((resolve, reject) => {
                    tg.openInvoice(invoiceLink, async (status: any) => {
                        if (status === 'paid') {
                            setIsSuccessPurchase(true);
                            setSelectedOffer(null);
                            resolve(status);
                        } else {
                            reject(status);
                        }
                        setIsPurchaseLoading(false);

                        setTimeout(async () => {
                            try {
                                await getBalance({ clientId: `${userId}` });
                                resetPurchase();
                            } catch (error) {
                                console.error('Помилка в setTimeout:', error);
                            }
                        }, 3000);
                    });

                    const onInvoiceClosed = async (event: any) => {
                        console.log('InvoiceClosed event received:', event);

                        if (!event.status) {
                            console.log('Invoice window was closed without a purchase.');
                            reject(new Error('Invoice closed without payment'));
                        } else if (event.status === 'paid') {
                            setIsSuccessPurchase(true);
                            setSelectedOffer(null);
                            resolve(event.status);
                        }

                        setIsPurchaseLoading(false);

                        // Виконуємо додаткові дії після обробки події
                        setTimeout(async () => {
                            try {
                                await getBalance({ clientId: `${userId}` });
                                console.log('Get Balance');
                                resetPurchase();
                                console.log('Purchase reset');
                            } catch (error) {
                                console.error('Помилка в setTimeout:', error);
                            }
                        }, 3000);

                        // Видаляємо обробник події після виклику
                        tg.offEvent('invoiceClosed', onInvoiceClosed);
                    };

                    tg.onEvent('invoiceClosed', onInvoiceClosed);
                });
            }
        } catch (error) {
            console.error('Purchase failed:', error);
            setIsPurchaseLoading(false);
        }
    };

    const resetPurchase = () => {
        setSelectedOffer(null);
        setIsSuccessPurchase(false);
    };

    return {
        selectedOffer,
        isPurchaseLoading,
        isSuccessPurchase,
        confirmPurchase,
        resetPurchase,
    };
};
