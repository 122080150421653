import { useTranslation } from "react-i18next";
import { HandleBackButton } from "../../../layout/components/HeaderCave/components";
import { useUtils } from "../../../utils/navigateTo";
import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { useTelegram } from "../../../hooks/useTelegram";
import { PopupButton } from "../../../components/PopupButton";
import * as Sentry from "@sentry/react";
import { ErrorType, RuntimeErrorData } from "../../../interfaces/error";
import { ErrorReportingService } from "../../../services/errorReportingService";
import { ErrorComponent } from "../../../components";
import { getLeaderboard } from "../../../endpoints/Leaderboard/getLeaderboard";

export const JoinAlliance = () => {
  const { t } = useTranslation();
  const { goBack, navigateTo } = useUtils();
  const dispatch = useDispatch<AppDispatch>();
  const { tg, userId } = useTelegram();

  const [safeAreaTop, setSafeAreaTop] = React.useState(() => {
    return sessionStorage.getItem("safeAreaTop") || 0;
  });

  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: "" };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return (
          <ErrorComponent
            jsError={{ error, resetError, componentStack, eventId }}
          />
        );
      }}
    >
      <div
        className="w-full h-full max-w-[550px] mx-auto z-[56] shadow-inner-sm-black overflow-y-auto max-h-[100vh]"
        style={{
          background:
            "radial-gradient(circle at center, #332B26 20%, rgba(0,0,0,0.5) 100%)",
          marginTop: `${safeAreaTop}px`,
        }}
      >
        <div className="fixed top-0 flex justify-start items-center w-full bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black z-[999]">
          <div className="absolute top-[1px] pl-3">
            <HandleBackButton onClick={goBack} />
          </div>
          <div className="text-white text-2xl font-black leading-normal pl-16 text-shadow-black-sm">
            Alliances
          </div>
        </div>

        <div className="relative w-full h-full mt-1 bg-[#242520] border border-[#18191a] rounded-[2px] -top-1 p-3 pt-0 shadow-inner-sm-white">
          <div
            className="relative w-full bg-[#201b18] rounded-[1px] p-3 shadow-inner-sm-black h-full"
            style={{
              background:
                "radial-gradient(circle at center, #332B26 20%, #201B18 100%)",
            }}
          >
            <div className="mt-10 text-white">Alliance</div>
          </div>
        </div>
      </div>
    </Sentry.ErrorBoundary>
  );
};
