import { useSessionStorage } from '@uidotdev/usehooks';
import { PopupButton } from '../../../../../components/PopupButton';
import { Resources } from '../../../../../enums/resources';
import { defineCountString } from '../../../../../utils/farmConfigParser';
import { resourcesMap } from '../../../../../utils/mapping';
import { RootState } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { ConfigOffer, OfferType } from '../../../../../mock/offers';
import { usePurchase } from '../../../../../hooks/usePurchase';
import { SuccessOverlay } from '../../../../../components/SuccessOverlay';
import { EnterCodePopup, UseCodeToPromotePopup } from './components';
import { useTranslation } from 'react-i18next';
import { HandleBackButton } from '../../../../../layout/components/HeaderCave/components';
import { getCodesByClientId, getPromotions } from '../../../../../endpoints/codesEndpoints';
import { useTelegram } from '../../../../../hooks/useTelegram';
import { getUserDeposites, getUserOffers } from '../../../../../endpoints/getProfileInfo';
import { useSoundService } from '../../../../../utils/soundService';
import { motion } from 'framer-motion';

function makeNiceLink(link: string) {
  try {
    const url = new URL(link);

    const hostAndPath = url.host + url.pathname;

    return hostAndPath.replace(/\/$/, '').replace('t.me/', '@');
  } catch (err) {
    return link;
  }
}
export interface Code {
  amount: number;
  clientId: string;
  code: string;
  id: number;
  isPromoting: boolean;
  type: number;
}
export const PurchasePopup = () => {
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);
  const [openUseCodeToPromotePopup, setOpenUseCodeToPromotePopup] = useSessionStorage<Code | null>(
    'openUseCodeToPromotePopup',
    null
  );
  const [refBalance, setRefBalance] = useState(0);
  const [isRefCodeAccess, setIsRefCodeAccess] = useSessionStorage('isRefCodeAccess', true);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>('shouldHideElementKey', true);
  const [shouldHideButtons, setShouldHideButtons] = useState<boolean>(false);
  const [userDepositedAmount, setUserDepositedAmount] = useState(0);
  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);
  const [promotionsTooltip, setPromotionsTooltip] = useState<boolean>(false);
  const [openEnterCodePopup, setOpenEnterCodePopup] = useSessionStorage('openEnterCodePopup', false);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const { userId } = useTelegram();
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const [lt, lst] = useState(false);
  const { t } = useTranslation();
  const { playSound } = useSoundService();

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft((prev) => prev - 1);
    }, 1000);

    if (secondsLeft === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [secondsLeft]);

  const gamePassTicketOffer: ConfigOffer = useMemo(() => {
    if (!appConfig) return undefined;

    const {
      offers: { variables: allOffers },
    } = appConfig;
    console.log('all offers', allOffers);
    const gamePassTicketOffer = allOffers.filter(
      (offer: ConfigOffer) => offer.offerType.value === OfferType.gamePassTicket
    );

    console.log('gamePassTicketOffer', gamePassTicketOffer);
    return gamePassTicketOffer[0];
  }, [appConfig]);

  const [safeAreaTop, setSafeAreaTop] = useState(() => {
    return sessionStorage.getItem('safeAreaTop') || 0;
  });

  const resources = useSelector((state: RootState) => state.resources.resources);

  const [userPromotions, setUserPromotions] = useState(0);

  useEffect(() => {
    const currentValue = resources.find((v) => v.resourceType === Resources.promotions)?.value || 0;
    setUserPromotions(currentValue);
  }, [resources]);

  setTimeout(() => {
    lst(true);
  }, 300);

  useEffect(() => {
    if (isSuccessPurchase) {
      setShouldHideElement(true);
    }
  }, [isSuccessPurchase]);
  const [userCodes, setUserCodes] = useSessionStorage('userCodes', []);

  const getUserCodes = async () => {
    const data = await getCodesByClientId({
      clientId: userId,
    });
    console.log('data', data);
    setUserCodes(data);
  };
  useEffect(() => {
    getUserCodes();
  }, []);
  const [promotions, setPromotions] = useState([]);

  const getAllPromotions = async () => {
    const data = await getPromotions({});
    console.log('data', data);

    if (data) {
      setPromotions(data);
    }
  };

  useEffect(() => {
    const currentValue = resources.find((v) => v.resourceType === Resources.refPoints)?.value || 0;
    setRefBalance(currentValue);
  }, [resources]);

  useEffect(() => {
    getAllPromotions();
  }, []);

  const getLinkIcon = (url: string) => {
    if (url[0] === '@') return 'telegram.png';

    const socialMediaIcons = {
      'x.com': 'x.png',
      'twitter.com': 'twitter.png',
      't.me': 'telegram.png',
      'youtube.com': 'youtube.png',
      'facebook.com': 'facebook.png',
      'instagram.com': 'instagram.png',
      'tiktok.com': 'tiktok.png',
      'vk.com': 'vk.png',
      'twitch.tv': 'twitch.png',
    };

    for (const [domain, icon] of Object.entries(socialMediaIcons)) {
      if (url.includes(domain)) return icon;
    }

    return 'link.png';
  };

  const fetchProfileInfo = async () => {
    const userDeposites = await getUserDeposites({
      clientId: userId,
    });

    if (userDeposites) {
      console.log('userDeposites 1', userDeposites);
      setUserDepositedAmount(userDeposites.deposited);
    }

    const userOffersData = await getUserOffers({
      clientId: userId,
    });

    if (Array.isArray(userOffersData)) {
      setPurchasedOfferIds(
        new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
      );
    }
  };

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  useEffect(() => {
    const isNotPurchased = !purchasedOfferIds || purchasedOfferIds.size === 0;
    const isLowDeposit = userDepositedAmount < 50000;

    if (!isNotPurchased || !isLowDeposit) {
      setShouldHideButtons(false);
    } else {
      setShouldHideButtons(!isRefCodeAccess);
    }
  }, [purchasedOfferIds, userDepositedAmount, isRefCodeAccess]);

  const getFullLink = (link: string): string => {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      return `https://${link[0] === '@' ? 't.me/' + link.slice(1) : link}`;
    }
    return link;
  };

  // useEffect(() => {
  //   if (typeof userDepositedAmount !== 'number' || !purchasedOfferIds || typeof isRefCodeAccess === 'undefined') return;
  //   const isNotPurchased = !purchasedOfferIds || purchasedOfferIds.size === 0;
  //   const isLowDeposit = userDepositedAmount < 50000;

  //   if (!isNotPurchased || !isLowDeposit) {
  //     setShouldHideActivate(true);
  //   } else {
  //     setShouldHideActivate(isRefCodeAccess);
  //   }
  // }, [purchasedOfferIds, userDepositedAmount, isRefCodeAccess]);

  console.log('purchasedOfferIds', purchasedOfferIds);
  console.log('userDepositedAmount', userDepositedAmount);
  console.log('isRefCodeAccess', isRefCodeAccess);
  console.log('Promotions', promotions);

  return (
    <div className="absolute w-full h-full flex flex-col items-center justify-center min-w-full max-w-full min-h-full max-h-full">
      {openedDepositModal ? null : (
        <motion.div
          initial={{ transform: 'translateY(200%)', opacity: '0' }}
          animate={{ transform: 'translateY(0%)', opacity: '1' }}
          exit={{ transform: 'translateY(100%)' }}
          transition={{ delay: 0.3 }}
          className={`w-full h-full flex flex-col items-center justify-center`}
        >
          {!openedDepositModal && shouldHideButtons ? null : (
            <div className="absolute flex justify-start items-start w-full p-2  top-44">
              <HandleBackButton
                onClick={() => {
                  setShouldHideElement(true);
                  setOpenedDepositModal(true);
                  window.location.hash = '';
                }}
              />
            </div>
          )}
          {isRefCodeAccess && (
            <img
              className="w-[282px] mt-[25px]"
              src={require('../../../../../assets/images/code-buyers-header.png')}
              alt=""
            />
          )}
          {!isRefCodeAccess && <h2 className="text-white text-[24px] text-stroke-small">Activate full version</h2>}

          <div
            className={`relative flex flex-col justify-center items-center rounded-[2px] gap-2 border-[#7b7253] border-[2px]
            w-[77%] bg-[#231e1a] p-4 ${isRefCodeAccess ? 'mb-[6rem]' : 'mb-[45px'} ${userCodes.length ? 'max-h-[59vh]' : ''}`}
          >
            {secondsLeft === 0 || isRefCodeAccess ? (
              <button
                className="absolute -top-4 -right-4 text-white z-10 w-8 h-8"
                onClick={() => {
                  playSound('button');
                  setShouldHideElement(true);
                }}
              >
                <img
                  src={require('../../../../../assets/images/shop-modal-close.png')}
                  className="w-full h-full"
                  alt=""
                />
              </button>
            ) : (
              <div
                className="text-white rounded-full w-8 h-8 flex justify-center items-center border border-[#000] bg-[#ad2525]
              absolute -top-4 -right-4 z-10 leading-none text-[16px]"
              >
                <p className="h-[15px]">{secondsLeft}</p>
              </div>
            )}
            {!isRefCodeAccess && (
              <>
                <div className="flex w-full max-w-[305px] justify-between gap-3 items-start">
                  <div className="flex-1 flex flex-col gap-1 max-w-[120px]">
                    <img
                      src={require('../../../../../assets/images/activation/activation-image1.webp')}
                      alt=""
                      className="w-full h-auto max-w-[120px] rounded-lg"
                    />
                    <h3 className="text-white text-[16px] text-stroke-small text-center">KITSU farm - ON</h3>
                  </div>
                  <div className="flex-1 flex flex-col gap-1 max-w-[120px]">
                    <img
                      src={require('../../../../../assets/images/activation/activation-image2.webp')}
                      alt=""
                      className="w-full h-auto max-w-[120px] rounded-lg"
                    />
                    <h3 className="text-white text-[16px] text-stroke-small text-center">
                      Activation <br /> banner - OFF
                    </h3>
                  </div>
                </div>
                <div className="w-full h-fit flex flex-col">
                  <span className="w-full h-[2px] bg-[#191716]"></span>
                  <span className="w-full h-[2px] bg-[#3B332E]"></span>
                </div>
                {promotions.length && !isRefCodeAccess ? (
                  <h4 className="text-white text-[16px] text-stroke-small text-center">Ask Code in this groups</h4>
                ) : null}
              </>
            )}

            {isRefCodeAccess && (
              <div className="text-center text-white text-[16px] font-black leading-normal">
                {t('codePurchase.mainModalTitle')}
              </div>
            )}
            <div
              className="w-full overflow-auto max-h-[300px]"
              style={{
                maxHeight: `calc(100vh - 520px - ${safeAreaTop}px)`,
              }}
            >
              {promotions.length ? (
                promotions
                  .sort((a: any, b: any) => b.id - a.id)
                  .map((promotion: any, index) => {
                    const image = require('../../../../../assets/images/social/' + getLinkIcon(promotion.link));
                    return (
                      <a
                        className="w-full flex items-center bg-[#473D37] p-2 rounded-[2px] shadow-inner-sm-white
                        relative mb-1"
                        target="_blank"
                        rel="noreferrer"
                        href={`${getFullLink(promotion.link)}`}
                      >
                        <div
                          className="flex justify-center items-center w-12 h-12 min-w-12 bg-[#242520] rounded-[3px]
                          border border-[#19191B] p-[2px] mr-2"
                        >
                          <img className="w-full h-full object-contain block rounded-sm" src={image} alt="kitsu" />
                        </div>
                        <div className="flex flex-col items-start gap-2" style={{ width: 'calc(100% - 116px)' }}>
                          <div
                            className="text-white text-[14.89px] font-black leading-[14.89px] text-stroke-small
                          overflow-hidden text-ellipsis w-full whitespace-nowrap"
                          >
                            {promotion.title}
                          </div>
                          <div
                            className="text-[#4096ff] text-[12px] font-black leading-[10px]
                          overflow-hidden text-ellipsis w-full whitespace-nowrap"
                          >
                            {makeNiceLink(promotion.link)}
                          </div>
                        </div>
                        <div
                          className={`absolute right-2 w-12 h-12 min-w-12 flex justify-center items-center border
                        border-white rounded-[3px] bg-gradient-to-bl ${
                          promotion.amount > 51
                            ? 'from-[#fffe2e] to-[#fe8315]'
                            : promotion.amount > 11
                              ? 'from-[#d95bd8] to-[#9245df]'
                              : 'from-[#33a8cc] to-[#0d81b6]'
                        }`}
                        >
                          <img src={require('../../../../../assets/images/ticket-icon.png')} className="w-[48px]" />
                          <div className="absolute text-stroke-small bottom-0.5 right-1 text-[12px] text-white leading-none">
                            {promotion.amount ? promotion.amount : 0}
                          </div>
                        </div>
                      </a>
                    );
                  })
              ) : (
                <div className="text-white py-10 text-center">{t('tutorial.modals.empty')}</div>
              )}
            </div>
          </div>

          <div className="flex flex-col items-center mt-6 mb-4 gap-4 w-[90%]">
            {userCodes.length || isRefCodeAccess ? null : (
              <>
                <div className="flex justify-center items-center gap-2 w-full ">
                  {gamePassTicketOffer ? (
                    <PopupButton
                      type="green"
                      size="medium"
                      className="flex-1"
                      onClick={() => setOpenEnterCodePopup(true)}
                    >
                      <div className="flex justify-center items-center gap-1 px-3 whitespace-nowrap">
                        <div className="text-[20px] text-white">{t('codePurchase.activate')}</div>
                      </div>
                    </PopupButton>
                  ) : null}

                  <div className="text-[18px] text-white">{t('codePurchase.or')}</div>

                  <PopupButton
                    type="blue"
                    size="medium"
                    className="flex-1 relative"
                    onClick={() => setOpenedDepositModal(true)}
                  >
                    <div className="text-[18px] text-white">{t('codePurchase.getCode')}</div>
                    {refBalance >= 50000 ? (
                      <img
                        className="absolute top-0 right-0 w-3 h-3"
                        src={require('../../../../../assets/images/heroes/cards/notification.png')}
                        alt="Notification"
                      />
                    ) : null}
                  </PopupButton>
                </div>
              </>
            )}
          </div>
          {userCodes.length || isRefCodeAccess ? (
            <div className="absolute  bottom-0 flex flex-col text-[#dfd9c3] text-center text-[14px] w-full">
              <div className="bg-[#1b1b1d] border border-[#46372f] overflow-auto pb-3 border-t-[4px]">
                <div className="flex justify-center items-center p-2 gap-3">
                  <div className="text-white text-[15px] font-black leading-none">{t('codePurchase.tapToPromote')}</div>
                  <div className="flex justify-center items-center gap-1 whitespace-nowrap">
                    <div className="w-6 h-6">
                      <img className="w-full h-full" src={resourcesMap[Resources.promotions].image} />
                    </div>
                    <div className="text-center text-white text-xl font-black leading-tight">{userPromotions}</div>
                  </div>
                  <div className="relative" onClick={() => setPromotionsTooltip(!promotionsTooltip)}>
                    <img src={require('../../../../../assets/images/tooltip-icon.png')} className="w-5 h-5" alt="" />
                    <div
                      className="absolute w-[166px] text-center text-[10px] font-bold bg-white border border-[#45362e]
                        rounded-[4px] leading-[1.2] p-2 mx-auto text-black top-full mt-2 -right-2 z-[2]"
                      style={{
                        opacity: promotionsTooltip ? '1' : '0',
                        display: promotionsTooltip ? 'block' : 'none',
                      }}
                      onClick={() => setPromotionsTooltip(false)}
                    >
                      <div
                        className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
              border-r-transparent border-b-[#45362e] bottom-full right-[6%]"
                      ></div>
                      <div
                        className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
              border-r-transparent border-b-white bottom-full right-[6%] -mb-[1px]"
                      ></div>
                      {t('codePurchase.promotionsTooltip')}
                    </div>
                  </div>
                </div>

                {userCodes.length ? (
                  <div className="overflow-auto px-1.5 pt-1">
                    <div className=" bg-black/20 rounded-md justify-start items-start inline-flex">
                      {userCodes.map((code: any, index: number) => {
                        return (
                          <div
                            key={code.id}
                            className={`flex justify-center items-start p-[5px]`}
                            onClick={() => setOpenUseCodeToPromotePopup(code)}
                          >
                            <div
                              className={`relative w-[70px] h-[70px] min-w-12 flex flex-col border border-white 
                              bg-gradient-to-b rounded-md py-1 px-1 ${
                                code.type === 0
                                  ? 'from-[#33a8cc] to-[#0d81b6]'
                                  : code.type === 1
                                    ? 'from-[#d95bd8] to-[#9245df]'
                                    : code.type === 2
                                      ? 'from-[#fffe2e] to-[#fe8315]'
                                      : ''
                              }`}
                            >
                              {code.isPromoting ? (
                                <>
                                  <img
                                    src={require('../../../../../assets/images/promotion-edit-icon.png')}
                                    className="w-5 absolute top-0.5 left-1"
                                    alt=""
                                  />
                                  <div
                                    className="absolute uppercase -right-2 -top-2 bg-[#3ebe87] border border-black
                                    rounded-[16px] text-stroke-small text-[10px] text-white leading-none py-0.5 px-2"
                                  >
                                    live
                                  </div>
                                </>
                              ) : null}
                              <div className="flex justify-center items-center w-full">
                                <img
                                  className="w-[68px]"
                                  src={require('../../../../../assets/images/ticket-icon.png')}
                                  alt="Ticket Icon"
                                />
                              </div>

                              <div
                                className="absolute right-1 bottom-1 text-right text-white text-[17px] font-bold
                                leading-3 text-shadow-black-sm"
                              >
                                {code.amount}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="overflow-auto px-1.5 pt-1">
                    <p className="text-[20px] py-5 font-bold text-[#454242]">Your Codes will be here</p>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </motion.div>
      )}

      {openEnterCodePopup ? (
        <>
          <div className="absolute min-w-full min-h-full bg-[rgba(0,0,0,0.8)] z-10"></div>
          <div className="z-10">
            <EnterCodePopup />
          </div>
        </>
      ) : null}

      {openUseCodeToPromotePopup !== null ? (
        <>
          <div className="absolute min-w-full min-h-full bg-[rgba(0,0,0,0.8)] z-[2]"></div>
          <div className="z-10">
            <UseCodeToPromotePopup getAllPromotions={getAllPromotions} />
          </div>
        </>
      ) : null}

      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </div>
  );
};
