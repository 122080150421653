import { PopupButton } from "../../../../../components/PopupButton";
import { getBalance } from "../../../../../endpoints/farmMock";
import { claimGrowthFundTask } from "../../../../../endpoints/growthFundEndpoints";
import { useTelegram } from "../../../../../hooks/useTelegram";
import { GrowthFundOfferStatus } from "../GrowthFund";

export const GrowthFundOffer = ({
  isAvailable,
  lvl,
  kitsu,
  isEnabled,
  onClaim,
  id,
  status,
  onRevalidate,
  onPopupOpen,
}: {
  isAvailable: boolean;
  lvl: number;
  kitsu: number;
  isEnabled: boolean;
  onClaim?: () => void;
  id: number;
  status: number;
  onRevalidate?: () => void;
  onPopupOpen: () => void;
}) => {
  const { userId } = useTelegram();
  const claimTask = async (taskId: number) => {
    await claimGrowthFundTask({ clientId: userId, taskId: taskId });
    await getBalance({ clientId: userId });
  };

  const handleClaim = (taskId: number) => {
    claimTask(taskId);
    if (onClaim && onRevalidate) {
      setTimeout(() => {
        onClaim();
        onRevalidate();
      }, 500);
    }
  };

  const preloadedImg =
    id < 4
      ? require("../../../../../assets/images/offers/offer-1.png")
      : id < 9
      ? require("../../../../../assets/images/offers/offer-2.png")
      : id < 10
      ? require("../../../../../assets/images/offers/offer-4.png")
      : id < 11
      ? require("../../../../../assets/images/offers/offer-5.png")
      : require("../../../../../assets/images/offers/offer-0.png");
  return (
    <>
      <div
        className={`relative ${!isEnabled ? "" : ""} ${
          status === GrowthFundOfferStatus.claimed ? "grayscale order-last " : ""
        } flex w-full h-[80px] rounded-md p-[3px] bg-[#f28a2f]`}
      >
        <div
          style={{ clipPath: "polygon(0 0, 100% 0, 75% 100%, 0% 100%)" }}
          className="relative w-[84px] rounded-l-md flex items-center bg-[#c8742c]"
        >
          <img className={`w-[80%] h-[80%]`} src={preloadedImg} alt="" />
        </div>
        <div className={`flex flex-col justify-center`}>
          <div className={`text-white font-900 text-[14px]`}>Upgrade SHRINE to Lvl. {lvl} </div>
          <div className={`text-white text-stroke-small text-[14px] font-[900]`}>
            Available <span className={`text-[#fdc924]`}>{kitsu} $KITSU</span>
          </div>
        </div>
        <div className={`flex justify-end items-center grow`}>
          {isAvailable && (
            <PopupButton 
            onClick={() => {
              if(isAvailable && isEnabled){
                handleClaim(id)
              }else{
                onPopupOpen()
              }
            } } type="green">
              {status === GrowthFundOfferStatus.available?'Claim':'Claimed'}
            </PopupButton>
          )}
          {!isAvailable && (
            <div className={`w-[57px] h-[57px]`}>
              <img
                className="w-full h-full"
                src={require("../../../../../assets/images/shop/offer/growthFund-offer-locked.png")}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
