import { CardImage, TextColor } from "../components/Card/CardComponentBuilder";
import { Items } from "../mock/offers";
import { BattleEffectsData } from "../pages/Battle/EnemyBattleData";

export interface BattleInit {
  maxHp: number;
  currentHp: number;
  maxMana: number;
  defense: number;
  heroId: number;
  battleEffectsData: BattleEffectsData[][];
  cardsAtDeck: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  cardsAtHand: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  actionPoints: number;
  enemy: {
    id: number;
    lvl: number;
    currentHp: number;
    maxHp: number;
    maxMana: number;
    defense: number;
    battleEffectsData: BattleEffectsData[][];
    cardsAtHand: [
      {
        id: number;
        uid: string;
        lvl: number;
      }
    ];
  };
}

export enum ExecutionActionType {
  addEffect, // накалдываем бафф/дебафф
  executeEffect, // исполняется еффект бафф/дебаффа
  removeEffect, // удаляем бафф/дебафф
  executeSkill, // исполняем скилл
  changeParameters, // добавление/уменьшения хп/маны....
  updateCards, // обновляем карты в руке
  discardCards, // сбрасываем карты
  shuffleCards, // перемешиваем карты
  noEffect, // никакого эффекта для карты пранка
}

export enum Opponent {
  hero,
  enemy,
}

export enum Parameter {
  health,
  mana,
  actionPoints,
  defense,
}

export enum CardType {
  atk,
  equip,
  spell = 3,
}

// export interface MakeAction {
//   type: ExecutionActionType;
//   data: {
//     effectUid?: string;
//     effectId?: number;
//     durationInRounds?: number;
//     skillId?: string;
//     target: Opponent;
//     parameters?: {
//       parameter: Parameter,
//       value: number;
//     }
//   }
// }

export interface HeroCards {
  cardId: number;
  stars: number;
}

export enum HeroType {
  warrior,
  wizard,
}

export const heroConfig: HeroConfig[] = [
  {
    id: 0,
    type: HeroType.warrior,
    hp: 100,
    mana: 100,
    cards: [],
    lvl: 1,
  },
];

export interface HeroConfig {
  id: number;
  type: HeroType;
  hp: number; //начальные х-ки
  mana: number; //начальные х-ки
  cards: HeroCards[];
  lvl: number;
}

export type Action = | {
  type: ExecutionActionType;
  effectUid: string;
  effectId: number;
  duration: number;
  target: Opponent;
}
| {
  type: ExecutionActionType;
  parameter: Parameter;
  value: number;
  target: Opponent;
}
| {
  type: ExecutionActionType;
  target: Opponent;
  skillId: string;
}
| {
  type: ExecutionActionType;
  target: Opponent;
  cardIds: number[];
}
| {
  type: ExecutionActionType;
  target: Opponent;
  value: number;
  cardIds: number[];
}
| {
  type: ExecutionActionType;
  target: Opponent;
  value: number;
};


export type MakeAction = {
  actions: Action[],
  battleEffectsData: any,
  enemyBattleEffectsData: any,
}

export type Effect = {
  effectUid: string;
  effectId: number;
  duration: number;
  amount?: number
  target: Opponent;
}

export interface EndTurn {
  steps: {
    cardInfo?: { cardId: number; lvl: number; uid: string };
    actions: Action[];
  }[];
  battleEffectsData: BattleEffectsData[][];
  cardsAtHand: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  cardsAtDeck: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  enemy: {
    battleEffectsData: BattleEffectsData[][];
    cardsAtHand: [
      {
        id: number;
        uid: string;
        lvl: number;
      }
    ];
  };
}

// enum CardType {
//   atk
// act
// equip
// spell
// }

export enum ConditionParameter {
  health,
  mana,
  actionPoints,
  defense,
}

export enum CalculationMethod {
  percent,
  count,
}

export enum ConditionSide {
  less,
  more,
}

export enum StageType {
  enemy,
  elixir,
  shop,
  forge,
  bonfire,
  abyss,
  boss,
  chest,
  random,
  endWay,
  secretroom
}

export enum AwardType {
  gold,
  card,
  mana,
  health,
}

export interface DungeonStagesResponse {
  id: number;
  stageId: number;
  type: StageType;
  amount: number;
  currentAmount: number;
}

export type DungeonStage =
  | {
      id: number; //хилка
      type: StageType;
      amount: number;
      lvl?: number;
      title: string;
      description: string;
      currentAmount: number;
      percent?: number;
      pool?: StageType[];
      buildingId?: number;
    }
  | {
      //батл
      id: number;
      type: StageType;
      enemyId: number;
      lvl?: number;
      title: "";
      description?: string;
      buildingId?: number;
      pool?: StageType[];
    }
  | {
      id: number; // сундук
      type: StageType;
      awardType: AwardType;
      title: string;
      description?: string;
      buildingId?: number;
      lvl?: number;
      rewardAmount?: number;
      percent?: number;
      pool?: StageType[];
    }
  | {
      id: number; // магазин/кузница/таверна/сундук
      type: StageType;
      title: string;
      description?: string;
      buildingId?: number;
      lvl?: number;
      percent?: number;
      pool?: StageType[];
    }
  | {
      id: number; // random
      type: StageType;
      description?: string;
      lvl?: number;
      buildingId?: number;
      poolId?: StageType[];
    };

export interface DungeonType {
  dungeonId: number;
  description: string;
  stages: DungeonStage[];
}

interface DungeonStageConfig {
  id: { value: number };
  amount?: { value: number };
  currentAmount?: { value: number };
  enemyId?: { value: number };
  awardType?: { value: AwardType };
  rewardAmount?: { value: number };
  title?: { value: string };
  percent?: { value: number };
  pool?: { value: StageType[] };
  type: { value: StageType };
  description?: { value: string };
  lvl?: { value: number };
  buildingId?: { value: number };
  poolId?: { value: StageType[] };
}

interface DungeonUnlockConditions {
  buildingId: { value: number };
  level: { value: number };
}

export interface DungeonTypeConfig {
  description: { value: string };
  dungeonId: { value: number };
  rewards: { value: Items[] };
  stages: { value: DungeonStageConfig[] };
  conditions?: { value: DungeonUnlockConditions[] };
}

export interface BattleSaves {
  dungeonId: number;
  stages: DungeonStagesResponse[];
  enemyId?: number;
  currentHp: number;
  currentMana: number;
  gold: number;
  currentStage?: number;
  buildingId?: number;
  bossId?: number;
}

//export const dungeonConfig: DungeonType[] = [];
// dungeonId: 1,
//   stages: [
//     {
//       type: StageType.health,
//       amount: 2,
//     },
//     {
//      type: StageType.enemy,
//       enemyId: 0,
//     },
//     {
//       type: StageType.chest,
//      awardType: AwardType.gold,
//       rewardAmount: 10,
//     },
//     {
//       type: StageType.health,
//       amount: 2,
//     },
//     {
//       type: StageType.enemy,
//       enemyId: 0,
//     },
//     {
//       type: StageType.chest,
//       awardType: AwardType.gold,
//       rewardAmount: 10,
//     },
//     {
//       type: StageType.health,
//       amount: 2,
//     },
//     {
//       type: StageType.boss,
//       enemyId: 1,
//     },
//     {
//       type: StageType.chest,
//       awardType: AwardType.gold,
//       rewardAmount: 10,
//     },
//   ]
// }];

export enum EffectType {
  defense,
  triggeredByEnemiesAttackCard,
  doubleDamage,
  returnDamage,
  damage,
  repeatableDamage,
  getCard,
  shuffle,
  discard,
  gainMaxHp,
  lessCardPerRound,
  heal,
  piercingDamage,
  negateAttack,
  damageEqualPercentCurrentHealth,
  exiled,
  addActionPoint,
  copyAllFromHandByTypeAndShuffle,
  lessDamage,
  additionalDamage,
  additionalDefense,
  returnDamageByHandSize,
  damagePerLostHP, // - убрано
  damagePerEquiped, // 23 - убрано
  copyPreviousEffects, // 24 - убрано
  viewCardsandKeep, // view - value, keep - animationCount, card 37, 39, новые
  drawCardPerUseATK, // amount - value не используется
  transformLeftmostAttackToCopy, // card 40, новые
  shuffleTempCopiesOfAttack, // card 41, новые
  reduceCost, // - -1 cost, на зеленых
  priceManna, // todo old by dima
  getRandomCardFromDeck, //
  gainRandomTempAttack,
  getRandomConfigCard, // todo new for green 12
  damagePerArtefact, // five red
  viewCardConfig,
  viewCardDeck,
  // getRandomNotArtefactCard
  grimHarvest,
  recklessSlash,
  getBasicAttack,
  weightOfSteel,
  deathSentence,
  minusManaPerPower,
  addMana,
  arcaneEcho,
  mindBreak,
  spectralOnslaught,
  getCardNotArtefact,
  getCardDefence,
  equipCardArtefact,
  dealDamageByHandSize,
  restoreHealthByDamage,
  cloneBasicAttack,
  doubleEffects,
  noEffect,
  echoStrike,
  harlequinsTrick,
}

export type level = {
  value: number;
  target: "enemy" | "self";
  effectType: number;
  animationCount?: number;
};

export enum ArtefactActionType {
  startTurn,
  endTurn,
  trigger,
}

export enum TriggerType {
  none,
  cardType,
  effect,
}

export enum TriggerCondition {
  playAttackCard,
  gettingDamageOnAttackersTurn,
  playActionCard,
  enemyAttack,
  copyBasicAttackAfterUse,
  dealDamageAmount,
  playCard,
}

export interface ISkill {
  value: number;
  target: Opponent;
  duration: number;
  cost: number;
  effectType: EffectType;
  roundAmount?: number;
  animationCount: number;
  // condition?: {
  //   target: Opponent;
  //   parameter: ConditionParameter;
  //   value: number;
  //   calculationMethod: CalculationMethod;
  //   conditionSide: ConditionSide;
  // }
}

export interface ArtefactOptions {
  artefactActionType: ArtefactActionType;
  triggerType?: TriggerType;
  triggerValue: number;
  triggerCondition?: TriggerCondition;
  cardType?: CardType;
}

// export interface CardsConfig {
//   id: number;
//   data: {
//     type: CardType;
//     name: string;
//     description: string;
//     stars: number;
//     image: CardImage;
//     priceManna?: number;
//     actionPoints?: number;
//     draggable?: boolean;
//     condition?: {
//       target: Opponent;
//       parameter: ConditionParameter;
//       value: number;
//       calculationMethod: CalculationMethod;
//       conditionSide: ConditionSide;
//     };
//   };
//   levels?: level[][];
// }

export interface ICard {
  id: number;
  energyType: number;
  cardType: number;
  image: CardImage;
  description: string;
  cost: number;
  upgrades: number;
  isExiled?: boolean;
  draggable?: boolean;
  stars: {
    skills: ISkill[];
    cost: number;
  }[];
  name: string;
  artefactOptions?: ArtefactOptions;
  probability: number;
  condition?: {
    target: Opponent;
    parameter: ConditionParameter;
    value: number;
    calculationMethod: CalculationMethod;
    conditionSide: ConditionSide;
  };
}

export const mockCards: ICard[] = [
  {
    id: 0,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.STONE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 1,
          },
        ],
        cost: 0,
      },
    ],
    name: "ROCK",
    description: `Deallll 2 {${TextColor.RED}:{${EffectType.damage}} dmg}`,
    probability: 1,
  },
  {
    id: 8, // DOUBLE ID
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.PRANK,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 1,
          },
        ],
        cost: 0,
      },
    ],
    name: "Prank",
    description: "Probably nothings",
    probability: 1,
  },
  {
    id: 9,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.GRIM_HARVEST,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 3,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 1,
          },
        ],
        cost: 0,
      },
    ],
    name: "Grim Harvest",
    description: `Deal {${TextColor.RED}:{${EffectType.damage}} dmg}. Kill: Gain {${EffectType.gainMaxHp}} max health`,
    probability: 1,
  },
  {
    id: 17,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.RECKLESS_SLASH,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 3,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 1,
          },
        ],
        cost: 0,
      },
    ],
    name: "Reckless Slash",
    description: `Deal {${TextColor.RED}:{${EffectType.damage}} dmg} +1 per for 5 lost HP`,
    probability: 1,
  },
  {
    id: 6,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.DESPERATION_STRIKE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 9,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 1,
          },
        ],
        cost: 0,
      },
    ],
    name: "Desperation Strike",
    description: `Deal {${TextColor.RED}:{${EffectType.damage}} dmg}. {${TextColor.WHITE}:{selfPierce}}`,
    probability: 1,
  }, // {t('cards.9', {textColor: TextColor.RED})}
  {
    id: 18,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.EXILED_FURY,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 3,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 1,
          },
        ],
        cost: 0,
      },
    ],
    name: "Exiled Fury",
    description: `Exiled. Deal {${TextColor.RED}:{${EffectType.exiled}} dmg}. Get a level 3 Basic Attack`,
    probability: 1,
  },
  {
    id: 19,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.WEIGHT_OF_STEEL,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 2,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 1,
          },
        ],
        cost: 0,
      },
    ],
    name: "Weight of Steel",
    description: `Deal {${TextColor.RED}:{dmg} dmg} per equipped item`,
    probability: 1,
  },
  {
    id: 1,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.TRIPLE_SCAR,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Triple Scar",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.WHITE}:Repeats {repeats} times}`,
    probability: 1,
  },
  {
    id: 15,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.FROST_BITE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 12,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Frost Bite",
    description: `Deal {${TextColor.RED}:{dmg} dmg}`,
    probability: 1,
  },
  {
    id: 20,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.TRICKSTERS_GAMBIT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Trickster's Gambit",
    description: "Shuffle {shuffle} Pranks into enemy deck",
    probability: 1,
  },
  {
    id: 4,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.DEATH_SENTENCE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Death Sentence",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. If <30% HP, deal {${TextColor.RED}:{conditionalDmg} dmg}`,
    condition: {
      target: Opponent.enemy,
      parameter: ConditionParameter.health,
      value: 30,
      calculationMethod: CalculationMethod.percent,
      conditionSide: ConditionSide.less,
    },
    probability: 1,
  },
  {
    id: 13,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.BORROWED_INSIGHT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Borrowed Insight",
    description: `Draw {drawCards} cards. {${TextColor.WHITE}:Next turn: -{reduceCards} card}`,
    probability: 1,
  },
  {
    id: 11,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.SHADOW_SUPPRESSION,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 3,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Shadow Suppression",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.WHITE}:Both draw 1 less card}`,
    probability: 1,
  },
  {
    id: 22,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.STORMCALLERS_WRATH,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 4,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Stormcaller's Wrath",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.RED}:+{extraDmg} dmg} this turn`,
    probability: 1,
  },
  {
    id: 23,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.SHIELD_BASH,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 5,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Shield Bash",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. Take 1 less dmg till next turn`,
    probability: 1,
  },
  {
    id: 21,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.GHOST_PIERCER,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 6,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Ghost Piercer",
    description: `Deal {${TextColor.RED}:{dmg} piercing dmg}`,
    probability: 1,
  },
  {
    id: 10,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.MIND_RIPPER,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 3,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Mind Ripper",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. Draw 1 card`,
    probability: 1,
  },
  {
    id: 14,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.LIFE_DRAIN,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 3,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Life Drain",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. Restore same HP`,
    probability: 1,
  },
  {
    id: 36,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.ECHO_STRIKE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Echo Strike",
    description: "Copy previous attack card's effects.",
    probability: 1,
  },
  {
    id: 12,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.BLADES_DANCE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 2,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Blades Dance",
    description: `Deal {${TextColor.RED}:{dmg} dmg} {${TextColor.WHITE}:and draw 2 cards}`,
    probability: 1,
  },
  {
    id: 24,
    energyType: 0,
    cardType: CardType.atk,
    image: CardImage.SHADOW_PARRY,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 2,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Shadow Parry",
    description: `Negate enemy attack. {${TextColor.RED}:2 dmg}. Draw 1 card`,
    probability: 1,
  },
  {
    id: 25,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.SANGUINE_PACT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Sanguine Pact",
    description: "Deal dmg equal to {percent}% of your health.",
    probability: 1,
  },
  {
    id: 26,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.OBSIDIAN_GUARD,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 8,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Obsidian Guard",
    description: `Gain {${TextColor.GREEN}:Armor +{armor}}, {${TextColor.WHITE}:take 1 pierce dmg}`,
    probability: 1,
  },
  {
    id: 2,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.BOLSTERED_DEFENSES,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 6,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Bolstered Defenses",
    description: `Gain {${TextColor.GREEN}:{armor} Armor}. {${TextColor.WHITE}:Draw a card.}`,
    probability: 1,
  },
  {
    id: 27,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.STONEHOLD_BARRIER,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 8,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Stonehold \\n Barrier",
    description: "Gain {armor} Armor.",
    probability: 1,
  },
  {
    id: 37,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.FORSAKEN_KNOWLEDGE,
    cost: 0,
    upgrades: 0,
    isExiled: true,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Forsaken Knowledge",
    description: "Exiled. View 3 cards, keep 1 permanently.",
    probability: 1,
  },
  {
    id: 16,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.QUICKDRAW_STRATEGY,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Quickdraw Strategy",
    description: "Draw {drawCards} cards. Cost -1 Action  per power.",
    probability: 1,
  },
  {
    id: 28,
    energyType: 1,
    cardType: CardType.spell,
    image: CardImage.VOIDSTRIKE,
    cost: 0,
    upgrades: 0,
    isExiled: true,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Voidstrike",
    description: `Exiled, {${TextColor.GREEN}:+{mana} Mana,} {${TextColor.RED}:{selfPierce} pierce dmg}, {${TextColor.WHITE}:draw 1 card}`,
    probability: 1,
  },
  {
    id: 38,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.ARCANE_ECHO,
    cost: 0,
    upgrades: 0,
    isExiled: true,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Arcane Echo",
    description: `{${TextColor.WHITE}:Exiled. This turn,} {${TextColor.WHITE}:draw 1 card} per {${TextColor.GREEN}:use ATK card}`,
    probability: 1,
  },
  {
    id: 29,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.MIRAGE_ASSAULT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Mirage Assault",
    description: `Copy all {${TextColor.GREEN}:ATK} cards, shuffle into deck. {${TextColor.WHITE}:Draw 1 card}`,
    probability: 1,
  },
  {
    id: 33,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.MINDBREAK,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Mindbreak",
    description: "Each draws {drawCards} cards. Enemy discards non-attacks.",
    probability: 1,
  },
  {
    id: 30,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.BLAZING_WRATH,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 20,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Blazing Wrath",
    description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.WHITE}:Exile this card}`,
    probability: 1,
  },
  {
    id: 34,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.WARRIORS_GAMBIT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Warrior's Gambit",
    description: "Draw {drawCards} card. Gain random temp attack.",
    probability: 1,
  },
  {
    id: 31,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.KEEN_INSIGHT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Keen Insight",
    description: `Next card {${TextColor.RED}:dmg is} {${TextColor.RED}:doubled} this turn`,
    probability: 1,
  },
  {
    id: 39,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.EXILED_BASTION,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Exiled Bastion",
    description: "View 3 your cards, copy 1 to hand.",
    probability: 1,
  },
  {
    id: 32,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.ASTRAL_WARD,
    cost: 0,
    upgrades: 0,
    isExiled: true,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 2,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Astral Ward",
    description: `Exiled. Gain {${TextColor.GREEN}:Armor +{armor}}, -{dmg} dmg for 2 turns`,
    probability: 1,
  },
  {
    id: 40,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.SPECTRAL_ONSLAUGHT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 4,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Spectral Onslaught",
    description: `{${TextColor.GREEN}:Armor +4}. Transform {${TextColor.WHITE}:leftmost attack} {${TextColor.WHITE}:to copy of this}`,
    probability: 0,
  },
  {
    id: 7,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.JESTERS_MALICE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Jester's Malice",
    description: "Shuffle {drawCards} Pranks into enemy deck. Enemy discards 1 next turn",
    probability: 1,
  },
  {
    id: 41,
    energyType: 0,
    cardType: CardType.spell,
    image: CardImage.HARLEQUINS_TRICK,
    cost: 0,
    upgrades: 0,
    isExiled: true,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Harlequin’s Trick",
    description: "Exiled. Shuffle 3 temp copies of attack into deck",
    probability: 1,
  },
  {
    id: 411,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.SLINGSHOT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Slingshot",
    description: `When use 2 attacks: Deal {${TextColor.RED}:{dmg} dmg}`,
    probability: 1,
  },
  {
    id: 42,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.BLADE_OF_VENGEANCE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Blade of Vengeance",
    description: `Take dmg on your turn: {${TextColor.GREEN}:Next your dmg is doubled}`,
    probability: 1,
  },
  {
    id: 43,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.SPIKED_SHIELD,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Spiked Shield",
    description: `{${TextColor.BROWN}:Start turn:} Take 1 pierce dmg, gain {armor} armor`,
    probability: 1,
  },
  {
    id: 44,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.ROGUES_SATCHEL,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Rogue's Satchel",
    description: `{${TextColor.BROWN}:Start turn:} Copy 1 random card to hand`,
    probability: 1,
  },
  {
    id: 45,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.POTION_OF_RAGE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Potion of Rage",
    description: `{${TextColor.BROWN}:When use attacks:} Deal {${TextColor.RED}:{dmg} dmg}`,
    probability: 1,
  },
  {
    id: 46,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.BAG_OF_PAIN,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Bag of Pain",
    description: `{${TextColor.BROWN}:Start turn:} Take {selfPierce} pierce dmg, draw {drawCards} card`,
    probability: 1,
  },
  {
    id: 47,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.SORCERERS_HORN,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Sorcerer's Horn",
    description: `{${TextColor.BROWN}:When Use Mana:} Next attack deals double dmg, {${TextColor.GREEN}:+1 Mana} next turn`,
    probability: 1,
  },
  {
    id: 48,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.MIRACLE_MIRROR,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Miracle Mirror",
    description: `{${TextColor.BROWN}:Use ACT:} {${TextColor.WHITE}:Enemy gets basic attack,} you draw {drawCards} card`,
    probability: 1,
  },
  {
    id: 35,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.SNAKE_TRAP,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Snake Trap",
    description: `{${TextColor.BROWN}:Each time enemy ATK:} Deal pierce dmg = hand size`,
    probability: 1,
  },
  {
    id: 50,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.BEAR_SNARE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Bear Snare",
    description: `{${TextColor.BROWN}:Start Enemy turn:} +{dmg} all dmg`,
    probability: 1,
  },
  {
    id: 51,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.CAGE_OF_FURY,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Cage of Fury",
    description: `{${TextColor.BROWN}:Enemy uses an attack} {${TextColor.BROWN}:card:} Takes {${TextColor.RED}:{dmg} dmg}`,
    probability: 1,
  },
  {
    id: 52,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.TOME_OF_CURSES,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Tome of Curses",
    description: `{${TextColor.BROWN}:Play Basic Attack:} Add temp copy to hand`,
    probability: 1,
  },
  {
    id: 53,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.GOBLET_OF_BLOOD,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Goblet of Blood",
    description: "Restore 1 health for every {dmg} damage you deal.",
    probability: 1,
  },
  {
    id: 54,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.ARMOR_OF_THE_WOLF,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Armor of the Wolf",
    description: `{${TextColor.BROWN}:Start turn:} Draw armor card, {${TextColor.GREEN}:+1 Mana}`,
    probability: 1,
  },
  {
    id: 55,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.SCYTHE_OF_DESPAIR,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Scythe of Despair",
    description: "Attacks take effect twice, discard left-most card.",
    probability: 1,
  },
  {
    id: 56,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.AMULET_OF_LIGHT,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 0,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Amulet of Light",
    description: `{${TextColor.BROWN}:End turn:} {${TextColor.WHITE}:Equip random item,} become Wind-Up Knight`,
    probability: 1,
  },
  {
    id: 57,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.WARRIORS_BREASTPLATE,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Warrior's Breastplate",
    description: "You take -{dmg} damage.",
    probability: 1,
  },
  {
    id: 3,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.STAFF_OF_ARCANE_POWER,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Staff of Arcane Power",
    description: `{${TextColor.BROWN}:End turn:} {${TextColor.RED}:{dmg} dmg} per power`,
    probability: 1,
  },
  {
    id: 5,
    energyType: 0,
    cardType: CardType.equip,
    image: CardImage.HELM_OF_VALOR,
    cost: 0,
    upgrades: 0,
    isExiled: false,
    draggable: true,
    stars: [
      {
        skills: [
          {
            value: 1,
            target: Opponent.enemy,
            duration: 1,
            cost: 0,
            effectType: EffectType.damage,
            roundAmount: 1,
            animationCount: 3,
          },
        ],
        cost: 0,
      },
    ],
    name: "Helm of Valor",
    description: `{${TextColor.BROWN}:Start turn:} {${TextColor.WHITE}:Gain +{armor} armor}`,
    probability: 1,
  },
  // {
  //   id: 5,
  //   data: {
  //     type: CardType.equip,
  //     name: "20Blue",
  //     description: "At the start of your turn gain Armor x2.",
  //     stars: 0,
  //     actionPoints: 0,
  //   },
  // },
  // last id: 58
];
