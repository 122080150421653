import React, { useEffect, useMemo, useState } from "react";
import { InviteBonuse } from "./components/InviteBonuse";
import { useTranslation } from "react-i18next";

import { useTelegram } from "../../hooks/useTelegram";
import {
  claimAllAvailableInfluencerRewards,
  claimPremiumReferralTask,
  claimRegularReferralTask,
  claimRevShare,
  createRequestForInfluencer,
} from "../../endpoints/refEndpoints";
import { PopupButton } from "../PopupButton";
import { resourcesMap } from "../../utils/mapping";
import Confetti from "react-confetti";
import { InfluencersApplication } from "./components";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import {useSoundService} from "../../utils/soundService";

export const InviteBonuses = ({
  regularReferralsAvailable,
  referralsEarn,
  userEarnFromRefs,
  onClaim,
  multiplier,
  isUserInfluencer,
  isUserInfluencerRequestPending,
  revShareInfluencer,
  toClaimBalanceInfluencer,
  totalInfluencerBalance,
  expandPopup,
}: {
  referralsEarn: number;
  userEarnFromRefs: number;
  regularReferralsAvailable: any;
  onClaim: () => void;
  multiplier: number;
  isUserInfluencer: boolean;
  isUserInfluencerRequestPending: boolean;
  revShareInfluencer?: number;
  toClaimBalanceInfluencer?: number;
  totalInfluencerBalance?: number;
  expandPopup: () => void;
}) => {
  const { t } = useTranslation();
  const address = useTonAddress();
  const { userId, tg } = useTelegram();
  const [confettiActive, setConfettiActive] = useState<boolean>(false);
  const [confettiDimensions, setConfettiDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: window.innerWidth, height: window.innerHeight });
  const { playSound } = useSoundService();

  const [isOpenInfluencersApplication, setIsOpenInfluencersApplication] =
    useState(false);

  const handleClaim = async () => {
    playSound('questsReward');
    try {
      await claimRegularReferralTask({ clientId: userId });
      onClaim();

      // Активуємо конфетті
      setConfettiActive(true);

      // Вимірюємо розміри вікна для конфетті
      setConfettiDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      // Вимикаємо конфетті через 3 секунди
      setTimeout(() => {
        setConfettiActive(false);
      }, 1500);
    } catch (error) {
      console.error("Error claiming task:", error);
    }
  };

  const handleClaimRef = async () => {
    playSound('questsReward');
    try {
      await claimRevShare({
        clientId: userId,
      });

      onClaim();

      // Активуємо конфетті
      setConfettiActive(true);

      // Вимірюємо розміри вікна для конфетті
      setConfettiDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      // Вимикаємо конфетті через 3 секунди
      setTimeout(() => {
        setConfettiActive(false);
      }, 3000);
    } catch (error) {
      console.error("Error claiming task:", error);
    }
  };

  const handleInviteClick = () => {
    const url = `t.me/DungeonsOfKitsune_bot/game?startapp=ref_${userId}`;

    if (tg) {
      tg.openTelegramLink(`https://t.me/share/url?url=${url}`);
    }
  };

  const handleOpenInfluencersApplication = () => {
    setIsOpenInfluencersApplication(true);
  };

  const handleFillInfluencersApplication = async ({
    link,
    about,
  }: {
    link: string;
    about: string;
  }) => {
    try {
      const data = await createRequestForInfluencer({
        clientId: userId,
        link: link,
        about: about,
      });
      if (data) {
        setIsOpenInfluencersApplication(false);
        setTimeout(() => {
          window.location.hash = '';
        }, 0);
      }
      onClaim();
    } catch (e) {
      console.error("Error createRequestForInfluencer:", e);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleclaimAllAvailableInfluencerRewards = async () => {
    try {
      await claimAllAvailableInfluencerRewards({
        clientId: userId,
      });

      onClaim();

      // Активуємо конфетті
      setConfettiActive(true);

      // Вимірюємо розміри вікна для конфетті
      setConfettiDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      // Вимикаємо конфетті через 3 секунди
      setTimeout(() => {
        setConfettiActive(false);
      }, 3000);
    } catch (e) {
      console.error("Error claimAllAvailableInfluencerRewards:", e);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setConfettiDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [openModal, setOpenModal] = useState(false);


  const tasks = [
    {
      taskDescription: t("rewards.titles.commonFriend", {
        count: 1,
      }),
      eligibleClaim: !!regularReferralsAvailable,
      claimFunc: () =>
        !!regularReferralsAvailable ? handleClaim() : handleInviteClick(),
      regularReferralsAvailable,
    },
    {
      taskDescription: t("rewards.titles.percentageReward", {
        name: "$KITSU",
        percentage: "20%",
      }),

      eligibleClaim: !!userEarnFromRefs,
      claimFunc: () =>
        !!userEarnFromRefs ? handleClaimRef() : handleInviteClick(),
      multiplier,
      userEarnFromRefs,
    },
    {
      taskDescription: isUserInfluencer ? "Available Balance:" : "Influencer?",
      taskDescription2: isUserInfluencer
        ? "Total Balance:"
        : "Let’s Make a Deal!",
      eligibleClaim: isUserInfluencer ? !!toClaimBalanceInfluencer && toClaimBalanceInfluencer > 10 : false,
      isUserInfluencer,
      isUserInfluencerRequestPending,
      revShareInfluencer,
      toClaimBalanceInfluencer,
      totalInfluencerBalance,
      claimFunc: () =>
        isUserInfluencer
          ? !!toClaimBalanceInfluencer && toClaimBalanceInfluencer > 10
            ? address
              ? handleclaimAllAvailableInfluencerRewards()
              : handleOpenModal()
            : handleInviteClick()
          : isUserInfluencerRequestPending
          ? () => {}
          : handleOpenInfluencersApplication(),
    },
  ];
  const [tonConnectUI] = useTonConnectUI();

  const handleConnect = () => {
    tonConnectUI.openModal();
    setOpenModal(false);
  };

  return (
    <div className="flex flex-col gap-4 py-4 ">
      {confettiActive && (
        <Confetti
          className="z-[999]"
          width={confettiDimensions.width}
          height={confettiDimensions.height}
          numberOfPieces={300}
          recycle={false}
          gravity={0.3}
        />
      )}
      {tasks.map((task, index) => {
        return (
          <InviteBonuse
            index={index}
            taskDescription={task.taskDescription}
            taskDescription2={task.taskDescription2}
            eligibleClaim={task.eligibleClaim}
            claimFunc={task.claimFunc}
            multiplier={task.multiplier}
            regularReferralsAvailable={task.regularReferralsAvailable}
            userEarnFromRefs={task.userEarnFromRefs}
            isUserInfluencer={task.isUserInfluencer}
            isUserInfluencerRequestPending={task.isUserInfluencerRequestPending}
            revShareInfluencer={task.revShareInfluencer}
            toClaimBalanceInfluencer={task.toClaimBalanceInfluencer}
            totalInfluencerBalance={task.totalInfluencerBalance}
            expandPopup={expandPopup}
          />
        );
      })}

      {isOpenInfluencersApplication ? (
        <InfluencersApplication
          onCancel={() => {
            setIsOpenInfluencersApplication(false);
            window.location.hash = '';
          }}
          onSubmit={handleFillInfluencersApplication}
        />
      ) : null}
      {openModal && (
        <div
          className="absolute inset-0 bg-black opacity-60 z-[55]"
          onClick={() => setOpenModal(false)}
        ></div>
      )}
      {openModal && (
        <div
          className={`absolute transform transition-transform duration-200 z-[999] left-0 right-0 p-5 h-[282px] w-full 
  -bottom-[282px] bg-no-repeat bg-[length:100%_100%] bg-[url('./assets/images/shop-buy-modal-background.png')] 
  ${openModal ? "-translate-y-full" : "translate-y-0"}`}
        >
          <button
            className="absolute z-10 w-5 h-5 top-3.5 right-3.5 flex items-center justify-center"
            onClick={() => setOpenModal(false)}
          >
            <img
              src={require("../../assets/images/shop-modal-close.png")}
              className="w-5 h-5"
              alt=""
            />
          </button>
          <div className="text-[30px] leading-[1.2] mb-2 text-[#19191B] text-center">
            Please connect your wallet to continue
          </div>
          {!address ? (
            <div
              className={`flex justify-center ${!address ? "mt-8" : "mt-2"}`}
            >
              <div className="mt-5">
                <PopupButton type="blue" width="200px" onClick={handleConnect}>
                  {t("profile.connect")}
                </PopupButton>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
