import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveFarmTutorialProgress } from "../../../../../app/features/farmTutoralSlice";
import { AppDispatch, RootState } from "../../../../../app/store";
import { useTelegram } from "../../../../../hooks/useTelegram";
import { TutorialFarmSave, TutorialFarmStage } from "../../../../../interfaces/tutorial";

export let pageName: string;
export let remainingPages: number;

export const PageName: React.FC<{
  pageName: string;
  remainingPages?: number;
}> = ({ pageName, remainingPages }) => {
  const { t } = useTranslation();


  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  }

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );


  return (
    <div className={`w-full h-full`}>
      <div className="h-full flex flex-col ml-5 items-start justify-center">
        <div
          className={`text-center text-amber-100 
            ${remainingPages && remainingPages > 0 ? "text-sm" : "text-base"} 
            leading-[14px] break-words`}
          style={{
            paddingTop: remainingPages && remainingPages > 0 ? "0" : "10px",
          }}
        >
          {pageName}
        </div>
        {remainingPages && remainingPages > 0 ? (
          <div className="text-center text-white text-xs leading-3 break-words">
            {t("dungeon.remainingPages")}: {remainingPages}
          </div>
        ) : null}
      </div>
    </div>
  );
};
